import { TextField } from 'core/uiKit/inputs/TextField';
import React from 'react';
import { InputProps } from 'types/inputs';

export const NAME_NAME = 'name';

/**
 * Наименование.
 *
 * @param props - Пропсы.
 * @returns <TextField/>.
 */
export const Name = (props: InputProps) => {
  // @ts-ignore
  return <TextField label={'Наименование'} {...props} />;
};
