import {
  GOV_NUMBER_NAME,
  GovNumber,
} from 'core/uiKit/preparedInputs/selects/autocompleteAsync/GovNumber';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * ## Рег. номер
 * ### React Final Form Field
 *
 * ###### type = `AutocompleteAsync`
 * ###### name = `govNumber`
 *
 * @param {object} props - Props.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли компонент для редактирования.
 * @param {object} [props.otherProps] - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const GovNumberFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={GOV_NUMBER_NAME}>
      {(props) => {
        return (
          <GovNumber
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { GOV_NUMBER_NAME };
