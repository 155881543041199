import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  CLARIFYING_LOCATION_NAME_NAME,
  ClarifyingLocationName,
} from 'core/uiKit/preparedInputs/textField/ClarifyingLocationName/ClarifyingLocationName';
import React from 'react';

/**
 * Текстовое поле withFF __Адрес__.
 *
 * @param {object} props - Пропсы.
 * @returns {React.ReactElement}
 */
export const ClarifyingLocationNameFF = (props) => {
  return (
    <FieldFF
      {...props}
      name={CLARIFYING_LOCATION_NAME_NAME}
      component={ClarifyingLocationName}
    />
  );
};
