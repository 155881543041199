import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import { QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/Quantity';
import React from 'react';

import { QuantityWithFF } from './QuantityWithFF';

/**
 * Количество.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const QuantityFf = ({ ...otherProps }) => {
  return (
    <FieldFF {...otherProps} component={QuantityWithFF} name={QUANTITY_NAME} />
  );
};
