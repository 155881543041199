import { usePreparation } from 'core/utils/hooks/usePreparation';

import { IS_AVAILABILITY_PAYMENT_TERMINAL_NAME } from './IsAvailabilityPaymentTerminal';

/**
 * Хук перевода значения поля в булев тип.
 *
 */
export const usePreparationIsAvailabilityPaymentTerminal = () => {
  usePreparation(IS_AVAILABILITY_PAYMENT_TERMINAL_NAME, (value) => value > 0);
};
