import { OznLazy } from 'app/pages/registry/ogh/ozn/Ozn';
import { WaterBuildingLazy } from 'app/pages/registry/ogh/yard/WaterBuilding';
import { pathWaterBuilding } from 'app/pages/registry/ogh/yard/WaterBuilding/path.WaterBuilding';
import { pathContainer } from 'app/pages/registry/ogh/сontainer/Container/path.Container';
import { useGetOghType } from 'core/utils/hooks/useGetOghType';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { pathOzn } from '../ozn/Ozn/path.Ozn';
import { AbutmentLazy } from '../yard/Abutment';
import { pathAbutment } from '../yard/Abutment/path.Abutment';
import { BuildingLazy } from '../yard/Building';
import { pathBuilding } from '../yard/Building/path.Building';
import { ContainerOdhLazy } from '../yard/Container';
import { DtsLazy } from '../yard/Dts';
import { pathDts } from '../yard/Dts/path.Dts';
import { EngineerBuildingLazy } from '../yard/EngineerBuilding';
import { pathEngineerBuilding } from '../yard/EngineerBuilding/path.EngineerBuilding';
import { FlowersGardenLazy } from '../yard/FlowersGarden';
import { pathFlowersGarden } from '../yard/FlowersGarden/path.FlowersGarden';
import { FunctionalityLazy } from '../yard/Functionality';
import { pathFunctionality } from '../yard/Functionality/path.Functionality';
import { IceRinkLazy } from '../yard/IceRink';
import { pathIceRink } from '../yard/IceRink/path.IceRink';
import { LawnLazy } from '../yard/Lawn';
import { pathLawn } from '../yard/Lawn/path.Lawn';
import { LittleFormLazy } from '../yard/LittleForm';
import { pathLittleForm } from '../yard/LittleForm/path.LittleForm';
import { OtherTechPlaceLazy } from '../yard/OtherTechPlace';
import { pathOtherTechPlace } from '../yard/OtherTechPlace/path.OtherTechPlace';
import { PlanarStructureLazy } from '../yard/PlanarStructure';
import { pathPlanarStructure } from '../yard/PlanarStructure/path.PlanarStructure';
import { PpiLazy } from '../yard/Ppi';
import { pathPpi } from '../yard/Ppi/path.Ppi';
import { RedBookPlantLazy } from '../yard/RedBookPlant';
import { pathRedBookPlant } from '../yard/RedBookPlant/path.RedBookPlant';
import { ReliefLazy } from '../yard/Relief';
import { pathRelief } from '../yard/Relief/path.Relief';
import { StoragePlaceLazy } from '../yard/StoragePlace';
import { pathStoragePlace } from '../yard/StoragePlace/path.StoragePlace';
import { TreesShrubsLazy } from '../yard/TreesShrubs';
import { pathTreesShrubs } from '../yard/TreesShrubs/path.TreesShrubs';
import { VerticalLandscapingLazy } from '../yard/VerticalLandscaping';
import { pathVerticalLandscaping } from '../yard/VerticalLandscaping/path.VerticalLandscaping';
import { treeOzn } from './tree.Ozn';

/**
 * Router для Объектов озеленения.
 *
 * @returns {JSX.Element}
 */
const OznRoute = () => {
  useEffect(() => {
    BuildingLazy.preload();
    ContainerOdhLazy.preload();
    DtsLazy.preload();
    FlowersGardenLazy.preload();
    FunctionalityLazy.preload();
    LawnLazy.preload();
    LittleFormLazy.preload();
    OtherTechPlaceLazy.preload();
    OznLazy.preload();
    PlanarStructureLazy.preload();
    PpiLazy.preload();
    RedBookPlantLazy.preload();
    ReliefLazy.preload();
    StoragePlaceLazy.preload();
    TreesShrubsLazy.preload();
    WaterBuildingLazy.preload();
    VerticalLandscapingLazy.preload();
    EngineerBuildingLazy.preload();
    AbutmentLazy.preload();
    IceRinkLazy.preload();
  });
  const { id: parentTypeId } = useGetOghType(pathOzn.code);

  return (
    <Routes>
      <Route element={<OznLazy />} path={'/'} exact={true} />
      <Route
        element={
          <PlanarStructureLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathPlanarStructure.path}
      />
      <Route
        element={
          <OtherTechPlaceLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathOtherTechPlace.path}
      />
      <Route
        element={<BuildingLazy tree={treeOzn} parentTypeId={parentTypeId} />}
        path={pathBuilding.path}
      />
      <Route
        element={
          <ContainerOdhLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathContainer.path}
      />
      <Route
        element={
          <StoragePlaceLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathStoragePlace.path}
      />
      <Route
        element={<DtsLazy tree={treeOzn} parentTypeId={parentTypeId} />}
        path={pathDts.path}
      />
      <Route
        element={<PpiLazy tree={treeOzn} parentTypeId={parentTypeId} />}
        path={pathPpi.path}
      />
      <Route
        element={<ReliefLazy tree={treeOzn} parentTypeId={parentTypeId} />}
        path={pathRelief.path}
      />
      <Route
        element={
          <FunctionalityLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathFunctionality.path}
      />
      <Route
        element={<LittleFormLazy tree={treeOzn} parentTypeId={parentTypeId} />}
        path={pathLittleForm.path}
      />
      <Route
        element={<LawnLazy tree={treeOzn} parentTypeId={parentTypeId} />}
        path={pathLawn.path}
      />
      <Route
        element={
          <FlowersGardenLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathFlowersGarden.path}
      />
      <Route
        element={<TreesShrubsLazy tree={treeOzn} parentTypeId={parentTypeId} />}
        path={pathTreesShrubs.path}
      />
      <Route
        element={
          <WaterBuildingLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathWaterBuilding.path}
      />
      <Route
        element={
          <RedBookPlantLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathRedBookPlant.path}
      />
      <Route
        element={
          <VerticalLandscapingLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathVerticalLandscaping.path}
      />
      <Route
        element={
          <EngineerBuildingLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathEngineerBuilding.path}
      />
      <Route
        element={<AbutmentLazy tree={treeOzn} parentTypeId={parentTypeId} />}
        path={pathAbutment.path}
      />

      <Route
        element={<IceRinkLazy parentTypeId={parentTypeId} />}
        path={pathIceRink.path}
      />
    </Routes>
  );
};

export default OznRoute;
