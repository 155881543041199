import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  CORRECTION_ID_NAME,
  CorrectionId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CorrectionId';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * Select FF __Детализация ОТ__.
 *
 * @param props - Пропсы.
 * @returns Select RF.
 */
export const CorrectionIdFF = (props: SelectPropsType) => {
  return (
    <FieldFF {...props} component={CorrectionId} name={CORRECTION_ID_NAME} />
  );
};
