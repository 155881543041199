import { MafTypeLevel1Id } from 'core/uiKit/preparedInputs/selects/simpleSelect/MafTypeLevel1Id';

/**
 * Обертка RF для __Вид МАФ__.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Пропсы инпута.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const MafTypeLevel1IdWithReduxForm = ({ input, ...otherProps }) => {
  return <MafTypeLevel1Id {...input} {...otherProps} />;
};
