import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { parseDate } from 'app/components/card/common/parse';
import { useGetInitialValuesTablesDocuments } from 'app/pages/cardsOgh/components/tables/Documents/hooks/useGetInitialValuesTables';
import { useFilterQueryParamsVersionDefault } from 'app/pages/cardsOgh/components/tables/Versions/Filters/useFilterQueryParamsVersionDefault';
import { OWNER_ID_NAME } from 'core/uiKit/preparedInputs/selects/autocompleteAsync/OwnerId';
import { CATEGORY_TOILET_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CategoryToiletId';
import { CORRECTION_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CorrectionId';
import { DEPARTMENT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/DepartmentId';
import { DETAILS_MODELS_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/DetailsModelsId';
import { DISTRICT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/DistrictId';
import { FORM_VISIT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/FormVisitId';
import { IS_ADAPTED_PEOPLE_LIMITED_MOBILITY_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/IsAdaptedPeopleLimitedMobility';
import { IS_AVAILABILITY_PAYMENT_TERMINAL_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/IsAvailabilityPaymentTerminal';
import { LOCATION_METHOD_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/LocationMethodId';
import { OKRUG_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/Okrug';
import { PUBLIC_TOILET_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/PublicToiletTypeId';
import { ADDRESS_NAME_NAME } from 'core/uiKit/preparedInputs/textField/AddressName/AddressName';
import { CLARIFYING_LOCATION_NAME_NAME } from 'core/uiKit/preparedInputs/textField/ClarifyingLocationName/ClarifyingLocationName';
import { QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/Quantity';

import { useGetTableWorkTimeData } from '../LeftPanel.PublicToilet/Tab.Properties/Table.WorkTime.FF/useGetTableWorkTimeData';

/**
 * Хук useInitialValues.
 *
 * @returns {object} - Возвращает параметры полей по умолчанию.
 * @example
 * useInitialValues()
 */
export const useInitialValuesPublicToilet = () => {
  const { startDate, endDate, attribute, owner_name, belongingOgh } =
    useGetCurrentCard();

  const { filters } = useFilterQueryParamsVersionDefault();
  const objTables = useGetInitialValuesTablesDocuments();
  const workTime = useGetTableWorkTimeData();

  return {
    [ADDRESS_NAME_NAME]: attribute?.[ADDRESS_NAME_NAME],
    [CATEGORY_TOILET_ID_NAME]:
      attribute?.[CATEGORY_TOILET_ID_NAME]?.category_toilet,
    [CLARIFYING_LOCATION_NAME_NAME]: attribute?.[CLARIFYING_LOCATION_NAME_NAME],
    [CORRECTION_ID_NAME]: attribute?.[CORRECTION_ID_NAME]?.correction,
    [DEPARTMENT_ID_NAME]: attribute?.[DEPARTMENT_ID_NAME]?.legal_person,
    [DETAILS_MODELS_ID_NAME]:
      attribute?.[DETAILS_MODELS_ID_NAME]?.details_models,
    [DISTRICT_ID_NAME]: attribute?.[DISTRICT_ID_NAME]?.bti_object,
    [FORM_VISIT_ID_NAME]: attribute?.[FORM_VISIT_ID_NAME]?.form_visit,
    [IS_ADAPTED_PEOPLE_LIMITED_MOBILITY_NAME]: attribute?.[
      IS_ADAPTED_PEOPLE_LIMITED_MOBILITY_NAME
    ]
      ? 1
      : -1,
    [IS_AVAILABILITY_PAYMENT_TERMINAL_NAME]: attribute?.[
      IS_AVAILABILITY_PAYMENT_TERMINAL_NAME
    ]
      ? 1
      : -1,
    [LOCATION_METHOD_ID_NAME]:
      attribute?.[LOCATION_METHOD_ID_NAME]?.location_method,
    [OKRUG_NAME]: attribute?.okrug_id?.bti_object,
    [OWNER_ID_NAME]: {
      id: attribute?.owner_id?.legal_person,
      name: owner_name,
    },
    [PUBLIC_TOILET_TYPE_ID_NAME]:
      attribute?.[PUBLIC_TOILET_TYPE_ID_NAME]?.public_toilet_type,
    [QUANTITY_NAME]: attribute?.[QUANTITY_NAME],
    belonging_ogh: belongingOgh,
    endDate: parseDate(endDate),
    startDate: parseDate(startDate),
    ...filters,
    ...objTables,
    ...workTime,
  };
};
