import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const CORRECTION_ID_NAME = 'correction_id';

/**
 * Select __Детализация (для Общественных туалетов)__.
 *
 * @param props - Пропсы.
 * @returns Select Детализация.
 */
export const CorrectionId = (props: SelectPropsType) => {
  const dict = 'correction';

  const { data } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={data || []}
      label={'Детализация'}
      name={CORRECTION_ID_NAME}
    />
  );
};
