import { MafTypeLevel1IdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/MafTypeLavel1Id.RF';
import React from 'react';

/**
 * Поле __Вид МАФ__.
 *
 * @param {object} props - Пропсы.
 * @param {Function} props.getQuantityCharacteristicsProps - Функция getQuantityCharacteristicsProps.
 * @param {Function} props.changeFieldValue - Функция изменения значений полей redux form.
 * @param {number} props.parentTypeId - Тип родительского ОГХ.
 * @param {Array} props.mafTypeLevel1 - Массив справочных значений.
 * @returns {JSX.Element}
 */
export const MafTypeLevel1RFWrapper = ({ parentTypeId, ...otherProps }) => {
  return (
    <MafTypeLevel1IdRF
      {...otherProps}
      filter={(dict) => {
        return parentTypeId
          ? (dict || [])?.filter(({ parent_ogh_object_type_list = [] }) =>
              parent_ogh_object_type_list?.includes(parentTypeId),
            )
          : dict;
      }}
    />
  );
};
