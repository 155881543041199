import {
  AddButtonOgh,
  Body,
  DialogCreateWithDXF,
  Header,
  Main,
  RegistryToolbar,
} from 'app/pages/components';
import { FilterPT } from 'app/pages/registry/ogh/PT/Filter.PT';
import { PrintButtonPt } from 'app/pages/registry/ogh/PT/PrintButton.PT';
import { ReportsButtonPT } from 'app/pages/registry/ogh/PT/Reports.Button.PT';
import { TablePT } from 'app/pages/registry/ogh/PT/Table.PT';
import { useGetOghTypes } from 'core/utils/hooks';
import React from 'react';

/**
 * Реестр __Публичные туалеты__.
 *
 * @returns Реестр публичных туалетов.
 */
const PT = () => {
  const oghType = useGetOghTypes('public_toilet');
  const typeId = oghType?.id;

  return typeId ? (
    <Main>
      <Header Filter={FilterPT} />
      <Body>
        <RegistryToolbar title={oghType.name}>
          <AddButtonOgh typeCode={oghType.code} />
          <PrintButtonPt {...{ typeId }} />
          <ReportsButtonPT />
        </RegistryToolbar>
        <TablePT typeId={typeId} />
        {/*@ts-ignore*/}
        <DialogCreateWithDXF typeId={typeId} />
      </Body>
    </Main>
  ) : null;
};

export default PT;
