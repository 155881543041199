import { TextField } from 'core/uiKit/inputs/TextField';
import React from 'react';

export const CLARIFYING_LOCATION_NAME_NAME = 'сlarifying_location_name';

/**
 * Текстовое поле __Уточнение местоположения__.
 *
 * @param {object} props - Пропсы.
 * @returns {React.ReactElement}
 */
export const ClarifyingLocationName = (props) => {
  return (
    <TextField
      {...props}
      label={'Уточнение местоположения'}
      name={CLARIFYING_LOCATION_NAME_NAME}
    />
  );
};
