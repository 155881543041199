import { Cell } from 'types/table';

/**
 * Колонка __Назначение__.
 *
 * @type {{
 * accessor: 'bridge_type_level2_name',
 * Header: 'Назначение',
 * sortable: boolean
 * }}
 * @augments Cell
 */
export const bridge_type_level2_name = {
  Header: 'Назначение',
  accessor: 'bridge_type_level2_name',
  sortable: true,
};
