import {
  ANNUAL_VASE_AREA,
  ANNUALS_AREA,
  APOTHECARY_GARDEN,
  BIENNIALS_AREA,
  BULBOUS_AREA,
  CARPET_PLANT_AREA,
  FLINDERS_AREA,
  FLOOR_VERTICAL_DESIGN_AREA,
  FLOOR_VERTICAL_DESIGN_QTY,
  FLOWERS_VERTICAL_DESIGN_AREA,
  FLOWERS_VERTICAL_DESIGN_QTY,
  GREEN_NUM,
  INERT_AREA,
  INERT_OTHER_AREA,
  INERT_STONE_CHIPS_AREA,
  INERT_TREE_BARK_AREA,
  LAWN_AREA,
  PERENNIALS_AREA,
  ROCKERY_AREA,
  SEASON_SHIFT_AREA,
  SECTION_NUM,
  SHRUBS_AREA,
  SUSPEND_VERTICAL_DESIGN_AREA,
  SUSPEND_VERTICAL_DESIGN_QTY,
  TOTAL_AREA,
  TULIP_AREA,
  VASE_QTY,
} from 'app/constants/fieldNames';
import { SHRUBS_HINT } from 'app/constants/messages';
import { IMPROVEMENT_OBJECT, OZN, SPA, YARD } from 'app/constants/oghTypes';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TOTAL_AREA_GEO_NAME } from 'core/uiKit/preparedInputs/numberField/TotalAreaGeo';
import React from 'react';

import { useSetupCharacteristicsTab } from './useSetup.CharacteristicsTab';

/**
 * Проверка на отображение полей.
 *
 * @param {Array} arr - Массив допустимых родительских паспортов.
 * @param {number} parentTypeId - Текущий тип родительского паспорта.
 * @returns {boolean}
 */
const getHideField = (arr = [], parentTypeId = 0) => {
  const isParent = [IMPROVEMENT_OBJECT, OZN, SPA, YARD].includes(parentTypeId);
  return isParent && !arr.includes(parentTypeId);
};

/**
 * Таб Цветников __Исходные данные__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const CharacteristicsTab = (props) => {
  const {
    editMode,
    isEditCurrentObjectIsExternalSystem,
    isCurrentObjectIsExternalSystem,
    parentTypeId,
    required,
    setCalculatedField,
  } = useSetupCharacteristicsTab(props);

  return (
    <FieldsSplitColumns>
      <NumberFieldRF
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        label={'№ участка'}
        name={SECTION_NUM}
        required={true}
        maxLength={3}
      />
      <NumberFieldRF
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        label={'№ цветника'}
        name={GREEN_NUM}
        required={required}
        maxLength={15}
        hidden={getHideField(
          [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
          parentTypeId,
        )}
      />
      <NumberFieldRF
        disabled={true}
        label={'Общая площадь цветника, кв.м'}
        name={TOTAL_AREA}
        decimalScale={2}
        maxLength={16}
        hidden={getHideField(
          [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
          parentTypeId,
        )}
      />
      <NumberFieldRF
        disabled={true}
        label={'Площадь объекта по пространственным координатам, кв.м.'}
        name={TOTAL_AREA_GEO_NAME}
        decimalScale={2}
        maxLength={16}
        hidden={getHideField(
          [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
          parentTypeId,
        )}
      />
      <NumberFieldRF
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        label={'Однолетники, кв.м'}
        name={ANNUALS_AREA}
        decimalScale={2}
        maxLength={11}
        hidden={getHideField(
          [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
          parentTypeId,
        )}
        withChange={(value) => {
          setCalculatedField(TOTAL_AREA, ANNUALS_AREA, value);
          setCalculatedField(SEASON_SHIFT_AREA, ANNUALS_AREA, value);
        }}
      />
      <NumberFieldRF
        disabled={!editMode}
        label={'Однолетники в вазонах, кв.м'}
        name={ANNUAL_VASE_AREA}
        decimalScale={2}
        maxLength={11}
        hidden={getHideField([SPA], parentTypeId)}
      />
      <NumberFieldRF
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        label={
          SPA === parentTypeId
            ? 'Кустовые и корневищные многолетники, кв.м'
            : 'Многолетники и розы, кв.м'
        }
        name={PERENNIALS_AREA}
        decimalScale={2}
        maxLength={11}
        hidden={getHideField(
          [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
          parentTypeId,
        )}
        withChange={(value) => {
          setCalculatedField(TOTAL_AREA, PERENNIALS_AREA, value);
          setCalculatedField(SEASON_SHIFT_AREA, PERENNIALS_AREA, value);
        }}
      />
      <NumberFieldRF
        disabled={!([YARD].includes(parentTypeId) && editMode)}
        label={'Инертный материал (отсыпка), кв.м'}
        name={INERT_AREA}
        decimalScale={2}
        maxLength={11}
        hidden={getHideField(
          [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
          parentTypeId,
        )}
        withChange={(value) => {
          setCalculatedField(TOTAL_AREA, INERT_AREA, value);
          setCalculatedField(SEASON_SHIFT_AREA, INERT_AREA, value);
        }}
      />
      <NumberFieldRF
        disabled={
          !(
            [OZN].includes(parentTypeId) &&
            editMode &&
            !isCurrentObjectIsExternalSystem
          )
        }
        label={'Инертный материал (отсыпка), каменная крошка, кв.м'}
        name={INERT_STONE_CHIPS_AREA}
        decimalScale={2}
        maxLength={11}
        hidden={getHideField([OZN, YARD], parentTypeId)}
        withChange={(value) => {
          switch (parentTypeId) {
            case OZN: {
              setCalculatedField(INERT_AREA, INERT_STONE_CHIPS_AREA, value);
              break;
            }
            default: {
              break;
            }
          }
        }}
      />
      <NumberFieldRF
        disabled={
          !(
            [OZN].includes(parentTypeId) &&
            editMode &&
            !isCurrentObjectIsExternalSystem
          )
        }
        label={'Инертный материал (отсыпка), древесная кора (щепа), кв.м'}
        name={INERT_TREE_BARK_AREA}
        decimalScale={2}
        maxLength={11}
        hidden={getHideField([OZN, YARD], parentTypeId)}
        withChange={(value) => {
          switch (parentTypeId) {
            case OZN: {
              setCalculatedField(INERT_AREA, INERT_TREE_BARK_AREA, value);
              break;
            }
            default: {
              break;
            }
          }
        }}
      />
      <NumberFieldRF
        disabled={
          !(
            [OZN].includes(parentTypeId) &&
            editMode &&
            !isCurrentObjectIsExternalSystem
          )
        }
        label={'Инертный материал (отсыпка), иное, кв.м'}
        name={INERT_OTHER_AREA}
        decimalScale={2}
        maxLength={11}
        hidden={getHideField([OZN, YARD], parentTypeId)}
        withChange={(value) => {
          switch (parentTypeId) {
            case OZN: {
              setCalculatedField(INERT_AREA, INERT_OTHER_AREA, value);
              break;
            }
            default: {
              break;
            }
          }
        }}
      />
      <NumberFieldRF
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        label={'Ковровые растения, кв.м'}
        name={CARPET_PLANT_AREA}
        decimalScale={2}
        maxLength={11}
        hidden={getHideField([SPA], parentTypeId)}
        withChange={(value) => {
          setCalculatedField(TOTAL_AREA, CARPET_PLANT_AREA, value);
        }}
      />
      <NumberFieldRF
        disabled={!editMode}
        label={'Декоративные кустарники (как элемент цветника), кв.м'}
        name={SHRUBS_AREA}
        helperText={SHRUBS_HINT}
        decimalScale={2}
        maxLength={11}
        hidden={getHideField(
          [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
          parentTypeId,
        )}
        withChange={(value) => {
          setCalculatedField(TOTAL_AREA, SHRUBS_AREA, value);
          setCalculatedField(SEASON_SHIFT_AREA, SHRUBS_AREA, value);
        }}
      />
      <NumberFieldRF
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        label={'Аптекарский огород, кв.м'}
        name={APOTHECARY_GARDEN}
        decimalScale={2}
        maxLength={11}
        hidden={getHideField([SPA], parentTypeId)}
        withChange={(value) => {
          setCalculatedField(TOTAL_AREA, APOTHECARY_GARDEN, value);
        }}
      />
      <NumberFieldRF
        disabled={true}
        label={'С учетом сезонной сменности, кв.м'}
        name={SEASON_SHIFT_AREA}
        decimalScale={2}
        maxLength={16}
        hidden={getHideField(
          [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
          parentTypeId,
        )}
      />
      <NumberFieldRF
        disabled={!editMode}
        label={'Луковичные и клубнелуковичные кроме тюльпанов, нарциссов, кв.м'}
        name={BULBOUS_AREA}
        decimalScale={2}
        maxLength={11}
        hidden={getHideField(
          [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
          parentTypeId,
        )}
        withChange={(value) => {
          setCalculatedField(SEASON_SHIFT_AREA, BULBOUS_AREA, value);
        }}
      />
      <NumberFieldRF
        disabled={!editMode}
        label={'Тюльпаны, нарциссы, кв.м'}
        name={TULIP_AREA}
        decimalScale={2}
        maxLength={11}
        hidden={getHideField(
          [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
          parentTypeId,
        )}
        withChange={(value) => {
          setCalculatedField(SEASON_SHIFT_AREA, TULIP_AREA, value);
        }}
      />
      <NumberFieldRF
        disabled={!editMode}
        label={'Двулетники (виола), кв.м'}
        name={BIENNIALS_AREA}
        decimalScale={2}
        maxLength={11}
        hidden={getHideField(
          [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
          parentTypeId,
        )}
        withChange={(value) => {
          setCalculatedField(SEASON_SHIFT_AREA, BIENNIALS_AREA, value);
        }}
      />
      <NumberFieldRF
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        label={'Вазоны, шт.'}
        name={VASE_QTY}
        decimalScale={2}
        maxLength={11}
        hidden={getHideField([IMPROVEMENT_OBJECT, SPA], parentTypeId)}
      />
      <NumberFieldRF
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        label={'Напольные вертикальные конструкции, кв.м'}
        name={FLOOR_VERTICAL_DESIGN_AREA}
        decimalScale={2}
        maxLength={11}
        hidden={getHideField([IMPROVEMENT_OBJECT, SPA], parentTypeId)}
      />
      <NumberFieldRF
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        label={'Напольные вертикальные конструкции, шт.'}
        name={FLOOR_VERTICAL_DESIGN_QTY}
        maxLength={11}
        hidden={getHideField([IMPROVEMENT_OBJECT, SPA], parentTypeId)}
      />
      <NumberFieldRF
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        label={'Подвесные вертикальные конструкции, кв.м'}
        name={SUSPEND_VERTICAL_DESIGN_AREA}
        decimalScale={2}
        maxLength={11}
        hidden={getHideField([IMPROVEMENT_OBJECT, SPA], parentTypeId)}
      />
      <NumberFieldRF
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        label={'Подвесные вертикальные конструкции, шт.'}
        name={SUSPEND_VERTICAL_DESIGN_QTY}
        maxLength={11}
        hidden={getHideField([IMPROVEMENT_OBJECT, SPA], parentTypeId)}
      />
      <NumberFieldRF
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        label={'Вертикальные конструкции. Иные элементы, кв.м'}
        name={FLOWERS_VERTICAL_DESIGN_AREA}
        decimalScale={2}
        maxLength={11}
        hidden={getHideField([IMPROVEMENT_OBJECT, SPA], parentTypeId)}
      />
      <NumberFieldRF
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        label={'Вертикальные конструкции. Иные элементы, шт.'}
        name={FLOWERS_VERTICAL_DESIGN_QTY}
        maxLength={11}
        hidden={getHideField([IMPROVEMENT_OBJECT, SPA], parentTypeId)}
      />
      <NumberFieldRF
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        label={'Декорирование щепой, кв.м'}
        name={FLINDERS_AREA}
        maxLength={11}
        decimalScale={2}
        hidden={getHideField(
          [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
          parentTypeId,
        )}
      />
      <NumberFieldRF
        disabled={!([YARD].includes(parentTypeId) && editMode)}
        label={'Рокарий (многолетние, однолетние), кв.м'}
        name={ROCKERY_AREA}
        maxLength={11}
        withChange={(value) => {
          setCalculatedField(TOTAL_AREA, ROCKERY_AREA, value);
        }}
        decimalScale={2}
        hidden={getHideField([OZN, YARD], parentTypeId)}
      />
      <NumberFieldRF
        disabled={!editMode}
        label={'Газоны (как элемент цветника), кв.м'}
        name={LAWN_AREA}
        maxLength={11}
        decimalScale={2}
        withChange={(value) => {
          setCalculatedField(TOTAL_AREA, LAWN_AREA, value);
        }}
        hidden={getHideField([OZN, YARD], parentTypeId)}
      />
    </FieldsSplitColumns>
  );
};
