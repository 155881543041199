import { SubTypeIdFilter } from 'core/form/filterForm/components/autocompleteAsync/SubTypeId.Filter';
import React from 'react';

import { useSetupSubTypeIdFilterField } from './useSetup.SubTypeId.Filter.Field';

export { subTypeIdParams } from 'core/form/filterForm/components/autocompleteAsync/SubTypeId.Filter';

/**
 * Фильтр __Тип__.
 *
 * @returns {JSX.Element}
 */
export const SubTypeIdFilterField = () => {
  const { disabled, odsType, odsParentType } = useSetupSubTypeIdFilterField();
  return <SubTypeIdFilter {...{ disabled, odsParentType, odsType }} />;
};
