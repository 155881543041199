import { useGetDictionary } from 'app/api/hooks/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const DISTRICT_BRIDGE_ID_NAME = 'district_bridge_id';

/**
 * Select __Район__.
 *
 * @param {object} props - Пропсы.
 * @returns Select.
 */
export const DistrictBridgeId = (props: SelectPropsType) => {
  const dict = 'okrug_bridge';
  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      {...{
        label: 'Район',
        name: DISTRICT_BRIDGE_ID_NAME,
        options: data,
      }}
    />
  );
};
