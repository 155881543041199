import './select.scss';

import cn from 'classnames';
import {
  FormControl,
  FormHelperText,
  Icon,
  Input,
  InputLabel,
  MenuItem,
  SelectMUI,
  Tooltip,
} from 'core/uiKit/material-ui';
import React from 'react';

import { useSetupSelect } from './useSetup.Select';

/**
 * Компонент селекта.
 *
 * @param {object} props - Пропсы.
 * @param {string} [props.className] - Класс.
 * @param {boolean} [props.dark] - Флаг переключения темя.
 * @param {string} [props.errorText] - Текст ошибки.
 * @param {string|React.Element} [props.helperText] - Текс помощи.
 * @param {string} [props.label] - Лейбл.
 * @param {boolean} [props._multiple] - Multiple.
 * @param {*} [props.optionKey] - Ключ.
 * @param {*} [props.optionName] - OptionName.
 * @param {Array} [props.options] - Options.
 * @param {string|number} [props.value] - Значение.
 * @param {*} [props.meta] - Meta.
 * @param {boolean} [props.required] - Флаг обязательности поля.
 * @param {Function} [props.onChange] - Функция изменения значения.
 * @param {Function} [props.withChange] - Функция изменения других полей.
 * @param {boolean} [props.disabled] - Флаг редактирования.
 * @param {*} [props.native] - Native.
 * @param {*} [props.withAll] - WithAll.
 * @param {string} [props.name] - Имя.
 * @param {string} [props.placeholder] - Плейсхолдер.
 * @param {Function} [props.filter] - Функция фильтрации.
 * @param {string|React.Element} [props.helpTitle] - Вспомогательный текст (подсказка).
 * @param {boolean} [props.clear] - Очистка.
 * @returns {React.ReactComponentElement} - ReactComponentElement.
 * @example
 * <Select {...props}/>
 */
const Select = ({
  className,
  _multiple = false,
  value,
  meta = {},
  withChange,
  native = false, // для тестов
  withAll = true,
  filter,
  helpTitle,
  dark = false,
  disabled = false,
  errorText = '',
  helperText = '',
  label = 'select label',
  name = 'select',
  onChange = (_) => _,
  optionKey = 'id',
  optionName = 'name',
  options = [],
  placeholder = '',
  required = false,
  clear = true,
  ...otherProps
}) => {
  const { customValue, error, getFilteredOptions, handleChange, isError } =
    useSetupSelect({
      _multiple,
      disabled,
      errorText,
      filter,
      meta,
      onChange,
      optionKey,
      optionName,
      options,
      value,
      withChange,
    });

  const selectAttributes = {
    multiple: _multiple,
    value: customValue,
    ...(clear && { clear: 'true' }),
    ...(dark && { dark: true }),
    ...(dark === false && { dark: 'false' }),
    placeholder,
    ...otherProps,
  };

  return (
    <FormControl
      {...{
        className,
        style: { width: '100%' },
      }}
      disabled={disabled}
      error={isError}
      required={required}
      variant="standard"
    >
      <InputLabel
        id={name}
        className={cn('select__label', { label: label.length > 30 })}
      >
        {label}
        {helpTitle ? (
          <Tooltip title={helpTitle}>
            <Icon className={'ml-1'} style={{ fontSize: 14 }}>
              help_outline
            </Icon>
          </Tooltip>
        ) : null}
      </InputLabel>
      <SelectMUI
        {...selectAttributes}
        name={name}
        labelId={name}
        role="select"
        input={<Input />}
        native={native}
        onChange={handleChange}
        SelectDisplayProps={{ 'data-testid': name }}
      >
        {withAll && (
          <MenuItem role="menuitem" key={'all'} value={''}>
            Все
          </MenuItem>
        )}

        {getFilteredOptions().map((item, index) => (
          <MenuItem
            disabled={item.is_archive}
            role="menuitem"
            key={index}
            value={item[optionKey]}
          >
            {item[optionName]}
          </MenuItem>
        ))}
      </SelectMUI>
      <FormHelperText>{error || errorText || helperText} &nbsp;</FormHelperText>
    </FormControl>
  );
};

export { Select };
