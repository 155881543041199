import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const IS_AVAILABILITY_PAYMENT_TERMINAL_NAME =
  'is_availability_payment_terminal';

const options = [
  { id: 1, name: 'Да' },
  { id: -1, name: 'Нет' },
];

/**
 * Select __Наличие терминала оплаты__.
 *
 * @param props - Пропсы.
 * @returns Select Наличие терминала оплаты.
 */
export const IsAvailabilityPaymentTerminal = (props: SelectPropsType) => {
  return (
    <Select
      {...props}
      options={options}
      label={'Наличие терминала оплаты'}
      name={IS_AVAILABILITY_PAYMENT_TERMINAL_NAME}
    />
  );
};
