import {
  PROVIDER_LIST_ID_NAME,
  ProviderListId,
} from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * ## Поставщик телеметрических услуг
 * ### React Final Form Field
 *
 * ###### type = `AutocompleteMultiAsync`
 * ###### name = `providerListId`
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.disabled] - Определяет, будет ли компонент заблокирован.
 * @param {object} [props.otherProps] - Другие пропсы.
 * @returns {JSX.Element}
 */
export const ProviderListIdFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={PROVIDER_LIST_ID_NAME}>
      {(props) => {
        return (
          <ProviderListId
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { PROVIDER_LIST_ID_NAME };
