import { OKRUG_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/Okrug';
import { usePreparation } from 'core/utils/hooks/usePreparation';

/**
 * Хук перевода поля OKRUG_NAME.
 *
 */
export const usePreparationOkrugList = () => {
  usePreparation(
    OKRUG_NAME,
    (value) => {
      if (value) {
        return { bti_object: value };
      }
    },
    'okrug_id',
  );
};
