import { Select } from 'core/uiKit/inputs/selects/Select';

import { useSetupMafTypeLevel2 } from './useSetup.MafTypeLevel2';

/**
 * @typedef {'maf_type_level2_id'} MafTypeLevel2Name
 */

/**
 * Name inputs. Уточнение.
 *
 * @constant
 * @type {MafTypeLevel2Name}
 */
export const MAF_TYPE_LEVEL_2_ID_NAME = 'maf_type_level2_id';

/**
 * Select Уточнение.
 *
 * @param {object} props - Properties.
 * @param {string} [props.label] - Лейбл.
 * @param {object} props.otherProps - Properties.
 * @returns {JSX.Element} - JSX.
 * @example --
 */
export const MafTypeLevel2 = ({ label = 'Уточнение', ...otherProps }) => {
  const { isLoading, options } = useSetupMafTypeLevel2();

  return (
    <Select
      {...otherProps}
      {...{
        isLoading,
        label,
        name: MAF_TYPE_LEVEL_2_ID_NAME,
        options,
      }}
    />
  );
};
