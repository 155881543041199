import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

import { usePreloaderCard } from '../../components/PreloadedCard/usePreloaderCard';
import { useGetSavingFn } from './useGetSavingFn';
import { useOnErrorSaveCard } from './useOnError.SaveCard';

/**
 * Хук, получения функции сохранения паспортов.
 *
 * @param {object} root0 - Properties.
 * @param {Function} root0.showAlert - Показывает предупреждение.
 * @returns Мутации.
 */
export const useSaveCardMuttanion = ({
  showAlert,
}: {
  showAlert: (message: string | React.ReactElement) => void;
}) => {
  const { endPreload } = usePreloaderCard();
  const saving = useGetSavingFn({ showAlert });
  const onError = useOnErrorSaveCard({ showAlert });

  const [save, { isLoading }] = useMutationAdaptor(saving, {
    onError,
    onSuccess: endPreload,
  });

  return { isLoading, saveCard: save };
};
