import { IS_DIFF_HEIGHT_MARK_NAME } from 'core/form/finalForm/fields/prepared/checkboxes/IsDiffHeightMark.FF';
import { NO_CALC_NAME } from 'core/uiKit/preparedInputs/checkboxes/NoCalc';
import { TOTAL_AREA_GEO_NAME } from 'core/uiKit/preparedInputs/numberField/TotalAreaGeo';
import { CLEAN_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleanTypeId';
import { COATING_GROUP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';
import { COATING_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingTypeId';
import { ICE_RINK_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/IceRinkTypeId';

import { getTransform } from '../helpers/Transform';

export const mapAttribute = [
  getTransform(ICE_RINK_TYPE_ID_NAME, 'ice_rink_type', null),
  getTransform(CLEAN_TYPE_ID_NAME, 'clean_type', 1),
  getTransform(COATING_TYPE_ID_NAME, 'coating', 60),
  getTransform(COATING_GROUP_ID_NAME, 'coating_group', 2),
  getTransform(TOTAL_AREA_GEO_NAME, null, null),
  getTransform(IS_DIFF_HEIGHT_MARK_NAME, null, null),
  getTransform(NO_CALC_NAME, null, null),
  getTransform('total_area', null, null),
];
