import { ListDataType } from 'app/pages/cardsOgh/components/RightPanelCard/components/DialogEditButton/listDataType';
import { FilterFF } from 'core/form/filterForm/FilterFF';
import { preparationValues } from 'core/utils/helpers/preparationValues';
import React from 'react';

import { filtersMap } from './filtersMap';

interface FilterDialogEditButtonType {
  handleRegistryFormSubmit: (formValues: object) => void;
  listData: ListDataType;
}

/**
 * Компонент фильтрации таблицы изменений.
 *
 * @param {object} params - Параметры.
 * @param {Function} params.handleRegistryFormSubmit - Функция сабмита формы фильтрации.
 * @param {ListDataType} params.listData - Данные списка.
 * @returns Возвращает компонент фильтрации.
 */
export const FilterDialogEditButton = ({
  handleRegistryFormSubmit,
  listData,
}: FilterDialogEditButtonType) => {
  const Filter = filtersMap(listData.typeId);

  /**
   * Функция сброса значений.
   *
   * @param {object} params - Параметры формы.
   * @param {Function} params.reset - Функция сброса значений.
   */
  const reset = ({ reset }: { reset: () => void }) => {
    reset();
    handleRegistryFormSubmit({});
  };

  /**
   * Функция onsubmit.
   *
   * @param {object} values - Значения формы.
   */
  const onSubmit = (values: object) => {
    // @ts-ignore
    const preparationVales = preparationValues(values);
    handleRegistryFormSubmit(preparationVales);
  };

  return (
    <FilterFF onSubmit={onSubmit} initialValues={{}} reset={reset}>
      <Filter />
    </FilterFF>
  );
};
