import { Validator } from 'core/form/Validator';
import { OWNER_ID_NAME } from 'core/uiKit/preparedInputs/selects/autocompleteAsync/OwnerId';
import { BRIDGE_STATE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/BridgeStateId';
import { BRIDGE_TYPE_LEVEL_1_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/BridgeTypeLevel1Id';
import { BRIDGE_TYPE_LEVEL_2_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/BridgeTypeLevel2Id';
import { CLEAN_CATEGORY_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleanCategoryId';
import { DISTRICT_BRIDGE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/DistrictBridgeId';
import { OBJECT_SERVICE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/ObjectServiceId';
import { OKRUG_BRIDGE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/OkrugBridgeId';
import { ADDRESS_NAME } from 'core/uiKit/preparedInputs/textField/Address';
import { NAME_NAME } from 'core/uiKit/preparedInputs/textField/Name';
import { SHORT_NAME_NAME } from 'core/uiKit/preparedInputs/textField/ShortName';

/**
 * Функция валидации формы Мостов.
 *
 * @param values - Значения формы.
 * @returns Функция валидации.
 */
export const validateFormBridge = (values: Record<string, unknown>) => {
  const validator = new Validator(values);

  validator.setRequired(NAME_NAME);
  validator.setRequired(SHORT_NAME_NAME);
  validator.setRequired(BRIDGE_TYPE_LEVEL_1_ID_NAME);
  validator.setRequired(BRIDGE_TYPE_LEVEL_2_ID_NAME);
  validator.setRequired(CLEAN_CATEGORY_ID_NAME);
  validator.setRequired(ADDRESS_NAME);
  validator.setRequired(OKRUG_BRIDGE_ID_NAME);
  validator.setRequired(DISTRICT_BRIDGE_ID_NAME);
  validator.setRequired(BRIDGE_STATE_ID_NAME);
  validator.setRequired(OBJECT_SERVICE_ID_NAME);
  validator.setRequired('area');
  validator.setRequired('distance');

  if (values[OBJECT_SERVICE_ID_NAME] !== 2) {
    validator.setRequired(OWNER_ID_NAME);
    validator.setRequired('cad_number');
    validator.setRequired('get_balance_date');
    validator.setRequired('exploitation_date');
  }

  return validator.getErrors();
};
