import { deepGet } from 'app/utils/helpers/deepGet';
import { deepSet } from 'app/utils/helpers/deepSet';

/**
 * Создаёт объект трансформации для работы с полями формы и данными для бэкенда.
 * Функция возвращает объект с методами для преобразования данных между формой и бэкендом.
 *
 * @param name - Имя поля, которое будет использоваться в ключе.
 * @param path - Путь к значению в объекте, который будет использоваться для получения и установки значения.
 * @param defaultValue - Значение по умолчанию, которое используется, если значение не найдено.
 * @returns Объект с методами `toForm` и `toBackend` для преобразования данных.
 *
 * @example
 * const transform = getTransform('username', 'user.name', 'Default Name');
 * const formValue = transform.toForm({ user: { name: 'John' } }); // 'John'
 * const backendValue = transform.toBackend({ user: { name: 'John' } }); // { user: { name: 'John' } }
 */
export const getTransform = (
  name: string,
  path: string | null,
  defaultValue: unknown | null,
) => {
  return {
    key: name,

    /**
     * Преобразует данные из формы для отправки на бэк.
     *
     * @param value - Данные из формы.
     * @returns Преобразованные данные для бэка.
     */
    toBackend: (value: Record<string, unknown>) => deepSet(path, value),

    /**
     * Преобразует данные с бэка для отображения в форме.
     *
     * @param value - Данные с бэка.
     * @returns Преобразованные данные для формы.
     */
    toForm: (value: Record<string, unknown>) =>
      deepGet(value, path, defaultValue),
  };
};

/**
 * Абстрактный класс для преобразования данных с бэка в формат для формы и обратно.
 */
export abstract class BaseTransform {
  abstract readonly key: string;
  abstract toBackend(data: unknown): unknown;
  abstract toForm(data: unknown): unknown;
}

/**
 * Класс для преобразования данных с бэка в формат для формы и обратно.
 * Позволяет изменять функции преобразования через метод `setTransform`.
 *
 * @template TypeInputValue Тип данных для формы.
 * @template TypeBackendValue Тип данных с бэка.
 */
export class Transform<TypeInputValue, TypeBackendValue> extends BaseTransform {

  /** Ключ для инпута, который не изменяется после создания экземпляра. */
  readonly key: string;

  /** Приватное поле для функции преобразования данных из формы для отправки на бэк. */
  #toForm: (data: TypeBackendValue) => TypeInputValue;

  /** Приватное поле для функции преобразования данных с бэка для формы. */
  #toBackend: (data: TypeInputValue) => TypeBackendValue;

  /**
   * Конструктор для создания экземпляра трансформера.
   *
   * @param {string} key - Ключ для инпута.
   * @param {(data: TypeInputValue) => TypeBackendValue} toBackend - Функция преобразования данных из формы для отправки на бэк.
   * @param {(data: TypeBackendValue) => TypeInputValue} toForm - Функция преобразования данных с бэка для формы.
   */
  constructor(
    key: string,
    toBackend: (data: TypeInputValue) => TypeBackendValue,
    toForm: (data: TypeBackendValue) => TypeInputValue,
  ) {
    super();
    this.key = key;
    this.#toBackend = toBackend;
    this.#toForm = toForm;
  }

  /**
   * Преобразует данные из формы для отправки на бэк.
   *
   * @param {TypeInputValue} data - Данные из формы.
   * @returns {TypeBackendValue} Преобразованные данные для бэка.
   */
  toBackend(data: TypeInputValue): TypeBackendValue {
    return this.#toBackend(data);
  }

  /**
   * Преобразует данные с бэка для отображения в форме.
   *
   * @param {TypeBackendValue} data - Данные с бэка.
   * @returns {TypeInputValue} Преобразованные данные для формы.
   */
  toForm(data: TypeBackendValue): TypeInputValue {
    return this.#toForm(data);
  }

  /**
   * Устанавливает новые функции для преобразования данных.
   *
   * @param {object} options - Объект с функциями преобразования.
   * @param {(data: TypeInputValue) => TypeBackendValue} [options.toBackend] - Новая функция для преобразования данных из формы для бэка.
   * @param {(data: TypeBackendValue) => TypeInputValue} [options.toForm] - Новая функция для преобразования данных с бэка для формы.
   */
  setTransform(options: {
    toBackend?: (data: TypeInputValue) => TypeBackendValue;
    toForm?: (data: TypeBackendValue) => TypeInputValue;
  }): void {
    if (options.toBackend) this.#toBackend = options.toBackend;
    if (options.toForm) this.#toForm = options.toForm;
  }
}
