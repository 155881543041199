import { Cell } from 'types/table';

/**
 * Колонка __Категория по уборке__.
 *
 * @type {{
 * accessor: 'clean_category_name',
 * Header: 'Категория по уборке',
 * sortable: boolean
 * }}
 * @augments Cell
 */
export const clean_category_name = {
  Header: 'Категория по уборке',
  accessor: 'clean_category_name',
  sortable: true,
};
