import { Validator } from 'core/form/Validator';
import moment from 'moment';

/**
 * Функция валидации.
 *
 * @param values - Значения формы.
 * @param values.startTime - Время с.
 * @param values.endTime - Время по.
 * @returns
 */
export const validateWorkTimeCell = (values: {
  startTime: string;
  endTime: string;
}) => {
  const validator = new Validator(values);

  const startTime = moment(values.startTime, 'HH:mm');
  const endTime = moment(values.endTime, 'HH:mm');

  if (values.startTime === 'Invalid date') {
    validator.addError('startTime', 'укажите валидное время');
  }
  if (values.endTime === 'Invalid date') {
    validator.addError('endTime', 'укажите валидное время');
  }

  if (endTime.isBefore(startTime)) {
    validator.addError('endTime', `не может быть меньше ${values.startTime}`);
  }

  validator.setRequired('startTime');
  validator.setRequired('endTime');

  return validator.getErrors();
};
