import './AutoFAQ.Service.scss';

import { DragWrapperLocalStorage } from 'core/uiKit/components/DragWrapper/DragWrapper.LocalStorage';
import React from 'react';
import { createPortal } from 'react-dom';

import { useSetupAutoFAQService } from './useSetup.AutoFAQ.Service';

/**
 * Хук инициализации помощника autoFAQ.
 *
 * @returns React ReactPortal.
 */
export const AutoFAQService = () => {
  const { ref, initPosition } = useSetupAutoFAQService();

  return createPortal(
    <DragWrapperLocalStorage
      idLocalStorage={'AutoFAQService'}
      initPosition={initPosition}
    >
      <div ref={ref} />
    </DragWrapperLocalStorage>,
    document.body,
  );
};
