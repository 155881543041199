import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { useEffect, useState } from 'react';
import { vi } from 'vitest';

import { useSetupMafTypeLevel3Field } from './useSetup.MafTypeLevel3.Field';

vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('app/api/hooks/useGetParentCard');
vi.mock('app/api/hooks/autocomplete/useGetDictionary', () => ({
  useGetDictionary: vi.fn(),
}));
vi.mock('core/form/reduxForm/hooks/useField.RF');

vi.mock('react', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    useEffect: vi.fn((fn) => fn()),
    useState: vi.fn(),
  };
});

/**
 * Дефолтные моки.
 *
 */
const defaultMock = () => {
  useGetParentCard.mockReturnValue({});
  useMode.mockReturnValue({});
  useFieldRF.mockReturnValue({});
  useState.mockReturnValue([false, vi.fn()]);
};

describe('🐛 UseSetupMafTypeLevel3FieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    //☣️ Arrange (всякие моки)
    defaultMock();

    //🔥 Act
    const res = useSetupMafTypeLevel3Field();

    //❓ Assert
    expect(res).toStrictEqual({
      disabled: true,
      filter: expect.any(Function),
    });
  });

  it('🧪 useEffect deps', () => {
    expect.hasAssertions();

    //☣️ Arrange (всякие моки)
    defaultMock();
    const onChange = vi.fn();
    useFieldRF.mockImplementation((key) => {
      switch (key) {
        case 'maf_type_level1_id':
          return {
            onChange,
            value: 1,
          };
        case 'maf_type_level2_id':
          return { onChange, value: 2 };
        default:
          return { onChange };
      }
    });

    //🔥 Act
    useSetupMafTypeLevel3Field();

    //❓ Assert
    expect(useEffect).toHaveBeenCalledWith(expect.any(Function), [1, 2]);
  });

  it('🧪 called onChange', () => {
    expect.hasAssertions();

    //☣️ Arrange (всякие моки)
    defaultMock();

    const onChangeSpy = vi.fn();
    useMode.mockReturnValue({ editMode: true });

    useFieldRF.mockImplementation((key) => {
      switch (key) {
        case 'maf_type_level1_id':
          return { onChange: onChangeSpy, value: 1 };
        case 'maf_type_level2_id':
          return { onChange: onChangeSpy, value: 2 };
        default:
          return { onChange: onChangeSpy };
      }
    });

    //🔥 Act
    useSetupMafTypeLevel3Field();

    //❓ Assert
    expect(onChangeSpy).toHaveBeenCalledWith('');
  });

  it('🧪 filter', () => {
    expect.hasAssertions();

    //☣️ Arrange (всякие моки)
    defaultMock();

    const setHaveOptionsSpy = vi.fn();
    useState.mockReturnValue([false, setHaveOptionsSpy]);

    const onChangeSpy = vi.fn();
    useMode.mockReturnValue({ editMode: true });
    useGetParentCard.mockReturnValue({ typeId: RANDOM_NUMBER });

    useFieldRF.mockImplementation((key) => {
      switch (key) {
        case 'maf_type_level1_id':
          return { onChange: onChangeSpy, value: 1 };
        case 'maf_type_level2_id':
          return { onChange: onChangeSpy, value: 5 };
        default:
          return { onChange: onChangeSpy };
      }
    });

    const dict = [
      {
        id: 1,
        mafTypeList: [
          {
            mafTypeLevel2Id: 5,
            parentTypeIdList: [1, 2, RANDOM_NUMBER],
          },
        ],
      },
      {
        id: 2,
        mafTypeList: [
          {
            mafTypeLevel2Id: 5,
            parentTypeIdList: [1, 2, RANDOM_NUMBER],
          },
          {
            mafTypeLevel2Id: 5,
            parentTypeIdList: [1, 2],
          },
        ],
      },
      {
        id: 3,
        mafTypeList: [
          {
            mafTypeLevel2Id: 5,
            parentTypeIdList: [1, 2],
          },
        ],
      },
    ];

    //🔥 Act
    const { filter } = useSetupMafTypeLevel3Field();
    const res = filter(dict);

    //❓ Assert
    expect(res).toStrictEqual([
      {
        id: 1,
        mafTypeList: [
          {
            mafTypeLevel2Id: 5,
            parentTypeIdList: [1, 2, RANDOM_NUMBER],
          },
        ],
      },
      {
        id: 2,
        mafTypeList: [
          {
            mafTypeLevel2Id: 5,
            parentTypeIdList: [1, 2, RANDOM_NUMBER],
          },
          {
            mafTypeLevel2Id: 5,
            parentTypeIdList: [1, 2],
          },
        ],
      },
    ]);
    expect(setHaveOptionsSpy).toHaveBeenCalledWith(true);
  });

  it('🧪 returns hook', () => {
    expect.hasAssertions();

    //☣️ Arrange (всякие моки)
    defaultMock();
    useState.mockReturnValue([true, vi.fn()]);
    useMode.mockReturnValue({ editMode: true });

    //🔥 Act
    const res = useSetupMafTypeLevel3Field();

    //❓ Assert
    expect(res).toStrictEqual({
      disabled: false,
      filter: expect.any(Function),
    });
  });
});
