import { WorkingHoursDurationCell } from './cells/WorkingHoursDuration.Cell';
import { WorkTimeCell } from './cells/WorkTime.Cell';

/**
 * Получение колонок реестра ОТ.
 *
 * @returns
 */
export const getColumnsTableWorkTime = () => {
  return [
    {
      Header: 'День недели',
      accessor: 'day',
    },
    {
      Cell: WorkTimeCell,
      Header: 'Время',
      accessor: 'time',
    },
    {
      Cell: WorkingHoursDurationCell,
      Header: 'Продолжительность работы',
      accessor: 'working_hours_duration',
    },
  ];
};
