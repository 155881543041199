import { BikeParkTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/BikeParkTypeId.RF';
import React from 'react';

import { useSetupBikeParkTypeIdField } from './useSetup.BikeParkTypeId.Field';

/**
 * Field Select RF __Тип велопарковки__.
 *
 * @returns
 */
export const BikeParkTypeIdField = () => {
  const { disabled, filter } = useSetupBikeParkTypeIdField();

  return (
    <BikeParkTypeIdRF
      withAll={false}
      disabled={disabled}
      filter={filter}
      required={true}
    />
  );
};
