import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  IS_AVAILABILITY_PAYMENT_TERMINAL_NAME,
  IsAvailabilityPaymentTerminal,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/IsAvailabilityPaymentTerminal';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * Select FF __Наличие терминала оплаты__.
 *
 * @param props - Пропсы.
 * @returns Select Наличие терминала оплаты.
 */
export const IsAvailabilityPaymentTerminalFF = (props: SelectPropsType) => {
  return (
    <FieldFF
      {...props}
      component={IsAvailabilityPaymentTerminal}
      name={IS_AVAILABILITY_PAYMENT_TERMINAL_NAME}
    />
  );
};
