import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useState } from 'react';
import { useField } from 'react-final-form';

import { WORK_TIME_NAME } from '../../Table.WorkTime.FF';

interface IuseSetupWorkTimeCell {
  row: {
    original: {
      id: string;
      day: string;
      startTime: string;
      endTime: string;
    };
  };
}

/**
 * Setup Cell Времени в таблице График работы.
 *
 * @param props - Пропсы.
 * @param props.row - Выбранный ряд.
 * @returns
 */
export const useSetupWorkTimeCell = ({ row }: IuseSetupWorkTimeCell) => {
  const { input } = useField<{ id: string }[]>(WORK_TIME_NAME);
  const { editMode } = useMode();

  const {
    original: { startTime, endTime, id },
  } = row;

  const [isOpen, setIsOpen] = useState(false);

  /**
   * Функция изменения таблицы.
   *
   * @param values - Значения формы.
   * @param values.startTime - Стартовое время.
   * @param values.endTime - Конечное время.
   */
  const onSubmit = (values: { startTime: string; endTime: string }) => {
    const newWorkTimeValue = input.value.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          endTime: values.endTime,
          startTime: values.startTime,
        };
      }
      return item;
    });

    input.onChange(newWorkTimeValue);
    setIsOpen(false);
  };

  return { editMode, endTime, isOpen, onSubmit, setIsOpen, startTime };
};
