import { mapAttributeBridge } from 'app/pages/cardsOgh/Bridge/mapAttribute.Bridge';
import { getAttributesByMapForBackend } from 'app/pages/cardsOgh/helpers/getAttributesByMap.ForBackend';

import { getFileList } from '../components/tables/Documents/helpers/getFileList';
import { useInitialValueBridge } from './hooks/useInitialValue.Bridge';

/**
 * Setup для Мостов.
 *
 * @returns Data and fns.
 */
export const useSetupBridge = () => {
  const initialValues = useInitialValueBridge();

  /**
   * Функция подготовка данных для Атрибутов.
   *
   * @param values - Значения формы.
   * @returns Атрибуты.
   */
  const preparingDataForAttributes = (values: Record<string, unknown>) => {
    return {
      file_list: getFileList(values),
      ...getAttributesByMapForBackend(values, mapAttributeBridge),
    };
  };

  return {
    initialValues,
    preparingDataForAttributes,
  };
};
