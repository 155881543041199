import { useEffect } from 'react';

import { Position } from '../../types.DragWrapper';

interface IuseDragAction {
  onDragStart?: (position: Position) => void;
  onDragEnd?: (position: Position) => void;
  onDragMove?: (position: Position) => void;
  onDragIdle?: (position: Position) => void;
  position: Position;
  dragStatus: 'idle' | 'move' | 'start' | 'end';
}

/**
 * Хук передающий экшен в калбек.
 *
 * @param params - Параметры хука.
 * @param params.dragStatus - Статус перемещения.
 * @param params.position - Позиция перемещения.
 * @param params.onDragStart - Функция, вызывается когда стартует перемещение.
 * @param params.onDragEnd - Функция, вызывается когда заканчивается перемещение.
 * @param params.onDragMove - Функция, вызывается когда идет перемещение.
 * @param params.onDragIdle - Функция вызывается когда статус idle.
 */
export const useDragAction = ({
  dragStatus,
  position,
  onDragStart,
  onDragEnd,
  onDragMove,
  onDragIdle,
}: IuseDragAction) => {
  useEffect(() => {
    switch (dragStatus) {
      case 'start': {
        onDragStart?.(position);
        break;
      }
      case 'end': {
        onDragEnd?.(position);
        break;
      }
      case 'move': {
        onDragMove?.(position);
        break;
      }
      default: {
        onDragIdle?.(position);
        break;
      }
    }
  }, [dragStatus, position, onDragStart, onDragEnd, onDragMove, onDragIdle]);
};
