// 5 Остановки общественного транспортаimport CardAbstract from 'app/components/card/common/CardAbstract';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { elementFormGrid } from 'app/components/card/common/grid/index';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
  validateValidateCleanArea,
} from 'app/components/card/common/validation';
import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import { AreaField } from 'app/components/card/ogh/components/fields/numberFields/Area.Field';
import { BordBeginField } from 'app/components/card/ogh/components/fields/numberFields/BordBegin.Field';
import { BordEndField } from 'app/components/card/ogh/components/fields/numberFields/BordEnd.Field';
import { OdhSideIdField } from 'app/components/card/ogh/components/fields/selectFields/OdhSideId.Field';
import { DescriptionField } from 'app/components/card/ogh/components/fields/textFields/Description.Field';
import { OdhAxisField } from 'app/components/card/ogh/components/fields/textFields/OdhAxis.Field';
import { QuantityField } from 'app/components/card/ogh/components/selects/Quantity.Field';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { column } from 'app/constants/tables';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/numberField/TotalAreaGeo.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['stops'];
const Versions = VersionsTemplate(types);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

const propTypes = {
  card: PropTypes.object,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * Классовый компонет StopsCard.
 */
class StopsCard extends React.Component {

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   */
  render() {
    const {
      mode,
      card,
      createMode,
      editMode,
      required,
      isEditCurrentObjectIsExternalSystem,
    } = this.props;

    return (
      <CardContainer>
        <CardAbstract>
          <div id="editor-card">
            {printOghCardHeader(card, mode)}
            {card && objectTabs(card.type_id)}

            <div id="props">
              <FieldsSplitColumns>
                <SelectRF
                  name={'conv_element_type_id'}
                  label={'Тип'}
                  options={card.conv_element_type}
                  required={isMandatory(
                    card.type_id,
                    'conv_element_type_id',
                    required,
                  )}
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                />
                <ParentInfo />
                <IsDiffHeightMarkField />
              </FieldsSplitColumns>

              <Tabs tabs={tabs} />

              <div id="characteristics">
                <GroupContainer title={'Местоположение'}>
                  <FieldsSplitColumns>
                    <OdhAxisField />
                    <OdhSideIdField />
                    <BordBeginField />
                    <BordEndField />
                  </FieldsSplitColumns>
                </GroupContainer>

                {elementFormGrid(
                  [],
                  column.TWO,
                  'Характеристики',
                  card.type_id,
                )}

                <GroupContainer title={'Характеристики'}>
                  <FieldsSplitColumns>
                    <TextFieldRF
                      name={'name'}
                      label={'Название остановки (адресная привязка)'}
                      disabled={!editMode}
                      required={isMandatory(card.type_id, 'name', required)}
                    />
                    <QuantityField
                      digits={0}
                      disabled={
                        !(editMode && isEditCurrentObjectIsExternalSystem)
                      }
                    />
                    <TextFieldRF
                      disabled={
                        !(editMode && isEditCurrentObjectIsExternalSystem)
                      }
                      label={'Наличие маршрутов'}
                      name={'routes'}
                    />
                    <AreaField
                      label={
                        'Площадь посадочной площадки(включая павильон), кв.м'
                      }
                    />
                    <TotalAreaGeoRF disabled={true} decimalScale={2} />
                    <NumberFieldRF
                      label={'Площадь уширения(кармана), кв.м'}
                      name={'pocket_area'}
                      disabled={
                        !(editMode && isEditCurrentObjectIsExternalSystem)
                      }
                      digits={2}
                    />
                    <NumberFieldRF
                      label={'Площадь павильонов, кв.м'}
                      name={'pavilion_area'}
                      disabled={
                        !(editMode && isEditCurrentObjectIsExternalSystem)
                      }
                      digits={2}
                    />
                    <NumberFieldRF
                      label={'Площадь уборки, кв.м'}
                      name={'station_clean_area'}
                      disabled={
                        !(editMode && isEditCurrentObjectIsExternalSystem)
                      }
                      digits={2}
                    />
                  </FieldsSplitColumns>
                </GroupContainer>
                <DescriptionField />
              </div>
              <div hidden={true} id={'docs'}>
                <OtherDocumentsRF />
              </div>
            </div>
            <div hidden={true} id="versions">
              <Versions
                card={card}
                disabled={createMode}
                key={`versions-${card.root_id}`}
              />
            </div>
          </div>
        </CardAbstract>
      </CardContainer>
    );
  }
}

StopsCard.propTypes = propTypes;

const stopsCardDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

const dictFields = ['conv_element_type_id', 'odh_side_id'];

const numericFields = [
  { name: 'quantity', positive: true, type: 'integer' },
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  {
    name: 'pavilion_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'station_clean_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'pocket_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
];

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
const validate = (values) => ({
  ...validateNumeric(values, numericFields),
  ...validateValidateCleanArea(values),
});

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void} - Жопа.
 * @example ----
 */
export function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );
  formValuesWithAttribute.is_diff_height_mark = Boolean(
    formValuesWithAttribute.is_diff_height_mark,
  );

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);

  const result = {
    createMode: isCreateMode(state, props),

    editMode: isEditMode(state, props),

    enableReinitialize: true,
    initialValues: {
      area: parse('area'),
      bord_begin: parse('bord_begin') || '0.00',
      bord_end: parse('bord_end') || '0.00',
      conv_element_type_id: parse('conv_element_type_id.conv_element_type'),
      customer_id: parse('customer_id'),
      description: parse('description'),
      endDate: parseDate(props.card.end_date),
      is_diff_height_mark: parse('is_diff_height_mark'),
      name: parse('name'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      parent_name: props.card.parent_info,
      pavilion_area: parse('pavilion_area'),
      pocket_area: parse('pocket_area'),
      quantity: parse('quantity'),
      routes: parse('routes'),
      startDate: parseDate(props.card.start_date),
      station_clean_area: parse('station_clean_area'),
      totalAreaGeo: parse('total_area_geo'),
    },
    onSubmit: onSubmit.bind({ props }),

    required: isRequired(state, props),
    validate,
  };

  return stopsCardDocumentsReducer(result, state, props);
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'editorCard',
  })(StopsCard),
);
