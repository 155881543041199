import { WORK_TIME_NAME } from 'app/pages/cardsOgh/PublicToilet/LeftPanel.PublicToilet/Tab.Properties/Table.WorkTime.FF';
import { usePreparation } from 'core/utils/hooks/usePreparation';

type WorkTimeItem = {
  id: string;
  startTime: string;
  endTime: string;
};

type WorkTimeTransformedArr = Record<
  string,
  { starttime: string; endtime: string }
>[];

/**
 * Хук перевода поля WORK_TIME_NAME.
 *
 */
export const usePreparationWorkTime = () => {
  usePreparation(WORK_TIME_NAME, (value: WorkTimeItem[]) => {
    return value.reduce((arr, item) => {
      arr.push({
        [item.id]: {
          endtime: item.endTime,
          starttime: item.startTime,
        },
      });

      return arr;
    }, [] as WorkTimeTransformedArr);
  });
};
