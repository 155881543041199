import { generateYearsArr } from 'app/utils/date/generateYearsArr';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const GET_BALANCE_DATE_NAME = 'get_balance_date';

/**
 * Select  __Год принятия на баланс__.
 *
 * @param props - Пропсы.
 * @returns Select.
 */
export const GetBalanceDate = (props: SelectPropsType) => {
  const options = generateYearsArr().map((year) => ({
    id: year,
    name: year,
  }));

  return (
    <Select
      {...props}
      label={'Год принятия на баланс'}
      name={GET_BALANCE_DATE_NAME}
      options={options}
    />
  );
};
