import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { Position } from '../../types.DragWrapper';
import { getMargins } from '../../utils/getMargins';
import { getScopedPosition } from '../../utils/getScopedPosition';

interface IuseDrag {
  initPosition?: Position;
}

/**
 * Кастомный хук для реализации перетаскивания элемента.
 *
 * @param params - Начальная позиция.
 * @param params.initPosition - Начальная позиция.
 * @returns Объект с состоянием позиции, рефом и обработчиком.
 */
export const useDrag = ({ initPosition }: IuseDrag = {}) => {
  const [position, setPosition] = useState<Position>(() => {
    return initPosition || { cX: 0, cY: 0 };
  });
  const [dragStatus, setDragStatus] = useState<
    'idle' | 'move' | 'start' | 'end'
  >('idle');
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState<Position>({ cX: 0, cY: 0 });
  const draggableRef = useRef<HTMLElement>(null);

  /**
   * Обработчик начала перетаскивания.
   *
   * @param ev - Событие указателя.
   */
  const handlePointerDown = useCallback((ev: PointerEvent) => {
    setIsDragging(true);
    const rect = draggableRef.current!.getBoundingClientRect();
    const margins = getMargins(draggableRef.current!);
    const result = {
      cX: ev.clientX - rect.left + margins.left,
      cY: ev.clientY - rect.top + margins.top,
    };
    setOffset(result);
    setDragStatus('start');
  }, []);

  /**
   * Обработчик перемещения элемента.
   *
   * @param {PointerEvent} ev - Событие перемещения указателя.
   */
  const handlePointerMove = useCallback(
    (ev: PointerEvent) => {
      if (isDragging && draggableRef.current) {
        const newCX = ev.clientX - (offset.cX ?? 0);
        const newCY = ev.clientY - (offset.cY ?? 0);

        const margins = getMargins(draggableRef.current);
        const maxCX =
          window.innerWidth -
          draggableRef.current.offsetWidth -
          margins.left -
          margins.right;
        const maxCY =
          window.innerHeight -
          draggableRef.current.offsetHeight -
          margins.top -
          margins.bottom;

        const result = {
          cX: getScopedPosition(newCX, margins.left - margins.right, maxCX),
          cY: getScopedPosition(newCY, margins.top - margins.bottom, maxCY),
        };
        setPosition(result);
        setDragStatus('move');
      }
    },
    [isDragging, offset.cX, offset.cY],
  );

  /**
   * Обработчик окончания перетаскивания.
   *
   */
  const handlePointerUp = useCallback(() => {
    setIsDragging(false);
    setDragStatus('end');
  }, []);

  useEffect(() => {
    let timeoutId: string | number | NodeJS.Timeout | null | undefined = null;
    if (dragStatus === 'end') {
      timeoutId = setTimeout(() => {
        setDragStatus('idle');
      }, 1000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [dragStatus]);

  useLayoutEffect(() => {
    if (isDragging) {
      window.addEventListener('pointermove', handlePointerMove);
      window.addEventListener('pointerup', handlePointerUp);
    }
    return () => {
      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('pointerup', handlePointerUp);
    };
  }, [isDragging, handlePointerMove, handlePointerUp]);

  return {
    dragStatus,
    draggableRef,
    handlePointerDown,
    isDragging,
    position,
    setPosition,
  };
};
