import { render } from '@testing-library/react';
import { useSetupMainParamsTab } from 'app/components/card/ogh/ImprovementTerritoryCard/components/MainParamsTab/useSetup.MainParamsTab';
import React from 'react';
import { vi } from 'vitest';

import { MainParamsTab } from './MainParamsTab';

vi.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/CleanCoverArea',
  () => ({
    CleanCoverArea: vi.fn(() => '🔢CleanCoverArea🔢\n'),
  }),
);

vi.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/LawnArea',
  () => ({
    LawnArea: vi.fn(() => '🔢LawnArea🔢\n'),
  }),
);

vi.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/MainParamsTab/DataPassport',
  () => ({
    DataPassport: vi.fn(() => '🔢DataPassport🔢\n'),
  }),
);

vi.mock(
  'core/form/reduxForm/fields/prepared/numberField/TotalAreaGeo.RF',
  () => ({
    TotalAreaGeoRF: vi.fn(() => '🔢TotalAreaGeoRF🔢\n'),
  }),
);

vi.mock('core/form/reduxForm/fields/default/NumberField.RF', () => ({
  NumberFieldRF: vi.fn(() => '🔘 NumberFieldRF 🔘'),
}));

vi.mock('core/uiKit/inputs/NumberField', () => ({
  NumberField: vi.fn(
    (props) => `🔢 ${props.name} | ${props.label} 'NumberField' 🔢\n`,
  ),
}));

vi.mock('core/uiKit/components/card.components/FieldsSplitColumns', () => ({
  FieldsSplitColumns: vi.fn(({ children }) => <div>{children}</div>),
}));

vi.mock('core/uiKit/components/card.components/GroupContainer', () => ({
  GroupContainer: vi.fn(({ children, title }) => (
    <div>
      🏷️ {title} 🏷️
      {children}
    </div>
  )),
}));

vi.mock('./useSetup.MainParamsTab');

describe('🐛 MainParamsTab', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupMainParamsTab.mockReturnValue({});

    //🔥 Act
    const { container } = render(<MainParamsTab />);

    //❓ Assert
    expect(container.textContent).toBe(
      "🔢 total_area | Общая площадь объекта, кв.м 'NumberField' 🔢\n" +
        '🏷️ Уборочная площадь 🏷️' +
        "🔢 manual_clean_area | Площадь ручной уборки, кв. м 'NumberField' 🔢\n" +
        "🔢 auto_clean_area | Площадь механизированной уборки, кв. м 'NumberField' 🔢\n" +
        "🔢 total_clean_area | Общая уборочная площадь, кв.м 'NumberField' 🔢\n" +
        "🔢 undefined | Общая площадь уборки с применением средств малой механизации, кв.м 'NumberField' 🔢\n" +
        '🔢CleanCoverArea🔢\n' +
        '🔢LawnArea🔢\n' +
        '🔢DataPassport🔢\n' +
        '🏷️ Площадь вывоза снега 🏷️' +
        '🔘 NumberFieldRF 🔘' +
        '🏷️ Водоемы 🏷️' +
        '🔘 NumberFieldRF 🔘',
    );
  });
});
