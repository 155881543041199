import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  CLEAN_TYPE_ID_NAME,
  CleanTypeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CleanTypeId';
import React from 'react';
import { SelectFFPreparedPropsType } from 'types/inputs';

import { usePreparationCleanTypeIdFF } from './usePreparation.CleanTypeId.FF';

/**
 * Select FF __Способ уборки__.
 *
 * @param props - Пропсы.
 * @returns Способ уборки.
 */
export const CleanTypeIdFF = (props: SelectFFPreparedPropsType) => {
  usePreparationCleanTypeIdFF();

  return (
    <FieldFF {...props} name={CLEAN_TYPE_ID_NAME} component={CleanTypeId} />
  );
};
