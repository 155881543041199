import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { OghCardHeaderFF } from 'app/pages/cardsOgh/components/headerFF/OghHeaderFF';
import { Versions } from 'app/pages/cardsOgh/components/tables/Versions';
import { TabDocs } from 'app/pages/cardsOgh/PublicToilet/LeftPanel.PublicToilet/Tab.Docs';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { TabsCardOGH } from 'core/uiKit/components/card.components/TabsCardOGH';
import { TabsCardOGHItem } from 'core/uiKit/components/card.components/TabsCardOGH/TabsCardOGH.Item';
import React from 'react';

import { TabProperties } from './Tab.Properties';

/**
 * LeftPanel Инженерные сооружения.
 *
 * @returns {JSX.Element}
 */
export const LeftPanelPublicToilet = () => {
  const { rootId = 0 } = useGetCurrentCard();
  const isCreatedPassport = Boolean(rootId);

  return (
    <CardContainer>
      <OghCardHeaderFF />
      <TabsCardOGH>
        <TabsCardOGHItem tabTitle={'Свойства'}>
          <TabProperties />
        </TabsCardOGHItem>
        <TabsCardOGHItem tabTitle={'Версии'} hide={!isCreatedPassport}>
          <Versions />
        </TabsCardOGHItem>
        <TabsCardOGHItem tabTitle={'Документы'}>
          <TabDocs />
        </TabsCardOGHItem>
      </TabsCardOGH>
    </CardContainer>
  );
};
