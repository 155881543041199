import './style.scss';

import cn from 'classnames';
import FieldLabel from 'core/components/FieldLabel';
import FieldTooltip from 'core/components/FieldTooltip';
import { TextFieldMUI } from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

import { textFieldStyle, tooltipFieldStyle } from './styles';

const propTypes = {
  cancelInput: PropTypes.func,
  className: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  labelStyle: PropTypes.object,
  meta: PropTypes.object,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  style: PropTypes.object,
  tooltipText: PropTypes.string,
};

/**
 * Компонент TextField.
 *
 * @returns {JSX.Element}
 */
class TextField extends React.Component {

  /**
   * Метод жизненного цикла constructor.
   *
   * @param {object} props - Пропсы.
   * @returns {void}
   */
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }

  /**
   * Метод handleBlur.
   *
   * @param {Event} event - Event.
   * @returns {void}
   */
  handleBlur = (event) => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(event);
    }
    this.setState({
      isFocused: false,
    });
  };

  /**
   * Метод handleFocus.
   *
   * @returns {void}
   */
  handleFocus = () => {
    this.setState({
      isFocused: true,
    });
  };

  /**
   * Метод handleKeyDown.
   *
   * @param {Event} event - Event.
   * @returns {void}
   */
  handleKeyDown = (event) => event.nativeEvent.preventDefault();

  /**
   * Метод renderLabel.
   *
   * @returns {JSX.Element}
   */
  renderLabel() {
    const { labelStyle, label, required } = this.props;
    return (
      <FieldLabel required={required} style={labelStyle}>
        {label}
      </FieldLabel>
    );
  }

  /**
   * Метод renderTooltip.
   *
   * @returns {JSX.Element}
   */
  renderTooltip() {
    const { tooltipText } = this.props;
    const { isFocused } = this.state;
    return (
      isFocused && (
        <FieldTooltip style={tooltipFieldStyle}>{tooltipText}</FieldTooltip>
      )
    );
  }

  /**
   * Метод жизненного цикла render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { label, tooltipText, style, className, cancelInput, ...otherProps } =
      this.props;

    const containerClassName = cn(
      'ods-text-field',
      {
        'ods-text-field__withText': Boolean(label),
      },
      className,
    );

    return (
      <div className={containerClassName} style={style}>
        {label && this.renderLabel()}

        {tooltipText ? (
          <TextFieldMUI
            {...otherProps}
            style={textFieldStyle}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
          />
        ) : (
          <TextFieldMUI
            {...otherProps}
            style={textFieldStyle}
            onKeyDown={cancelInput && this.handleKeyDown}
          />
        )}

        {tooltipText && this.renderTooltip()}
      </div>
    );
  }
}

TextField.propTypes = propTypes;

export default TextField;
