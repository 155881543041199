import toArray from 'app/utils/toArray';
import { IGeometryNeighboursData } from 'types/api.d';
import { v4 } from 'uuid';

import { GeoJSONCoordinatesFormatter } from '..';

/**
 * Форматирует данные для отрисовки Смежных ОГХ.
 *
 * @param params - Параметры.
 * @returns Форматированные данные.
 */
export const prepareForNeighboursGeometry = (
  params: IGeometryNeighboursData[],
) => {
  if (!params) return [];

  const neighboursArray = toArray(params);

  // @ts-ignore
  return neighboursArray.map((item) => createObjectForMap(item)).flat(1);
};

/**
 * Создает объект для отрисовки на карте.
 *
 * @param item - Объект с данными.
 * @returns Объект с данными геометрии.
 */
export const createObjectForMap = (item: IGeometryNeighboursData) => {
  const hintFields = [
    {
      key: 'title',
      // @ts-ignore
      rawValue: item?.object_type_name,
      // @ts-ignore
      value: `${item?.object_type_name}`,
    },
    {
      key: 'object_link',
      // @ts-ignore
      rawValue: item?.object_link,
      // @ts-ignore
      value: item?.object_link,
    },
    {
      key: 'state_name',
      // @ts-ignore
      rawValue: item.state_name,
      // @ts-ignore
      value: `Статус: ${item.state_name}`,
    },
    {
      key: 'start_date',
      // @ts-ignore
      rawValue: item.start_date,
      // @ts-ignore
      value: `Дата c: ${item.start_date}`,
    },
    {
      key: 'end_date',
      // @ts-ignore
      rawValue: item.end_date,
      // @ts-ignore
      value: `Дата по: ${item.end_date}`,
    },
    {
      key: 'owner_short_name',
      // @ts-ignore
      rawValue: item.owner_short_name,
      // @ts-ignore
      value: `Балансодержатель: ${item.owner_short_name}`,
    },
    {
      key: 'customer_short_name',
      // @ts-ignore
      rawValue: item.customer_short_name,
      // @ts-ignore
      value: `Заказчик: ${item.customer_short_name}`,
    },
    {
      key: 'passport_date',
      // @ts-ignore
      rawValue: item.passport_date,
      // @ts-ignore
      value: `Дата составления/согласования паспорта: ${item.passport_date}`,
    },
    {
      key: 'create_type_name',
      // @ts-ignore
      rawValue: item.create_type_name,
      // @ts-ignore
      value: `Тип загрузки: ${item.create_type_name}`,
    },
  ];

  const hint = hintFields.filter((item) => item.rawValue);
  const finalHint = {
    object_attribute_hint: hint,
    object_attribute_hint_short: [
      {
        key: 'name',
        // @ts-ignore
        value: item.full_name || item.object_name,
      },
    ],
  };
  const ids = {
    // @ts-ignore
    oghObjectId: item?.object_root_id,
    // @ts-ignore
    oghTypeId: item?.object_type_id,
    // @ts-ignore
    stateId: item?.state_id,
  };

  return {
    hint: finalHint,
    id: v4(),

    layerType: 'objects',
    // @ts-ignore
    lines: item?.geometry?.lines
      ? // @ts-ignore
        item.geometry.lines.map((line) => ({
          ...line,
          hint: finalHint,
          ...ids,
          coordinates: GeoJSONCoordinatesFormatter.polylineToGeoJSON(
            line.coordinates,
          ),
        }))
      : null,
    oghObjectId: ids.oghObjectId,
    // @ts-ignore
    points: item.geometry.points
      ? // @ts-ignore
        item.geometry.points.map((point) => ({
          ...point,
          hint: finalHint,
          ...ids,
          coordinates: GeoJSONCoordinatesFormatter.polygonToGeoJSON(
            point.coordinates,
          ),
        }))
      : null,

    // @ts-ignore
    polygons: item.geometry.polygons
      ? // @ts-ignore
        item.geometry.polygons.map((polygon) => ({
          ...polygon,
          hint: finalHint,
          ...ids,
          coordinates: GeoJSONCoordinatesFormatter.polygonToGeoJSON(
            polygon.coordinates,
          ),
        }))
      : null,
    type_id: ids.oghTypeId,
  };
};
