import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const FORM_VISIT_ID_NAME = 'form_visit_id';

interface IFormVisitIdData {
  id: number;
  name: string;
}

/**
 * Select __Форма посещения__.
 *
 * @param props - Пропсы.
 * @returns Select Способ расположения.
 */
export const FormVisitId = (props: SelectPropsType) => {
  const dict = 'form_visit';

  const { data } = useGetDictionary<IFormVisitIdData[]>(dict);

  return (
    <Select
      {...props}
      options={data || []}
      label={'Форма посещения'}
      name={FORM_VISIT_ID_NAME}
    />
  );
};
