import { clearCurrentCardCash } from 'app/api/hooks/useGetCurrentCard/clearCurrentCardCash';
import MapEditPanel from 'app/components/card/common/MapEditPanel';
import { prepareActionsTree } from 'app/components/card/ogh/index/card/utils/prepareActionsTree';
import CompareReport from 'app/components/card/ogh/versions/CompareReport/index';
import DialogAction from 'app/components/dialogs/common/DialogAction';
import DialogAlert from 'app/components/dialogs/common/DialogAlert';
import LegendPanel from 'app/components/MainPage/LegendPanel';
import { FOREGROUND, INTERSECTIONS, PARENTS } from 'app/constants/layers';
import geometryService from 'app/services/geometryService';
import {
  getCheckGeometryIntersectionAttributes,
  onSave,
} from 'app/utils/card/checkGeometry';
import * as defineType from 'app/utils/card/defineType';
import formatRootId from 'app/utils/formatRootId';
import cn from 'classnames';
import { switchServisMap } from 'core/utils/map/switchServisMap';
import { get } from 'lodash';
import React from 'react';

import LeftPanel from '../LeftPanel';
import RightPanel from '../RightPanel';

/**
 * Индексный Компонент карточки.
 *
 * @returns {JSX.ElementClass} - Жопа.
 */
export default class Card extends React.Component {
  //

  /**
   * Конструктор компонента React вызывается до того, как компонент будет примонтирован. В начале конструктора необходимо вызывать super(props). Если это не сделать, this.props не будет определён. Это может привести к багам.
   *
   * @example
   * -----
   * @param {object} props - Props.
   */
  constructor(props) {
    super(props);
    this.state = {
      id: false,
      showGeometryMissing: false,
    };
  }

  /**
   * Этот метод жизненного цикла раньше назывался componentWillReceiveProps. По этому названию он будет доступен до 17 версии. Чтобы автоматически обновить компоненты, используйте rename-unsafe-lifecycles.
   *
   * @param {object} nextProps - Следующий пропс.
   * @returns {void} - Nothing.
   * @example
   * -----
   */
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { action, goToRegistry, card, history } = this.props;

    if (nextProps.action && nextProps.action !== action) {
      nextProps.action();
    }

    if (nextProps.goToRegistry && nextProps.goToRegistry !== goToRegistry) {
      history.push('/r/ogh/' + card.type_id);
    }
  };

  /**
   * Перерисовать объект на карте.
   *
   * @param {number|string} id - Id.
   * @returns {void} - Nothing.
   * @example
   * -----
   */
  redrawObject(id) {
    this.props.fetchObjectsGeometry({ ids: [Number(id)] }).then((json) => {
      this.props.mapContext.clearLayer(FOREGROUND);
      this.props.mapContext.drawCurrentObjectsGeometry(json);
    });
  }

  /**
   * Перерисовать Родитель объект на карте.
   *
   * @param {number|string} parentId - Id родителя.
   * @returns {void} - Nothing.
   * @example
   * -----
   */
  redrawParentObject(parentId) {
    this.props.fetchObjectsGeometry({ ids: [parentId] }).then((json) => {
      this.props.mapContext.clearLayer(PARENTS);
      this.props.mapContext.drawParentGeometry(json);
    });
  }

  /**
   * Компонент жизненого цикла componentDidUpdate.
   *
   * @returns {void} - Nothing.
   * @example .
   */
  componentDidUpdate() {
    const { afterReceiving, viewCard, mode } = this.props;

    if (mode && mode.didReceived) {
      if (mode.editMode) {
        afterReceiving();
      } else {
        viewCard();
      }
    }
  }

  /**
   * Метод вызывается при удалении компонента из DOM.
   *
   * @returns {void} - Nothing.
   * @example
   * -----
   */
  componentWillUnmount() {
    const { hideDialog, clearCard } = this.props;
    hideDialog();
    clearCard();
  }

  /**
   * Метод alert.
   *
   * @param {string} message - Сообщение.
   * @returns {void} - Nothing.
   * @example ---
   */
  alert = (message) => {
    this.props.showAlert(message);
  };

  /**
   * Метод createObject.
   *
   * @param {object} objectAttributes - Атрибуты.
   * @returns {void} - Nothing.
   * @example ---
   */
  createObject = (objectAttributes) => {
    const createOrUpdateObject = switchServisMap({
      dtw: this.createOrUpdateObjectDTW,
      egip: this.createOrUpdateObjectEgip,
    });

    createOrUpdateObject(this.props.sendCreateCard, objectAttributes);
  };

  /**
   * Метод createOrUpdateObject DTW.
   *
   * @param {Function} fetchAction - Жопа.
   * @param {object} objectAttributes - Атрибуты.
   * @returns {void} - Nothing.
   * @example ---
   */
  createOrUpdateObjectDTW = (fetchAction, objectAttributes) => {
    const { allowedWithoutGeometry, card, hasGeometry, mapContext, selected } =
      this.props;

    if (hasGeometry === true) {
      const geometry = mapContext.getGeometry((selected || card).record_id);
      if (
        geometryService.isGeometryMissing(geometry) &&
        !allowedWithoutGeometry
      ) {
        this.setState({
          showGeometryMissing: true,
        });
      } else {
        this.createOrUpdateObjectWithGeometryDTW(fetchAction, objectAttributes);
      }
    } else {
      this.createOrUpdateObjectWithoutGeometryDTW(
        fetchAction,
        objectAttributes,
      );
    }
  };

  /**
   * Метод createOrUpdateObject Egip.
   *
   * @param {Function} fetchAction - Жопа.
   * @param {object} objectAttributes - Атрибуты.
   * @returns {void} - Nothing.
   * @example ---
   */
  createOrUpdateObjectEgip = (fetchAction, objectAttributes) => {
    const { hasGeometry, card, selected, allowedWithoutGeometry } = this.props;

    if (hasGeometry === true) {
      const geometry = this.props.mapContext.getRawGeometry(
        (selected || card).record_id,
      );
      if (
        geometryService.isGeometryMissing(geometry) &&
        !allowedWithoutGeometry
      ) {
        this.setState({
          showGeometryMissing: true,
        });
      } else {
        this.createOrUpdateObjectWithGeometryEgip(
          fetchAction,
          objectAttributes,
        );
      }
    } else {
      this.createOrUpdateObjectWithoutGeometryEgip(
        fetchAction,
        objectAttributes,
      );
    }
  };

  /**
   * Метод createOrUpdateObjectWithGeometry.
   *
   * @param {Function} fetchAction - Жопа.
   * @param {object} objectAttributes - Атрибуты.
   * @returns {void} - Nothing.
   * @example ---
   */
  createOrUpdateObjectWithGeometryDTW = (fetchAction, objectAttributes) => {
    const {
      treeJournal,
      card,
      selected,
      currentFormValues,
      parentInfo,
      updateTree,
      actionsTree,
      mapContext,
    } = this.props;
    const currentCard = selected || card;

    const typeId = objectAttributes.type_id || objectAttributes.type;
    const isChild = parentInfo.isChild;

    const geometry = mapContext.getGeometryToJson(currentCard.record_id);

    const data = {
      attribute: getCheckGeometryIntersectionAttributes(
        currentCard.type_id,
        currentFormValues,
        card.attribute,
      ),
      cnt: isChild ? 0 : treeJournal !== null ? treeJournal.cnt_action : 0,
      geometry,
      id: objectAttributes.rootId || objectAttributes.root_id,
      journal: isChild
        ? {}
        : treeJournal !== null
        ? JSON.stringify(treeJournal.actions)
        : null,
      parentId: objectAttributes.parent_id,
      startDate: currentCard.start_date,
      treeParentId: parentInfo.isChild ? parentInfo.recordId : null,
      typeId,
    };

    /**
     * Метод сохранения.
     *
     * @returns {void} - Nothing.
     * @example
     * -----
     */
    const saveObject = () => {
      const group = get(this.props, 'treeParams.group');
      const childObjectTypeId =
        objectAttributes.type_id || objectAttributes.type;
      const oldChildObjectId = objectAttributes.record_id || null;
      const pathObjectTypeId = get(group, 'type_path');
      const pathObjectId = get(group, 'recalc_path_object_id') || null;
      const rootObjectId = parentInfo.recordId;
      const rootObjectTypeId = parentInfo.typeId;

      const actionData = {
        ...objectAttributes,
        ...geometry,
        ...prepareActionsTree(actionsTree),
        childObjectTypeId,
        isChild,
        oldChildObjectId,
        pathObjectId,
        pathObjectTypeId,
        rootObjectId,
        rootObjectTypeId,
      };

      fetchAction(actionData, isChild)
        .then(this.props.fetchActionWithGeometryDTW)
        .finally(() => {

          // TODO: Вынести finally в fetchActionWithGeometry

          // this.props.clearActionsTree();
          clearCurrentCardCash();
          updateTree();
        });
    };

    onSave(
      data,
      this.props,
      (object) => {
        this.props.mapContext.drawIntersections(object);
      },
      (
        continueAction,
        message = 'Пересечение геометрии. Продолжить сохранение?',
      ) => {
        this.props.showNotification(message, continueAction);
      },
      saveObject,
    );
  };

  /**
   * Метод createOrUpdateObjectWithGeometry.
   *
   * @param {Function} fetchAction - Жопа.
   * @param {object} objectAttributes - Атрибуты.
   * @returns {void} - Nothing.
   * @example ---
   */
  createOrUpdateObjectWithGeometryEgip = (fetchAction, objectAttributes) => {
    const {
      treeJournal,
      card,
      selected,
      currentFormValues,
      fetchRootCard,
      navigateWithLoading,
      parentInfo,
      updateTree,
      actionsTree,
    } = this.props;
    const currentCard = selected || card;

    const geometry = this.props.mapContext.getGeometry(currentCard.record_id);

    const typeId = objectAttributes.type_id || objectAttributes.type;
    const isChild = parentInfo.isChild;

    const data = {
      attribute: getCheckGeometryIntersectionAttributes(
        currentCard.type_id,
        currentFormValues,
        card.attribute,
      ),
      cnt: isChild ? 0 : treeJournal !== null ? treeJournal.cnt_action : 0,
      geometry,
      id: objectAttributes.rootId || objectAttributes.root_id,
      journal: isChild
        ? {}
        : treeJournal !== null
        ? JSON.stringify(treeJournal.actions)
        : null,
      parentId: objectAttributes.parent_id,
      startDate: currentCard.start_date,
      treeParentId: parentInfo.isChild ? parentInfo.recordId : null,
      typeId,
    };

    this.props.mapContext.clearLayer(INTERSECTIONS);

    /**
     * Метод сохранения.
     *
     * @returns {void} - Nothing.
     * @example
     * -----
     */
    const saveObject = () => {
      const group = get(this.props, 'treeParams.group');
      const childObjectTypeId =
        objectAttributes.type_id || objectAttributes.type;
      const oldChildObjectId = objectAttributes.record_id || null;
      const pathObjectTypeId = get(group, 'type_path');
      const pathObjectId = get(group, 'recalc_path_object_id') || null;
      const rootObjectId = parentInfo.recordId;
      const rootObjectTypeId = parentInfo.typeId;

      const actionData = {
        ...objectAttributes,
        ...geometry,
        ...prepareActionsTree(actionsTree),
        childObjectTypeId,
        isChild,
        oldChildObjectId,
        pathObjectId,
        pathObjectTypeId,
        rootObjectId,
        rootObjectTypeId,
      };

      fetchAction(actionData, isChild)
        .then((id) => {
          if (id && isChild) {
            navigateWithLoading(`/ogh/${actionData.rootObjectId}/${id}`);

            this.props.fetchTreeSelectedElement({ id });

            this.props.fetchObjectsGeometry({ ids: [id] }).then((json) => {
              this.props.mapContext.clearLayer(FOREGROUND);
              this.props.mapContext.drawCurrentObjectsGeometry(json);
            });

            this.props.viewCard();
          } else {
            if (id) {
              fetchRootCard(id);
              navigateWithLoading(`/ogh/${id}`);
            }
          }
          updateTree();
        })
        .finally(() => {

          // this.props.clearActionsTree();
          clearCurrentCardCash();
        });
    };

    onSave(
      data,
      this.props,
      this.props.mapContext.drawIntersectionsGeometry,
      (
        continueAction,
        message = 'Пересечение геометрии. Продолжить сохранение?',
      ) => {
        this.props.showNotification(message, continueAction);
      },
      saveObject,
    );
  };

  /**
   * Метод createOrUpdateObjectWithoutGeometry DTW.
   *
   * @param {Function} fetchAction - Жопа.
   * @param {object} objectAttributes - Атрибуты.
   * @returns {void} - Nothing.
   * @example ---
   */
  createOrUpdateObjectWithoutGeometryDTW = (fetchAction, objectAttributes) => {
    const { parentInfo, actionsTree } = this.props;

    const isChild = !!get(this.props, 'selected');

    const group = get(this.props, 'treeParams.group');
    const childObjectTypeId = objectAttributes.type_id || objectAttributes.type;
    const oldChildObjectId = objectAttributes.record_id || null;
    const pathObjectTypeId = get(group, 'type_path');
    const pathObjectId = get(group, 'recalc_path_object_id') || null;
    const rootObjectId = parentInfo.recordId;
    const rootObjectTypeId = parentInfo.typeId;

    const actionData = {
      ...objectAttributes,
      ...prepareActionsTree(actionsTree),
      childObjectTypeId,
      isChild,
      oldChildObjectId,
      pathObjectId,
      pathObjectTypeId,
      rootObjectId,
      rootObjectTypeId,
    };

    fetchAction(actionData, isChild)
      .then((id) => {
        if (id && isChild) {
          this.props.fetchTreeSelectedElement({ id });
          this.props.viewCard();
        }
      })
      .finally(() => {

        // this.props.clearActionsTree();
        clearCurrentCardCash();
      });
  };

  /**
   * Метод createOrUpdateObjectWithoutGeometry Egip.
   *
   * @param {Function} fetchAction - Жопа.
   * @param {object} objectAttributes - Атрибуты.
   * @returns {void} - Nothing.
   * @example ---
   */
  createOrUpdateObjectWithoutGeometryEgip = (fetchAction, objectAttributes) => {
    const { parentInfo, actionsTree } = this.props;

    const isChild = !!get(this.props, 'selected');

    const group = get(this.props, 'treeParams.group');
    const childObjectTypeId = objectAttributes.type_id || objectAttributes.type;
    const oldChildObjectId = objectAttributes.record_id || null;
    const pathObjectTypeId = get(group, 'type_path');
    const pathObjectId = get(group, 'recalc_path_object_id') || null;
    const rootObjectId = parentInfo.recordId;
    const rootObjectTypeId = parentInfo.typeId;

    const actionData = {
      ...objectAttributes,
      ...prepareActionsTree(actionsTree),
      childObjectTypeId,
      isChild,
      oldChildObjectId,
      pathObjectId,
      pathObjectTypeId,
      rootObjectId,
      rootObjectTypeId,
    };

    fetchAction(actionData, isChild)
      .then((id) => {
        if (id && isChild) {
          this.props.fetchTreeSelectedElement({ id });
          this.props.viewCard();
        }
      })
      .finally(() => {

        // this.props.clearActionsTree();
        clearCurrentCardCash();
      });
  };

  /**
   * Метод createOrUpdateObjectWithoutGeometry.
   *
   * @param {Function} fetchAction - Жопа.
   * @param {object} objectAttributes - Атрибуты.
   * @returns {void} - Nothing.
   * @example ---
   */
  createOrUpdateObjectWithoutGeometry = switchServisMap({
    dtw: this.createOrUpdateObjectWithoutGeometryDTW,
    egip: this.createOrUpdateObjectWithoutGeometryEgip,
  });

  /**
   * Метод deleteObject.
   *
   * @param {object} objectAttributes - Атрибуты.
   * @returns {void} - Nothing.
   * @example ---
   */
  deleteObject = (objectAttributes) => {
    const { sendDeleteCard, parentTypeId } = this.props;

    return sendDeleteCard(objectAttributes, parentTypeId);
  };

  /**
   * Метод updateObject.
   *
   * @param {object} objectAttributes - Атрибуты.
   * @returns {void} - Nothing.
   * @example ---
   */
  updateObject = (objectAttributes) => {
    const createOrUpdateObject = switchServisMap({
      dtw: this.createOrUpdateObjectDTW,
      egip: this.createOrUpdateObjectEgip,
    });

    createOrUpdateObject(this.props.sendUpdateCard, objectAttributes);
  };

  /**
   * Отрисовывает левую панель.
   *
   * @returns {JSX.Element} - JSX.
   * @example
   * -----
   */
  renderLeftPanel() {
    const {
      card,
      mode,
      selected,
      matching,
      matchingRegistryColumns,
      location: { pathname },
      treeJournal,
    } = this.props;

    return (
      <LeftPanel
        alert={this.alert}
        card={card}
        createObject={this.createObject}
        deleteOgh={this.deleteObject}
        entity={defineType.defineEntity(pathname || '')}
        fetchObjectsGeometry={this.props.fetchObjectsGeometry}
        redrawObject={(id) => this.redrawObject(id)}
        matching={matching}
        matchingRegistryColumns={matchingRegistryColumns}
        mode={mode}
        rollbackTreeChanges={this.props.rollbackTreeChanges}
        selected={selected}
        treeJournal={treeJournal}
        treeParams={this.props.treeParams}
        updateObject={this.updateObject}
        viewMatchingTechMode={this.props.viewMatchingTechMode}
      />
    );
  }

  /**
   * Отрисовывает панель редактирования карты.
   *
   * @returns {JSX.Element} - JSX.
   * @example
   * -----
   */
  renderMapEditPanel() {
    const {
      selected,
      card,
      mode,
      isMapLoaded,
      isCurrentObjectIsExternalSystem,
    } = this.props;

    return (
      <MapEditPanel
        card={card}
        isMapLoaded={isMapLoaded}
        isCurrentObjectIsExternalSystem={isCurrentObjectIsExternalSystem}
        mode={mode}
        selected={selected}
        showAlert={this.props.showAlert}
      />
    );
  }

  /**
   * Обычно создаётся с помощью JSX. Указывает React, что рендерить: DOM-узел или пользовательский компонент. Например, <div /> или <MyComponent />.
   *
   * @returns {JSX.Element} - JSX.
   * @example
   * ---------
   */
  render() {
    const { card, mode, selected, versionsModal } = this.props;
    // const { TreeOghModal } = this.props;
    const {
      toggleCompareReportVisibility,
      cancelLeaveCurrentElement,
      cancelMode,
      isCurrentObjectIsExternalSystem,
    } = this.props;
    const { showGeometryMissing } = this.state;

    const currentCard = selected || card;

    /**
     * Function closeCompareReport.
     *
     * @returns {void} - Nothing.
     * @example
     * ---------
     */
    const closeCompareReport = () => {
      toggleCompareReportVisibility();
    };

    return (
      <div className={cn('h-100 position-relative', this.props.className)}>
        {card && card.type_id && this.renderLeftPanel()}
        <RightPanel mapPanel={this.renderMapEditPanel()} />
        <LegendPanel />
        {this.props.Map({
          editable: Boolean(mode?.editMode && !isCurrentObjectIsExternalSystem),
          style: {
            bottom: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 0,
          },
        })}
        <DialogAlert
          maxWidth={'xl'}
          showCondition={versionsModal}
          title={`Сравнение версий объекта ${
            currentCard && currentCard.type_name_rus
          } ${currentCard && formatRootId(currentCard.root_id)}`}
          onAfterHiding={closeCompareReport}
        >
          <CompareReport typeId={(selected || card || {}).type_id} />
        </DialogAlert>
        <DialogAlert
          showCondition={showGeometryMissing}
          title="Не указана геометрия объекта"
          onAfterHiding={() => {
            this.setState({
              showGeometryMissing: false,
            });
          }}
        >
          Отобразите геометрию ОГХ перед сохранением.
        </DialogAlert>
        <DialogAction
          handleNo={() => {
            cancelMode();
          }}
          showCondition={mode && mode.editMode && mode.cancelMode}
          onCancel={() => {
            cancelMode();
          }}
          onOk={() => {
            if (mode.leavePageHandler) {
              mode.leavePageHandler();
            }
            cancelLeaveCurrentElement();
          }}
        />
      </div>
    );
  }
}
