import { useGetDictionary } from 'app/api/hooks/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const CLEAN_CATEGORY_IDS_NAME = 'clean_category_ids';

/**
 * MultiSelect __Категория по уборке__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const CleanCategoryIds = (props: SelectPropsType) => {
  const dict = 'clean_category';
  const { data } =
    useGetDictionary<{ id: number; attribute: { name: string } }[]>(dict);

  const options = (data || []).map((item) => ({
    id: item.id,
    name: item.attribute.name,
  }));

  return (
    // @ts-ignore
    <MultiSelect
      {...props}
      options={options}
      label={'Категория по уборке'}
      name={CLEAN_CATEGORY_IDS_NAME}
    />
  );
};
