import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const IS_ADAPTED_PEOPLE_LIMITED_MOBILITY_NAME =
  'is_adapted_people_limited_mobility';

const options = [
  { id: 1, name: 'Да' },
  { id: -1, name: 'Нет' },
];

/**
 * Select __Адаптирован для маломобильных групп населения__.
 *
 * @param props - Пропсы.
 * @returns Select Адаптирован для маломобильных групп населения.
 */
export const IsAdaptedPeopleLimitedMobility = (props: SelectPropsType) => {
  return (
    <Select
      {...props}
      options={options}
      label={'Адаптирован для маломобильных групп населения'}
      name={IS_ADAPTED_PEOPLE_LIMITED_MOBILITY_NAME}
    />
  );
};
