import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import { fetchCustomers } from 'core/uiKit/preparedInputs/selects/api';
import { removePreparation } from 'core/utils/hooks/usePreparation/preparation';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

export const CUSTOMER_ODH_NAME = 'customer_id';

/**
 * Заказчик.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 * @description `###### type = `AutocompleteAsync`
 * ```###### name = `customer``
 * ```
 * Заказчик   odh   customer
 * place=odh
 * ```
 *
 * ```js
 * import { CustomerOdh, CUSTOMER_ODH_NAME } from 'core/uiKit/preparedInputs';
 * ````
 */
export const CustomerOdh = (props) => {
  useEffect(() => {
    return () => {
      removePreparation(CUSTOMER_ODH_NAME);
    };
  }, []);

  return (
    <AutocompleteAsync
      {...props}
      fetchFunction={fetchCustomers('odh')}
      label={'Заказчик'}
      name={CUSTOMER_ODH_NAME}
    />
  );
};

CustomerOdh.propTypes = {

  /**
   * Очищаем инпут с сохранением значения.
   */
  clear: PropTypes.bool,

  /**
   * Быбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Вывод вспомогательного текста.
   */
  helperText: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение.
   */
  value: PropTypes.any,
};
