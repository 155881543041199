import { usePreparation } from 'core/utils/hooks/usePreparation';

import { CORRECTION_ID_NAME } from './CorrectionId';

/**
 * Returns an object with 'correction' property if 'value' is truthy.
 *
 * @returns
 */
export const usePreparationCorrectionId = () =>
  usePreparation(CORRECTION_ID_NAME, (value) => {
    if (value) {
      return { correction: value };
    }
  });
