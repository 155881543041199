import { ActsFF } from 'core/form/finalForm/fields/prepared/tables/documentTables/Acts.FF';
import { ConclusionsFF } from 'core/form/finalForm/fields/prepared/tables/documentTables/Conclusions.FF';
import { OriginalTechnicalDocumentationFF } from 'core/form/finalForm/fields/prepared/tables/documentTables/OriginalTechnicalDocumentation.FF';
import React from 'react';

/**
 * Таб документов __Общественные туалеты.
 *
 * @returns {Element}
 */
export const TabDocs = () => {
  return (
    <>
      <OriginalTechnicalDocumentationFF />
      <ActsFF />
      <ConclusionsFF />
    </>
  );
};
