/* eslint-disable  */

import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useState } from 'react';

import { useDrawGeometry } from './hooks/useDrawGeometry';
import { useGetTreePanelData } from './hooks/useGetTreePanelData/useGetTreePanelData';
import { useThrottleLoading } from './hooks/useThrottleLoading';
import { DataItem } from './Tree';

interface SetupTreeRightPanel {
  isLoading: boolean;
  /**
   * Флаг с состоянием.
   * Флаг устанавливается в true при каждом рендере компонента,
   * а затем автоматически сбрасывается в false через 1 секунду после каждого рендера,
   * если новый рендер не произошёл.
   */
  isLoadingRender: boolean;
  setOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
  open: boolean;
  openAll: boolean;
  parentId: number;
  treeData: {
    recordId: number;
    id: number;
    title: string;
    items: undefined | DataItem[];
    open: boolean;
    typeId: number;
  };
  /**
   * Функция с задержкой лоудер что бы отложить изменния флага как прекратиться пререрндер в течении 3 секунд.
   */
  setLoadingRender: (v: boolean) => void;
}

/**
 * Дерево __Список объектов__.
 *
 * @returns {SetupTreeRightPanel}
 */
export const useSetupTreeRightPanel = (): SetupTreeRightPanel => {
  const [open, setOpen] = useState<boolean>(true);
  const [openAll, setOpenAll] = useState<boolean>(false);
  const { treeData, isLoading } = useGetTreePanelData();
  const { recordId: parentId } = useGetParentCard();

  const { isLoadingRender, setLoadingRender } = useThrottleLoading();

  const { drawGeometry } = useDrawGeometry();

  return {
    // @ts-ignore
    drawGeometry,
    isLoading,
    isLoadingRender,
    open,
    openAll,
    parentId,
    setLoadingRender,
    setOpen,
    setOpenAll,
    // @ts-ignore
    treeData,
  };
};
