import { DISTRICT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/DistrictId';
import { usePreparation } from 'core/utils/hooks/usePreparation';

/**
 * Хук перевода поля DISTRICT_ID_NAME.
 *
 */
export const usePreparationDistrictList = () => {
  usePreparation(DISTRICT_ID_NAME, (value) => {
    if (value) {
      return { bti_object: value };
    }
  });
};
