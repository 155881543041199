import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  TOTAL_AREA_GEO_NAME,
  TotalAreaGeo,
} from 'core/uiKit/preparedInputs/numberField/TotalAreaGeo';
import React from 'react';
import { NumberPreparedPropsType } from 'types/inputs';

/**
 * FF Площадь объекта по пространственным координатам, кв.м..
 *
 * @param props - Пропсы.
 * @param props.disabled - Disabled.
 * @param props.otherProps - Остальные пропсы.
 * @returns FF Площадь объекта по пространственным координатам, кв.м..
 */
export const TotalAreaGeoFF = ({
  disabled = false,
  ...otherProps
}: NumberPreparedPropsType) => {
  return (
    <FieldFF
      disabled={disabled}
      name={TOTAL_AREA_GEO_NAME}
      component={TotalAreaGeo}
      {...otherProps}
    />
  );
};
