import { CheckboxCell } from 'core/uiKit/components/cells/Checkbox.Cell';
import { Cell } from 'types/table';

/**
 * Колонка __Адаптирован для маломобильных групп населения__.
 *
 * @type {{
 * accessor: 'is_adapted_people_limited_mobility',
 * Header: 'Адаптирован для маломобильных групп населения',
 * }}
 * @augments Cell
 */
export const is_adapted_people_limited_mobility: Cell = {
  Cell: CheckboxCell,
  Header: 'Адаптирован для маломобильных групп населения',
  accessor: 'is_adapted_people_limited_mobility',
  sortable: true,
};
