import {
  AddButtonOgh,
  Body,
  DialogCreateWithDXF,
  Header,
  Main,
  RegistryToolbar,
} from 'app/pages/components';
import { useGetOghTypes } from 'core/utils/hooks';
import React from 'react';

import { FilterBridge } from './Filter.Bridge';
import { PrintButtonBridge } from './PrintButton.Bridge';
import { TableBridge } from './Table.Bridge';

/**
 * Реестр __Мостов__.
 *
 * @returns Реестр мостов.
 */
const Bridge = () => {
  const oghType = useGetOghTypes('bridge');
  const typeId = oghType?.id;

  return typeId ? (
    <Main>
      <Header Filter={FilterBridge} />
      <Body>
        <RegistryToolbar title={oghType.name}>
          <AddButtonOgh typeCode={oghType.code} />
          <PrintButtonBridge {...{ typeId }} />
        </RegistryToolbar>
        <TableBridge typeId={typeId} />
        {/*@ts-ignore*/}
        <DialogCreateWithDXF typeId={typeId} />
      </Body>
    </Main>
  ) : null;
};

export default Bridge;
