import { CAR_CONDITION_NAME, CarCondition } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

export { CAR_CONDITION_NAME };

/**
 * ## Техническое состояние техники
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.dark] - Имя.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли компонент для редактирования.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const CarConditionFF = ({
  dark = false,
  disabled = false,
  ...otherProps
}) => {
  return (
    <Field name={CAR_CONDITION_NAME}>
      {(props) => {
        return (
          <CarCondition
            dark={dark}
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};
