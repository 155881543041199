import {
  CAR_EQUIPMENT_OWNER_ID_NAME as CAR_EQUIPMENT_OWNER_ID_NAME_D,
  CarEquipmentOwnerIdFF as CarEquipmentOwnerIdFFD,
} from './CarEquipmentOwnerId.FF';
import {
  CAR_EQUIPMENT_OWNERSHIP_ID_NAME as CAR_EQUIPMENT_OWNERSHIP_ID_NAME_D,
  CarEquipmentOwnershipIdFF as CarEquipmentOwnershipIdFFD,
} from './CarEquipmentOwnershipId.FF';
import {
  CAR_EQUIPMENT_TYPE_ID_NAME as CAR_EQUIPMENT_TYPE_ID_NAME_D,
  CarEquipmentTypeIdFF as CarEquipmentTypeIdFFD,
} from './CarEquipmentTypeId.FF';
import {
  CAR_FUNC_TYPE_GROUP_NAME as CAR_FUNC_TYPE_GROUP_NAME_D,
  CarFuncTypeGroupFF as CarFuncTypeGroupFFD,
} from './CarFuncTypeGroup.FF';
import {
  CAR_FUNC_TYPE_ID_NAME as CAR_FUNC_TYPE_ID_NAME_D,
  CarFuncTypeIdFF as CarFuncTypeIdFFD,
} from './CarFuncTypeId.FF';
import { CarGroupTypeFF as CarGroupTypeFFD } from './CarGroupType.FF';
import { CAR_GROUP_TYPE_NAME as CAR_GROUP_TYPE_NAME_D } from './CarGroupType.FF';
import {
  CAR_OWNERS_NAME as CAR_OWNERS_NAME_D,
  CarOwnersFF as CarOwnersFFD,
} from './CarOwners.FF';
import {
  CAR_SEASON_NAME as CAR_SEASON_NAME_D,
  CarSeasonFF as CarSeasonFFD,
} from './CarSeason.FF';
import {
  CUSTOMERS_NAME as CUSTOMERS_NAME_D,
  CustomersFF as CustomersFFD,
} from './Customers.FF';
import {
  ENTITY_CODE_NAME as ENTITY_CODE_NAME_D,
  EntityCodeFF as EntityCodeFFD,
} from './EntityCode.FF';
import {
  EXPLICATION_TYPE_NAME as EXPLICATION_TYPE_NAME_D,
  ExplicationTypeFF as ExplicationTypeFFD,
} from './ExplicationType.FF';
import {
  GPS_STATE_ID_NAME as GPS_STATE_ID_NAME_D,
  GpsStateIdFF as GpsStateIdFFD,
} from './GpsStateId.FF';
import {
  GRBS_SHORT_NAME as GRBS_SHORT_NAME_D,
  GrbsShortNameFF as GrbsShortNameFFD,
} from './GrbsShortName.FF';
import {
  GROUPING_NAME as GROUPING_NAME_D,
  GroupingFF as GroupingFFD,
} from './Grouping.FF';
import {
  INSTALL_DRNO_NAME as INSTALL_DRNO_NAME_D,
  InstallDrnoFF as InstallDrnoFFD,
} from './InstallDrno.FF';
import {
  INSTALL_DUT_NAME as INSTALL_DUT_NAME_D,
  InstallDutFF as InstallDutFFD,
} from './InstallDut.FF';
import {
  LEGAL_PERSON_ID_NAME as LEGAL_PERSON_ID_NAME_D,
  LegalPersonIdFF as LegalPersonIdFFD,
} from './LegalPersonId.FF';
import {
  SENSOR_KINDS_NAME as SENSOR_KINDS_NAME_D,
  SensorKindsFF as SensorKindsFFD,
} from './SensorKinds.FF';
import {
  SENSOR_STATE_NAME as SENSOR_STATE_NAME_D,
  SensorStateFF as SensorStateFFD,
} from './SensorState.FF';

export * from './BuildingsTypeSpecFF';
export * from './BuildingTypeFF';
export * from './CarConditionFF';
export * from './ContainerTypeFF';
export * from './CreateTypeFF';
export * from './DocumentStatus.FF';
export * from './Entity.FF';
export * from './LawnTypeFF';
export * from './LawnTypeFF';
export * from './LifeFormTypeFF';
export * from './LifeFormTypeFF';
export * from './ListTypeId.FF';
export * from './MatchingStatuses.FF';
export * from './OghStatus.FF';
export * from './OghTitleReason.FF';
export * from './OghTypeSelect.FF';
export * from './OkrugtFF';
export * from './OoptCategoryFF';
export * from './OoptMeaningFF';
export * from './OoptProfileFF';
export * from './OoptStatusFF';
export * from './OrgTypeFF';
export * from './OtherImprovementObjectTypeFF';
export * from './OwnerFF';
export * from './PlantationTypeFF';
export * from './PlantationTypeFF';
export * from './PlantTypeFF';
export * from './PlantTypeFF';
export * from './ReagentBaseTypeFF';
export * from './ReagentFF';
export * from './ReagentIdFF';
export * from './ReagentKindFF';
export * from './ReagentKindIdFF';
export * from './ReasonFF';
export * from './StageResolution.FF';
export * from './TechnicStatus.FF';
export * from './TypeIdTechnic.FF';

/**
 * @deprecated
 * @see используй из CarGroupType.FF
 */
export const CarGroupTypeFF = CarGroupTypeFFD;

/**
 * CAR_GROUP_TYPE_NAME.
 *
 * @deprecated
 * @see используй из CarGroupType.FF
 */
export const CAR_GROUP_TYPE_NAME = CAR_GROUP_TYPE_NAME_D;

/**
 * @deprecated
 * @see используй из CarEquipmentTypeId.FF
 */
export const CarEquipmentTypeIdFF = CarEquipmentTypeIdFFD;

/**
 * CAR_EQUIPMENT_TYPE_ID_NAME.
 *
 * @deprecated
 * @see используй из CarEquipmentTypeId.FF
 */
export const CAR_EQUIPMENT_TYPE_ID_NAME = CAR_EQUIPMENT_TYPE_ID_NAME_D;

/**
 * @deprecated
 * @see используй из InstallDrno.FF
 */
export const InstallDrnoFF = InstallDrnoFFD;

/**
 * INSTALL_DRNO_NAME.
 *
 * @deprecated
 * @see используй из InstallDrno.FF
 */
export const INSTALL_DRNO_NAME = INSTALL_DRNO_NAME_D;

/**
 * @deprecated
 * @see используй из InstallDut.FF
 */
export const InstallDutFF = InstallDutFFD;

/**
 * INSTALL_DUT_NAME.
 *
 * @deprecated
 * @see используй из InstallDut.FF
 */
export const INSTALL_DUT_NAME = INSTALL_DUT_NAME_D;

/**
 * CarFuncTypeGroupFF.
 *
 * @deprecated
 * @see используй из CarFuncTypeGroup.FF
 */
export const CarFuncTypeGroupFF = CarFuncTypeGroupFFD;

/**
 * CAR_FUNC_TYPE_GROUP_NAME.
 *
 * @deprecated
 * @see используй из CarFuncTypeGroup.FF
 */
export const CAR_FUNC_TYPE_GROUP_NAME = CAR_FUNC_TYPE_GROUP_NAME_D;

/**
 * CarSeasonFF.
 *
 * @deprecated
 * @see используй из CarSeason.FF
 */
export const CarSeasonFF = CarSeasonFFD;

/**
 * CAR_SEASON_NAME.
 *
 * @deprecated
 * @see используй из CarSeason.FF
 */
export const CAR_SEASON_NAME = CAR_SEASON_NAME_D;

/**
 * GpsStateIdFF.
 *
 * @deprecated
 * @see используй из GpsStateId.FF
 */
export const GpsStateIdFF = GpsStateIdFFD;

/**
 * CAR_SEASON_NAME.
 *
 * @deprecated
 * @see используй из CarSeason.FF
 */
export const GPS_STATE_ID_NAME = GPS_STATE_ID_NAME_D;

/**
 * CarFuncTypeIdFF.
 *
 * @deprecated
 * @see используй из CarFuncTypeId.FF
 */
export const CarFuncTypeIdFF = CarFuncTypeIdFFD;

/**
 * CAR_FUNC_TYPE_ID_NAME.
 *
 * @deprecated
 * @see используй из CarFuncTypeId.FF
 */
export const CAR_FUNC_TYPE_ID_NAME = CAR_FUNC_TYPE_ID_NAME_D;

/**
 * CarEquipmentOwnershipIdFF.
 *
 * @deprecated
 * @see используй из CarEquipmentOwnershipId.FF
 */
export const CarEquipmentOwnershipIdFF = CarEquipmentOwnershipIdFFD;

/**
 * CAR_EQUIPMENT_OWNERSHIP_ID_NAME.
 *
 * @deprecated
 * @see используй из CarEquipmentOwnershipId.FF
 */
export const CAR_EQUIPMENT_OWNERSHIP_ID_NAME =
  CAR_EQUIPMENT_OWNERSHIP_ID_NAME_D;

/**
 * CarEquipmentOwnerIdFF.
 *
 * @deprecated
 * @see используй из CarEquipmentOwnerId.FF
 */
export const CarEquipmentOwnerIdFF = CarEquipmentOwnerIdFFD;

/**
 * CAR_EQUIPMENT_OWNER_ID_NAME.
 *
 * @deprecated
 * @see используй из CarEquipmentOwnerId.FF
 */
export const CAR_EQUIPMENT_OWNER_ID_NAME = CAR_EQUIPMENT_OWNER_ID_NAME_D;

/**
 * SensorKindsFF.
 *
 * @deprecated
 * @see используй из SensorKinds.FF
 */
export const SensorKindsFF = SensorKindsFFD;

/**
 * SENSOR_KINDS_NAME.
 *
 * @deprecated
 * @see используй из SensorKinds.FF
 */
export const SENSOR_KINDS_NAME = SENSOR_KINDS_NAME_D;

/**
 * SensorStateFF.
 *
 * @deprecated
 * @see используй из SensorState.FF
 */
export const SensorStateFF = SensorStateFFD;

/**
 * SENSOR_STATE_NAME.
 *
 * @deprecated
 * @see используй из SensorState.FF
 */
export const SENSOR_STATE_NAME = SENSOR_STATE_NAME_D;

/**
 * EntityCodeFF.
 *
 * @deprecated
 * @see используй из EntityCode.FF
 */
export const EntityCodeFF = EntityCodeFFD;

/**
 * ENTITY_CODE_NAME.
 *
 * @deprecated
 * @see используй из EntityCode.FF
 */
export const ENTITY_CODE_NAME = ENTITY_CODE_NAME_D;

/**
 * CustomersFF.
 *
 * @deprecated
 * @see используй из Customers.FF
 */
export const CustomersFF = CustomersFFD;

/**
 * CUSTOMERS_NAME.
 *
 * @deprecated
 * @see используй из Customers.FF
 */
export const CUSTOMERS_NAME = CUSTOMERS_NAME_D;

/**
 * CarOwnersFF.
 *
 * @deprecated
 * @see используй из CarOwners.FF
 */
export const CarOwnersFF = CarOwnersFFD;

/**
 * CAR_OWNERS_NAME.
 *
 * @deprecated
 * @see используй из CarOwners.FF
 */
export const CAR_OWNERS_NAME = CAR_OWNERS_NAME_D;

/**
 * ExplicationTypeFF.
 *
 * @deprecated
 * @see используй из ExplicationType.FF
 */
export const ExplicationTypeFF = ExplicationTypeFFD;

/**
 * EXPLICATION_TYPE_NAME.
 *
 * @deprecated
 * @see используй из ExplicationType.FF
 */
export const EXPLICATION_TYPE_NAME = EXPLICATION_TYPE_NAME_D;

/**
 * Grouping.
 *
 * @deprecated
 * @see используй из Grouping.FF
 */
export const GroupingFF = GroupingFFD;

/**
 * GROUPING_NAME.
 *
 * @deprecated
 * @see используй из Grouping.FF
 */
export const GROUPING_NAME = GROUPING_NAME_D;

/**
 * GrbsShortName.
 *
 * @deprecated
 * @see используй из GrbsShortName.FF
 */
export const GrbsShortNameFF = GrbsShortNameFFD;

/**
 * GRBS_SHORT_NAME.
 *
 * @deprecated
 * @see используй из GrbsShortName.FF
 */
export const GRBS_SHORT_NAME = GRBS_SHORT_NAME_D;

/**
 * LegalPersonIdFF.
 *
 * @deprecated
 * @see используй из LegalPersonId.FF
 */
export const LegalPersonIdFF = LegalPersonIdFFD;

/**
 * LEGAL_PERSON_ID_NAME.
 *
 * @deprecated
 * @see используй из LegalPersonId.FF
 */
export const LEGAL_PERSON_ID_NAME = LEGAL_PERSON_ID_NAME_D;
