import { Customers, CUSTOMERS_NAME } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * ## Заказчик
 * ### React Final Form Field
 *
 * ###### type = `Select`
 * ###### name = `CUSTOMERS_NAME`
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.disabled] - Определяет, будет ли компонент заблокирован.
 * @param {object} [props.otherProps] - Другие пропсы.
 * @returns {JSX.Element}
 */
export const CustomersFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={CUSTOMERS_NAME}>
      {(props) => {
        return (
          <Customers
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { CUSTOMERS_NAME };
