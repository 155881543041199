import { useCheckingItIsChildOfImprovementObject } from 'app/pages/registry/ogh/hooks/useCheckingItIsChildOfImprovementObject';
import {
  CustomerFilter,
  customerParams,
  FilterFF,
  OkrugFilter,
  okrugParams,
  OwnerIdFilter,
  ownerIdParams,
} from 'core/form/filterForm';
import {
  SubTypeIdFilter,
  subTypeIdParams,
} from 'core/form/filterForm/components/autocompleteAsync/SubTypeId.Filter';
import {
  DateFromFilter,
  dateFromParams,
} from 'core/form/filterForm/components/dateFilters/DateFromFilter';
import {
  DateToFilter,
  dateToParams,
} from 'core/form/filterForm/components/dateFilters/DateToFilter';
import {
  OghStatusesFilter,
  oghStatusesParams,
} from 'core/form/filterForm/components/miltiSelect/OghStatusesFilter';
import {
  GrbsIdFilter,
  grbsIdParams,
} from 'core/form/filterForm/components/selects/GrbsId.Filter';
import {
  IdOrNameFilter,
  idOrNameParams,
} from 'core/form/filterForm/components/text/IdOrName.Filter';
import {
  IdOrNameParentFilter,
  idOrNameParentParams,
} from 'core/form/filterForm/components/text/IdOrNameParent.Filter';
import { useFilterQueryParams } from 'core/form/filterForm/hooks/useFilterQueryParams';
import React from 'react';

/**
 * @typedef {object} FilterQueryParamsType - Данные QueryParams для фильтра формы FF.
 * @property {object} prepareFilters - Отформатированые данные для бэка.
 * @property {Function} setFilters - Функция для переноса состояния формы в урл.
 * @property {object} filters - Обычные данные формы.
 */

/**
 * Хук QueryParams для фильтра Дорожно-тропиночные сети.
 *
 * @returns Фильтры.
 * @example --------
 * const { setFilters, filters, prepareFilters } = useFilterQueryParamsDts()
 */
export const useFilterQueryParamsDts = () => {
  return useFilterQueryParams([
    idOrNameParams,
    oghStatusesParams,
    dateFromParams,
    dateToParams,
    subTypeIdParams,
    grbsIdParams,
    okrugParams,
    idOrNameParentParams,
    ownerIdParams,
    customerParams,
  ]);
};

/**
 * Filter FF реестров Дорожно-тропиночные сети.
 *
 * @param {object} params - Parameters.
 * @param {number} params.typeId - Id типа огх.
 * @param {number} params.parentTypeId - Id типа родителя реестра.
 * @returns JSX.
 */
export const FilterDts = ({
  typeId,
  parentTypeId,
}: {
  typeId: number;
  parentTypeId: number;
}) => {
  const { setFilters, filters } = useFilterQueryParamsDts();
  const { isChildOfImprovementObject } =
    useCheckingItIsChildOfImprovementObject(parentTypeId);

  /**
   * Фукция submit фильтра формы FF.
   *
   * @param {object} values - Values.
   */
  const onSubmit = (values: Record<string, string>) => {
    setFilters(values, 'push');
  };

  /**
   * Функия сброса формы FF.
   *
   * @param {object} form - Параметры формы.
   * @param {Function} form.reset - Функция сброса формы FF.
   */
  const reset = ({ reset }: { reset: () => void }) => {
    reset();
    setFilters({}, 'replace');
  };

  return (
    <FilterFF {...{ initialValues: filters, onSubmit, reset }}>
      <IdOrNameFilter label={'ID'} />
      <IdOrNameParentFilter />
      <OghStatusesFilter />
      <DateFromFilter />
      <DateToFilter />
      <SubTypeIdFilter odsType={typeId} odsParentType={parentTypeId} />
      <GrbsIdFilter hidden={isChildOfImprovementObject} />
      <OkrugFilter />
      <CustomerFilter />
      <OwnerIdFilter />
    </FilterFF>
  );
};
