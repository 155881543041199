import { TextField } from 'core/uiKit/inputs/TextField';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Компонент текстового поля для использования с react-final-form, TextFieldFF.
 *
 * @param {object} props - Пропсы компонента.
 * @param {string} [props.color] - Цвет текстового поля.
 * @param {boolean} [props.dark] - Флаг для включения темной темы.
 * @param {boolean} [props.disabled] - Флаг для отключения поля.
 * @param {string} [props.helperText] - Вспомогательный текст.
 * @param {string} [props.label] - Лейбл для текстового поля.
 * @param {boolean} [props.multiline] - Флаг для включения многострочного режима.
 * @param {string} [props.name] - Имя поля.
 * @param {boolean} [props.required] - Флаг обязательности поля.
 * @param {string} [props.variant] - Вариант отображения текстового поля.
 * @param {object} [props.otherProps] - Дополнительные пропсы для передачи в TextField.
 * @param {number} [props.maxLength] - Максимальная длина текста.
 * @param {number} [props.minRows] - Минимальное количество строк.
 * @param {string} [props.className] - Класс.
 * @param {number} [props.maxRows] - Максимальное количество строк.
 * @param {Array} [props.mask] - Маска.
 * @returns {JSX.Element} - Компонент текстового поля.
 */
export const TextFieldFF = ({
  color = 'primary',
  dark = false,
  disabled = false,
  helperText = '',
  label = '',
  multiline = false,
  name = 'TextFieldFF',
  required = false,
  variant = 'standard',
  ...otherProps
}) => {
  return (
    <Field name={name} type="text">
      {({ input: { value, onChange }, meta }) => {
        return (
          <TextField
            {...{
              color,
              dark,
              disabled,
              helperText,
              label,
              multiline,
              required,
              variant,
              ...otherProps,
            }}
            {...{ name, onChange, value }}
            {...{ meta }}
          />
        );
      }}
    </Field>
  );
};
