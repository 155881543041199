import { CheckboxFF } from 'core/form/finalForm/fields/default';
import React from 'react';
import { CheckBoxFFPreparedPropsType } from 'types/inputs';

export const IS_DIFF_HEIGHT_MARK_NAME = 'is_diff_height_mark';

/**
 * Разновысотные отметки.
 *
 * @param props - Props.
 * @returns JSX.Element.
 */
export const IsDiffHeightMarkFF = (props: CheckBoxFFPreparedPropsType) => {
  return (
    <CheckboxFF
      {...props}
      name={IS_DIFF_HEIGHT_MARK_NAME}
      label={props.label || 'Разновысотные отметки'}
    />
  );
};
