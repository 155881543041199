import { Table } from 'core/uiKit/components/tables/Table';
import React from 'react';

import { TableOghChildrenProps } from '../../types';
import { getColumnsRedBookPlant } from './columns.RedBookPlant';
import { useSetupTableRedBookPlant } from './useSetup.Table.RedBookPlant';

/**
 * Таблица __Растения Красной книги__.
 *
 * @param {TableOghChildrenProps} props - Пропсы.
 * @returns JSX.
 * @example
 * <TableRedBookPlant typeId={typeId} parentTypeId={parentTypeId}/>
 */
export const TableRedBookPlant = (props: TableOghChildrenProps) => {
  const { table, isLoading, count } = useSetupTableRedBookPlant(props);

  return (
    <Table
      columns={getColumnsRedBookPlant()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
