import { FieldRF } from 'core/form/reduxForm/helpers/FieldRF';
import {
  TOTAL_AREA_GEO_NAME,
  TotalAreaGeo,
} from 'core/uiKit/preparedInputs/numberField/TotalAreaGeo';
import React from 'react';

/**
 * RF input - Площадь объекта по пространственным координатам, кв.м.
 *
 * @param {object} props - Properties.
 * @returns {JSX}
 */
export const TotalAreaGeoRF = (props) => {
  return (
    <FieldRF {...props} component={TotalAreaGeo} name={TOTAL_AREA_GEO_NAME} />
  );
};
