import { Document } from 'app/pages/cardsOgh/components/tables/Documents/Document';
import React from 'react';

/**
 * Обертка для таблиц документов final-form.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Свойства поля приходит из final form.
 * @param {string} props.title - Заголовок .
 * @param {number} props.id - Идентификатор.
 * @returns {React.ComponentType}
 */
export const DocumentWithFinalForm = ({ input, title, id, ...otherProps }) => {
  const { value, onChange } = input;

  return (
    <Document
      titleDialog={`Добавление файла (${title})`}
      title={title}
      dataTable={value || []}
      typeId={id}
      onChange={onChange}
      {...otherProps}
    />
  );
};
