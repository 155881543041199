import { CLEAN_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleanTypeId';
import { usePreparation } from 'core/utils/hooks/usePreparation/preparation';

/**
 * Хук перевода значений при сохранении для __Способ уборки__.
 *
 * @returns {void}
 */
export const usePreparationCleanTypeIdFF = () => {
  usePreparation(CLEAN_TYPE_ID_NAME, (value) => {
    if (value) {
      return { clean_type: value };
    }
  });
};
