import { Cell } from 'types/table';

/**
 * Колонка __Обслуживание объекта__.
 *
 * @type {{
 * accessor: 'object_service_name',
 * Header: 'Обслуживание объекта',
 * sortable: boolean
 * }}
 * @augments Cell
 */
export const object_service_name = {
  Header: 'Обслуживание объекта',
  accessor: 'object_service_name',
  sortable: true,
};
