import { Button as ButtonMUI } from '@mui/material';
import { CircularProgressMUI } from 'core/uiKit/material-ui';
import { Tooltip } from 'core/uiKit/material-ui';
import React from 'react';

import { ButtonProps } from './Button.d';

/**
 * Кнопка.
 *
 * @param props - Property.
 * @param props.className - ClassName.
 * @param props.loading - Флаг загрузки.
 * @param props.isLoading - Флаг загрузки.
 * @param props.disabled - Флаг отвечающий за доступность к вводу.
 * @param props.hidden - Спрятать элемент.
 * @param props.startIcon - Иконка.
 * @param props.toolTip - Тултип.
 * @param props.objectId - ObjectId.
 * @returns JSX.
 */
export const Button = ({
  className,
  loading,
  isLoading = loading,
  disabled,
  hidden,
  startIcon,
  toolTip = '',
  objectId,
  ...otherProps
}: ButtonProps) => {
  const buttonProps = {
    ...(objectId && { 'data-object-id': objectId }), // Исправлено
  };

  return !hidden ? (
    <Tooltip title={toolTip}>
      <span>
        <ButtonMUI
          startIcon={isLoading ? <CircularProgressMUI size={20} /> : startIcon}
          {...otherProps}
          className={className}
          disabled={disabled || isLoading}
          sx={{ whiteSpace: 'nowrap' }}
          {...buttonProps}
        />
      </span>
    </Tooltip>
  ) : null;
};
