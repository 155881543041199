import documentsReducer from 'app/components/card/ogh/DocumentsTab/mapStateToPropsReducer';
import { RANDOM_BOOL, RANDOM_ID } from 'core/forTesting/constants';
import { vi } from 'vitest';

import { mapStateToProps } from './LittleFormContainer';
vi.mock('app/components/card/ogh/DocumentsTab/mapStateToPropsReducer');
vi.mock('app/selectors/card/getUnitsByArrangeElementType');
vi.mock('app/selectors/card/getQuantityDigits');
vi.mock('app/selectors/card/hasQuantityLock');

describe('🐛 file LittleFormContainer', () => {
  it('🧪 should initialValues form', () => {
    expect.hasAssertions();

    //☣️ Arrange (всякие моки)
    documentsReducer.mockReturnValue((_) => _);

    const card = {
      attribute: {
        is_diff_height_mark: true,
      },
    };

    //🔥 Act
    const result = mapStateToProps({}, { card });

    //❓ Assert
    expect(result).toMatchObject({
      initialValues: {
        is_diff_height_mark: true,
      },
    });
  });

  it('🧪 should initialValues', () => {
    expect.hasAssertions();

    //☣️ Arrange (всякие моки)
    documentsReducer.mockReturnValue((_) => _);

    const card = {
      attribute: {
        is_diff_height_mark: RANDOM_BOOL,
        maf_type_level3_id: {
          maf_type_level3: RANDOM_ID,
        },
        material_id: {
          material: RANDOM_ID,
        },
      },
    };

    //🔥 Act
    const result = mapStateToProps({}, { card });

    //❓ Assert
    expect(result).toMatchObject({
      initialValues: {
        is_diff_height_mark: RANDOM_BOOL,
        maf_type_level3_id: RANDOM_ID,
        material_id: RANDOM_ID,
      },
    });
  });
});
