import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  OKRUG_BRIDGE_ID_NAME,
  OkrugBridgeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/OkrugBridgeId';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * SelectFF __Округ__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const OkrugBridgeIdFF = (props: SelectPropsType) => {
  return (
    <FieldFF {...props} component={OkrugBridgeId} name={OKRUG_BRIDGE_ID_NAME} />
  );
};
