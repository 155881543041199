import { getFieldError } from 'app/utils/validate';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import { get } from 'lodash';
import { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

/**
 * TransformDataBeforeFetch.
 *
 * @param {string} startsWith - Поисковая фраза.
 * @returns {{
 * maxRows: 12,
 * startsWith,
 * }}
 */
const transformDataBeforeFetch = (startsWith = '') => ({
  maxRows: 12,
  startsWith,
});

/**
 * Кастомный хук для настройки AutocompleteMultiAsync.
 *
 * @param {object} props - Пропсы.
 * @param {Function} props.fetchFunction - Функция запроса.
 * @param {Function} props.onChange - Функция изменения значения.
 * @param {object} props.meta - Мета данные.
 * @param {string} props.errorText - Текст ошибки.
 * @returns {object}
 */
export const useSetupAutocompleteMultiAsync = ({
  fetchFunction,
  onChange,
  meta,
  errorText,
}) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [inputValueDebounced] = useDebounce(inputValue, 300);

  const data = transformDataBeforeFetch(inputValueDebounced);
  const { data: res, isLoading } = useQueryAdaptor(
    ['' + fetchFunction.name, data],
    () => fetchFunction(data),
  );

  useMemo(() => {
    setOptions(res);
  }, [res]);

  /**
   * HandleChange.
   *
   * @param {object} event - The event.
   * @param {object} value - The value.
   * @returns {void}
   */
  const handleChange = (event, value) => {
    onChange(value);
  };

  const error = getFieldError(meta) || errorText;
  const isError = !!error;

  /**
   * GetByName.
   *
   * @param {*} value - The value.
   * @returns {string}
   */
  const getByName = (value) => get(value, 'name', '');

  return {
    error,
    getByName,
    handleChange,
    inputValue,
    isError,
    isLoading,
    options,
    setInputValue,
  };
};
