import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  ICE_RINK_TYPE_ID_NAME,
  IceRinkTypeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/IceRinkTypeId';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * Select Final Form. Тип катка.
 *
 * @param props - Пропсы.
 * @returns JSX.Element.
 */
export const IceRinkTypeIdFF = (props: SelectPropsType) => {
  return (
    <FieldFF
      {...props}
      name={ICE_RINK_TYPE_ID_NAME}
      component={IceRinkTypeId}
    />
  );
};
