import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';

/**
 * Name inputs. Вид МАФ.
 *
 * @constant
 * @type {'maf_type_level3_id'}
 */
export const MAF_TYPE_LEVEL_1_ID_NAME = 'maf_type_level1_id';

/**
 * Select __Вид МАФ__.
 *
 * @param {object} props - Properties.
 * @param {string} [props.label] - Лейбл.
 * @param {object} props.otherProps - Properties.
 * @returns {JSX.Element} - JSX.
 * @example --
 */
export const MafTypeLevel1Id = ({ label = 'Вид МАФ', ...otherProps }) => {
  const dict = 'mafTypeLevel1';
  const { data = [], isLoading } = useGetDictionary(dict);
  return (
    <Select
      {...otherProps}
      {...{
        isLoading,
        label,
        name: MAF_TYPE_LEVEL_1_ID_NAME,
        options: data,
      }}
    />
  );
};
