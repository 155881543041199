import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';

import { preparationMafTypeListLevel3 } from './preparationMafTypeListLevel3';

/**
 * Name inputs. Детализация.
 *
 * @constant
 * @type {'maf_type_level3_id'}
 */
export const MAF_TYPE_LEVEL_3_ID_NAME = 'maf_type_level3_id';

/**
 * Select Учредитель/ГРБС использует словарь grbs.
 * `name` - grbsId.
 *
 * @param {object} props - Properties.
 * @param {string} [props.label] - Лейбл.
 * @param {object} props.otherProps - Properties.
 * @returns {JSX.Element} - JSX.
 * @example --
 */
export const MafTypeLevel3 = ({ label = 'Детализация', ...otherProps }) => {
  const dict = 'mafTypeLevel3';
  const { data = [], isLoading } = useGetDictionary(dict);

  const options = data.map((item) => ({
    id: item.id,
    mafTypeLevel1: [item?.attribute?.maf_type_level1_id?.maf_type_level1 || 0],
    mafTypeList: preparationMafTypeListLevel3(item?.attribute?.maf_type_list),
    name: item.attribute.name,
  }));
  return (
    <Select
      {...otherProps}
      {...{
        isLoading,
        label,
        name: MAF_TYPE_LEVEL_3_ID_NAME,
        options,
      }}
    />
  );
};
