import { NumberFieldFFWrapper } from './NumberFieldFF.Wrapper';

/**
 * FieldSet.
 *
 * @param {object} props - Props.
 * @param {object} props.map - Map.
 * @returns {JSX.Element}
 */
export const FieldSet = ({ map }) => {
  return (
    <fieldset className={'dialog-edit-movement-characteristics__fieldset'}>
      <legend className={'dialog-edit-movement-characteristics__legend'}>
        {map.Header}
      </legend>
      <div className="two-column-container">
        {map.columns?.map((column) => {
          return !column.columns ? (
            <NumberFieldFFWrapper key={column.accessor} map={column} />
          ) : null;
        })}
      </div>
      <div className="two-column-container">
        {map.columns?.map((column) => {
          return column.columns ? (
            <FieldSet key={column.Header} map={column} />
          ) : null;
        })}
      </div>
    </fieldset>
  );
};
