import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';

/**
 * Setup Назначение для Дорожно-тропиночные сети (30 тип).
 *
 * @returns {{disabled: boolean}}
 */
export const useSetupDtsTypeIdRFField = () => {
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  const { editMode } = useMode();
  const { data = [] } = useGetDictionary('dtsType');
  const parent = useGetParentCard();

  const options = data.map(({ id, attribute, parent_ogh_object_type_list }) => {
    return {
      id: id,
      name: attribute?.name,
      parent_ogh_object_type_list,
    };
  });

  /**
   * Фильтрация.
   *
   * @param {Array} data - Данные.
   * @returns {Array} - Фильтрованные данные.
   */
  const filter = (data) => {
    return data.filter((item) =>
      item?.parent_ogh_object_type_list.includes(parent.type_id),
    );
  };

  return {
    disabled: !(editMode && isEditCurrentObjectIsExternalSystem),
    filter,
    options,
  };
};
