import moment from 'moment';

/**
 * Переводит время из строки в Moment.
 *
 * @param time - Время.
 * @example '13:00' => MomentData
 * @returns Moment Data.
 */
export const parseTimeStringToMoment = (time: string | undefined) => {
  return time ? moment(time, 'HH:mm') : undefined;
};
