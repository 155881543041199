import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import isMandatory from 'app/utils/card/isMandatory';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import React from 'react';

export const WIDTH_BEGIN_NAME = 'width_begin';

/**
 * Number Field RF __Ширина в начале, м__.
 *
 * @returns
 */
export const WidthBeginField = () => {
  const { typeId } = useGetCurrentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  return (
    <NumberFieldRF
      decimalScale={2}
      name={WIDTH_BEGIN_NAME}
      label={'Ширина в начале, м'}
      disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
      required={isMandatory(typeId, WIDTH_BEGIN_NAME, editMode)}
    />
  );
};
