import './Table.WorkTime.FF.scss';

import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import React from 'react';

import { TableWorkTime } from './Table.WorkTime';

export const WORK_TIME_NAME = 'workTime';

/**
 * WithFF Таблица график работы.
 *
 * @returns
 */
export const TableWorkTimeFF = () => {
  // @ts-ignore
  return <FieldFF component={TableWorkTime} name={WORK_TIME_NAME} />;
};
