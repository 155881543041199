import React from 'react';

import { DragWrapper } from './DragWrapper';
import { DragWrapperTooltip } from './DragWrapperTooltip';
import { useStorageDragPosition } from './hooks/useStorageDragPosition';
import { Position } from './types.DragWrapper';

interface IDragWrapperLocalStorage {
  children: React.ReactNode;
  initPosition?: Position;
  idLocalStorage: string;
}

/**
 * Обертка для драгендропа с локалстореджом.
 *
 * @param props - Пропсы.
 * @param props.children - Дети.
 * @param props.initPosition - Изначальная позиция.
 * @param props.idLocalStorage - Id которое записывается в локальное хранилище.
 * @returns React.ReactElement.
 */
export const DragWrapperLocalStorage = ({
  children,
  initPosition,
  idLocalStorage,
}: IDragWrapperLocalStorage) => {
  const { haveStorage, loadPosition, savePosition } =
    useStorageDragPosition('FAQService');

  const initPositionLocal = haveStorage ? loadPosition() : initPosition;

  return (
    <DragWrapper
      selector={`#${idLocalStorage}`}
      initPosition={initPositionLocal}
      onResizeEnd={savePosition}
      onDragEnd={savePosition}
    >
      <DragWrapperTooltip id={idLocalStorage}>{children}</DragWrapperTooltip>
    </DragWrapper>
  );
};
