import { DistrictBridgeIdsFF } from 'core/form/finalForm/fields/prepared/selects/multiSelect/DistrictBridgeIds.FF';
import { DISTRICT_BRIDGE_IDS_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/DistrictBridgeIds';
import React from 'react';
import { NumericArrayParam } from 'use-query-params';

import { filterInput } from '../../filterInputsClasses';

/**
 * Фильтр __Район__.
 *
 * @returns {JSX.Element}
 */
export const DistrictBridgeIdsFilter = () => {
  return <DistrictBridgeIdsFF className={filterInput} />;
};

export const districtBridgeIdsParams = {
  [DISTRICT_BRIDGE_IDS_NAME]: NumericArrayParam,
};
