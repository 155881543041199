import { Position } from 'core/uiKit/components/DragWrapper/types.DragWrapper';
import { useEffect } from 'react';

interface IseResizeAction {
  onResizeStart?: (position: Position) => void;
  onResizeEnd?: (position: Position) => void;
  position: Position;
  resizeStatus: 'start' | 'end' | 'idle';
}

/**
 * Действия во время ресайза.
 *
 * @param params - Парметры хука.
 * @param params.resizeStatus - Статус ресайза.
 * @param params.onResizeStart - Callback вызывается когда начинается ресайза.
 * @param params.onResizeEnd - Callback вызывается когда оканчивается ресайз.
 * @param params.position - Позиция.
 */
export const useResizeAction = ({
  resizeStatus,
  onResizeStart,
  onResizeEnd,
  position,
}: IseResizeAction) => {
  useEffect(() => {
    switch (resizeStatus) {
      case 'start': {
        onResizeStart?.(position);
        break;
      }
      case 'end': {
        onResizeEnd?.(position);
        break;
      }
      default: {
        break;
      }
    }
  }, [resizeStatus, onResizeStart, onResizeEnd, position]);
};
