import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { RANDOM_ID } from 'core/forTesting/constants';
import { vi } from 'vitest';

import { useSetupMafQuantityCharacteristicsField } from './useSetup.MafQuantityCharacteristics.Field';

vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('core/form/reduxForm/hooks/useField.RF');
vi.mock('app/api/hooks/useGetDictionary', () => ({
  useGetDictionary: vi.fn((dict) => ({ data: [dict] })),
}));

/**
 * Дефолтные пропсы.
 *
 */
const defaultProps = () => {
  useMode.mockReturnValue({});
  useFieldRF.mockReturnValue({});
};

describe('🐛 useSetupMafQuantityCharacteristicsField', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    defaultProps();

    //🔥 Act
    const res = useSetupMafQuantityCharacteristicsField();

    //❓ Assert
    expect(res).toStrictEqual({
      disabled: true,
      label: 'Количество мест, шт.',
      options: ['maf_type_level1'],
      required: false,
    });
  });

  it('🧪 options', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    defaultProps();
    useFieldRF.mockReturnValue({
      maf_type_level1_id: RANDOM_ID,
    });

    //🔥 Act
    const res = useSetupMafQuantityCharacteristicsField();

    //❓ Assert
    expect(res).toStrictEqual({
      disabled: true,
      label: 'Количество мест, шт.',
      options: ['maf_type_level1'],
      required: false,
    });
  });

  it('🧪 disabled', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useFieldRF.mockReturnValue({
      maf_type_level1_id: RANDOM_ID,
    });
    useMode.mockReturnValue({ editMode: true });

    //🔥 Act
    const { disabled } = useSetupMafQuantityCharacteristicsField();

    //❓ Assert
    expect(disabled).toBeTruthy();
  });
});
