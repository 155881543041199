import { useGetParentCard } from 'app/api/hooks/useGetParentCard';

/**
 * Setup __Вид МАФ__.
 *
 * @returns Вид МАФ.
 */
export const useSetupMafTypeLevel1IdField = () => {
  const { typeId } = useGetParentCard();

  /**
   * Функция фильтрации.
   *
   * @param {Array} dict - Справочник.
   * @returns Отфильтрованный справочник.
   */
  const filter = (
    dict: {
      id: number;
      name: string;
      parent_ogh_object_type_list?: number[];
    }[],
  ) => {
    return (dict || []).filter(({ parent_ogh_object_type_list }) =>
      parent_ogh_object_type_list?.includes(typeId),
    );
  };

  return { filter, typeId };
};
