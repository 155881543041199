import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';

/**
 * Setup для компонента MainParamsTab.
 *
 * @returns Артгументы.
 */
export const useSetupMainParamsTab = () => {
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  const { editMode } = useMode();
  const { typeId, calcAttribute } = useGetCurrentCard();

  return {
    calcAttribute,
    editMode,
    isEditCurrentObjectIsExternalSystem,
    typeId,
  };
};
