import {
  Organization,
  ORGANIZATION_NAME,
} from 'core/uiKit/preparedInputs/selects/autocompleteAsync/Organization';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * ## Организация
 * ### React Final Form Field
 *
 * ###### type = `AutocompleteAsync`
 * ###### name = `organization`
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли компонент для редактирования.
 * @param {object} [props.otherProps] - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const OrganizationFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={ORGANIZATION_NAME}>
      {(props) => {
        return (
          <Organization
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { ORGANIZATION_NAME };
