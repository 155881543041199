import { useGetDictionary } from 'app/api/hooks/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const BRIDGE_TYPE_LEVEL_2_IDS_NAME = 'bridge_type_level2_ids';

/**
 * MultiSelect __Назначение__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const BridgeTypeLevel2Ids = (props: SelectPropsType) => {
  const dict = 'bridge_type_level2';
  const { data } = useGetDictionary(dict);

  return (
    // @ts-ignore
    <MultiSelect
      {...props}
      options={data || []}
      label={'Назначение'}
      name={BRIDGE_TYPE_LEVEL_2_IDS_NAME}
    />
  );
};
