/**
 * Тип рекурсивно создаёт вложенный объект на основе пути.
 * Например, для пути `'a.b.c'` и значения `number` вернёт `{ a: { b: { c: number } } }`.
 */
type SetValueType<
  ObjectPath extends string,
  ValueType,
> = ObjectPath extends `${infer Key}.${infer Rest}`
  ? { [PropertyKey in Key]: SetValueType<Rest, ValueType> }
  : { [PropertyKey in ObjectPath]: ValueType };

/**
 * Создаёт объект с вложенной структурой на основе переданного пути и значения.
 *
 * @template ObjectPath - Путь к значению в объекте (например, 'a.b.c').
 * @template ValueType - Тип передаваемого значения.
 * @param path - Путь к значению в объекте.
 * @param value - Значение, которое нужно установить.
 * @returns Новый объект с вложенной структурой.
 *
 * @example
 * deepSet(undefined, 42); // 42
 * const obj = deepSet('a.b.c', 42);
 * console.log(obj); // { a: { b: { c: 42 } } }
 */
export const deepSet = <ObjectPath extends string, ValueType>(
  path: ObjectPath | null,
  value: ValueType,
): SetValueType<ObjectPath, ValueType> => {
  if (!path) return value as SetValueType<ObjectPath, ValueType>;

  const keys = path.split('.') as string[];
  return keys.reduceRight<unknown>(
    (acc, key) => ({ [key]: acc }),
    value,
  ) as SetValueType<ObjectPath, ValueType>;
};
