import {
  AutocompleteMUI,
  ChipMUI,
  CircularProgress,
  TextFieldMUI,
} from 'core/uiKit/material-ui';
import React from 'react';

import { useSetupAutocompleteMultiAsync } from './useSetup.AutocompleteMultiAsync';

/**
 * Асинхронное мульти автозаполнение.
 *
 * @param {*} props - The Properties.
 * @param {string} [props.className] - The name of the class.
 * @param {boolean} [props.disabled] - Заблокировать на редактирование.
 * @param {string} [props.errorText] - Текст ошибки.
 * @param {*} props.fetchFunction - Функция запроса.
 * @param {string} [props.helperText] - Вывод вспомогательного текста.
 * @param {string} props.label - The label.
 * @param {*} [props.meta] - The meta.
 * @param {Function} [props.onChange] - Функция вызова когда выберется итем.
 * @param {string} [props.placeholder] - The placeholder.
 * @param {boolean} [props.required] - Вывод информации об обязательном поле.
 * @param {{}} [props.value] - Значение.
 * @returns {JSX.Element}
 */
export const AutocompleteMultiAsync = ({
  className,
  disabled = false,
  errorText,
  fetchFunction,
  helperText = '',
  label = 'AutocompleteMultAsync',
  meta = {},
  onChange,
  placeholder = 'Начните вводить',
  required = false,
  value = [],
}) => {
  const {
    options,
    isLoading,
    handleChange,
    isError,
    error,
    getByName,
    setInputValue,
  } = useSetupAutocompleteMultiAsync({
    errorText,
    fetchFunction,
    meta,
    onChange,
    value,
  });

  return (
    <AutocompleteMUI
      className={className}
      disabled={disabled}
      filterOptions={(options) => options}
      getOptionLabel={(option) => getByName(option)}
      isOptionEqualToValue={(option, value) => {
        if (!value) {
          return [];
        }
        return option?.name === value?.name;
      }}
      limitTags={2}
      loading={isLoading}
      multiple={true}
      options={options || []}
      renderInput={(params) => (
        <TextFieldMUI
          {...params}
          required={required}
          error={isError}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label={label}
          placeholder={placeholder}
          helperText={error || helperText || <span>&nbsp;</span>}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <ChipMUI
            label={option.name}
            size="small"
            variant="outlined"
            {...getTagProps({ index })}
          />
        ))
      }
      style={{ width: '100%' }}
      value={value}
      loadingText={'Загрузка...'}
      openText="Открыть"
      closeText="Закрыть"
      clearText="Очистить"
      noOptionsText="Ничего не найдено"
      onChange={handleChange}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  );
};
