import { get } from 'app/api/crud/get';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

const FETCH_NOTIFICATION_INTERVAL = 60 * 1000; // 1 minute

/**
 * Загрузка счетчиков уведомлений о заявках.
 *
 * @returns UseFetchNotifications.
 */
export const useFetchNotifications = () => {
  const { data } = useQueryAdaptor<{
    all_request_count: number;
    delay_request_count: number;
  }>(['notification'], () => get('/notification/get'), {
    gcTime: FETCH_NOTIFICATION_INTERVAL,
    staleTime: FETCH_NOTIFICATION_INTERVAL,
  });

  return {
    allRequestCount: data?.all_request_count || 0,
    delayRequestCount: data?.delay_request_count || 0,
  };
};
