import { KeyboardDateTimePicker } from 'core/uiKit/inputs/datePickers/KeyboardDateTimePicker';
import React from 'react';

/**
 *  ## Выбор даты с временем
 *
 * @deprecated
 * @see используй KeyboardDateTimePicker
 *
 * @param {object} props - Пропсы.
 * @param {string} [props.name] - Имя поля.
 * @param {string} [props.label] - Подпись, отображаемая над полем даты и времени.
 * @param {object} [props.otherProps] - Другие пропсы.
 * @returns {JSX.Element}
 */
export const DateTimePicker = ({
  label = 'DateTimePicker',
  name = 'DateTimePicker',
  ...otherProps
}) => {
  return <KeyboardDateTimePicker {...{ label, name }} {...otherProps} />;
};
