import { TextField } from 'core/uiKit/inputs/TextField';
import React from 'react';
import { InputProps } from 'types/inputs';

export const CROSSING_NAME = 'crossing';

/**
 * Пересечение.
 *
 * @param props - Пропсы.
 * @returns <TextField/>.
 */
export const Crossing = (props: InputProps) => {
  // @ts-ignore
  return <TextField label={'Пересечение'} {...props} />;
};
