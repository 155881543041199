import { FormVisitIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/FormVisitId.FF';
import { FORM_VISIT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/FormVisitId';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export { FORM_VISIT_ID_NAME };

/**
 * Фильтр __Способ расположения__.
 *
 * @returns
 */
export const FormVisitIdFilter = () => (
  <FormVisitIdFF className={filterInput} />
);

/**
 * Параметры __Способ расположения__.
 *
 * @returns
 */
export const publicFormVisitIdParams = () => ({
  [FORM_VISIT_ID_NAME]: NumberParam,
});
