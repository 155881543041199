import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import React, { FC } from 'react';
import { SelectPropsType } from 'types/inputs';

import { fetchDistrictDetails } from './api';

export const DISTRICT_AL_NAME = 'district_al';

type DistrictAlProps = Omit<SelectPropsType, 'value' | 'onChange'> & {
  value: { id: number; name: string } | undefined;
  onChange: (value: { id: number; name: string } | undefined) => void;
  okrug_id?: number | undefined;
};

/**
 * Район.
 *
 * @param props - Properties.
 * @returns JSX.
 */
export const DistrictAl: FC<DistrictAlProps> = (props) => {
  return (
    <AutocompleteAsync
      {...props}
      {...{
        additionalDataForRequest: { okrug_id: props.okrug_id || 0 },
        fetchFunction: fetchDistrictDetails,
        fetchFunctionName: DISTRICT_AL_NAME,
        label: 'Район',
        name: DISTRICT_AL_NAME,
      }}
    />
  );
};
