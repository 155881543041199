import './InfoPanel.scss';

import cn from 'classnames';
import { Card } from 'core/uiKit/components/Card';
import {
  ArrowDropDownIcon,
  CardActions,
  CardContent,
  CardHeader,
  CloseIcon,
  IconButton,
  Typography,
} from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

/**
 * Диалоговое окно InfoPanel.
 *
 * @param {object} props - Пропсы.
 * @param {string} [props.maxWidth] - Максимальная ширина.
 * @param {string|JSX.Element} props.textHeader - Заголовок.
 * @param {JSX.Element} props.Body - JSX.
 * @param {JSX.Element} [props.ButtonsActions] - JSX.
 * @param {boolean} [props.isOpen] - Флаг открыть закрыть диалоговое окно.
 * @param {Function} [props.onClose] - Функция закрытия диалогового окна.
 * @param {boolean} [props.accordion] - Флаг активности аккардиона.
 * @returns {JSX.Element|null}
 */
export const InfoPanel = ({
  maxWidth = 'md',
  // fullWidth = true,
  textHeader,
  Body,
  ButtonsActions,
  isOpen = false,
  onClose = () => {},
  accordion = false,
}) => {
  const [bodyClose, setBodyClose] = useState(false);

  return isOpen ? (
    <Card
      elevation={24}
      className={`info-panel info-panel__draggable draggable text-left info-panel__size--${maxWidth}`}
    >
      {textHeader ? (
        <CardHeader
          className={'info-panel__title py-2'}
          title={
            <div className={'info-panel__header-container'}>
              <Typography variant="h6" component="h2">
                {textHeader}
              </Typography>
              {onClose ? (
                <IconButton
                  aria-label="close"
                  className={'close-button'}
                  onClick={onClose}
                >
                  <CloseIcon />
                </IconButton>
              ) : null}
              {accordion && (
                <IconButton
                  data-testId={'icon-hide-body'}
                  style={{ padding: 0 }}
                  className={cn('info-panel__icon-hide-body', {
                    'info-panel__icon-hide-body__active': bodyClose,
                  })}
                  fontSize={'large'}
                  onClick={() => setBodyClose((pre) => !pre)}
                >
                  <ArrowDropDownIcon color={'action'} fontSize={'large'} />
                </IconButton>
              )}
            </div>
          }
        />
      ) : null}
      <div
        data-testId={'info-panel__body'}
        className={cn('info-panel__body', {
          'info-panel__body__close': bodyClose,
        })}
      >
        {Body ? <CardContent>{Body}</CardContent> : null}
      </div>
      {ButtonsActions ? (
        <CardActions className={'justify-content-end'}>
          {ButtonsActions}
        </CardActions>
      ) : null}
    </Card>
  ) : null;
};

InfoPanel.propTypes = {

  /**
   * Текст тела окна.
   */
  Body: PropTypes.node,

  /**
   * Кастомные кнопки со своей логикой.
   */
  ButtonsActions: PropTypes.node,

  /**
   * Классы.
   */
  className: PropTypes.string,

  /**
   * Внешний флаг - отобразить окно, по умолчанию - нет.
   */
  isOpen: PropTypes.bool,

  /**
   * Максимальная ширина окна, по умолчанию - md.
   */
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xs']),
  //

  /**
  //  * растянуть диалоговое окно, по умолчанию - да
  //.
   */
  // fullWidth: PropTypes.bool,

  /**
   * Текст заголовка.
   */
  textHeader: PropTypes.node,

  /**
  //  * функция срабатывания при закрытии крестиком.
  //  * она же флаг показа оного
  //.
   */
  // onClose: PropTypes.func,
};
