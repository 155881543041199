import { useSwitchServisMap } from 'core/utils/map/useSwitchServisMap';
import { useEffect } from 'react';

import { useCheckGeometryByButtonDTW } from './useCheckGeometryBy.Button.DTW';
import { useCheckGeometryByButtonEgip } from './useCheckGeometryBy.Button.Egip';
import { useReduxCheckGeometryButton } from './useRedux.CheckGeometry.Button';

/**
 * Setup кнопки CheckGeometryButton.
 *
 * @returns Состояние кнопки.
 */
export const useSetupCheckGeometryButton = () => {
  const { hasIntersections, resetIntersectionsGeometry } =
    useReduxCheckGeometryButton();

  /**
   * Метод жизненого цикла componentWillUnmount.
   *
   * @returns Nothing.
   * @example .
   */
  useEffect(() => {
    return () => {
      resetIntersectionsGeometry();
    };
    // eslint-disable-next-line
  }, []);

  // @ts-ignore
  const checkGeometryByButton = useSwitchServisMap({
    dtw: useCheckGeometryByButtonDTW,
    egip: useCheckGeometryByButtonEgip,
  })();

  return {
    active: hasIntersections,
    isLoading: false,
    onClick: checkGeometryByButton,
  };
};
