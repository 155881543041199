import { CAR_GROUP_TYPE_NAME, CarGroupType } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * ## Группа техники
 *
 * ### React Final Form Field
 *
 * `type = Select`
 * `name = carGroupType`
 * `dict = carFuncTypeGroup`.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.disabled] - Определяет, будет ли компонент заблокирован.
 * @param {object} [props.otherProps] - Другие пропсы.
 * @returns {JSX.Element}
 */
export const CarGroupTypeFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={CAR_GROUP_TYPE_NAME}>
      {(props) => {
        return (
          <CarGroupType
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { CAR_GROUP_TYPE_NAME };
