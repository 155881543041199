import { CheckboxFF } from 'core/form/finalForm/fields';
import React from 'react';
import { BooleanParam } from 'use-query-params';

import { filterInput } from '../../filterInputsClasses';

const CULTURE_OBJECT_NAME = 'culture_object';

/**
 * CheckboxFF __Объект культурного наследия__.
 *
 * @returns CheckboxFF.
 */
export const CultureObjectFilter = () => {
  return (
    <CheckboxFF
      name={CULTURE_OBJECT_NAME}
      className={filterInput}
      label={'Объект культурного наследия'}
    />
  );
};

export const cultureObjectParams = {
  [CULTURE_OBJECT_NAME]: BooleanParam,
};
