import { useGetDictionary } from 'app/api/hooks/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const LOCATION_METHOD_ID_NAME = 'location_method_id';

interface ILocationMethodIdData {
  id: number;
  name: string;
}

/**
 * Select __Способ расположения__.
 *
 * @param props - Пропсы.
 * @returns Select Способ расположения.
 */
export const LocationMethodId = (props: SelectPropsType) => {
  const dict = 'location_method';

  const { data } = useGetDictionary<ILocationMethodIdData[]>(dict);

  return (
    <Select
      {...props}
      options={data || []}
      label={props?.label || 'Способ расположения'}
      name={LOCATION_METHOD_ID_NAME}
    />
  );
};
