import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import { fetchOwners } from 'core/uiKit/preparedInputs/selects/api/fetchOwners';
import PropTypes from 'prop-types';

export const OWNER_ID_NAME = 'owner_id';

/**
 * Балансодержатель.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const OwnerIdOdh = (props) => {
  return (
    <AutocompleteAsync
      {...props}
      fetchFunction={fetchOwners('odh')}
      label={'Балансодержатель'}
      name={OWNER_ID_NAME}
    />
  );
};

OwnerIdOdh.propTypes = {

  /**
   * Очищаем инпут с сохранением значения.
   */
  clear: PropTypes.bool,

  /**
   * Быбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Вывод вспомогательного текста.
   */
  helperText: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение.
   */
  value: PropTypes.any,
};
