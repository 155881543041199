import { PrintButtonOgh } from 'app/pages/components/buttons/PrintButton.Ogh';
import React from 'react';

import { useFilterQueryParamsBridge } from './Filter.Bridge';

/**
 * Кнопка печать для __Мостов__.
 *
 * @param props - Тип ОГХ.
 * @param props.typeId - Тип ОГХ.
 * @returns
 */
export const PrintButtonBridge = ({ typeId }: { typeId: number }) => {
  const { prepareFilters } = useFilterQueryParamsBridge();
  return <PrintButtonOgh {...{ prepareFilters, typeId }} />;
};
