import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  GET_BALANCE_DATE_NAME,
  GetBalanceDate,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/GetBalanceDate/GetBalanceDate';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * Select FF __Год принятия на баланс__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const GetBalanceDateFF = (props: SelectPropsType) => {
  return (
    <FieldFF
      {...props}
      component={GetBalanceDate}
      name={GET_BALANCE_DATE_NAME}
    />
  );
};
