import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import React from 'react';

import { FileGridKnowledgeBase } from '../FileGrid.KnowledgeBase';

/**
 * Плитка файлов ссылкой FF с видео проигрывателем.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.disabled] - Флаг редактирования.
 * @returns {React.ReactElement}
 */
export const FileGridKnowledgeBaseField = ({ disabled }) => {
  const { knowledgeBaseCode } = useGetParamsUrl();

  return knowledgeBaseCode === 'learning_material' ? (
    <FieldFF
      disabled={disabled}
      name={'file_list'}
      component={FileGridKnowledgeBase}
    />
  ) : null;
};
