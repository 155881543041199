import { Cell } from 'types/table';

/**
 * Колонка __Форма посещения__.
 *
 * @type {{
 * accessor: 'form_visit_name',
 * Header: 'Форма посещения',
 * }}
 * @augments Cell
 */
export const form_visit_name: Cell = {
  Header: 'Форма посещения',
  accessor: 'form_visit_name',
  sortable: true,
};
