import NavItem from 'app/components/common/NavBar/NavItem';
import { NSILazy } from 'app/pages/admin/nsi/NSI';
import { pathNsi } from 'app/pages/admin/nsi/NSI/path.Nsi';
import { pathAdmin } from 'app/pages/admin/path.Admin';
import { useUser } from 'app/pages/providers';
import React, { useEffect } from 'react';

/**
 * Link Админки НСИ в шапке сайта.
 *
 * @returns JSX.
 */
export const NSILink = () => {
  const { hasPermission } = useUser();

  const isShow = hasPermission(pathNsi.privilegeView);

  useEffect(() => {
    if (!isShow) {
      NSILazy.preload();
    }
  }, [isShow]);

  return isShow ? (
    <NavItem link={`/${pathAdmin.path}/${pathNsi.path}`}>
      {pathNsi.name}
    </NavItem>
  ) : null;
};
