import { CategoryToiletIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/CategoryToiletId.FF';
import { CATEGORY_TOILET_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CategoryToiletId';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export { CATEGORY_TOILET_ID_NAME };

/**
 * Фильтр __Категория (Туалета)__.
 *
 * @returns
 */
export const CategoryToiletIdFilter = () => (
  <CategoryToiletIdFF className={filterInput} />
);

/**
 * Параметры __Категория (Туалета)__.
 *
 * @returns
 */
export const publicCategoryToiletIdParams = () => ({
  [CATEGORY_TOILET_ID_NAME]: NumberParam,
});
