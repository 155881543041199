import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import React, { FC } from 'react';
import { AutocompleteAsyncPreparePropsType } from 'types/inputs';

import { fetchStreetDetails } from './api';

type StreetAlProps = AutocompleteAsyncPreparePropsType & {
  district_id?: number;
};

export const STREET_AL_NAME = 'street_al';

/**
 * Улица.
 *
 * @param props - Properties.
 * @returns JSX.
 */
export const StreetAl: FC<StreetAlProps> = (props) => {
  return (
    <AutocompleteAsync
      {...props}
      additionalDataForRequest={{ district_id: props.district_id || 0 }}
      fetchFunction={fetchStreetDetails}
      fetchFunctionName={STREET_AL_NAME}
      label={'Улица'}
      name={STREET_AL_NAME}
    />
  );
};
