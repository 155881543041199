import { useGetDictionary } from 'app/api/hooks/useGetDictionary';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { MAF_TYPE_LEVEL_1_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/MafTypeLevel1Id';

/**
 * Setup Number Field __Количество мест шт__.
 *
 * @returns
 */
export const useSetupMafQuantityCharacteristicsField = () => {
  const { data: options = [] } =
    useGetDictionary<{ quantity_name: string; id: number }[]>(
      'maf_type_level1',
    );
  const { editMode } = useMode();

  const { value: maf_type_level1_id } = useFieldRF<number>(
    MAF_TYPE_LEVEL_1_ID_NAME,
  );

  const option = options.find((item) => item.id === maf_type_level1_id);

  const label = option?.quantity_name || 'Количество мест, шт.';
  const disabled = !(option?.quantity_name && editMode);
  const required = Boolean(option?.quantity_name);

  return {
    disabled,
    label,
    options,
    required,
  };
};
