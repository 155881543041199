import { Transform } from 'app/pages/cardsOgh/helpers/Transform';

import { CLEAN_TYPE_ID_NAME } from './CleanTypeId';

export const cleanTypeIdTransform = new Transform<
  number,
  { clean_type: number }
>(
  CLEAN_TYPE_ID_NAME,
  (data) => ({ clean_type: data }),
  (data) => data.clean_type,
);
