import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { cards } from 'app/pages/cardsOgh/cards';
import { Layout } from 'app/pages/cardsOgh/components/Layout/Layout';
import { useStartEditModeCardCreateEffect } from 'app/pages/cardsOgh/hooks/useStartEditModeCardCreateEffect';
import React from 'react';

/**
 * Тост Карточки Огх.
 *
 * @param {object} props - Props.
 * @returns {JSX.Element}
 */
const CardOgh = (props) => {
  useStartEditModeCardCreateEffect();

  const { typeId } = useGetCurrentCard();

  const Component = cards[typeId]?.component;

  return Component ? (
    <Layout map={cards[typeId].map}>
      <Component {...props} />
    </Layout>
  ) : null;
};

export default CardOgh;
