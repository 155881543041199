import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  Address,
  ADDRESS_NAME,
} from 'core/uiKit/preparedInputs/textField/Address';
import React from 'react';
import { InputPreparedProps } from 'types/inputs';

/**
 * TextField FF __Адрес__.
 *
 * @param props - Пропсы.
 * @returns FieldFF.
 */
export const AddressFF = (props: InputPreparedProps) => {
  return <FieldFF {...props} component={Address} name={ADDRESS_NAME} />;
};
