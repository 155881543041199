import {
  CAR_CUSTOMERS_NAME as CAR_CUSTOMERS_NAME_D,
  CarCustomersFF as CarCustomersFFD,
} from './CarCustomers.FF';
import {
  PROVIDER_LIST_ID_NAME as PROVIDER_LIST_ID_NAME_D,
  ProviderListIdFF as ProviderListIdFFD,
} from './ProviderListId.FF';
import {
  USERNAME_NAME as USERNAME_NAME_D,
  UsernameFF as UsernameFFD,
} from './Username.FF';

export * from './CarCustomerListIdFF';

/**
 * @deprecated
 * @see используй из CarCustomers.FF
 */
export const CarCustomersFF = CarCustomersFFD;

/**
 * CAR_CUSTOMERS_NAME.
 *
 * @deprecated
 * @see используй из CarCustomers.FF
 */
export const CAR_CUSTOMERS_NAME = CAR_CUSTOMERS_NAME_D;

/**
 * ProviderListIdFF.
 *
 * @deprecated
 * @see используй из ProviderListId.FF
 */
export const ProviderListIdFF = ProviderListIdFFD;

/**
 * PROVIDER_LIST_ID_NAME.
 *
 * @deprecated
 * @see используй из ProviderListId.FF
 */
export const PROVIDER_LIST_ID_NAME = PROVIDER_LIST_ID_NAME_D;

/**
 * UsernameFF.
 *
 * @deprecated
 * @see используй из Username.FF
 */
export const UsernameFF = UsernameFFD;

/**
 * USERNAME_NAME.
 *
 * @deprecated
 * @see используй из Username.FF
 */
export const USERNAME_NAME = USERNAME_NAME_D;
