import 'assets/styles/dialogs.form.scss';

import { Button } from 'core/uiKit/components/buttons/Button';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import React, { FC } from 'react';
import { useForm } from 'react-final-form';

export interface DialogContinueFFDialogProps {

  /**
   * Тело диалогового окна.
   */
  Body: React.ReactElement;

  /**
   * Функция закрытия диалогового окна.
   */
  closeDialog: () => void;

  /**
   * Флаг загрузки.
   */
  isLoading?: boolean;

  /**
   * Флаг показа диалога.
   */
  isOpen: boolean;

  /**
   * Заголовок Диалога.
   */
  textHeader: string;

  /**
   * Флаг не доступности ввода.
   */
  disabled?: boolean;
}

/**
 * Диалог для формы Продолжить.
 *
 * @param {DialogContinueFFDialogProps} props - Properties.
 * @returns {JSX.Element}
 */
export const DialogContinueFFDialog: FC<DialogContinueFFDialogProps> = (
  props,
) => {
  const form = useForm();
  return (
    <Dialog
      className={'dialog__title_underline'}
      maxWidth="sm"
      textHeader={props.textHeader}
      isOpen={props.isOpen}
      Body={props.Body}
      ButtonsActions={
        <>
          <Button
            disabled={props.isLoading}
            variant={'outlined'}
            onClick={() => {
              form.reset();
              props.closeDialog();
            }}
          >
            Отменить
          </Button>
          <Button
            color={'info'}
            loading={props.isLoading}
            disabled={props.disabled}
            variant={'contained'}
            onClick={form.submit}
          >
            Продолжить
          </Button>
        </>
      }
    />
  );
};
