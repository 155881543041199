import { FieldRF } from 'core/form/reduxForm/helpers/FieldRF';
import {
  LAYOUT_LENGTH_NAME,
  LayoutLength,
} from 'core/uiKit/preparedInputs/numberField/layoutLength';
import React from 'react';
import { NumberPreparedPropsType } from 'types/inputs';

/**
 * Протяженность объекта по всем осям, п.м RF.
 *
 * @param props - Пропсы.
 * @returns
 */
export const LayoutLengthRF = (props: NumberPreparedPropsType) => {
  return (
    <FieldRF {...props} name={LAYOUT_LENGTH_NAME} component={LayoutLength} />
  );
};
