import { showComponent } from 'app/pages/cardsOgh/Bridge/helpers/showComponent';
import { CheckboxFF } from 'core/form/finalForm/fields/default/CheckboxFF';
import { OwnerIdFF } from 'core/form/finalForm/fields/prepared/selects/autocompleteAsync/OwnerIdFF';
import { BridgeTypeLevel1IdFF } from 'core/form/finalForm/fields/prepared/selects/simple/BridgeTypeLevel1Id.FF';
import { BridgeTypeLevel2IdFF } from 'core/form/finalForm/fields/prepared/selects/simple/BridgeTypeLevel2Id.FF';
import { CleanCategoryIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/CleanCategoryId.FF';
import { CreateTypeFF } from 'core/form/finalForm/fields/prepared/selects/simple/CreateTypeFF';
import { DistrictBridgeIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/DistrictBridgeId.FF';
import { GrbsIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/GrbsId.FF';
import { OkrugBridgeIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/OkrugBridgeId.FF';
import { AddressFF } from 'core/form/finalForm/fields/prepared/textField/Address.FF';
import { CrossingFF } from 'core/form/finalForm/fields/prepared/textField/Crossing.FF';
import { NameFF } from 'core/form/finalForm/fields/prepared/textField/Name.FF';
import { PlacementNameFF } from 'core/form/finalForm/fields/prepared/textField/PlacementName.FF';
import { ShortNameFF } from 'core/form/finalForm/fields/prepared/textField/ShortName.FF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TabsCardOGH } from 'core/uiKit/components/card.components/TabsCardOGH';
import { TabsCardOGHItem } from 'core/uiKit/components/card.components/TabsCardOGH/TabsCardOGH.Item';
import { OBJECT_SERVICE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/ObjectServiceId';
import React from 'react';
import { useField } from 'react-final-form';

import { CleaningAreasBridge } from './tabs/CleaningAreas.Bridge';
import { DocsBridge } from './tabs/Docs.Bridge';
import { InitialDataBridge } from './tabs/InitialData.Bridge';

/**
 * Таб __Свойства__.
 *
 * @returns PropertiesBridge.
 */
export const PropertiesBridge = () => {
  const {
    input: { value: objectServiceId },
  } = useField(OBJECT_SERVICE_ID_NAME);

  return (
    <>
      <FieldsSplitColumns>
        <CreateTypeFF />

        {showComponent({
          Component: <OwnerIdFF required={true} />,
          conditions: objectServiceId !== 2,
        })}
        <GrbsIdFF />
        <NameFF required={true} />
        <ShortNameFF required={true} />
        <BridgeTypeLevel1IdFF required={true} />
        <BridgeTypeLevel2IdFF required={true} />
        <CleanCategoryIdFF required={true} />
        <AddressFF required={true} />
        <OkrugBridgeIdFF required={true} />
        <DistrictBridgeIdFF required={true} />
        <PlacementNameFF />
        <CrossingFF />
        <CheckboxFF
          label={'Объект культурного наследия'}
          name={'culture_object'}
        />
      </FieldsSplitColumns>
      <TabsCardOGH>
        <TabsCardOGHItem tabTitle={'Исходные данные'}>
          <InitialDataBridge />
        </TabsCardOGHItem>
        <TabsCardOGHItem tabTitle={'Уборочные площади'}>
          <CleaningAreasBridge />
        </TabsCardOGHItem>
        <TabsCardOGHItem tabTitle={'Ремонты'}>
          <p>Ремонты</p>
        </TabsCardOGHItem>
        <TabsCardOGHItem tabTitle={'Документы'}>
          <DocsBridge />
        </TabsCardOGHItem>
        <TabsCardOGHItem tabTitle={'Инспекционный контроль'}>
          <p>Инспекционный контроль</p>
        </TabsCardOGHItem>
      </TabsCardOGH>
    </>
  );
};
