import {
  MATCHING_STATUSES_NAME,
  MatchingStatuses,
} from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

export { MATCHING_STATUSES_NAME };

/**
 * ## Состояние. Компонент MatchingStatuses.FF.
 *
 * ### React Final Form Field
 *
 * type = Select
 * name = state_id
 * dict = matchingStatus.
 *
 * @param {object} props - Props.
 * @param {boolean} [props.dark] - Темная тема.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли компонент для редактирования.
 * @param {object} [props.otherProps] - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const MatchingStatusesFF = ({
  dark = false,
  disabled = false,
  ...otherProps
}) => {
  return (
    <Field name={MATCHING_STATUSES_NAME}>
      {(props) => {
        return (
          <MatchingStatuses
            {...{ dark, disabled }}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};
