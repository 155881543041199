import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  OBJECT_SERVICE_IDS_NAME,
  ObjectServiceIds,
} from 'core/uiKit/preparedInputs/selects/multiSelect/ObjectServiceIds';
import React from 'react';
import { SelectPreparedPropsType } from 'types/inputs';

/**
 * MultiSelect __Обслуживание объекта__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const ObjectServiceIdsFF = (props: SelectPreparedPropsType) => {
  return (
    <FieldFF
      {...props}
      component={ObjectServiceIds}
      name={OBJECT_SERVICE_IDS_NAME}
    />
  );
};
