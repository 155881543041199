import { OWNER_ID_NAME, OwnerId } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Компонент OwnerIdFF.
 *
 * @param {object} props - Свойства компонента.
 * @param {boolean} [props.disabled] - Флаг, который отключает поле ввода (если `true`).
 * @param {boolean} [props.required] - Флаг, который отключает поле ввода (если `true`).
 * @param {object} [props.otherProps] - Дополнительные пропсы, которые будут переданы компоненту `OwnerId`.
 *
 * @returns {React.Element}
 */
export const OwnerIdFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={OWNER_ID_NAME}>
      {(props) => {
        return (
          <OwnerId
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { OWNER_ID_NAME };
