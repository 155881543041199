import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  Crossing,
  CROSSING_NAME,
} from 'core/uiKit/preparedInputs/textField/Crossing';
import React from 'react';
import { InputPreparedProps } from 'types/inputs';

/**
 * TextField FF __Пересечение__.
 *
 * @param props - Пропсы.
 * @returns FieldFF.
 */
export const CrossingFF = (props: InputPreparedProps) => {
  return <FieldFF {...props} component={Crossing} name={CROSSING_NAME} />;
};
