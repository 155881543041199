import { TYPE_ID_TECHNIC_NAME, TypeIdTechnic } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

export { TYPE_ID_TECHNIC_NAME };

/**
 * Тип запроса.
 *
 * ### React Final Form Field
 *
 * `type = Select`.
 * `name = TYPE_ID_TECHNIC_NAME`.
 *
 * @param {object} props - Props.
 * @param {boolean} [props.dark] - Темная тема.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли компонент для редактирования.
 * @param {object} [props.otherProps] - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const TypeIdTechnicFF = ({
  dark = false,
  disabled = false,
  ...otherProps
}) => {
  return (
    <Field name={TYPE_ID_TECHNIC_NAME}>
      {(props) => {
        return (
          <TypeIdTechnic
            {...{ dark, disabled }}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};
