import CreateOgh from 'app/components/card/ogh/index/create';
import CreateCardOgh from 'app/pages/cardsOgh/CreateCardOgh';
import { checkIsNewCard } from 'app/routes/components/bridgeMap';
import { ContextProviderDTW } from 'core/uiKit/components/DTW/contexts/ContextProvider.DTW';
import { useGetOghType } from 'core/utils/hooks/useGetOghType';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import { log } from 'core/utils/log';

/**
 * Мост связующий два типа Создания карточек. Новые и старые.
 *
 * @param {object} props - Properties.
 * @returns {JSX.Element}
 */
export const RoutingBridgeCreateOgh = (props) => {
  const { typeId } = useGetParamsUrl();

  const isNewCardCreate = checkIsNewCard(+typeId);

  log.info(
    `${isNewCardCreate ? 'Новая' : 'Старая'} карточка типа`,
    useGetOghType(+typeId),
  );

  const Component = isNewCardCreate ? CreateCardOgh : CreateOgh;

  return (
    <ContextProviderDTW>
      <Component {...props} />
    </ContextProviderDTW>
  );
};
