import { Cell } from 'types/table';

/**
 * Колонка __Категория__.
 *
 * @type {{
 * accessor: 'category_toilet_name',
 * Header: 'Категория',
 * }}
 * @augments Cell
 */
export const category_toilet_name: Cell = {
  Header: 'Категория',
  accessor: 'category_toilet_name',
  sortable: true,
};
