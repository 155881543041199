import { FieldRF } from 'core/form/reduxForm/helpers/FieldRF';
import {
  MAF_TYPE_LEVEL_2_ID_NAME,
  MafTypeLevel2,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/MafTypeLevel2';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * Select with RF MAF_TYPE_LEVEL_2_ID_NAME.
 *
 * @param props - Пропсы.
 * @returns JSX.Element MafTypeLevel2RF.
 */
export const MafTypeLevel2RF = (props: SelectPropsType) => {
  return (
    <FieldRF
      {...props}
      component={MafTypeLevel2}
      name={MAF_TYPE_LEVEL_2_ID_NAME}
    />
  );
};
