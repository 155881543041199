import { AreaField } from 'app/components/card/ogh/components/fields/numberFields/Area.Field';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/numberField/TotalAreaGeo.RF';
import { CoatingTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingTypeId.RF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import React from 'react';

/**
 * Component Characteristics.
 *
 * @param params - Parameters.
 * @param params.isCurrentObjectIsExternalSystem - True if current object is external system.
 * @param params.card - Card object.
 * @param params.isPointGeometry - True if current object is point geometry.
 * @param params.editMode - True if current object is edit mode.
 * @param params.required - True if current object required.
 * @param params.typeId - Type id.
 * @param params.changeFieldValue - S.
 * @param params.card.coating_group - W.
 * @param params.isEditCurrentObjectIsExternalSystem - Возможность редактирования объекта созданного из внешней системы.
 * @returns
 */
export const Characteristics = ({
  isEditCurrentObjectIsExternalSystem,
  card,
  editMode,
}: {
  isCurrentObjectIsExternalSystem?: boolean;
  isEditCurrentObjectIsExternalSystem?: boolean;
  card: { coating_group: number[] };
  isPointGeometry?: unknown;
  editMode?: boolean;
  required?: boolean;
  changeFieldValue?: (_: unknown) => unknown;
  typeId?: number;
}) => {
  return (
    <div id="characteristics">
      <FieldsSplitColumns>
        <SelectRF
          label={'Вид покрытия'}
          name={'coating_group_id'}
          options={card.coating_group}
          disabled={true}
        />
        <CoatingTypeIdRF
          filter={(dict: { code: string }[]) => {
            return dict.filter(({ code }) =>
              [
                'asphalt_concrete',
                'paving_stones',
                'tiled',
                'cement_concrete',
              ].includes(code),
            );
          }}
          {...{
            disabled: !(editMode && isEditCurrentObjectIsExternalSystem),
            label: 'Вид покрытия (уточнение)',
            required: true,
            withAll: false,
          }}
        />
        <AreaField />
        <TotalAreaGeoRF
          {...{
            decimalScale: 2,
            disabled: true,
          }}
        />
      </FieldsSplitColumns>
      {/*{elementFormGrid(*/}
      {/*  [*/}
      {/*    {*/}
      {/*      addition: {*/}
      {/*        FormHelperTextProps: {*/}
      {/*          error: true,*/}
      {/*        },*/}
      {/*        digits: 2,*/}
      {/*        positive: true,*/}
      {/*        required,*/}
      {/*        type: 'decimal',*/}
      {/*        zero: false,*/}
      {/*      },*/}
      {/*      component: AreaContainer,*/}
      {/*      editable:*/}
      {/*        editMode && !isPointGeometry && !isCurrentObjectIsExternalSystem,*/}
      {/*      formValue: true,*/}
      {/*      id: 'area',*/}
      {/*      label: 'Площадь, кв.м',*/}
      {/*      name: 'area',*/}
      {/*      props: {*/}
      {/*        changeFieldValue,*/}
      {/*        editMode,*/}
      {/*        isPointGeometry,*/}
      {/*      },*/}
      {/*    },*/}
      {/*  ],*/}
      {/*  2,*/}
      {/*  '',*/}
      {/*  typeId,*/}
      {/*  {},*/}
      {/*)}*/}
    </div>
  );
};
