import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import React, { FC } from 'react';
import { AutocompleteAsyncPreparePropsType } from 'types/inputs';

import { fetchOkrugDetails } from './api';

export const OKRUG_AL_NAME = 'okrug_al';

type OkrugAlProps = AutocompleteAsyncPreparePropsType;

/**
 * Округ.
 *
 * @param props - Properties.
 * @returns JSX.
 */
export const OkrugAl: FC<OkrugAlProps> = (props) => {
  return (
    // @ts-ignore
    <AutocompleteAsync
      {...props}
      fetchFunction={fetchOkrugDetails}
      fetchFunctionName={OKRUG_AL_NAME}
      label={'Округ'}
      name={OKRUG_AL_NAME}
    />
  );
};
