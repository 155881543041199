import {
  CAR_EQUIPMENT_OWNER_NAME,
  CarEquipmentOwner,
} from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * ## Владелец
 * ### React Final Form Field
 *
 * ###### type = `Select`
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.disabled] - Определяет, будет ли компонент заблокирован.
 * @param {object} [props.otherProps] - Другие пропсы.
 * @returns {JSX.Element}
 */
export const CarEquipmentOwnerFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={CAR_EQUIPMENT_OWNER_NAME}>
      {(props) => {
        return (
          <CarEquipmentOwner
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { CAR_EQUIPMENT_OWNER_NAME };
