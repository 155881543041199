import { usePreparation } from 'core/utils/hooks/usePreparation';

import { DEPARTMENT_ID_NAME } from './DepartmentId';

/**
 * Returns an object with 'department_type' property if 'value' is truthy.
 *
 * @returns
 */
export const usePreparationDepartmentId = () =>
  usePreparation(DEPARTMENT_ID_NAME, (value) => {
    if (value) {
      return { legal_person: value };
    }
  });
