import { CheckboxCell } from 'core/uiKit/components/cells/Checkbox.Cell';
import { Cell } from 'types/table';

/**
 * Колонка __Наличие терминала оплаты__.
 *
 * @type {{
 * accessor: 'is_availability_payment_terminal',
 * Header: 'Наличие терминала оплаты',
 * }}
 * @augments Cell
 */
export const is_availability_payment_terminal: Cell = {
  Cell: CheckboxCell,
  Header: 'Наличие терминала оплаты',
  accessor: 'is_availability_payment_terminal',
  sortable: true,
};
