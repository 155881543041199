import './Versions.scss';

import { DefaultFilters } from 'app/pages/cardsOgh/components/tables/Versions/Filters/DefaultFilters';
import { FilterForm } from 'app/pages/cardsOgh/components/tables/Versions/Filters/FilterForm';
import { Button } from 'core/uiKit/components/buttons/Button';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';

import { defaultColumns } from './columns';
import { DialogComparison } from './DialogComparison/DialogComparison';
import { useSetupVersions } from './useSetup.Versions';

/**
 * Компонент Versions.
 *
 * @param {object} props - Пропсы.
 * @param {JSX.Element} [props.Filters] - Компонент Фильтров.
 * @param {Array} [props.columns] - Массив колонок.
 * @returns {JSX.Element} - Компонент таблицы версии.
 * @example <Versions />
 */
const Versions = ({ Filters, columns }) => {
  const {
    isLoading,
    openDialog,
    resetTable,
    rows,
    submitSearch,
    table,
    warningMessage,
    startComparison,
    selectedRow,
    closeDialog,
  } = useSetupVersions();

  return (
    <div>
      <h4 className={'mt-3'}>Версии объекта</h4>
      <FilterForm submitSearch={submitSearch} resetTable={resetTable}>
        {Filters ? <Filters /> : <DefaultFilters />}
      </FilterForm>
      {rows.length === 2 && (
        <DialogComparison
          open={openDialog}
          closeDialog={closeDialog}
          rows={rows}
        />
      )}
      <div className={'table-card-version__wrapper'}>
        <p className={'table-card-version__message'}>{warningMessage}</p>
        <p>
          <Button
            className={'ms-2'}
            variant={'contained'}
            color={'primary'}
            onClick={startComparison}
          >
            Сравнение
          </Button>
        </p>
      </div>
      <div>
        <TableSimple
          columns={columns ?? defaultColumns}
          data={table}
          isLoading={isLoading}
          checkboxSelection={true}
          selectedRows={selectedRow}
        />
      </div>
    </div>
  );
};

export default Versions;
