import { Transform } from 'app/pages/cardsOgh/helpers/Transform';

import { ICE_RINK_TYPE_ID_NAME } from './IceRinkTypeId';

export const iceRinkTypeIdTransform = new Transform<
  number,
  { ice_rink_type: number }
>(
  ICE_RINK_TYPE_ID_NAME,
  (data) => ({ ice_rink_type: data }),
  (data) => data.ice_rink_type,
);
