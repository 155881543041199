import {
  AddButtonOgh,
  Body,
  DialogCreateWithDXF,
  Header,
  Main,
  RegistryToolbar,
  TreePanelOgh,
} from 'app/pages/components';
import { pathOgh } from 'app/pages/registry/ogh/Ogh/path.Ogh';
import { ReportsOzn } from 'app/pages/registry/ogh/ozn/Ozn/Reports.Ozn';
import { pathRegistry } from 'app/pages/registry/path.Registry';
import { useGetOghTypes } from 'core/utils/hooks';
import React from 'react';

import { treeOzn } from '../tree.Ozn';
import { FilterOzn } from './Filter.Ozn';
import { pathOzn } from './path.Ozn';
import { PrintButtonOzn } from './PrintButton.Ozn';
import { TableOzn } from './Table.Ozn';

/**
 * Реестр Объектов озеленения.
 *
 * @returns {JSX.Element}
 */
const Ozn = () => {
  const oghType = useGetOghTypes('ozn');
  const typeId = oghType.id;
  const path = `/${pathRegistry.path}/${pathOgh.path}/${pathOzn.path}`;

  return (
    <Main>
      <Header Filter={FilterOzn} />
      <Body>
        <RegistryToolbar showToggle={true} title={oghType.name}>
          <AddButtonOgh typeCode={oghType.code} />
          <PrintButtonOzn typeId={typeId} />
          <ReportsOzn typeId={typeId} />
        </RegistryToolbar>

        <div className={'d-flex h-100 overflow-hidden-x'}>
          <TreePanelOgh root={path} tree={treeOzn} />
          <TableOzn typeId={typeId} />
        </div>
        <DialogCreateWithDXF typeId={typeId} />
      </Body>
    </Main>
  );
};

export default Ozn;
