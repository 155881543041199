import { Table } from 'core/uiKit/components/tables/Table';
import React from 'react';

import { getColumnsBridge } from './columns.Bridge';
import { useSetupTableBridge } from './useSetup.Table.Bridge';

/**
 * Таблица __Мостов_.
 *
 * @param props - Пропсы.
 * @param props.typeId - Тип Мостов.
 * @returns Таблицу Мостов.
 */
export const TableBridge = ({ typeId }: { typeId: number }) => {
  const { count, isLoading, table } = useSetupTableBridge({ typeId });

  return (
    <Table
      columns={getColumnsBridge()}
      data={table}
      total={count}
      isLoading={isLoading}
    />
  );
};
