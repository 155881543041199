import React, { useCallback } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';

interface FieldFFProps {
  component: React.ElementType; // тип компонента
  name: string; // имя поля
  [key: string]: unknown; // остальные пропсы
}

/**
 * Обертка для Field.
 *
 * @param props - Пропсы.
 * @param props.component - Компонент.
 * @param props.name - Имя.
 * @returns JSX.Element.
 */
export const FieldFF: React.FC<FieldFFProps> = ({
  component: Component,
  name,
  ...otherProps
}) => {
  const ComponentMemo = useCallback(
    // @ts-ignore
    ({ input, ...props }: { input: FieldRenderProps }) => (
      <Component {...input} {...props} />
    ),
    [Component],
  );

  return <Field {...otherProps} name={name} component={ComponentMemo} />;
};
