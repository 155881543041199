import { get as getProjection, transform } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';

const def =
  '+proj=tmerc +ellps=bessel +towgs84=367.93,88.45,553.73,-0.8777,1.3231,2.6248,8.96 +units=m +lon_0=37.5 +lat_0=55.66666666667 +k_0=1 +x_0=0 +y_0=0';
proj4.defs('EPSG:MSK77', def);
register(proj4);

const msk77Projection = getProjection('EPSG:MSK77');
const wgs84Projection = getProjection('EPSG:4326');

/**
 * Преобразование точки из мск77 в geoJSON.
 *
 * @param coordinate - Точка.
 * @returns Array.
 */
export const toGeoJSON = (coordinate: Array<number>) => {
  return transform(coordinate, msk77Projection!, wgs84Projection!);
};
