import { FormCardFF } from 'app/pages/cardsOgh/components/FormCard.FF';
import React from 'react';

import { LeftPanelBridge } from './LeftPanel.Bridge';
import { useSetupBridge } from './useSetup.Bridge';
import { validateFormBridge } from './validateForm.Bridge';

/**
 * Карточка мостов.
 *
 * @class
 */
export const Bridge = () => {
  const { initialValues, preparingDataForAttributes } = useSetupBridge();

  return (
    <FormCardFF
      preparingDataForAttributes={preparingDataForAttributes}
      validate={validateFormBridge}
      initialValues={initialValues}
      loading={false}
    >
      <LeftPanelBridge />
    </FormCardFF>
  );
};
