import { IGeometryChildrenData } from 'types/api.d';

import { formatResponseToGeoJSON } from './formatResponseToGeoJSON';

type FormattedGeometry = ReturnType<typeof formatResponseToGeoJSON> &
  Pick<IGeometryChildrenData, 'id' | 'hint'>;

/**
 *  Форматирование ответа для карты DTW.
 *
 * @param data - Данные с бэка.
 * @returns Форматированные данные.
 */
export const prepareForDrawAllChildrenGeometry = (
  data: IGeometryChildrenData[],
) => {
  return data.reduce<FormattedGeometry[]>((acc, value) => {
    const accValue = {
      ...formatResponseToGeoJSON(value),
      hint: value.hint,
      id: value.id,
    };

    acc.push(accValue);

    return acc;
  }, []);
};
