import React from 'react';

/**
 * Функция получения массива табов из дочерних TabsCardOGHItem.
 *
 * @param children - Дети, обязательно должен быть передана дочка с названием TabsCardOGHItem, иначе таб не отобразится.
 * @returns
 */
export const getTabsFromChildren = (children: React.ReactNode): string[] => {
  if (Array.isArray(children)) {
    return children.reduce((arr, child) => {
      if (child.props?.tabTitle && !child?.props.hide) {
        arr.push(child.props.tabTitle);
      }
      return arr;
    }, []);
  }

  // Если children — это одиночный элемент
  // @ts-ignore
  if (React.isValidElement(children) && children.props?.tabTitle) {
    // @ts-ignore
    return [children.props.tabTitle];
  }

  // Если children — это не React-элемент (например, строка, число и т.д.)
  return [];
};
