/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

/**
 * Колонка __Краткое наименование__.
 *
 * @type {{
 * accessor: 'short_name',
 * Header: 'Краткое наименование',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const short_name = {
  Header: 'Краткое наименование',
  accessor: 'short_name',
  sortable: true,
};
