import { formatResponseToGeoJSON } from 'app/actions/geometryActions/fetchCheckGeometryIntersections.DTW';
import { checkParent } from 'app/api/hooks/helpers/checkParent';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGeometryMapParent } from 'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGeometryMapParent';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  IGeometryChildrenData,
  IGeometryIntersectionData,
  IGeometryNeighboursData,
} from 'types/api';

import { UseGetDTWTokensResult } from './hooks/useGetDTWTokens';
import { useGetGeometries } from './hooks/useGetGeometries';
import {
  DEFAULT_LAYER_INSTANCES,
  formatGeometriesToUpdate,
  GeometryUtils,
  mapGeometries,
} from './utils';
import { prepareForDrawAllChildrenGeometry } from './utils/formating/prepareForDrawAllChildrenGeometry';
import { prepareForIntersectionGeometry } from './utils/formating/prepareForIntersectionGeometry';
import { prepareForNeighboursGeometry } from './utils/formating/prepareForNeighboursGeometry';
import useCreateMapService from './utils/hooks/useCreateMapService';
import { useGeometryInstances } from './utils/hooks/useGeometryInstances';
import { useGeometryStorage } from './utils/hooks/useGeometryStorage';
import { useHandleGeometryClickEvent } from './utils/hooks/useHandleGeometryClickEvent';
import { useHandleGeometryHoverEvent } from './utils/hooks/useHandleGeometryHoverEvent';
import { useShowHintOnClick } from './utils/hooks/useShowHintOnClick';
import { useShowHintOnHover } from './utils/hooks/useShowHintOnHover';
import { Polyline } from './utils/Polyline';
import { trimCoordinates } from './utils/trimCoordinates';
import {
  DrawGeometryObjects,
  DrawMethod,
  GeometryTypes,
  Layers,
  MapContextState,
  MapglContextValue,
  MappedGeometries,
  MappedGeometryInstances,
  Marker,
  Polygon,
  RawGeometry,
  ResponseGeometryObjects,
} from './utils/types.d';

/**
 * Setup Контекст провайдер с апи карты.
 *
 * @returns Реакт компонент.
 */
export const useSetupMapglContextProvider = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);

  // @ts-ignore
  const { dataMap, parentId } = useGeometryMapParent();

  const currentCard = useGetCurrentCard();
  const isParent = checkParent(currentCard.typeId);
  const { editMode } = useMode();

  const [, setOnHoverHintElement] = useState<HTMLDivElement | null>(null);

  const onHoverHintElementRef = useRef<{
    closeHint: VoidFunction;
    element: HTMLDivElement | null;
    // eslint-disable-next-line
    setPosition(hoveredObject: RawGeometry | null, x: number, y: number): void;
  } | null>(null);

  const onClickHintElementRef = useRef<{
    closeHint: VoidFunction;
    element: HTMLDivElement | null;
    setOnCloseClickHandler(cb: MouseEventHandler | null): void;
    // eslint-disable-next-line
    setPosition(clickedObject: RawGeometry | null, x: number, y: number): void;
  } | null>(null);

  const [, setOnClickHintElement] = useState<HTMLDivElement | null>(null);

  const [{ id, mapgl }, setMapglContext] = useState<
    Omit<MapContextState, keyof UseGetDTWTokensResult>
  >({
    id: '',
    mapgl: null,
  });

  const $mapElement = useRef<HTMLDivElement | null>(null);

  const currentHoveredGeometryRef = useRef<Polygon | Polyline | Marker | null>(
    null,
  );

  const { mapService } = useCreateMapService(mapgl, id);

  const geometryUtils: GeometryUtils | null = useMemo(() => {
    if (!mapService?.map) return null;
    return new GeometryUtils(mapService);
  }, [mapService]);

  const loadedGeometries: ReturnType<typeof useGeometryStorage> | null =
    useGeometryStorage();

  const instances: ReturnType<typeof useGeometryInstances> | null =
    useGeometryInstances();

  const drawGeometries = useCallback<MapglContextValue['drawGeometries']>(
    (
      newGeometries?: Partial<Record<Layers, MappedGeometries>>,
      method?: DrawMethod,
    ): Record<Layers, MappedGeometryInstances> =>
      mapService?.drawGeometries({ method, newGeometries }) ||
      DEFAULT_LAYER_INSTANCES,
    // eslint-disable-next-line
    [mapService, isEditing, isDrawing, loadedGeometries],
  );

  /**
   * Сохраняет загруженные геометрии, и возвращает их в формате для отрисовки.
   *
   * @param geometries - Объект с геометриями для хранения в контексте.
   * @param geometries.adjacent - Массив объектов геометрий.
   * @param geometries.allChildren - Массив объектов геометрий.
   * @param geometries.children - Массив объектов геометрий.
   * @param geometries.copyDiff - Массив объектов геометрий.
   * @param geometries.districts - Массив объектов геометрий.
   * @param geometries.intersections - Массив объектов геометрий.
   * @param geometries.originalDiff - Массив объектов геометрий.
   * @param geometries.parent - Массив объектов геометрий.
   * @param geometries.reonArea - Массив объектов геометрий.
   * @param geometries.selected - Массив объектов геометрий.
   * @returns {object} Объект с геометриями для отрисовки.
   */
  const updateLoadedGeometries = useCallback(
    (geometries: Partial<Record<Layers, DrawGeometryObjects>>) => {
      if (geometries.intersections)
        loadedGeometries.intersections.set(
          mapGeometries(geometries.intersections),
        );

      if (geometries.districts)
        loadedGeometries.districts.set(mapGeometries(geometries.districts));

      if (geometries.parent)
        loadedGeometries.parent.set(mapGeometries(geometries.parent));

      if (geometries.selected)
        loadedGeometries.selected.set(mapGeometries(geometries.selected));

      if (geometries.children) {
        loadedGeometries.children.set(mapGeometries(geometries.children));
      }

      if (geometries.allChildren)
        loadedGeometries.allChildren.set(mapGeometries(geometries.allChildren));

      if (geometries.adjacent)
        loadedGeometries.adjacent.set(mapGeometries(geometries.adjacent));

      if (geometries.reonArea)
        loadedGeometries.reonArea.set(mapGeometries(geometries.reonArea));

      if (geometries.copyDiff)
        loadedGeometries.copyDiff.set(mapGeometries(geometries.copyDiff));

      if (geometries.originalDiff)
        loadedGeometries.originalDiff.set(
          mapGeometries(geometries.originalDiff),
        );

      return {
        adjacent: loadedGeometries.adjacent.get(),
        allChildren: loadedGeometries.allChildren.get(),
        children: loadedGeometries.children.get(),
        copyDiff: loadedGeometries.copyDiff.get(),
        districts: loadedGeometries.districts.get(),
        intersections: loadedGeometries.intersections.get(),
        originalDiff: loadedGeometries.originalDiff.get(),
        parent: loadedGeometries.parent.get(),
        reonArea: loadedGeometries.reonArea.get(),
        selected: loadedGeometries.selected.get(),
      };
    },
    [loadedGeometries],
  );

  /**
   * Получает геометрию в формате для получения пересечений.
   *
   * @deprecated Use getGeometry instead.
   */
  const getGeometries = useGetGeometries(mapService, currentCard.recordId);

  /**
   * Получает геометрию в формате для получения пересечений.
   */
  const getGeometry = useGetGeometries(mapService, currentCard.recordId);

  /**
   * Получает геометрию в формате для отправки на сервер.
   *
   * @param objectId - Id объекта.
   * @returns Геометрия в JSON.
   */
  const getGeometriesToJson = (objectId: number | string) => {
    const geometries = getGeometry(objectId);
    return {
      // @ts-ignore
      lines: JSON.stringify(trimCoordinates(geometries.lines)),
      // @ts-ignore
      points: JSON.stringify(trimCoordinates(geometries.points)),
      // @ts-ignore
      polygons: JSON.stringify(trimCoordinates(geometries.polygons)),
    };
  };

  /**
   * Отрисовка геометрий всех детей. С учетом форматирования которое приходит с бэка.
   */
  const drawAllChildrenGeometryWithFormat = useCallback(
    (data: IGeometryChildrenData[]) => {
      if (!mapService) throw new Error('mapService is not defined');

      const items = prepareForDrawAllChildrenGeometry(data);

      mapService.updateGeometriesData({
        intersections:
          // @ts-ignore
          formatGeometriesToUpdate(items, 'allChildren', mapService) || [],
      });
      return mapService.drawGeometries({ method: 'replaceAll' });
    },
    [mapService],
  );

  /**
   * Отрисовка геометрий пересечений.
   */
  const drawIntersections = useCallback(
    (items: ResponseGeometryObjects | ResponseGeometryObjects[]) => {
      if (!mapService) throw new Error('mapService is not defined');

      mapService.updateGeometriesData({
        intersections:
          formatGeometriesToUpdate(items, 'intersections', mapService) || [],
      });
      return mapService.drawGeometries({ method: 'replaceAll' });
    },
    [mapService],
  );

  /**
   * Отрисовка геометрий пересечений. С учетом форматирования которое приходит с бэка.
   *
   * @param data - Данные пересечений с бэка.
   * @returns Отрисовка геометрий.
   */
  const drawIntersectionsWithFormat = (data: IGeometryIntersectionData[]) => {
    const items = prepareForIntersectionGeometry(data);
    // @ts-ignore
    return drawIntersections(items);
  };

  /**
   * Прорисовать геометрии Смежных объектов.
   * С форматированием.
   *
   * @param data - Сырые данные от бэка.
   * @returns Отрисовка геометрий.
   */
  const drawNeighboursGeometryWithFormat = (
    data: IGeometryNeighboursData[],
  ) => {
    if (!mapService) throw new Error('mapService is not defined');

    const neighbours = prepareForNeighboursGeometry(data);

    const loadedGeometries = mapService?.updateGeometriesData({
      adjacent:
        formatGeometriesToUpdate(neighbours, 'adjacent', mapService) || [],
    });

    return mapService?.drawGeometries({
      method: 'replaceAll',
      newGeometries: loadedGeometries,
    });
  };

  /**
   * Отрисовка геометрий пересечений РЕОН . С учетом форматирования которое приходит с бэка.
   *
   * @param data - Данные пересечений с бэка.
   * @returns Отрисовка геометрий.
   */
  const drawReonIntersectionsWithFormat = (
    data: IGeometryIntersectionData[],
  ) => {
    if (!mapService) throw new Error('mapService is not defined');

    const items = prepareForIntersectionGeometry(data);

    mapService.updateGeometriesData({
      reonArea:
        // @ts-ignore
        formatGeometriesToUpdate(items, 'reonArea', mapService) || [],
    });
    return mapService.drawGeometries({ method: 'replaceAll' });
  };

  const drawLayers = useCallback(
    (newLayers: Partial<Record<Layers, DrawGeometryObjects>>) => {
      const newLoadedGeometries = updateLoadedGeometries(newLayers);
      return drawGeometries(newLoadedGeometries, 'replaceAll');
    },
    [updateLoadedGeometries, drawGeometries],
  );

  useHandleGeometryHoverEvent({
    geometryUtils,
    isDisabled: editMode || isEditing,
    mapService,
  });

  useHandleGeometryClickEvent({
    geometryUtils,
    isDisabled: isEditing,
    mapService,
  });

  useEffect(() => {
    mapService?.cancelEditing();

    // TODO: установить тут mode для карточки
    // callFunction();

    setIsEditing(false);
    setIsDrawing(false);
  }, [currentCard.recordId, mapService]);

  useEffect(() => {
    // @ts-ignore
    if (dataMap?.length && mapService?.isMapLoaded) {
      mapService.setIsGroupSelected(false);
      mapService.setSelectedId(currentCard.recordId);
      mapService.setRootId(parentId);
      mapService.setSelectedLayerType(isParent ? 'parent' : 'children');

      const formatted = formatGeometriesToUpdate(
        // @ts-ignore
        formatResponseToGeoJSON(dataMap),
        'parent',
        mapService,
      );

      mapService.updateGeometriesData({
        parent: formatted,
      });
      const instances = mapService.drawGeometries({
        method: 'replaceAll',
      });

      if (instances?.parent) mapService.zoomToGeometries(instances.parent);
    }

    // eslint-disable-next-line
  }, [isParent, parentId, dataMap, mapService, mapService?.isMapLoaded]);

  useShowHintOnHover({
    geometryUtils,
    isDisabled: isEditing,
    mapService,
    onHoverHintElementRef,
  });

  useShowHintOnClick({
    currentHoveredGeometryRef,
    geometryUtils,
    isDisabled: isEditing,
    mapService,
    onClickHintElementRef,
    onHoverHintElementRef,
  });

  useEffect(() => {
    if (mapService?.isMapLoaded) {
      //

      /**
       * Обработчик, который вызывается после окончания зуммирования карты.
       *
       */
      const handleMapZoomEnd = () => {
        const markers = Object.values(mapService.layers).reduce<Marker[]>(
          (acc, geometries) => {
            return acc.concat(
              (geometries as MappedGeometryInstances)[GeometryTypes.Point],
            );
          },
          [],
        );

        markers.forEach((marker) => {
          marker.userData.recalcHoverArea?.();
        });
      };
      mapService.map.on('zoomend', handleMapZoomEnd);
      mapService.map.on('moveend', handleMapZoomEnd);

      return () => {
        mapService.map.off('zoomend', handleMapZoomEnd);
        mapService.map.off('moveend', handleMapZoomEnd);
      };
    }
  }, [mapService, instances]);

  const value = useMemo(
    () => ({
      $mapElement,
      drawAllChildrenGeometryWithFormat,
      drawGeometries,
      drawIntersections,
      drawIntersectionsWithFormat,
      drawLayers,
      drawNeighboursGeometryWithFormat,
      drawReonIntersectionsWithFormat,
      geometryUtils,
      getGeometries,
      getGeometry,
      getGeometryToJson: getGeometriesToJson,
      id,
      instances,
      loadedGeometries,
      mapService,
      mapgl,
      onClickHintElementRef,
      onHoverHintElementRef,
      setIsDrawing,
      setMapglContext,
      setOnClickHintElement,
      setOnHoverHintElement,

      /**
       * Запускает редактирование геометрий.
       *
       */
      startEditing() {
        setIsEditing(true);
      },

      /**
       * Останавливает редактирование геометрий.
       *
       */
      stopEditing() {
        setIsEditing(false);
      },
      updateLoadedGeometries,
      zoomToGeometries:
        mapService?.zoomToGeometries.bind(mapService) || (() => {}),
      // ...valueEditing,
    }),

    // eslint-disable-next-line
    [
      drawGeometries,
      geometryUtils,
      getGeometries,
      drawLayers,
      id,
      instances,
      loadedGeometries,
      mapService,
      mapgl,
      drawIntersections,
      updateLoadedGeometries,
    ],
  );

  return {
    drawGeometries,
    geometryUtils,
    isDrawing,
    isEditing,
    loadedGeometries,
    mapService,
    recordId: currentCard.recordId,
    setIsDrawing,
    setIsEditing,
    value,
  };
};
