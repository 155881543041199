import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { parseDate } from 'app/components/card/common/parse';
import { useGetInitialValuesTablesDocuments } from 'app/pages/cardsOgh/components/tables/Documents/hooks/useGetInitialValuesTables';
import { useFilterQueryParamsVersionDefault } from 'app/pages/cardsOgh/components/tables/Versions/Filters/useFilterQueryParamsVersionDefault';
import { START_DATE_NAME } from 'core/form/finalForm/fields/prepared/datePickers/keyboardDatePickerFF/StartDate';
import { CLEAN_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleanTypeId';
import { COATING_GROUP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';
import { COATING_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingTypeId';

import { getAttributesByMapForForm } from '../../helpers/getAttributesByMap.ForForm';
import { mapAttribute } from '../mapAttribute';

/**
 * Хук установки изначального состояния полей для Катков.
 *
 * @returns Возвращаем объект полей по умолчанию.
 */
export const useInitialValueIceRink = () => {
  const { startDate, endDate, attribute } = useGetCurrentCard();

  const objTables = useGetInitialValuesTablesDocuments();
  const { filters: filtersVersionTable } = useFilterQueryParamsVersionDefault();

  // Используем вспомогательную функцию для получения данных из attribute
  const result = getAttributesByMapForForm(attribute, mapAttribute);

  return {
    ...objTables,
    ...filtersVersionTable,
    ...result,
    [CLEAN_TYPE_ID_NAME]: 1,
    [COATING_GROUP_ID_NAME]: 2,
    [COATING_TYPE_ID_NAME]: 60,
    [START_DATE_NAME]: parseDate(startDate),
    endDate: parseDate(endDate),
  };
};
