import { useFetchOghTable } from 'app/pages/registry/ogh/api/useFetchOghTable';
import { useParamsTable } from 'core/uiKit/components/tables/Table';

import { useFilterQueryParamsBridge } from './Filter.Bridge';

/**
 * Setup Таблицы __Мостов__.
 *
 * @param props - Пропсы.
 * @param props.typeId - Тип Мостов.
 * @returns Данные для таблицы.
 */
export const useSetupTableBridge = ({ typeId }: { typeId: number }) => {
  const { prepareFilters } = useFilterQueryParamsBridge();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TablePublicToilet',
    { ...queryTable, ...prepareFilters, typeId },
  ];

  return useFetchOghTable(queryKey);
};
