import 'assets/styles/dialogs.form.scss';

import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import React from 'react';
import { Form } from 'react-final-form';

import { ButtonsSaveDialog } from './ButtonsSaveDialog';

interface SaveFFDialogProps<VALUES = Record<string, unknown>> {

  /**
   * Тело диалога.
   */
  Body: React.ReactElement;

  /**
   *  Функция закрытия окна.
   */
  closeDialog: () => void;

  /**
   * Начальное состояние формы.
   */
  isLoading?: boolean;

  /**
   *  Внешний флаг - отобразить диалоговое окно, по умолчанию - нет.
   */

  isOpen: boolean;

  /**
   *  Текст заголовка.
   */
  textHeader: string;

  /**
   * При нажатии на кнопку "Сохранить" - вызвать функцию.
   */
  onSubmit: (values: VALUES) => void;

  /**
   *  Начальное состояние формы.
   */
  initialValues?: Partial<VALUES>;

  /**
   * Функция валидации.
   */
  validate?: (values: VALUES) => Record<string, string> | null;

  /**
   *  Текст кнопки сохранения.
   */
  textButtonSave?: string;

  /**
   * Максимальная ширина диалога.
   */
  maxWidth?: 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined;
}

/**
 * Диалоговое окно сохранения с Формой FF.
 *
 * @param {SaveFFDialogProps} props - Пропсы.
 * @returns JSX.Element.
 */
export const SaveFFDialog = <VALUES extends object>({
  Body,
  closeDialog = () => {},
  isLoading = false,
  isOpen = false,
  textHeader,
  onSubmit = (_) => _,
  initialValues,
  validate = () => null,
  textButtonSave = 'Сохранить',
  maxWidth = 'sm',
}: SaveFFDialogProps<VALUES>) =>
  isOpen ? (
    <Form<VALUES>
      initialValues={initialValues || {}}
      render={({ handleSubmit, form }) => {
        return (
          <form onSubmit={handleSubmit} className="form__wrapper">
            <Dialog
              className={'dialog__title_underline'}
              maxWidth={maxWidth}
              textHeader={textHeader}
              isOpen={isOpen}
              Body={Body}
              ButtonsActions={
                <ButtonsSaveDialog
                  isLoading={isLoading}
                  closeDialog={closeDialog}
                  form={form}
                  textButtonSave={textButtonSave}
                />
              }
            />
          </form>
        );
      }}
      onSubmit={(value) => {
        const errors = validate(value);
        if (errors) return errors;
        onSubmit(value);
      }}
    />
  ) : null;
