interface MafType {
  parent_ogh_object_type_list: number[];
  maf_type_level2_id: { maf_type_level2: number };
}

/**
 * Функция подготовки значений mafTypeList для дальнейшей фильтрации.
 *
 * @param mafTypeList - Список полей.
 * @returns Подготовленный mafTypeList.
 */
export const preparationMafTypeListLevel3 = (mafTypeList: MafType[]) => {
  return (mafTypeList || []).map((item) => {
    return {
      mafTypeLevel2Id: item?.maf_type_level2_id?.maf_type_level2,
      parentTypeIdList: item?.parent_ogh_object_type_list || [],
    };
  });
};
