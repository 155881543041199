import { QueryObserverResult } from '@tanstack/react-query';
import { log } from 'core/utils/log';
import { useEffect } from 'react';

import { AuthTokensDTW_Type } from '../../types';
import { useGetDTWTokens } from './useGetDTWTokens';

let refreshRequest: Promise<
  AuthTokensDTW_Type | QueryObserverResult<AuthTokensDTW_Type, Error>
> | null = null;

/**
 * Определяет является ли ответ результатом запроса токенов.
 *
 * @param result - Ответ на запрос токенов.
 * @returns {boolean}
 */
const isQueryObserverResult = (
  result: AuthTokensDTW_Type | QueryObserverResult<AuthTokensDTW_Type, Error>,
): result is QueryObserverResult<AuthTokensDTW_Type, Error> => {
  return 'data' in result;
};

/**
 * Содержит ли ответ токены.
 *
 * @param {object} result - Ответ на запрос токенов.
 * @returns {boolean}
 */
const isDigitalAuthTokens = (
  result: AuthTokensDTW_Type | QueryObserverResult<AuthTokensDTW_Type, Error>,
): result is AuthTokensDTW_Type => {
  return 'accessToken' in result || 'refreshToken' in result;
};

/**
 * Хук-перехвадчик запросов тайлов карты.
 *
 * @param onAuthError - Обработчик ошибки авторизации.
 */
export const useDecorateOnload = (
  onAuthError: (
    token: string,
  ) => Promise<
    AuthTokensDTW_Type | QueryObserverResult<AuthTokensDTW_Type, Error>
  >,
) => {
  const { setAccessToken, setRefreshToken, getRefreshToken, getAccessToken } =
    useGetDTWTokens();

  useEffect(() => {
    const originalDescriptor = Object.getOwnPropertyDescriptor(
      XMLHttpRequestEventTarget.prototype,
      'onload',
    );

    // Define a new property with a custom setter
    Object.defineProperty(XMLHttpRequest.prototype, 'onload', {
      ...originalDescriptor,

      /**
       * Сеттер.
       *
       * @param value - Обработчик onload.
       */
      set(value: (event: ProgressEvent<XMLHttpRequest>) => void) {
        //

        /**
         * Декоратор для сеттера. Рефрешит токен и вызывает оригинальный обработчик.
         *
         * @param this - ТС параметр для this контекст.
         * @param event - ProgressEvent объект.
         */
        const decorator = async function (
          this: XMLHttpRequest,
          event: ProgressEvent<XMLHttpRequest>,
        ) {
          let newTileRequestSent = false;
          const refreshTokenInLocalStorage = getRefreshToken();

          if (
            this.responseURL.includes(
              'https://proxy.carsharing.test.intermb.ru/2gis/tiles',
            )
          ) {
            if (this.status === 401) {
              if (refreshRequest === null) {
                try {
                  refreshRequest = onAuthError(refreshTokenInLocalStorage);

                  const response = await refreshRequest;
                  const { accessToken = '', refreshToken = '' } =
                    isQueryObserverResult(response)
                      ? response.data || {}
                      : isDigitalAuthTokens(response)
                      ? response
                      : {};

                  if (!accessToken || !refreshToken) {
                    throw new Error('No tokens in response');
                  }

                  setAccessToken(accessToken);
                  setRefreshToken(refreshToken);
                } catch (error) {
                  setAccessToken('');
                  setRefreshToken('');
                  log.error(error);
                }

                refreshRequest = null;
              }

              await refreshRequest;

              // create new XMLHttpRequest and pass eventFromNewRequest to value.call(this, eventFromNewRequest) on load
              if (event.target?.responseURL) {
                newTileRequestSent = true;
                const request = new XMLHttpRequest();
                const url = new URL(event.target.responseURL);
                url.searchParams.set('t', getAccessToken());

                request.open('GET', url);

                /**
                 * Set the onload handler.
                 *
                 * @param newEvent - ProgressEvent объект.
                 */
                request.onload = (newEvent: ProgressEvent<EventTarget>) => {
                  // Call the original `set` function
                  if (typeof value === 'function') {
                    value.call(this, newEvent as ProgressEvent<XMLHttpRequest>);
                  }
                };
                request.send();
              }
            }

            await refreshRequest;
          }

          // Call the original `set` function
          if (!newTileRequestSent && typeof value === 'function') {
            value.call(this, event);
          }
        };

        // Set the proxy function as the onload handler
        if (originalDescriptor?.set) {
          originalDescriptor.set.call(this, decorator);
        }
      },
    });

    return () => {
      if (originalDescriptor) {
        Object.defineProperty(
          XMLHttpRequest.prototype,
          'onload',
          originalDescriptor,
        );
      }
    };
    // eslint-disable-next-line
  }, []);
};
