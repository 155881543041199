import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import isMandatory from 'app/utils/card/isMandatory';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import React from 'react';

export const HEIGHT_NAME = 'height';

/**
 * Number Field RF __Высота__.
 *
 * @param props - Пропсы.
 * @param props.label - Лейбл.
 * @param props.disabled - Флаг редактируемости.
 * @returns
 */
export const HeightField = (props: { label?: string; disabled?: boolean }) => {
  const { typeId } = useGetCurrentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  return (
    <NumberFieldRF
      decimalScale={2}
      name={HEIGHT_NAME}
      label={props.label || 'Высота'}
      disabled={
        props.disabled ?? !(editMode && isEditCurrentObjectIsExternalSystem)
      }
      required={isMandatory(typeId, HEIGHT_NAME, editMode)}
    />
  );
};
