import { TitleDocumentsFF } from 'core/form/finalForm/fields/prepared/tables/documentTables/TitleDocuments.FF';
import React from 'react';

/**
 * Таб __Документы__.
 *
 * @returns DocsBridge.
 */
export const DocsBridge = () => {
  return (
    <>
      <TitleDocumentsFF />
    </>
  );
};
