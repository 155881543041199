import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  DISTRICT_ID_NAME,
  DistrictId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/DistrictId';
import React from 'react';

/**
 * Select FF __Район__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const DistrictIdFF = (props) => {
  return <FieldFF {...props} name={DISTRICT_ID_NAME} component={DistrictId} />;
};
