export const dts_type = [
  {
    attribute: {
      code: 'track',
      migr_code: [],
      name: 'Беговая дорожка',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
      ],
    },
    id: 103,
    parent_ogh_object_type_list: [38],
  },
  {
    attribute: {
      code: 'running_road',
      migr_code: [],
      name: 'Беговая дорожка',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
      ],
    },
    id: 50,
    parent_ogh_object_type_list: [40],
  },
  {
    attribute: {
      code: 'bicycle_lane',
      migr_code: ['BYKE_WAYS'],
      name: 'Велосипедная дорожка',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 32,
    parent_ogh_object_type_list: [38, 40, 49, 39],
  },
  {
    attribute: {
      code: 'artificial_road_roughness',
      migr_code: [],
      name: 'Искусственная дорожная неровность',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 42,
    parent_ogh_object_type_list: [38, 49, 39],
  },
  {
    attribute: {
      code: 'stairs',
      migr_code: [],
      name: 'Лестница',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
      ],
    },
    id: 105,
    parent_ogh_object_type_list: [38],
  },
  {
    attribute: {
      code: 'pedestrian_bridge',
      migr_code: [],
      name: 'Мост пешеходный',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
      ],
    },
    id: 101,
    parent_ogh_object_type_list: [49],
  },
  {
    attribute: {
      code: 'ramp',
      migr_code: [],
      name: 'Пандус',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
      ],
    },
    id: 104,
    parent_ogh_object_type_list: [38],
  },
  {
    attribute: {
      code: 'footpath',
      migr_code: ['FOOT_WAYS'],
      name: 'Пешеходная дорожка',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 27,
    parent_ogh_object_type_list: [38, 40, 49, 39],
  },
  {
    attribute: {
      code: 'passage',
      name: 'Проезд',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 25,
    parent_ogh_object_type_list: [38, 40, 49, 39],
  },
  {
    attribute: {
      code: 'relief_crosswalk',
      migr_code: [],
      name: 'Рельефный пешеходный переход',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
      ],
    },
    id: 102,
    parent_ogh_object_type_list: [38],
  },
  {
    attribute: {
      code: 'technical_sidewalk',
      migr_code: [],
      name: 'Технический (технологический) тротуар',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 43,
    parent_ogh_object_type_list: [38, 40, 49, 39],
  },
  {
    attribute: {
      code: 'sidewalk',
      migr_code: ['SIDE_WAYS'],
      name: 'Тротуар',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 26,
    parent_ogh_object_type_list: [38, 40, 49, 39],
  },
  {
    attribute: {
      code: 'eco_ways',
      migr_code: [],
      name: 'Экологические тропы',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
      ],
    },
    id: 100,
    parent_ogh_object_type_list: [49],
  },
];
