import { cellsMainMap } from 'core/uiKit/components/cells/cellsMainMap';

/**
 *  Колонки для таблицы реестра __Мостов__.
 *
 * @returns Возвращает колонки.
 */
export const getColumnsBridge = () => [
  cellsMainMap.short_root_id, // ID
  cellsMainMap.name, // Наименование
  cellsMainMap.short_name, // Краткое наименование
  cellsMainMap.owner_name, // Балансодержатель
  cellsMainMap.grbs_short_name, // ГРБС
  cellsMainMap.bridge_type_level1_name, // Тип объекта
  cellsMainMap.bridge_type_level2_name, // Назначение
  cellsMainMap.okrug_bridge_name, // Округ
  cellsMainMap.district_bridge_name, // Район
  cellsMainMap.address, // Адрес
  cellsMainMap.unit_name, // Единица измерения по пространственным координатам
  {
    ...cellsMainMap.quantity,
    Header: 'Количество по пространственным координатам',
  }, // Количество по пространственным координатам
  cellsMainMap.get_balance_date, // Год принятия на баланс
  cellsMainMap.start_date, // Действует с
  cellsMainMap.end_date, // Действует по
  cellsMainMap.object_status_name, // Статус
  cellsMainMap.bridge_state_name, // Состояние объекта
  cellsMainMap.manual_clean_area, // Площадь уборки, кв.м (ручная)
  cellsMainMap.auto_clean_area, // Площадь уборки, кв.м (механизированная)
  {
    ...cellsMainMap.total_clean_area,
    Header: 'Площадь уборки общая, кв.м',
  }, // Площадь уборки общая, кв.м
  cellsMainMap.object_service_name, // Обслуживание объекта
  cellsMainMap.clean_category_name, // Категория по уборке
  cellsMainMap.area, // Площадь в плане, кв.м
  cellsMainMap.length_pgm,
];
