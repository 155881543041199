import { ListTypeIdMulti } from 'core/uiKit/preparedInputs/selects/multiSelect/ListTypeIdMulti';
import React from 'react';

/**
 * Компонент обертка для Final Form.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Параметры инпута.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element} - JSX.
 * @example ----
 */
export const ListTypeIdMultiFFWithFF = ({ input, ...otherProps }) => {
  return <ListTypeIdMulti {...otherProps} {...input} />;
};
