import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import React from 'react';

import {
  DESCRIPTION_NAME,
  useSetupDescriptionField,
} from './useSetup.Description.Field';

/**
 * Text Field RF __Примечание__.
 *
 * @param props - Пропсы.
 * @param props.label - Лейбл.
 * @returns
 */
export const DescriptionField = (props: { label?: string }) => {
  const { editMode } = useSetupDescriptionField();
  return (
    <TextFieldRF
      name={DESCRIPTION_NAME}
      label={props.label || 'Примечание'}
      tooltip={DESCRIPTION_NAME}
      disabled={!editMode}
    />
  );
};
