import moment from 'moment';

/**
 * Функция получения годов от текущего - 100 (или укажите длину вычета от текущего года).
 *
 * @param length - Длина вычета.
 * @returns Возвращает массив годов.
 */
export const generateYearsArr = (length = 100) => {
  const currentYear = moment().year();
  return Array.from({ length: length + 1 }, (_, index) => currentYear - index);
};
