import { Cell } from 'types/table';

/**
 * Колонка __Площадь уборки, кв.м (механизированная)__.
 *
 * @type {{
 * accessor: 'auto_clean_area',
 * Header: 'Площадь уборки, кв.м (механизированная)',
 * sortable: boolean
 * }}
 * @augments Cell
 */
export const auto_clean_area = {
  Header: 'Площадь уборки, кв.м (механизированная)',
  accessor: 'auto_clean_area',
  sortable: true,
};
