import './LeftPanel.scss';

import { formatResponseToGeoJSON } from 'app/actions/geometryActions/fetchCheckGeometryIntersections.DTW';
import { leaveOghCard } from 'app/actions/odsLeaveCard';
import {
  cancelDeleteCard,
  cancelEditCard,
  cancelMatchCard,
  continueEditCard,
  editCard,
  startEditCard,
  startMatchingCard,
  viewCard,
} from 'app/actions/odsObjectActions';
import { checkObjectParent } from 'app/actions/odsObjectActions/checkObjectParent';
import { fetchOgh } from 'app/actions/odsObjectActions/fetchOgh';
import { fetchTreeSelectedElement } from 'app/actions/odsObjectTreeActions';
import { linkDxfFileForUpdate } from 'app/actions/uploadActions';
import { parseDate } from 'app/components/common/misc/ConverterFunctions';
import DialogAlert from 'app/components/dialogs/common/DialogAlert';
import DialogCreate from 'app/components/dialogs/common/DialogCreate';
import DialogDelete from 'app/components/dialogs/common/DialogDelete';
import DialogLock from 'app/components/dialogs/common/DialogLock';
import DialogMatching from 'app/components/dialogs/common/DialogMatching';
import DialogSendMatching from 'app/components/dialogs/common/DialogSendMatching';
import DialogUpdate from 'app/components/dialogs/common/DialogUpdate';
import { WithMapContext } from 'app/components/map/withMap';
import { RECEIVE_EDIT_CARD_ALLOW } from 'app/constants/actionTypes';
import { MAX_DATE } from 'app/constants/dates';
import { dialog } from 'app/constants/elementNames';
import { FOREGROUND } from 'app/constants/layers';
import { entityCode, operation } from 'app/constants/matching';
import {
  ACTIVE,
  APPROVED_EXTERNAL_SYSTEM,
  CANCEL,
  PROJECT,
} from 'app/constants/oghStatus';
import getInventoryPlanTypeId from 'app/selectors/registry/getInventoryPlanTypeId';
import { getRottenTypeMessage } from 'app/utils/card/getRottenTypeMessage';
import { createMessageResponse } from 'app/utils/createMessageResponse';
import { getServerTime } from 'app/utils/dateUtils';
import formatRootId from 'app/utils/formatRootId';
import LeftPanel, { LeftPanelContent } from 'core/components/LeftPanel';
import withShowControls from 'core/hocs/withShowControls';
import dateFormatService from 'core/services/dateFormatService';
import { formatGeometriesToUpdate } from 'core/uiKit/components/DTW/contexts/utils';
import { toast } from 'core/uiKit/components/Toast';
import { log } from 'core/utils/log';
import { switchServisMap } from 'core/utils/map/switchServisMap';
import { flow, get, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, SubmissionError, submit } from 'redux-form';

import { BtnGroupOGH } from './components/BtnGroup.OGH';

/**
 * Функция joinIds.
 *
 * @param {object} parentInfo - Объект информации о родителе, берется из карточки.
 * @returns {Array | null} - Данные.
 * @example joinIds(parentInfo)
 */
function joinIds(parentInfo) {
  let result;
  if (parentInfo) {
    const sortedParentInfo = uniqBy(parentInfo, 'parent_root_id');
    result = sortedParentInfo
      .map((item) => formatRootId(item.parent_root_id))
      .join(', ');
  } else {
    result = null;
  }
  return result;
}

const propTypes = {
  alert: PropTypes.func,
  card: PropTypes.shape({
    logical_state_id: PropTypes.number,
  }),
  inventoryPlanTypeId: PropTypes.number,
};

/**
 * Компонент CardLeftPanel.
 *
 * @returns {JSX.ElementClass} - Жопа.
 */
class CardLeftPanel extends React.Component {

  /**
   * Конструктор.
   *
   * @param {object} props - Пропсы компонента.
   * @example -
   */
  constructor(props) {
    super(props);

    const serverTime = getServerTime().set({
      hour: 0,
      milliseconds: 0,
      minute: 0,
      seconds: 0,
    });

    this.tomorrow = serverTime.add({
      days: 1,
    });

    this.today = serverTime.clone();
  }

  static contextType = WithMapContext;

  /**
   * Метод componentWillUnmount.
   *
   * @returns {void} - Nothing.
   * @example -
   */
  componentWillUnmount() {
    this.props.viewCard();
  }

  /**
   * Функция закрытия по кнопке.
   */
  handleCloseRootTreeStateChangedAlert = () => {
    const { selected, currentCard, fetchTreeSelectedElement, fetchOgh } =
      this.props;

    this.cancelEdit();

    const recordId = currentCard.record_id;

    if (selected) {
      // если в дереве выбрана дочка
      fetchTreeSelectedElement({ id: recordId });
    } else {
      fetchOgh(recordId);
    }
  };

  /**
   * Функция клика по кнопке.
   *
   */
  handleEditButtonClick = () => {
    const {
      selected,
      rootIsProject,
      recordId,
      card: { start_date: startDate },
      checkObjectParent,
      alert,
    } = this.props;

    if (!selected && rootIsProject) {
      checkObjectParent(recordId, startDate).then((parentInfo) => {
        const ids = joinIds(parentInfo);
        if (ids) {
          alert(
            `Данная версия объекта является элементом объекта(ов) с id ${ids}. Редактирование невозможно`,
          );
        } else {
          this.startEdit();
        }
      });
    } else {
      this.startEdit();
    }
    this.props.clearCacheVisibilityButtonsOgh();
  };

  /**
   * Метод StartEdit.
   *
   * @returns {void}
   */
  startEdit() {
    const { currentCard, fetchObjectsGeometry, startEditCard, rootCard } =
      this.props;

    const { clearLayer, drawCurrentObjectsGeometry } = this.context;

    const typeId = currentCard.type_id;
    const recordId = currentCard.record_id;
    const rootId = currentCard.root_id;
    const rootIsProject =
      rootCard.logical_state_id === PROJECT ||
      rootCard.logical_state_id === APPROVED_EXTERNAL_SYSTEM ||
      rootCard.logical_state_id === CANCEL;

    /**
     * Метод redrawGeometry для EGIP.
     *
     * @returns {Promise<void>}
     */
    const redrawGeometryEgip = async () => {
      const geometry = await fetchObjectsGeometry({
        ids: [recordId],
      });
      await clearLayer(FOREGROUND);
      drawCurrentObjectsGeometry(geometry);
    };

    /**
     * Метод redrawGeometry дгя DTW.
     *
     * @returns {Promise<void>}
     */
    const redrawGeometryDTW = async () => {
      const { zoomToGeometries, mapService } = this.context;

      const geometry = formatResponseToGeoJSON(
        await fetchObjectsGeometry({
          ids: [recordId],
        }),
      );

      const parent = mapService.geometriesData.parent;
      const children = mapService.geometriesData.children;

      const layerType = (
        parent.point.find(
          (geometry) => geometry.options.userData.oghObjectId === recordId,
        ) ||
        parent.polyline.find(
          (geometry) => geometry.options.userData.oghObjectId === recordId,
        ) ||
        parent.polygon.find(
          (geometry) => geometry.options.userData.oghObjectId === recordId,
        ) ||
        children.point.find(
          (geometry) => geometry.options.userData.oghObjectId === recordId,
        ) ||
        children.polyline.find(
          (geometry) => geometry.options.userData.oghObjectId === recordId,
        ) ||
        children.polygon.find(
          (geometry) => geometry.options.userData.oghObjectId === recordId,
        )
      )?.options.userData.layerType;

      const formattedGeometry = formatGeometriesToUpdate(
        geometry,
        layerType,
        mapService,
      );
      mapService.updateGeometriesData({
        [layerType]: formattedGeometry,
      });

      const geometryInstances = mapService.drawGeometries({
        method: 'replaceAll',
      });
      zoomToGeometries(geometryInstances.selected);
    };

    const redrawGeometry = switchServisMap({
      dtw: redrawGeometryDTW,
      egip: redrawGeometryEgip,
    });

    /**
     * Метод startEditCurrentCard.
     *
     * @returns {Promise<void>}
     */
    const startEditCurrentCard = async () => {
      const action = await startEditCard(
        rootId,
        recordId,
        typeId,
        rootCard.record_id,
        rootCard.logical_state_id,
      );

      await redrawGeometry();

      if (action.type === RECEIVE_EDIT_CARD_ALLOW && rootIsProject) {
        this.startEditCard(
          parseDate(rootCard.start_date),
          parseDate(rootCard.end_date),
        );
      }
    };

    startEditCurrentCard();
  }

  /**
   * Функция прекращения редактирования.
   *
   */
  cancelEdit = () => {
    const { currentCard, cancelEditCard } = this.props;
    const rootId = currentCard.root_id;
    const recordId = currentCard.record_id;
    cancelEditCard(rootId, recordId);
  };

  /**
   * Метод handleContinueActionOnUpdate.
   *
   * @param {object} formValues - Значения формы.
   * @returns {Promise<void>}
   */
  handleContinueActionOnUpdate = async (formValues) => {
    const { startDate, endDate, file } = formValues;

    const {
      currentCard,
      selected,
      checkObjectParent,
      alert,
      linkDxfFileForUpdate,
      inventoryPlanTypeId,
      history,
    } = this.props;

    const recordId = currentCard.record_id;

    if (selected) {
      this.startEditCard(startDate, endDate);
    } else {
      const parentInfo = await checkObjectParent(
        recordId,
        dateFormatService.formatDate(startDate),
      );
      const ids = joinIds(parentInfo);
      if (ids) {
        alert(
          `Данная версия объекта является элементом объекта(ов) с id ${ids}. Редактирование невозможно`,
        );
      } else {
        if (file) {
          try {
            const response = await linkDxfFileForUpdate(
              startDate,
              endDate,
              recordId,
              file,
              inventoryPlanTypeId,
            );
            history.push({
              pathname: `/ogh/${response.id}`,
              state: response.id,
            });
          } catch (error) {
            alert(createMessageResponse(error));
          }
        } else {
          this.startEditCard(startDate, endDate);
        }
      }
    }
  };

  /**
   * Метод startEdit.
   *
   * @param {string} startDate - Стартовая дата.
   * @param {string} endDate - Конечная дата.
   * @returns {void}
   */
  startEditCard(startDate, endDate) {
    const editCard = switchServisMap({

      /**
       * Функция старта для новой карты, содержит две функции, старт редактирования геометрии и страт редактирования карточки.
       *
       */
      dtw: () => {
        this.context.startEditing();
        this.props.editCard(startDate, endDate);
      },
      egip: this.props.editCard,
    });

    editCard(startDate, endDate);
  }

  /**
   * Метод renderCard.
   *
   * @returns {React.ReactComponentElement|null} - Компонент карты.
   * @example -
   */
  renderCard() {
    const { props } = this;
    const {
      alert,
      cardType,
      createObject,
      currentCard,
      isCurrentObjectIsExternalSystem,
      isEditCurrentObjectIsExternalSystem,
      matching,
      matchingRegistryColumns,
      mode,
      redrawObject,
      selected,
      updateObject,
    } = props;

    let Card;
    try {
      // eslint-disable-next-line
      Card = require(`../../${cardType}`).default;
    } catch (error) {
      log.error(error);

      /**
       * Дефолтная карточка.
       *
       * @returns {null}
       */
      Card = () => null;
    }

    return cardType ? (
      <Card
        alert={alert}
        card={currentCard}
        createObject={createObject}
        matching={matching}
        matchingRegistryColumns={matchingRegistryColumns}
        mode={mode}
        selected={selected}
        updateObject={updateObject}
        redrawObject={redrawObject}
        isCurrentObjectIsExternalSystem={isCurrentObjectIsExternalSystem}
        isEditCurrentObjectIsExternalSystem={
          isEditCurrentObjectIsExternalSystem
        }
      />
    ) : null;
  }

  /**
   * Метод checkResrictions.
   *
   * @param {number} improvementCategoryId - ImprovementCategoryId.
   * @param {number} stateId - StateId.
   * @param {number} rootId - RootId.
   * @param {boolean} isMatching - Флаг isMatching.
   * @param {Date} startDate - Дата старта.
   * @param {string} messageRottenElements - Сообщение.
   * @returns {string|null} - Текст ошибки или null.
   * @example ----
   */
  checkResrictions = (
    improvementCategoryId,
    stateId,
    rootId,
    isMatching,
    startDate,
    messageRottenElements,
  ) => {
    if (improvementCategoryId && improvementCategoryId.indexOf('null') !== -1) {
      return 'Категория благоустройства ДТ не может быть определена: уборочная площадь = 0 кв.м. Укажите уборочные площади для элементов ДТ';
    }
    if (stateId === ACTIVE) {
      return `Версия объекта ${rootId} уже утверждена`;
    }
    if (stateId === PROJECT && isMatching) {
      return 'Объект на согласовании';
    }
    if (messageRottenElements.length > 0) {
      return `Дочерние ОГХ ${messageRottenElements} не актуальны. Отредактируйте версию ОГХ`;
    }

    return null;
  };

  // TODO: перенести в отдельный модуль

  /**
   * Метод renderModalWindows.
   *
   * @returns {React.ReactComponentElement} - Жопа.
   * @example -
   */
  renderModalWindows() {
    const { props } = this;
    const {
      currentCard,
      card,
      mode,
      deleteOgh,
      viewCard,
      continueEditCard,
      cancelDeleteCard,
      fetchOgh,
      cancelMatchCard,
      isShow,
      onHide,
      history,
      clearCacheVisibilityButtonsOgh,
    } = props;

    const { children } = card;
    const improvementCategoryId = card.improvement_category_id || null;
    const typeId = currentCard.type_id;
    const recordId = currentCard.record_id;
    const rootId = currentCard.root_id;
    const stateId = currentCard.state_id;
    const isMatching = currentCard.is_matching;
    const parentId = currentCard.parent_id;
    const startDate = currentCard && parseDate(currentCard.start_date);
    const rottenElements =
      !!children &&
      children
        .filter((child) => child.endDate)
        .filter((child) => child.endDate !== MAX_DATE);
    let messageRottenElements = '';
    if (rottenElements.length > 0) {
      messageRottenElements = getRottenTypeMessage(rottenElements);
    }
    return (
      <div>
        <DialogCreate oghTypeId={typeId} />

        <DialogUpdate
          card={currentCard}
          isMatching={isMatching}
          mode={mode}
          root={card}
          onCancel={this.cancelEdit}
          onCloseWarning={continueEditCard}
          onOk={this.handleContinueActionOnUpdate}
        />

        <DialogLock
          info={get(mode, 'lockInfo')}
          showCondition={get(mode, 'editModeDenyOnLock', false)}
          onAfterHiding={viewCard}
        />

        <DialogAlert
          showCondition={mode && mode.rootTreeStateChanged}
          onAfterHiding={this.handleCloseRootTreeStateChangedAlert}
        >
          Объект был изменён
        </DialogAlert>

        <DialogDelete
          isMatching={isMatching}
          mode={mode}
          statusId={currentCard.state_id}
          onAlertHide={viewCard}
          onCancel={() => cancelDeleteCard(recordId)}
          onSubmit={async (formValues) => {
            if (formValues.startDate === null || formValues.startDate === '') {
              throw new SubmissionError({
                startDate: 'укажите дату завершения',
              });
            }
            if (formValues.reason === null || formValues.reason === '') {
              throw new SubmissionError({
                reason: 'укажите причину удаления',
              });
            }
            const formValuesTransformed = {};
            Object.keys(formValues).forEach((key) => {
              if (key === 'startDate') {
                formValuesTransformed[key] = new Intl.DateTimeFormat(
                  'ru',
                ).format(formValues[key]);
              } else {
                formValuesTransformed[key] = formValues[key];
              }
            });
            await deleteOgh(formValuesTransformed);
            clearCacheVisibilityButtonsOgh();
          }}
          onSubmitDraft={(formValues) => {
            deleteOgh(formValues)
              .then((response) => {
                if (response.type === 'RECEIVE_DELETE_CARD') {
                  toast.success(response.message);
                  history.push('/r/ogh');
                }
              })
              .catch(() => {
                toast.error('Ошибка удаления карточки');
              });
          }}
          fetchOgh={() => {
            fetchOgh(recordId);
          }}
        />

        <DialogMatching
          entityCode={entityCode.OGH}
          id={recordId}
          operation={operation.APPROVED}
          showCondition={isShow(dialog.APPROVED_MATCHING)}
          title={
            stateId === 1
              ? 'Утвердить удаление объекта'
              : rootId === recordId && isMatching && stateId === 2
              ? 'Утвердить добавление объекта'
              : 'Утвердить редактирование объекта'
          }
          typeId={typeId}
          onCancel={onHide(dialog.APPROVED_MATCHING)}
          onOk={onHide(dialog.APPROVED_MATCHING)}
        />

        <DialogMatching
          alertResult={{
            isDone: mode && mode.sentRejectMatching,
            message: mode && mode.message,
          }}
          closeWarning={() => fetchOgh(recordId)}
          entityCode={entityCode.OGH}
          id={recordId}
          multiline={true}
          operation={operation.REJECTED}
          showCondition={isShow(dialog.REJECTED_MATCHING)}
          title={
            stateId === 1
              ? 'Отклонить удаление объекта'
              : rootId === recordId && isMatching && stateId === 2
              ? 'Отклонить добавление объекта'
              : 'Отклонение'
          }
          typeId={typeId}
          onCancel={onHide(dialog.REJECTED_MATCHING)}
          onOk={onHide(dialog.REJECTED_MATCHING)}
        />

        <DialogSendMatching
          alertResult={{
            isDone: mode && mode.sentMatching,
            message: mode && mode.message,
          }}
          alertStart={{
            errorMessage: mode && mode.message,
            errorStart: mode && mode.errorStartMatchingMode,
            restrictions: this.checkResrictions(
              improvementCategoryId,
              stateId,
              rootId,
              isMatching,
              startDate,
              messageRottenElements,
            ),
          }}
          id={recordId}
          showCondition={(mode && mode.startMatchingMode) || false}
          startDate={startDate}
          typeChange={parentId ? 'edit_ogh' : 'create_ogh'}
          typeId={typeId}
          onCancel={() => cancelMatchCard(recordId)}
          onHideOnFailed={() => fetchOgh(recordId)}
          onHideOnSuccess={() => fetchOgh(recordId)}
          onOk={viewCard}
        />
      </div>
    );
  }

  /**
   * Метод render.
   *
   * @returns {React.ReactComponentElement} - Левая панель.
   * @example -
   */
  render() {
    return (
      <LeftPanel>
        <BtnGroupOGH handleEditButtonClick={this.handleEditButtonClick} />
        {this.renderModalWindows()}

        <LeftPanelContent>{this.renderCard()}</LeftPanelContent>
      </LeftPanel>
    );
  }
}

CardLeftPanel.propTypes = propTypes;

/**
 * Method mapStateToProps.
 *
 * @param {object} state - State.
 * @param {object} props - Props.
 * @returns {{formValues: *, currentCard: *, cardType: *}|{inventoryPlanTypeId: unknown, currentCard: *, cardType: (*|string)}} - State.
 * @example - connect(mapStateToProps, mapDispatchToProps)
 */
const mapStateToProps = (state, props) => {
  const { card, selected } = props;

  const currentCard = selected || card;
  const cardType =
    currentCard.card_type && currentCard.card_type.trim().toLowerCase();

  if (cardType === 'flat_building' || cardType === 'ground_main_element') {
    return {
      cardType,
      currentCard,
      formValues: getFormValues('editorCard')(state),
    };
  }

  const inventoryPlanTypeId =
    cardType === 'odh' ? 18 : getInventoryPlanTypeId(state);

  return {
    cardType,
    currentCard,
    inventoryPlanTypeId,
  };
};

const mapDispatchToProps = {
  cancelDeleteCard,
  cancelEditCard,
  cancelMatchCard,
  checkObjectParent,
  continueEditCard,
  editCard,
  fetchOgh,
  fetchTreeSelectedElement,
  leaveOghCard,
  linkDxfFileForUpdate,
  startEditCard,
  startMatchingCard,
  submit,
  viewCard,
};

export const Card = flow(
  withShowControls(dialog.APPROVED_MATCHING, dialog.REJECTED_MATCHING),
  connect(mapStateToProps, mapDispatchToProps),
)(CardLeftPanel);
