// 20 Трамвайные путиimport CardAbstract from 'app/components/card/common/CardAbstract';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import { AreaField } from 'app/components/card/ogh/components/fields/numberFields/Area.Field';
import { BordBeginField } from 'app/components/card/ogh/components/fields/numberFields/BordBegin.Field';
import { BordEndField } from 'app/components/card/ogh/components/fields/numberFields/BordEnd.Field';
import { DistanceField } from 'app/components/card/ogh/components/fields/numberFields/Distance.Field';
import { DescriptionField } from 'app/components/card/ogh/components/fields/textFields/Description.Field';
import { OdhAxisField } from 'app/components/card/ogh/components/fields/textFields/OdhAxis.Field';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import printOghCardHeader from 'app/components/card/ogh/header/oghHeader';
import submitCard from 'app/components/card/ogh/submit';
import { CoatingTypeRfTramRails } from 'app/components/card/ogh/tram_rails/fields/CoatingTypeRf.TramRails';
import VersionsTemplate from 'app/components/card/ogh/versions/VersionsTemplate';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { CheckboxRF } from 'core/form/reduxForm/fields';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/numberField/TotalAreaGeo.RF';
import { CoatingGroupIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingGroupId.RF';
import { NetElementTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/NetElementTypeId.RF';
import { OdhSideIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/OdhSideId.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

const types = ['tram_rails'];
const Versions = VersionsTemplate(types);

const tabs = [
  { id: 'characteristics-tab', name: 'Исходные данные' },
  { id: 'docs-tab', name: 'Документы' },
];

/**
 * Классовый компонет TramRailsCard.
 */
class TramRailsCard extends React.Component {

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const {
      mode,
      card,
      createMode,
      editMode,
      isEditCurrentObjectIsExternalSystem,
      changeFieldValue,
    } = this.props;

    return (
      <CardContainer>
        <CardAbstract>
          <div id="editor-card">
            {printOghCardHeader(card, mode)}
            {card && objectTabs(card.type_id)}

            <div id="props">
              <FieldsSplitColumns>
                <NetElementTypeIdRF
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                  required={true}
                />
                <IsDiffHeightMarkField />
                <ParentInfo />
              </FieldsSplitColumns>

              <Tabs tabs={tabs} />

              <div id="characteristics">
                <GroupContainer title={'Местоположение'}>
                  <FieldsSplitColumns>
                    <OdhAxisField />
                    <OdhSideIdRF
                      disabled={
                        !(editMode && isEditCurrentObjectIsExternalSystem)
                      }
                      required={true}
                    />
                    <BordBeginField />
                    <BordEndField />
                  </FieldsSplitColumns>
                </GroupContainer>

                <GroupContainer title={'Характеристики'}>
                  <FieldsSplitColumns>
                    <CheckboxRF
                      label={'Обособленные'}
                      name={'detached'}
                      disabled={true}
                    />
                    <CheckboxRF
                      label={'В одном уровне'}
                      name={'is_isolated'}
                      disabled={true}
                    />
                    <DistanceField label={'Длина путей, п.м'} />
                    <AreaField />
                    <TotalAreaGeoRF disabled={true} decimalScale={2} />
                    <CoatingGroupIdRF
                      disabled={
                        !(editMode && isEditCurrentObjectIsExternalSystem)
                      }
                      withAll={false}
                      required={true}
                      withChange={() => {
                        changeFieldValue('coating_type_id', undefined);
                      }}
                    />
                    <CoatingTypeRfTramRails />
                  </FieldsSplitColumns>
                </GroupContainer>

                <DescriptionField />
              </div>
              <div hidden={true} id={'docs'}>
                <OtherDocumentsRF />
              </div>
            </div>
            <div hidden={true} id="versions">
              <Versions
                card={card}
                disabled={createMode}
                key={`versions-${card.root_id}`}
              />
            </div>
          </div>
        </CardAbstract>
      </CardContainer>
    );
  }
}

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
const validate = (values) => validateNumeric(values, numericFields);

const dictFields = [
  'odh_side_id',
  'net_element_type_id',
  'coating_group_id',
  'coating_type_road_id',
  'coating_type_id',
  'coating_group_road_id',
  'coating_type_road_id',
];

const numericFields = [
  {
    name: 'quantity',
    positive: true,
    type: 'integer',
    zero: false,
  },
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  {
    name: 'distance',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'susp_height',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'snow_area',
    positive: true,
    type: 'decimal',
  },
  {
    name: 'coating_tracks_area',
    positive: true,
    type: 'decimal',
  },
  {
    name: 'coating_between_road_area',
    positive: true,
    type: 'decimal',
  },
  {
    name: 'rotor_area',
    positive: true,
    type: 'decimal',
  },
  {
    name: 'reagent_area',
    positive: true,
    type: 'decimal',
  },
];

const tramRailsCardDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * Функция сабмита.
 *
 * @returns {void} - Жопа.
 * @param {object} formValues - Значения из формы.
 * @example ----
 */
export function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  formValuesWithAttribute.is_diff_height_mark = Boolean(
    formValuesWithAttribute.is_diff_height_mark,
  );
  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);

  const result = {
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),
    initialValues: {
      area: parse('area'),
      bord_begin: parse('bord_begin'),
      bord_end: parse('bord_end'),
      coating_between_road_area: parse('coating_between_road_area'),
      coating_group_id: parse('coating_group_id.coating_group'),
      coating_group_road_id: parse('coating_group_road_id.coating_group'),
      coating_tracks_area: parse('coating_tracks_area'),
      coating_type_id: parse('coating_type_id.coating_type'),
      coating_type_road_id: parse('coating_type_road_id.coating_type'),
      customer_id: parse('customer_id'),
      description: parse('description'),
      detached: parse('detached') ?? true,
      distance: parse('distance'),
      endDate: parseDate(props.card.end_date),
      is_diff_height_mark: parse('is_diff_height_mark'),
      is_isolated: parse('is_isolated'),
      net_element_type_id: parse('net_element_type_id.net_element_type'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      parent_name: props.card.parent_info,
      quantity: parse('quantity'),
      reagent_area: parse('reagent_area'),
      rotor_area: parse('rotor_area'),
      snow_area: parse('snow_area'),
      startDate: parseDate(props.card.start_date),
      susp_height: parse('susp_height'),
      totalAreaGeo: parse('total_area_geo'),
    },
    onSubmit: onSubmit.bind({ props }),
    required: isRequired(state, props),
    validate,
  };

  return tramRailsCardDocumentsReducer(result, state, props);
};

/**
 * Функция получения экшенов.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}} - Экшен changeFieldValue.
 * @example ----
 */
const mapDispatchToProps = (dispatch) => ({

  /**
   * Функция изменения поля.
   *
   * @param {string} field - Имя.
   * @param {*} value - Значение.
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(TramRailsCard));
