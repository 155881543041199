import {
  AUTH_URLS_NAME,
  AuthUrls,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/AuthUrls';
import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { SelectPropsType } from 'types/inputs';

/**
 * SelectFF урлов авторизации.
 *
 * @returns {JSX.Element}
 */
export const AuthUrlsFF: FC<SelectPropsType> = () => {
  return (
    <Field name={AUTH_URLS_NAME}>
      {(props) => {
        return <AuthUrls {...props} {...props.input} />;
      }}
    </Field>
  );
};
