import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  NO_CALC_NAME,
  NoCalc,
} from 'core/uiKit/preparedInputs/checkboxes/NoCalc';
import { usePreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';

export { NO_CALC_NAME };

/**
 * CheckboxFF Не учитывать.
 *
 * @param {*} props - Props.
 * @returns {JSX.Element}
 */
export const NoCalcFF = (props) => {
  usePreparation(NO_CALC_NAME, (value) => {
    if (value) {
      return Number(value);
    }
  });

  return <FieldFF {...props} name={NO_CALC_NAME} component={NoCalc} />;
};
