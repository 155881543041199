// 9 Прочие инженерные сооруженияimport CardAbstract from 'app/components/card/common/CardAbstract';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { AreaField } from 'app/components/card/ogh/components/fields/numberFields/Area.Field';
import { BordBeginField } from 'app/components/card/ogh/components/fields/numberFields/BordBegin.Field';
import { BordEndField } from 'app/components/card/ogh/components/fields/numberFields/BordEnd.Field';
import { OdhSideIdField } from 'app/components/card/ogh/components/fields/selectFields/OdhSideId.Field';
import { DescriptionField } from 'app/components/card/ogh/components/fields/textFields/Description.Field';
import { OdhAxisField } from 'app/components/card/ogh/components/fields/textFields/OdhAxis.Field';
import { PlacementIdRFWrapper } from 'app/components/card/ogh/components/selects/PlacementIdRFWrapper';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import { isThisViewing } from 'app/selectors/card/isThisViewing';
import filterDictBySprNumber from 'app/utils/card/filterDictBySprNumber';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { CheckboxRF, NumberFieldRF } from 'core/form/reduxForm/fields';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/numberField/TotalAreaGeo.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['other_engin_construct'];
const Versions = VersionsTemplate(types);

const tabs = [
  { id: 'characteristics-tab', name: 'Исходные данные' },
  { id: 'docs-tab', name: 'Документы' },
];

const propTypes = {
  card: PropTypes.object,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * Классовый компонет OtherEnginConstructCard.
 */
class OtherEnginConstructCard extends React.Component {

  /**
   * Функция getEnginStructTypeOptions.
   *
   * @returns {*} - Жопа.
   * @example ----
   */
  getEnginStructTypeOptions() {
    const { type_id, engin_struct_type, viewing } = this.props.card;
    if (viewing) {
      return engin_struct_type || [];
    }

    return filterDictBySprNumber(engin_struct_type, type_id);
  }

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const {
      card,
      mode,
      createMode,
      editMode,
      required,
      isEditCurrentObjectIsExternalSystem,
    } = this.props;

    return (
      <CardContainer>
        <CardAbstract>
          <div id="editor-card">
            {printOghCardHeader(card, mode)}
            {card && objectTabs(card.type_id)}

            <div id="props">
              <FieldsSplitColumns>
                <SelectRF
                  name={'engin_struct_type_id'}
                  label={'Тип'}
                  options={this.getEnginStructTypeOptions()}
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                  required={isMandatory(
                    card.type_id,
                    'engin_struct_type_id',
                    required,
                  )}
                />
                <ParentInfo />
              </FieldsSplitColumns>

              <Tabs tabs={tabs} />
              <div id="characteristics">
                <GroupContainer title={'Местоположение'}>
                  <FieldsSplitColumns>
                    <PlacementIdRFWrapper
                      disabled={true}
                      required={true}
                      withAll={false}
                    />
                    <OdhAxisField />
                    <OdhSideIdField />
                    <BordBeginField />
                    <BordEndField />
                  </FieldsSplitColumns>
                </GroupContainer>

                <GroupContainer title={'Характеристики'}>
                  <FieldsSplitColumns>
                    <AreaField label={'Площадь (в плане), кв.м'} />
                    <TotalAreaGeoRF disabled={true} decimalScale={2} />
                    <CheckboxRF
                      label={'Входить в общую площадь ОДХ'}
                      name={'is_object_area'}
                      disabled={
                        !(editMode && isEditCurrentObjectIsExternalSystem)
                      }
                    />
                    <NumberFieldRF
                      label={'Двухметровая прилотковая зона, п.м.'}
                      name={'gutters_length'}
                      disabled={
                        !(editMode && isEditCurrentObjectIsExternalSystem)
                      }
                      decimalScale={2}
                    />
                  </FieldsSplitColumns>
                </GroupContainer>
                <DescriptionField />
              </div>
              <div hidden={true} id={'docs'}>
                <OtherDocumentsRF />
              </div>
            </div>
            <div hidden={true} id="versions">
              <Versions
                card={card}
                disabled={createMode}
                key={`versions-${card.root_id}`}
              />
            </div>
          </div>
        </CardAbstract>
      </CardContainer>
    );
  }
}

OtherEnginConstructCard.propTypes = propTypes;

const otherEnginConstructDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

const dictFields = ['placement_id', 'engin_struct_type_id', 'odh_side_id'];

const numericFields = [
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  { name: 'gutters_length', type: 'decimal' },
  {
    name: 'area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
];

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
const validate = (values) => validateNumeric(values, numericFields);

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void}
 */
export function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const formValues = getFormValues('editorCard')(state);

  const result = {
    createMode: isCreateMode(state, props),

    editMode: isEditMode(state, props),

    enableReinitialize: true,
    engin_struct_type: get(formValues, 'engin_struct_type_id'),
    initialValues: {
      area: parse('area'),
      bord_begin: parse('bord_begin') || 0,
      bord_end: parse('bord_end') || 0,
      customer_id: parse('customer_id'),
      description: parse('description'),
      endDate: parseDate(props.card.end_date),
      engin_struct_type_id: parse('engin_struct_type_id.engin_struct_type'),
      gutters_length: parse('gutters_length'),
      is_object_area: parse('is_object_area'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      parent_name: props.card.parent_info,
      placement_id: parse('placement_id.placement'),
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
    },
    onSubmit: onSubmit.bind({ props }),

    required: isRequired(state, props),

    validate,
    viewing: isThisViewing(state),
  };

  return otherEnginConstructDocumentsReducer(result, state, props);
};

export default connect(mapStateToProps)(
  reduxForm({ form: 'editorCard' })(OtherEnginConstructCard),
);
