import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { fetchDeleteCard, fetchStartDeleteCard } from 'app/api/ogh';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import {
  UPDATE_FROM_PARENT,
  useTypeActionCard,
} from 'app/pages/cardsOgh/hooks/useTypeActionCard';
import { toast } from 'core/uiKit/components/Toast';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { log } from 'core/utils/log';
import { useNavigateRouter } from 'core/utils/router';
import { useState } from 'react';

interface UseSetupDeleteButtonCard {

  /**
   * Флаг загрузки .
   */
  closeDialog: () => void;

  /**
   * Флаг загрузки .
   */
  data: unknown;

  /**
   * Флаг загрузки .
   */
  deleteCardHandler: () => void;

  /**
   * Флаг загрузки .
   */
  dialog: boolean;

  /**
   * Флаг загрузки .
   */
  editMode: boolean;

  /**
   * Флаг загрузки .
   */
  isLoading: boolean;

  /**
   * Флаг загрузки .
   */
  sendDialog: () => void;
}

/**
 * DeleteButtonCard.
 *
 * @returns {UseSetupDeleteButtonCard}
 */
const useSetup = (): UseSetupDeleteButtonCard => {
  // @ts-ignore
  const { recordId, typeId, rootId } = useGetCurrentCard();
  // @ts-ignore
  const { editMode } = useEditMode();
  const { data } = useGetVisibilityButtonsOgh(rootId);
  const [dialog, setDialog] = useState(false);
  const navigate = useNavigateRouter();
  const typeAction = useTypeActionCard();
  const { id } = useGetObjectId();

  const [deleteCardHandler] = useMutationAdaptor(
    () => fetchStartDeleteCard({ recordId, typeId }),
    {

      /**
       * OnError.
       *
       * @param error - Error.
       * @param error.message - Message Error.
       * @returns {void}
       */
      onError(error: { message: string }): void {
        toast.errorDying(error?.message || 'Произошла ошибка');
      },

      /**
       * OnSuccess.
       *
       * @returns {void}
       */
      onSuccess(): void {
        setDialog(true);
      },
    },
  );

  const [sendDialog, { isLoading }] = useMutationAdaptor(
    () => fetchDeleteCard({ id: rootId, type: typeId }),
    {

      /**
       * OnError.
       *
       * @param error - Error.
       * @param error.message - Message Error.
       * @returns {void}
       */
      onError(error: { message: string }): void {
        toast.error(error ? error?.message : 'Произошла ошибка');
        log.warn(error ? error?.message : 'Произошла ошибка');
      },

      /**
       * OnSuccess.
       *
       * @returns {void}
       */
      onSuccess(): void {
        switch (typeAction) {
          case UPDATE_FROM_PARENT: {
            navigate(`/ogh/${id}`);
            break;
          }
          default: {
            navigate('/r/ogh');
            break;
          }
        }
        setDialog(false);
        toast.successDying('Удаление версии объекта успешно произведено');
      },
    },
  );

  /**
   * Close dialog fn.
   *
   * @returns {void}
   */
  const closeDialog = (): void => {
    setDialog(false);
  };

  return {
    closeDialog,
    data,
    deleteCardHandler,
    dialog,
    editMode,
    isLoading,
    sendDialog,
  };
};

export default useSetup;
