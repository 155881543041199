import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import React from 'react';
import { Field } from 'react-final-form';

import { DocumentWithFinalForm } from './DocumentWithFinalForm';

const ID = 23;
const NAME = getNameFile(ID);
const TITLE = 'Исходная техническая документация';

/**
 * Таблица документов __Исходная техническая документация__.
 *
 * @returns {JSX.Element}
 */
export const OriginalTechnicalDocumentationFF = () => {
  return (
    <Field
      name={NAME}
      component={DocumentWithFinalForm}
      id={ID}
      title={TITLE}
    />
  );
};
