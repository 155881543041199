import { Validator } from 'core/form/Validator';
import { ICE_RINK_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/IceRinkTypeId';

/**
 * Функция валидации для Катков.
 *
 * @param values - Значения формы.
 * @returns Ошибки валидации если есть.
 */
export const validateFormIceRink = (values: Record<string, unknown>) => {
  const validate = new Validator(values);

  validate.setRequired(ICE_RINK_TYPE_ID_NAME);

  return validate.getErrors();
};
