import { useGetDictionary } from 'app/api/hooks/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const OKRUG_BRIDGE_ID_NAME = 'okrug_bridge_id';

/**
 * Select __Округ__.
 *
 * @param {object} props - Пропсы.
 * @returns Select.
 */
export const OkrugBridgeId = (props: SelectPropsType) => {
  const dict = 'okrug_bridge';
  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      {...{
        label: 'Округ',
        name: OKRUG_BRIDGE_ID_NAME,
        options: data,
      }}
    />
  );
};
