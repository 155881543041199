import { TextField } from 'core/uiKit/inputs/TextField';
import React from 'react';
import { InputProps } from 'types/inputs';

export const ADDRESS_NAME = 'address';

/**
 * TextField Адрес.
 *
 * @param props - Пропсы.
 * @returns <TextField/>.
 */
export const Address = (props: InputProps) => {
  // @ts-ignore
  return <TextField label={'Адрес'} {...props} />;
};
