import './Tab.scss';

import TabMUI from '@mui/material/Tab';
import React from 'react';

/**
 * Tab.
 *
 * @param {*} props - Properties.
 * @param {string} props.label - Название.
 * @param {string} props.value - Value.
 * @returns JSX.Element.
 */
export const Tab = ({
  label,
  value,
  ...otherProperties
}: {
  label: string;
  value: string;
}) => {
  return (
    <TabMUI
      {...otherProperties}
      key={`title-${value}`}
      label={label}
      value={value}
      id={`tab-${value}`}
      classes={{ root: 'tab-root' }}
    />
  );
};
