import { useScriptRefFAQService } from './useScriptRef.AutoFAQ.Service';

/**
 * Хук инициализации помощника autoFAQ.
 *
 * @returns
 */
export const useSetupAutoFAQService = () => {
  const { ref } = useScriptRefFAQService();

  const initPosition = {
    cX: window.innerWidth - 90,
    cY: window.innerHeight - 90 - 20,
  };

  return {
    initPosition,
    ref,
  };
};
