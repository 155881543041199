import { SvgIconMUI } from 'core/uiKit/material-ui';
import React from 'react';

/**
 * HoverInformatrionIcon.
 */
export default class HoverInformatrionIcon extends React.Component {
  state = {
    hover: false,
  };

  /**
   * Render.
   *
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div
        style={{
          display: 'inline-block',
          position: 'relative',
        }}
        onMouseEnter={() => {
          this.setState({ hover: true });
        }}
        onMouseLeave={() => {
          this.setState({ hover: false });
        }}
      >
        <SvgIconMUI color="#00BCD4" style={{ height: 25, width: 25 }}>
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
        </SvgIconMUI>
        <div
          style={{
            background: 'rgb(243, 237, 227)',
            boxShadow: 'inset 0 0 6px rgba(66,66,66,1)',
            display: !this.state.hover && 'none',
            padding: 10,
            position: 'absolute',
            top: 0,
            zIndex: 4242,
          }}
        >
          <div>Предыдущее значение таблицы</div>
          {this.props.children}
        </div>
      </div>
    );
  }
}
