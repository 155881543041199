import { useGetDictionary } from 'app/api/hooks/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const OKRUG_BRIDGE_IDS_NAME = 'okrug_bridge_ids';

/**
 * MultiSelect __Округ__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const OkrugBridgeIds = (props: SelectPropsType) => {
  const dict = 'okrug_bridge';
  const { data } = useGetDictionary(dict);

  return (
    // @ts-ignore
    <MultiSelect
      {...props}
      options={data || []}
      label={'Округ'}
      name={OKRUG_BRIDGE_IDS_NAME}
    />
  );
};
