import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  LOCATION_METHOD_ID_NAME,
  LocationMethodId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/LocationMethodId';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * Select FF __Способ расположения__.
 *
 * @param props - Пропсы.
 * @returns Select FF.
 */
export const LocationMethodIdFF = (props: SelectPropsType) => {
  return (
    <FieldFF
      {...props}
      component={LocationMethodId}
      name={LOCATION_METHOD_ID_NAME}
    />
  );
};
