import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { IAbutmentTypeData } from 'core/uiKit/preparedInputs/selects/simpleSelect/AbutmentTypeName';

/**
 * Setup Тип элемента сопряжения"  "Элементы сопряжения поверхностей".
 *
 * @returns Флаги и функции.
 */
export const useSetupAbutmentTypeNameFFField = () => {
  const { editMode } = useMode();
  const parentCard = useGetParentCard();

  /**
   * Функция фильтрации.
   *
   * @param data - Данные.
   * @returns Фильтрованые Данные.
   */
  const filter = (data: IAbutmentTypeData[]) => {
    return data.filter((item) =>
      item?.parent_ogh_object_type_list?.includes(+parentCard?.type_id),
    );
  };

  return { editMode, filter };
};
