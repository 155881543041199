import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';

import { WORK_TIME_NAME } from './Table.WorkTime.FF';

export type IWorkTimeData = {
  day: string;
  endTime: string;
  id: string;
  startTime: string;
}[];

const defaultValue = {
  [WORK_TIME_NAME]: [
    {
      day: 'Понедельник',
      endTime: '23:00',
      id: 'monday',
      startTime: '09:00',
    },
    {
      day: 'Вторник',
      endTime: '23:00',
      id: 'tuesday',
      startTime: '09:00',
    },
    {
      day: 'Среда',
      endTime: '23:00',
      id: 'wednesday',
      startTime: '09:00',
    },
    {
      day: 'Четверг',
      endTime: '23:00',
      id: 'thursday',
      startTime: '09:00',
    },
    {
      day: 'Пятница',
      endTime: '23:00',
      id: 'friday',
      startTime: '09:00',
    },
    {
      day: 'Суббота',
      endTime: '23:00',
      id: 'saturday',
      startTime: '09:00',
    },
    {
      day: 'Воскресенье',
      endTime: '23:00',
      id: 'sunday',
      startTime: '09:00',
    },
  ],
};

const mapWeek: {
  [key: string]: string;
} = {
  friday: 'Пятница',
  monday: 'Понедельник',
  saturday: 'Суббота',
  sunday: 'Воскресенье',
  thursday: 'Четверг',
  tuesday: 'Вторник',
  wednesday: 'Среда',
};

/**
 * Хук инициализации Таблицы Графика посещения.
 *
 * @returns
 */
export const useGetTableWorkTimeData = () => {
  const {
    attribute: { work_time },
  } = useGetCurrentCard();

  if (!work_time) {
    return defaultValue;
  }

  return {
    [WORK_TIME_NAME]: (work_time || []).map((item) => {
      const key = Object.keys(item)[0];

      return {
        day: mapWeek[key],
        endTime: item[key].endtime,
        id: key,
        startTime: item[key].starttime,
      };
    }),
  };
};
