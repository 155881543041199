import { TextField } from 'core/uiKit/inputs/TextField';
import React from 'react';
import { InputProps } from 'types/inputs';

export const PLACEMENT_NAME_NAME = 'placement_name';

/**
 * Местоположение.
 *
 * @param props - Пропсы.
 * @returns <TextField/>.
 */
export const PlacementName = (props: InputProps) => {
  // @ts-ignore
  return <TextField label={'Местоположение'} {...props} />;
};
