import React from 'react';
import { AutocompleteAsyncPropsType, CommonParamsType } from 'types/inputs';

import { AutocompleteAsync_ as AutocompleteJs } from './_AutocompleteAsync';

/**
 * Обертка тс для Autocomplete.
 *
 * @param props - Properties.
 * @returns JSX.Element.
 */
export const AutocompleteAsync = <TYPE extends CommonParamsType>(
  props: AutocompleteAsyncPropsType<TYPE>,
) => {
  const otherProps: AutocompleteAsyncPropsType<
    Parameters<typeof props.fetchFunction>[0]
  > = {
    ...props,
    additionalDataForRequest: props.additionalDataForRequest,
    fetchFunction: props.fetchFunction,
  };

  return <AutocompleteJs {...otherProps} />;
};
