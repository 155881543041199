import { TextField } from 'core/uiKit/inputs/TextField';
import React from 'react';

export const ADDRESS_NAME_NAME = 'address_name';

/**
 * Текстовое поле __Адрес__.
 *
 * @param {object} props - Пропсы.
 * @returns {React.ReactElement}
 */
export const AddressName = (props) => {
  return <TextField {...props} label={'Адрес'} name={ADDRESS_NAME_NAME} />;
};
