import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import isMandatory from 'app/utils/card/isMandatory';
import { CoatingGroupIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingGroupId.RF';
import { COATING_GROUP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';
import React from 'react';

/**
 * Select Field RF __Вид покрытия__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const CoatingGroupIdField = (props: object) => {
  const { typeId } = useGetCurrentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  return (
    // @ts-ignore
    <CoatingGroupIdRF
      {...props}
      disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
      required={isMandatory(typeId, COATING_GROUP_ID_NAME, editMode)}
    />
  );
};
