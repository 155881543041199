import { useGetDictionary } from 'app/api/hooks/useGetDictionary';

import {
  IMafType,
  preparationMafTypeListLevel2,
} from './preparationMafTypeListLevel2';

/**
 * Setup for Select Уточнение.
 *
 * @returns Данные.
 */
export const useSetupMafTypeLevel2 = () => {
  const { data = [], isLoading } =
    useGetDictionary<
      { id: number; attribute: { name: string; maf_type_list: IMafType[] } }[]
    >('mafTypeLevel2');

  const options = data.map((item) => ({
    id: item.id,
    mafTypeList: preparationMafTypeListLevel2(item?.attribute?.maf_type_list),
    name: item.attribute.name,
  }));

  return { isLoading, options };
};
