import {
  AddressFilter,
  addressParams,
} from 'core/form/filterForm/components/autocompleteAsync/AddressFilter';
import {
  OwnerIdFilter,
  ownerIdParams,
} from 'core/form/filterForm/components/autocompleteAsync/OwnerIdFilter';
import {
  DateFromFilter,
  dateFromParams,
} from 'core/form/filterForm/components/dateFilters/DateFromFilter';
import {
  DateToFilter,
  dateToParams,
} from 'core/form/filterForm/components/dateFilters/DateToFilter';
import {
  OghStatusesFilter,
  oghStatusesParams,
} from 'core/form/filterForm/components/miltiSelect/OghStatusesFilter';
import {
  CategoryToiletIdFilter,
  publicCategoryToiletIdParams,
} from 'core/form/filterForm/components/selects/CategoryToiletId.Filter';
import {
  DepartmentFilter,
  departmentParams,
} from 'core/form/filterForm/components/selects/DepartmentFilter';
import {
  FormVisitIdFilter,
  publicFormVisitIdParams,
} from 'core/form/filterForm/components/selects/FormVisitId.Filter';
import {
  LocationMethodIdFilter,
  publicLocationMethodIdParams,
} from 'core/form/filterForm/components/selects/LocationMethodId.Filter';
import {
  OkrugFilter,
  okrugParams,
} from 'core/form/filterForm/components/selects/OkrugFilter';
import {
  PublicToiletTypeIdFilter,
  publicToiletTypeIdParams,
} from 'core/form/filterForm/components/selects/PublicToiletTypeId.Filter';
import {
  IdOrNameFilter,
  idOrNameParams,
} from 'core/form/filterForm/components/text/IdOrName.Filter';
import {
  IsAdaptedPeopleLimitedMobilityFilter,
  isAdaptedPeopleLimitedMobilityParams,
} from 'core/form/filterForm/components/yesOrNot/IsAdaptedPeopleLimitedMobility.Filter';
import {
  IsBelongingOghFilter,
  isBelongingOghParams,
} from 'core/form/filterForm/components/yesOrNot/isBelongingOgh.Filter';
import { RegistryFilterFormFF } from 'core/form/filterForm/RegistryFilterFormFF';
import { preparationValues } from 'core/utils/helpers/preparationValues';
import { useQueryParams } from 'core/utils/hooks/useQueryParams';
import React from 'react';

/**
 * Хук получения изначального состояния и изменения фильтров __Общественные туалеты__.
 *
 * @returns
 */
export const useFilterQueryParamsPt = () => {
  const [filters, setFilters] = useQueryParams([
    dateFromParams,
    dateToParams,
    idOrNameParams,
    oghStatusesParams([2]),
    ownerIdParams,
    okrugParams,
    addressParams,
    departmentParams,
    publicToiletTypeIdParams,
    publicLocationMethodIdParams,
    publicFormVisitIdParams,
    publicCategoryToiletIdParams,
    isAdaptedPeopleLimitedMobilityParams,
    isBelongingOghParams,
  ]);

  return {
    filters,
    prepareFilters: preparationValues(filters),
    setFilters,
  };
};

/**
 * Форма фильтрации _Малые архитектурные формы__.
 *
 * @returns Форму фильтрации.
 */
export const FilterPT = () => {
  return (
    <RegistryFilterFormFF useFilterQueryParams={useFilterQueryParamsPt}>
      <IdOrNameFilter />
      <OghStatusesFilter />
      <DateFromFilter />
      <DateToFilter />
      <AddressFilter />
      <OwnerIdFilter />
      <OkrugFilter />
      <PublicToiletTypeIdFilter />
      <DepartmentFilter />
      <LocationMethodIdFilter />
      <FormVisitIdFilter />
      <IsAdaptedPeopleLimitedMobilityFilter />
      <CategoryToiletIdFilter />
      <IsBelongingOghFilter />
    </RegistryFilterFormFF>
  );
};
