import { useState } from 'react';

import { ApproveElectronicSignatureButton } from './ApproveElectronicSignature.Button';
import { DialogApproveElectronicSignature } from './Dialog.ApproveElectronicSignature';

/**
 * Компонент Утвердить (ЭП).
 *
 * @returns {JSX}
 */
export const ApproveElectronicSignature = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ApproveElectronicSignatureButton onClick={() => setIsOpen(true)} />
      {isOpen ? (
        <DialogApproveElectronicSignature onClose={() => setIsOpen(false)} />
      ) : null}
    </>
  );
};
