import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import isMandatory from 'app/utils/card/isMandatory';
import { NumberFieldRF } from 'core/form/reduxForm/fields/default/NumberField.RF';
import React from 'react';

export const BORD_END_NAME = 'bord_end';

/**
 * Number Field RF __Конец, м__.
 *
 * @returns
 */
export const BordEndField = () => {
  const { typeId } = useGetCurrentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  return (
    <NumberFieldRF
      decimalScale={2}
      name={BORD_END_NAME}
      label={'Конец, м'}
      disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
      required={isMandatory(typeId, BORD_END_NAME, true)}
    />
  );
};
