import { NumberField } from 'core/uiKit/inputs/NumberField/NumberField';
import React from 'react';
import { Field } from 'redux-form';

/**
 * NumberFieldWithReduxForm.
 *
 * @param {object} props - Props.
 * @param {object} props.input - Input.
 * @returns {JSX.Element}
 */
const NumberFieldWithReduxForm = ({ input, ...props }) => {
  return <NumberField {...props} {...input} />;
};

/**
 * NumberFieldRF.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.name - Имя поля в Redux Form.
 * @param {string} props.label - Лейбл для числового поля.
 * @param {boolean} [props.disabled] - Флаг для отключения компонента.
 * @param {boolean} [props.dark] - Переключатель темной темы.
 * @param {boolean} [props.allowNegative] - Флаг для разрешения ввода отрицательных чисел.
 * @param {number} [props.decimalScale] - Количество знаков после запятой.
 * @param {object} [props.otherProps] - Дополнительные пропсы для компонента `Field` от Redux Form.
 * @param {boolean} [props.required] - Флаг обязательности поля.
 * @param {*} [props.helpTitle] - Тултип.
 * @returns {JSX.Element}
 */
export const NumberFieldRF = ({
  name = 'NumberFieldRF',
  label = 'NumberFieldRF label',
  disabled = false,
  dark = false,
  allowNegative = false,
  decimalScale = 0,
  ...otherProps
}) => {
  return (
    <Field
      {...{ allowNegative, dark, decimalScale, disabled, label }}
      {...otherProps}
      name={name}
      component={NumberFieldWithReduxForm}
    />
  );
};
