import { RefObject, useEffect, useLayoutEffect, useState } from 'react';

import { Position } from '../../types.DragWrapper';
import { getMargins } from '../../utils/getMargins';
import { getScopedPosition } from '../../utils/getScopedPosition';
import { calculateNewPosition } from './calculateNewPosition';

interface IuseResizePositionEffect {
  position: Position;
  ref: RefObject<HTMLElement | null>;
  setPosition: (position: Position) => void;
}

/**
 * Эффект изменения позиции относительно окна.
 *
 * @param params - Параметры хука.
 * @param params.position - Текущая позиция.
 * @param params.ref - Реф элемента.
 * @param params.setPosition - Функция изменения позиции.
 *
 * @returns Статус ресайза.
 */
export const useResizePositionEffect = ({
  position,
  ref,
  setPosition,
}: IuseResizePositionEffect) => {
  const [resizeStatus, setResizeStatus] = useState<'start' | 'end' | 'idle'>(
    'idle',
  );

  useEffect(() => {
    let timeoutId: string | number | NodeJS.Timeout | null | undefined = null;
    if (resizeStatus === 'end') {
      timeoutId = setTimeout(() => {
        setResizeStatus('idle');
      }, 1000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [resizeStatus]);

  useLayoutEffect(() => {
    let prevWidth = window.innerWidth;
    let prevHeight = window.innerHeight;

    /**
     * Обработчик изменения размеров окна.
     */
    const handleResize = () => {
      if (ref?.current) {
        setResizeStatus('start');
        const margins = getMargins(ref!.current);
        const elementWidth = ref!.current.offsetWidth;
        const elementHeight = ref!.current.offsetHeight;

        // Пересчитываем позицию по X
        const newCX = calculateNewPosition(
          position.cX ?? 0,
          prevWidth,
          window.innerWidth,
          margins.left,
          elementWidth,
        );

        // Пересчитываем позицию по Y
        const newCY = calculateNewPosition(
          position.cY ?? 0,
          prevHeight,
          window.innerHeight,
          margins.top + margins.bottom,
          elementHeight,
        );

        // Расчет минимальной позиции
        const minCX = margins.left - margins.right;
        const minCY = margins.top - margins.bottom;

        // Расчет максимально возможной позиции
        const maxCX = window.innerWidth - elementWidth - minCX;
        const maxCY = window.innerHeight - elementHeight - minCY;

        setPosition({
          cX: getScopedPosition(newCX, minCX, maxCX),
          cY: getScopedPosition(newCY, minCY, maxCY),
        });

        // Обновляем предыдущие размеры окна
        prevWidth = window.innerWidth;
        prevHeight = window.innerHeight;
        setResizeStatus('end');
      }
    };

    // Добавляем обработчик изменения размеров окна
    window.addEventListener('resize', handleResize);

    // Убираем обработчик при размонтировании
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [position.cX, position.cY, setPosition, ref]);

  return { resizeStatus };
};
