import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { shallow } from 'shallowly';
import { vi } from 'vitest';

import LittleForm from './LittleFormComponent';

vi.mock('app/api/hooks/useGetParentCard');

describe('🐛 LittleFormComponentSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)

    /**
     * Функция isEditableMaterial.
     *
     * @returns {boolean}
     */
    const isEditableMaterial = () => true;
    useGetParentCard.mockReturnValue({ typeId: 1 });
    const props = {
      card: {
        type_id: 37,
      },
      editMode: true,
      formValues: {
        maf_type_level1_id: 2,
        unit_id: 1,
      },
      isEditCurrentObjectIsExternalSystem: true,
      isEditableMaterial,
      required: true,
      units: [{ id: 2 }],
    };

    //🔥 Act
    const wrapper = shallow(<LittleForm {...props} />);

    //❓ Assert
    expect(wrapper.text()).toBe(`<CardContainer>
  <CardAbstract>
    <div>
      <CardHeader>
        <KeyboardDateStartComponent />
        <KeyboardDateEndComponent />
        <OghStatusComponent />
        <StageResolutionComponent />
        <SignDataComponent />
        <CheckboxGreeningComponent />
        <CheckboxUpdatingComponent />
        <CheckboxReapprovalComponent />
        <CheckboxLandscapingMark />
      </CardHeader>
      <div>
        <ul>
          <li>
            Свойства
          </li>
          <VersionsTab />
          null
          null
          null
          null
          null
        </ul>
      </div>
      <div>
        <MafType />
        <FieldsSplitColumns>
          <ParentInfo />
          <IsDiffHeightMarkField />
          <NoCalcRF />
        </FieldsSplitColumns>
        <Tabs />
        <div>
          <FieldsSplitColumns>
            <UnitIdRF />
            <NumberField />
            <SelectRF />
            <TextField />
            <IdRfidRF />
            <KeyboardDatePickerRF />
            <KeyboardDatePickerRF />
            <KeyboardDatePickerRF />
            <EaisIdRF />
            <IsFilled />
            <ErzStateIdRF />
            <KeyboardDatePickerRF />
          </FieldsSplitColumns>
        </div>
        <div>
          <OtherDocumentsRF />
        </div>
      </div>
      <div>
        <Unknown></Unknown>
      </div>
    </div>
  </CardAbstract>
</CardContainer>`);
  });

  it('🧪 find props())', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)

    /**
     * Функция isEditableMaterial.
     *
     * @returns {boolean}
     */
    const isEditableMaterial = () => true;
    useGetParentCard.mockReturnValue({ typeId: 1 });
    const props = {
      card: {
        type_id: 37,
      },
      editMode: true,
      formValues: {
        maf_type_level1_id: 2,
        unit_id: 1,
      },
      isEditCurrentObjectIsExternalSystem: true,
      isEditableMaterial,
      required: true,
      units: [{ id: 2 }],
    };

    //🔥 Act
    const wrapper = shallow(<LittleForm {...props} />);

    //❓ Assert
    expect(wrapper.find('NoCalcRF').props()).toStrictEqual({ disabled: false });
  });

  it('🧪 textWithProps toEqual', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)

    /**
     * Функция isEditableMaterial.
     *
     * @returns {boolean}
     */
    const isEditableMaterial = () => true;
    useGetParentCard.mockReturnValue({ typeId: 1 });
    const props = {
      card: {
        type_id: 37,
      },
      editMode: true,
      formValues: {
        maf_type_level1_id: 2,
        unit_id: 1,
      },
      isEditCurrentObjectIsExternalSystem: true,
      isEditableMaterial,
      required: true,
      units: [{ id: 2 }],
    };

    //🔥 Act
    const wrapper = shallow(<LittleForm {...props} />);

    //❓ Assert
    expect(wrapper.textWithProps()).toEqual(`<CardContainer>
  <CardAbstract>
    <div id="editor-card">
      <CardHeader subtitle=undefined title="не определено не определено">
        <KeyboardDateStartComponent />
        <KeyboardDateEndComponent />
        <OghStatusComponent />
        <StageResolutionComponent />
        <SignDataComponent />
        <CheckboxGreeningComponent />
        <CheckboxUpdatingComponent />
        <CheckboxReapprovalComponent />
        <CheckboxLandscapingMark />
      </CardHeader>
      <div className="tabs" style={"paddingLeft":"24px"}>
        <ul>
          <li className="active" id="props-tab">
            Свойства
          </li>
          <VersionsTab />
          null
          null
          null
          null
          null
        </ul>
      </div>
      <div id="props">
        <MafType changeFieldValue=undefined parentTypeId=1 editable=true isEditCurrentObjectIsExternalSystem=true mafTypeLevel1=undefined mafTypeLevel1Id=2 mafTypeLevel2=undefined mafTypeLevel3=undefined required=true typeId=37 onChangeMafTypeLevel2Id=undefined />
        <FieldsSplitColumns>
          <ParentInfo />
          <IsDiffHeightMarkField />
          <NoCalcRF disabled=false />
        </FieldsSplitColumns>
        <Tabs tabs=[{"id":"characteristics-tab","name":"Исходные данные"},{"id":"docs-tab","name":"Документы"}] />
        <div id="characteristics">
          <FieldsSplitColumns>
            <UnitIdRF required=false disabled=true />
            <NumberField label="Количество МАФ" name="quantity" value=0 disabled=true />
            <SelectRF label="Материал" name="material_id" options=undefined disabled=false />
            <TextField label="Принадлежность элемента к зоне территории" name="zone_name" value="" disabled=true />
            <IdRfidRF disabled=false />
            <KeyboardDatePickerRF label="Дата установки" name="installation_date" disabled=false />
            <KeyboardDatePickerRF label="Срок эксплуатации" name="lifetime" disabled=false />
            <KeyboardDatePickerRF label="Гарантийный срок" name="guarantee_period" disabled=false minDate="" />
            <EaisIdRF disabled=true />
            <IsFilled />
            <ErzStateIdRF disabled=true />
            <KeyboardDatePickerRF label="Дата состояния (из ЕРЗ)" name="erz_date" disabled=true />
          </FieldsSplitColumns>
        </div>
        <div hidden=true id="docs">
          <OtherDocumentsRF />
        </div>
      </div>
      <div hidden=true id="versions">
        <Unknown card={"type_id":37} disabled=undefined></Unknown>
      </div>
    </div>
  </CardAbstract>
</CardContainer>`);
  });

  it('🧪 find prop', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)

    /**
     * Функция isEditableMaterial.
     *
     * @returns {boolean}
     */
    const isEditableMaterial = () => true;
    useGetParentCard.mockReturnValue({ typeId: 1 });
    const props = {
      card: {
        type_id: 37,
      },
      editMode: true,
      formValues: {
        maf_type_level1_id: 2,
        unit_id: 1,
      },
      isEditCurrentObjectIsExternalSystem: true,
      isEditableMaterial,
      required: true,
      units: [{ id: 2 }],
    };

    //🔥 Act
    const wrapper = shallow(<LittleForm {...props} />);

    //❓ Assert
    expect(wrapper.find('NoCalc').prop('disabled')).toBeFalsy();
  });
});
