import {
  OwnerIdFilter,
  ownerIdParams,
} from 'core/form/filterForm/components/autocompleteAsync/OwnerIdFilter';
import {
  CultureObjectFilter,
  cultureObjectParams,
} from 'core/form/filterForm/components/checkBoxes/CultureObject.Filter';
import {
  DateFromFilter,
  dateFromParams,
} from 'core/form/filterForm/components/dateFilters/DateFromFilter';
import {
  DateToFilter,
  dateToParams,
} from 'core/form/filterForm/components/dateFilters/DateToFilter';
import {
  BridgeTypeLevel1IdsFilter,
  bridgeTypeLevel1IdsParams,
} from 'core/form/filterForm/components/miltiSelect/BridgeTypeLevel1Ids.Filter';
import {
  BridgeTypeLevel2IdsFilter,
  bridgeTypeLevel2IdsParams,
} from 'core/form/filterForm/components/miltiSelect/BridgeTypeLevel2Ids.Filter';
import {
  CleanCategoryIdsFilter,
  cleanCategoryIdsParams,
} from 'core/form/filterForm/components/miltiSelect/CleanCategoryIds.Filter';
import {
  DistrictBridgeIdsFilter,
  districtBridgeIdsParams,
} from 'core/form/filterForm/components/miltiSelect/DistrictBridgeIds.Filter';
import {
  ObjectServiceIdsFilter,
  objectServiceIdsParams,
} from 'core/form/filterForm/components/miltiSelect/ObjectServiceIds.Filter';
import {
  OghStatusesFilter,
  oghStatusesParams,
} from 'core/form/filterForm/components/miltiSelect/OghStatusesFilter';
import {
  OkrugBridgeIdsFilter,
  okrugBridgeIdsParams,
} from 'core/form/filterForm/components/miltiSelect/OkrugBridgeIds.Filter';
import {
  BridgeStateIdFilter,
  bridgeStateParams,
} from 'core/form/filterForm/components/selects/BridgeStateId.Filter';
import {
  GetBalanceDateFilter,
  getBalanceDateParams,
} from 'core/form/filterForm/components/selects/GetBalanceDate.Filter';
import {
  GrbsIdFilter,
  grbsIdParams,
} from 'core/form/filterForm/components/selects/GrbsId.Filter';
import {
  AddressFilter,
  addressParams,
} from 'core/form/filterForm/components/text/Address.Filter';
import {
  IdOrNameFilter,
  idOrNameParams,
} from 'core/form/filterForm/components/text/IdOrName.Filter';
import { RegistryFilterFormFF } from 'core/form/filterForm/RegistryFilterFormFF';
import { preparationValues } from 'core/utils/helpers/preparationValues';
import { useQueryParams } from 'core/utils/hooks/useQueryParams';
import React from 'react';

/**
 * Хук получения изначального состояния и изменения фильтров __Мостов__.
 *
 * @returns
 */
export const useFilterQueryParamsBridge = () => {
  const [filters, setFilters] = useQueryParams([
    grbsIdParams,
    addressParams,
    dateFromParams,
    dateToParams,
    idOrNameParams,
    oghStatusesParams([1]),
    ownerIdParams,
    cleanCategoryIdsParams,
    districtBridgeIdsParams,
    objectServiceIdsParams,
    okrugBridgeIdsParams,
    getBalanceDateParams,
    bridgeTypeLevel1IdsParams,
    bridgeTypeLevel2IdsParams,
    bridgeStateParams,
    cultureObjectParams,
  ]);

  return {
    filters,
    prepareFilters: preparationValues(filters),
    setFilters,
  };
};

/**
 * Форма фильтрации _Мостов__.
 *
 * @returns Форму фильтрации.
 */
export const FilterBridge = () => {
  return (
    <RegistryFilterFormFF useFilterQueryParams={useFilterQueryParamsBridge}>
      <IdOrNameFilter label={'Наименование/Краткое наименование/ID'} />
      <OghStatusesFilter />
      <DateFromFilter />
      <DateToFilter />
      <AddressFilter />
      <OwnerIdFilter />
      <GrbsIdFilter />
      <BridgeTypeLevel1IdsFilter />
      <BridgeTypeLevel2IdsFilter />
      <GetBalanceDateFilter />
      <ObjectServiceIdsFilter />
      <DistrictBridgeIdsFilter />
      <CleanCategoryIdsFilter />
      <OkrugBridgeIdsFilter />
      <BridgeStateIdFilter />
      <CultureObjectFilter />
    </RegistryFilterFormFF>
  );
};
