import { LinkShortObjectIdCell } from 'core/uiKit/components/cells/Link.ShortObjectId.Cell';

/**
 * Колонки таблицы IntersectionTable.
 *
 * @returns {object}
 */
export const getColumnsNeighboursTable = () => {
  return [
    {
      Cell: LinkShortObjectIdCell,
      Header: 'ID',
      accessor: 'short_object_id',
    },
    { Header: 'Заказчик', accessor: 'customer_short_name' },
    { Header: 'Наименование', accessor: 'object_name' },
    {
      Header: 'Балансодержатель',
      accessor: 'owner_short_name',
    },
    { Header: 'Статус', accessor: 'state_name' },
    { Header: 'Тип создания объекта', accessor: 'create_type_name' },
    { Header: 'Дата с', accessor: 'start_date' },
    { Header: 'Дата по', accessor: 'end_date' },
  ];
};
