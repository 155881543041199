import './login.scss';

import FaIcon from 'app/components/common/FaIcon';
import SudirIcon from 'assets/images/sudir.svg';
import * as env from 'config/env';
import { AuthUrlsFF } from 'core/form/finalForm/fields/prepared/selects/simple/AuthUrls.FF';
import { PasswordFF } from 'core/form/finalForm/fields/prepared/textField/PasswordFF';
import { UserNameFF } from 'core/form/finalForm/fields/prepared/textField/UserNameFF';
import { Button } from 'core/uiKit/components/buttons';
import { Link } from 'react-router-dom';

import { FormAuthFF } from '../components/Form.Auth.FF';
import { useMutationAuth } from './hooks/useMutationAuth';
import { validateForm } from './utils/validateForm';

const frontendEnvironment = env.FRONTEND_ENVIRONMENT;

/**
 * Login.
 *
 * @returns {JSX.Element}
 */
export const Login = () => {
  const { loginSubmit, isLoading, errorMessage } = useMutationAuth();
  return (
    <FormAuthFF
      submit={loginSubmit}
      isLoading={isLoading}
      errorMessage={errorMessage}
      validate={validateForm}
      Header={'Вход'}
      Footer={
        <>
          <div className={'d-flex gap-1'}>
            <Button
              loading={isLoading}
              dark={true}
              variant={'contained'}
              color={'primary'}
              type={'submit'}
            >
              {isLoading ? null : (
                <FaIcon className={'submitIcon'} icon={'sign-in'} />
              )}
              Войти
            </Button>
            {frontendEnvironment === 'preprod' ? (
              <Button
                variant={'contained'}
                className={'button__sudir'}
                startIcon={<img src={SudirIcon} width={20} height={20} />}
                onClick={() =>
                  (window.location.href =
                    'https://ods-bi.mos.ru/idm/realms/test-reestr-ogh/protocol/openid-connect/auth?client_id=broker2&redirect_uri=https://test-reestr-ogh.tech.mos.ru/api/login/keycloak&response_type=code&scope=openid&kc_idp_hint=sudir-internal')
                }
              >
                Войти через СУДИР
              </Button>
            ) : null}
          </div>
          <Link to="/auth/forgotPassword">Восстановить пароль</Link>
          {errorMessage && (
            <div className={'errorMessage'}>
              <span className={'error-message-details'}>
                {`Вход невозможен: ${errorMessage}`}
              </span>
            </div>
          )}
        </>
      }
    >
      <AuthUrlsFF />
      <UserNameFF autoFocus={true} />
      <PasswordFF />
    </FormAuthFF>
  );
};
