import './AutocompliteAsync.scss';

import { getFieldError } from 'app/utils/validate/getFieldError';
import { HelpTooltip } from 'core/uiKit/components';
import {
  AutocompleteMUI,
  CircularProgress,
  TextFieldMUI,
  Tooltip,
} from 'core/uiKit/material-ui';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import { log } from 'core/utils/log';
import { get } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

/**
 * TransformDataBeforeFetch.
 *
 * @param {string} startsWith - Поисковая фраза.
 * @returns {{
 * maxRows: 12,
 * startsWith,
 * }}
 */
const transformDataBeforeFetch = (startsWith = '') => ({
  maxRows: 12,
  startsWith,
});

/**
 * Асинхронное автозаполнение.
 *
 * @param {*} props - The Properties.
 * @param {*} [props.additionalDataForRequest] - Дополнительные данные для запроса.
 * @param {string} [props.className] - The name of the class.
 * @param {boolean} [props.disabled] - Заблокировать на редактирование.
 * @param {string} [props.errorText] - Текст ошибки.
 * @param {Function} props.fetchFunction - Функция запроса.
 * @param {string} [props.fetchFunctionName] - Имя Функция запроса.
 * @param {string} [props.helperText] - Вывод вспомогательного текста.
 * @param {string} props.label - The label.
 * @param {*} [props.meta] - The meta.
 * @param {Function} [props.onChange] - Функция вызова когда выберется итем.
 * @param {string} [props.placeholder] - The placeholder.
 * @param {boolean} [props.required] - Вывод информации об обязательном поле.
 * @param {string} [props.tooltip] - The tooltip.
 * @param {{}} [props.value] - Значение.
 * @param {string} [props.helpTooltip] - The help tooltip.
 * @returns {JSX.Element}
 */
export const AutocompleteAsync_ = ({
  additionalDataForRequest = {},
  className,
  disabled,
  errorText,
  fetchFunction,
  fetchFunctionName,
  helperText,
  label,
  meta = {},
  onChange: change,
  placeholder = '',
  required,
  tooltip = '',
  value = null,
  helpTooltip = '',
}) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [inputValueDebounced] = useDebounce(inputValue, 300);

  const data = transformDataBeforeFetch(inputValueDebounced);

  const functionName = fetchFunctionName || fetchFunction.name;

  const { data: res, isLoading } = useQueryAdaptor(
    ['' + functionName, { ...additionalDataForRequest, ...data }],
    () => fetchFunction({ ...additionalDataForRequest, ...data }),
  );

  useMemo(() => {
    let value = [];
    const maxRows = transformDataBeforeFetch().maxRows;
    if (Array.isArray(res)) {
      value = res;
      if (value.length > maxRows) {
        log.info(
          '!!! Из',
          functionName,
          'приходит слишком длинный массив длина',
          value.length,
          'элементов',
        );
        value = value.slice(0, maxRows);
      }
    }
    setOptions(value);
  }, [functionName, res]);

  const [openTooltip, setOpenTooltip] = React.useState(false);

  /**
   * HandleCloseTooltip.
   *
   * @returns {void}
   */
  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  /**
   * HandleOpenTooltip.
   *
   * @returns {void}
   */
  const handleOpenTooltip = () => {
    setOpenTooltip(value?.name?.length > 25);
  };

  /**
   * HandleChange.
   *
   * @param {Event} event - Event.
   * @param {{id: number, name: string}} value - Value.
   * @returns {void}
   */
  const handleChange = (event, value) => {
    change(value);
  };
  const error = getFieldError(meta) || errorText;
  const isError = !!error;

  /**
   * GetByName.
   *
   * @param {{}} value - Value.
   * @returns {void}
   */
  const getByName = (value) => get(value, 'name', '');

  return (
    <Tooltip title={tooltip}>
      <AutocompleteMUI
        className={className}
        disabled={disabled}
        filterOptions={(options) => options}
        getOptionLabel={(option) => getByName(option)}
        isOptionEqualToValue={(option, value) => {
          if (!value) {
            return [];
          }
          return option?.name === value?.name;
        }}
        loading={isLoading}
        options={options}
        renderInput={(params) => (
          <Tooltip
            open={openTooltip}
            onClose={handleCloseTooltip}
            onOpen={handleOpenTooltip}
            placement={'top'}
            title={value?.name || ''}
          >
            <TextFieldMUI
              {...params}
              required={required}
              error={isError}
              variant={'standard'}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              label={<HelpTooltip label={label} helpingElement={helpTooltip} />}
              placeholder={placeholder}
              helperText={error || helperText || <span>&nbsp;</span>}
            />
          </Tooltip>
        )}
        style={{ width: '100%' }}
        value={value}
        loadingText={'Загрузка...'}
        openText="Открыть"
        closeText="Закрыть"
        clearText="Очистить"
        noOptionsText="Ничего не найдено"
        onChange={handleChange}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
      />
    </Tooltip>
  );
};
