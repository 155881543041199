import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetDictionary } from 'app/api/hooks/useGetDictionary';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import isMandatory from 'app/utils/card/isMandatory';

type OGHObjectType = {
  id: number;
  code: string;
  name: string;
  ogh_object_type_id: number;
  parent_ogh_object_type_list: number[];
  buildings_type_id: { buildings_type: number };
};

/**
 * Setup Уточнение назначения.
 *
 * @returns Данные для комп.
 */
export const useSetupBuildingsTypeSpecIdField = () => {
  const { data = [] } = useGetDictionary<OGHObjectType[]>('buildingsTypeSpec');
  const { type_id: typeId } = useGetCurrentCard();
  const { type_id: parentTypeId } = useGetParentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  const options = data?.filter((item) =>
    item?.parent_ogh_object_type_list?.includes(parentTypeId),
  );

  return {
    disabled: !(editMode && isEditCurrentObjectIsExternalSystem),
    options,
    required: isMandatory(typeId, 'buildings_type_spec_id', true),
  };
};
