import { RANDOM_WORD } from 'core/forTesting/constants';
import React from 'react';
import { shallow } from 'shallowly';
import { vi } from 'vitest';

import { Cleaning } from './Cleaning';
import { useSetupCleaning } from './useSetup.Cleaning';

vi.mock('./useSetup.Cleaning');

describe('🐛 spec Cleaning', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupCleaning.mockReturnValue({
      calcAttributes: {},
      hintIfIsNotOrphanObject: vi.fn(),
    });

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<Cleaning propertyTab={''} />);

    //❓ Assert
    expect(wrapper.text()).toBe(`<TabPanel>
  <FieldsSplitColumns>
    <NumberFieldRF />
    <NumberFieldRF />
    null
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
    <NumberFieldRF />
  </FieldsSplitColumns>
</TabPanel>`);
  });

  it('🧪 show TabPanel', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupCleaning.mockReturnValue({
      calcAttributes: {},
      hintIfIsNotOrphanObject: vi.fn(),
    });

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<Cleaning propertyTab={'cleaning'} />);

    //❓ Assert
    expect(wrapper.find('TabPanel').prop('show')).toBeTruthy();
  });

  it('🧪 🧪 close TabPanel', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupCleaning.mockReturnValue({
      calcAttributes: {},
      hintIfIsNotOrphanObject: vi.fn(),
    });

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<Cleaning propertyTab={RANDOM_WORD} bla={'bla'} />);

    //❓ Assert
    expect(wrapper.find('TabPanel').prop('show')).toBeFalsy();
  });
});
