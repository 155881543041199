import {
  CloseIcon,
  DialogActionsMUI,
  DialogContentMUI,
  DialogMUI,
  DialogTitleMUI,
  IconButton,
  Typography,
} from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

import { dialogTitleWrapStyles, overflowStyle } from './styles';

const propTypes = {
  dialog: {
    PaperProps: PropTypes.object,
    actions: PropTypes.element,
    children: PropTypes.node,
    className: PropTypes.string,
    disableBackdropClick: PropTypes.bool,
    disableEscapeKeyDown: PropTypes.bool,
    fullScreen: PropTypes.bool,
    maxWidth: PropTypes.oneOf(['sm', 'md', 'lg']),
    onBackdropClick: PropTypes.func,
    onEnter: PropTypes.func,
    onEntered: PropTypes.func,
    onEntering: PropTypes.func,
    onEscapeKeyDown: PropTypes.func,
    onExit: PropTypes.func,
    onExited: PropTypes.func,
    onExiting: PropTypes.func,
    onHide: PropTypes.func,
    scroll: PropTypes.oneOf(['body', 'paper']),
    show: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  },
  dialogTitle: {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  },
};

const defaultProps = {
  maxWidth: 'sm',
};

/**
 * Компонент Dialog.
 *
 * @deprecated
 * @returns {JSX.Element}
 */
class Dialog extends React.Component {

  /**
   * Метод handleClose.
   *
   * @returns {void}
   */
  handleClose = () => {
    const { onHide } = this.props;
    onHide();
  };

  /**
   * Метод render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const {
      actions,
      children,
      className,
      disableBackdropClick,
      disableEscapeKeyDown,
      fullScreen,
      maxWidth,
      show,
      PaperProps,
      scroll,
      title,
      onBackdropClick,
      onEnter,
      onEntered,
      onEntering,
      onEscapeKeyDown,
      onExit,
      onExited,
      onExiting,
    } = this.props;

    return (
      <DialogMUI
        className={className}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={maxWidth}
        open={show}
        PaperProps={{
          ...PaperProps,
          style: overflowStyle,
        }}
        scroll={scroll}
        onBackdropClick={onBackdropClick}
        onClose={this.handleClose}
        onEnter={onEnter}
        onEntered={onEntered}
        onEntering={onEntering}
        onEscapeKeyDown={onEscapeKeyDown}
        onExit={onExit}
        onExited={onExited}
        onExiting={onExiting}
      >
        {title && <DialogTitle onClose={this.handleClose}>{title}</DialogTitle>}
        {children && (
          <DialogContentMUI dividers={true} style={overflowStyle}>
            {children}
          </DialogContentMUI>
        )}
        <DialogActionsMUI>{actions}</DialogActionsMUI>
      </DialogMUI>
    );
  }
}

Dialog.propTypes = propTypes.dialog;
Dialog.defaultProps = defaultProps;

/**
 * Компонент DialogTitle.
 *
 * @param {object} props - Пропсы.
 * @param {React.ReactNode} props.children - Дети.
 * @param {Function} props.onClose - Функция закрытия.
 * @returns {JSX.Element}
 */
function DialogTitle({ children, onClose }) {
  return (
    <DialogTitleMUI disableTypography={true}>
      <div style={dialogTitleWrapStyles}>
        <Typography align="left" variant="h6">
          {children}
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="Close"
            className="Dialog-Close-Btn"
            size="medium"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </DialogTitleMUI>
  );
}

DialogTitle.propTypes = propTypes.dialogTitle;

export default Dialog;
