import { CREATE_TYPE_NAME } from 'core/form/filterForm';
import { CreateType } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'redux-form';

/**
 * Компонент CreateTypeWithReduxForm.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Значения инпута и метаданные.
 * @returns {JSX.Element}
 */
export const CreateTypeWithReduxForm = ({ input, ...props }) => {
  return <CreateType {...input} {...props} />;
};

/**
 * Компонент CreateTypeRF.
 *
 * @param {object} params - Пропсы.
 * @param {boolean} [params.disabled] - Disabled.
 * @param {object} [params.otherProps] - Дополнительные пропсы.
 * @returns {JSX.Element}
 */
export const CreateTypeRF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field
      disabled={disabled}
      {...otherProps}
      name={CREATE_TYPE_NAME}
      component={CreateTypeWithReduxForm}
    />
  );
};

export { CREATE_TYPE_NAME };
