import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  DISTRICT_BRIDGE_ID_NAME,
  DistrictBridgeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/DistrictBridgeId';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * SelectFF __Район__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const DistrictBridgeIdFF = (props: SelectPropsType) => {
  return (
    <FieldFF
      {...props}
      component={DistrictBridgeId}
      name={DISTRICT_BRIDGE_ID_NAME}
    />
  );
};
