import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  OBJECT_SERVICE_ID_NAME,
  ObjectServiceId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/ObjectServiceId';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * SelectFF __Обслуживание объекта__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const ObjectServiceIdFF = (props: SelectPropsType) => {
  return (
    <FieldFF
      {...props}
      component={ObjectServiceId}
      name={OBJECT_SERVICE_ID_NAME}
    />
  );
};
