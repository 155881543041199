import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  PUBLIC_TOILET_TYPE_ID_NAME,
  PublicToiletTypeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/PublicToiletTypeId';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * Select FF __Тип ОТ__.
 *
 * @param props - Пропсы.
 * @returns Select RF.
 */
export const PublicToiletTypeIdFF = (props: SelectPropsType) => {
  return (
    <FieldFF
      {...props}
      component={PublicToiletTypeId}
      name={PUBLIC_TOILET_TYPE_ID_NAME}
    />
  );
};
