import { TextField } from 'core/uiKit/inputs/TextField';
import React from 'react';
import { InputPropsType } from 'types/inputs';

export const SHORT_NAME_NAME = 'short_name';

/**
 * Краткое наименование.
 *
 * @param props - Пропсы.
 * @returns <TextField/>.
 */
export const ShortName = (props: InputPropsType) => {
  // @ts-ignore
  return <TextField label={'Краткое наименование'} {...props} />;
};
