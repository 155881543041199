import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import { OwnerIdFF } from 'core/form/finalForm/fields/prepared/selects/autocompleteAsync/OwnerIdFF';
import { DepartmentIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/DepartmentId.FF';
import { DistrictIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/DistrictId.FF';
import { FormVisitIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/FormVisitId.FF';
import { IsAdaptedPeopleLimitedMobilityFF } from 'core/form/finalForm/fields/prepared/selects/simple/IsAdaptedPeopleLimitedMobility.FF';
import { IsAvailabilityPaymentTerminalFF } from 'core/form/finalForm/fields/prepared/selects/simple/IsAvailabilityPaymentTerminal.FF';
import { OkrugFF } from 'core/form/finalForm/fields/prepared/selects/simple/OkrugtFF';
import { PublicToiletTypeIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/PublicToiletTypeId.FF';
import { AddressNameFF } from 'core/form/finalForm/fields/prepared/textField/AddressName.FF';
import { ClarifyingLocationNameFF } from 'core/form/finalForm/fields/prepared/textField/ClarifyingLocationName.FF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import React from 'react';

import { FieldsTypePT } from './FieldsTypePT';
import { TableWorkTimeFF } from './Table.WorkTime.FF';

/**
 * Таб свойств.
 *
 * @returns {Element}
 */
export const TabProperties = () => {
  const { editMode } = useMode();
  return (
    <>
      <FieldsSplitColumns>
        <PublicToiletTypeIdFF withAll={false} disabled={!editMode} />
        <FieldsTypePT disabled={!editMode} />
      </FieldsSplitColumns>

      <OwnerIdFF disabled={!editMode} />

      <FieldsSplitColumns>
        <DepartmentIdFF disabled={!editMode} />
        <OkrugFF disabled={!editMode} />
        <DistrictIdFF disabled={!editMode} />
        <ClarifyingLocationNameFF disabled={!editMode} />
        <AddressNameFF disabled={!editMode} />
        <FormVisitIdFF withAll={false} disabled={!editMode} />
        <IsAdaptedPeopleLimitedMobilityFF
          withAll={false}
          disabled={!editMode}
        />
        <IsAvailabilityPaymentTerminalFF withAll={false} disabled={!editMode} />
      </FieldsSplitColumns>
      <TextFieldFF
        label={'Принадлежность к ОГХ'}
        disabled={true}
        name={'belonging_ogh'}
      />
      <TableWorkTimeFF />
    </>
  );
};
