import { WORK_TIME_NAME } from 'app/pages/cardsOgh/PublicToilet/LeftPanel.PublicToilet/Tab.Properties/Table.WorkTime.FF';
import { OWNER_ID_NAME } from 'core/uiKit/preparedInputs';
import { CATEGORY_TOILET_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CategoryToiletId';
import { CORRECTION_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CorrectionId';
import { DEPARTMENT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/DepartmentId';
import { DETAILS_MODELS_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/DetailsModelsId';
import { DISTRICT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/DistrictId';
import { FORM_VISIT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/FormVisitId';
import { IS_ADAPTED_PEOPLE_LIMITED_MOBILITY_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/IsAdaptedPeopleLimitedMobility';
import { IS_AVAILABILITY_PAYMENT_TERMINAL_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/IsAvailabilityPaymentTerminal';
import { LOCATION_METHOD_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/LocationMethodId';
import { PUBLIC_TOILET_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/PublicToiletTypeId';
import { ADDRESS_NAME_NAME } from 'core/uiKit/preparedInputs/textField/AddressName/AddressName';
import { CLARIFYING_LOCATION_NAME_NAME } from 'core/uiKit/preparedInputs/textField/ClarifyingLocationName/ClarifyingLocationName';
import { QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/Quantity';

export const mapAttribute = [
  PUBLIC_TOILET_TYPE_ID_NAME,
  LOCATION_METHOD_ID_NAME,
  CATEGORY_TOILET_ID_NAME,
  QUANTITY_NAME,
  DETAILS_MODELS_ID_NAME,
  CORRECTION_ID_NAME,
  OWNER_ID_NAME,
  DEPARTMENT_ID_NAME,
  FORM_VISIT_ID_NAME,
  CLARIFYING_LOCATION_NAME_NAME,
  ADDRESS_NAME_NAME,
  IS_ADAPTED_PEOPLE_LIMITED_MOBILITY_NAME,
  IS_AVAILABILITY_PAYMENT_TERMINAL_NAME,
  DISTRICT_ID_NAME,
  WORK_TIME_NAME,
  'okrug_id',
];
