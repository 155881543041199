import { MAF_TYPE_LEVEL_1_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/MafTypeLevel1Id';
import React from 'react';
import { Field } from 'redux-form';

import { MafTypeLevel1IdWithReduxForm } from './MafTypeLevel1IdwithRF';

/**
 * Select RF __Вид МАФ__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const MafTypeLevel1IdRF = (props) => {
  return (
    <Field
      {...props}
      name={MAF_TYPE_LEVEL_1_ID_NAME}
      component={MafTypeLevel1IdWithReduxForm}
    />
  );
};
