import { switchServisMap } from 'core/utils/map/switchServisMap';

import { useSetupNeighboursButtonDTW } from './useSetup.NeighboursButton.DTW';
import { useSetupNeighboursButtonEgip } from './useSetup.NeighboursButton.Egip';

export const useSetupNeighboursButton = switchServisMap({
  // @ts-ignore
  dtw: useSetupNeighboursButtonDTW,
  egip: useSetupNeighboursButtonEgip,
});
