import { Cell } from 'types/table';

/**
 * Колонка __Единица измерения по пространственным координатам__.
 *
 * @type {{
 * accessor: 'unit_name',
 * Header: 'Единица измерения по пространственным координатам',
 * sortable: boolean
 * }}
 * @augments Cell
 */
export const unit_name = {
  Header: 'Единица измерения по пространственным координатам',
  accessor: 'unit_name',
  sortable: true,
};
