import { Button } from 'core/uiKit/components/buttons/Button';
import { Menu } from 'core/uiKit/components/Menu/Menu';
import { ExpandMoreIcon } from 'core/uiKit/material-ui';
import React, { useState } from 'react';

import { PrintItemButtonPT } from './PrintItem.Button.PT';

/**
 * Кнопка для общественных туалетов __Отчеты__.
 *
 * @returns
 */
export const ReportsButtonPT = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * Функция handleOpen.
   *
   * @param event - Event.
   */
  const handleClick = (event: Event) => {
    // @ts-ignore
    setAnchorEl(event.currentTarget);
  };

  /**
   * Функция handleClose.
   *
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        // @ts-ignore
        onClick={handleClick}
        variant="contained"
        color="primary"
        endIcon={<ExpandMoreIcon />}
      >
        Отчёты
      </Button>

      {/*@ts-ignore*/}
      <Menu
        anchorEl={anchorEl}
        id="simple-menu"
        keepMounted={true}
        open={Boolean(anchorEl)}
        PaperProps={{
          style: {
            maxHeight: 450,
          },
        }}
        onClose={handleClose}
      >
        <PrintItemButtonPT
          text={'Отчет СОТ'}
          publicToiletTypeId={1}
          template={'pt_stationary'}
        />
        <PrintItemButtonPT
          text={'Отчет НТ'}
          publicToiletTypeId={2}
          template={'pt_non_stationary'}
        />
      </Menu>
    </>
  );
};
