import { usePreparationAbutmentTypeNameFF } from 'core/form/finalForm/fields/prepared/selects/simple/AbutmentTypeName.FF/usePreparation.AbutmentTypeName.FF';
import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  ABUTMENT_TYPE_ID_NAME,
  AbutmentTypeName,
  IAbutmentTypeProps,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/AbutmentTypeName';
import React from 'react';

/**
 * Select FF __Тип элемента сопряжения__.
 *
 * @param props - Пропсы.
 * @returns JSX.Element.
 */
export const AbutmentTypeNameFF = (props: IAbutmentTypeProps) => {
  usePreparationAbutmentTypeNameFF();

  return (
    <FieldFF
      {...props}
      name={ABUTMENT_TYPE_ID_NAME}
      component={AbutmentTypeName}
    />
  );
};
