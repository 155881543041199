// 33 Элементы благоустройства для маломобильных групп населенияimport { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import { QuantityField } from 'app/components/card/ogh/components/selects/Quantity.Field';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import getQuantityDigits from 'app/selectors/card/getQuantityDigits';
import hasQuantityLock from 'app/selectors/card/hasQuantityLock';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { NoCalcRF } from 'core/form/reduxForm/fields/prepared/checkboxes/NoCalc.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/numberField/TotalAreaGeo.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TextField } from 'core/uiKit/inputs/TextField';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import { ArrangeElementTypeIdField } from './components/selects/ArrangeElementTypeId.Field';
import { UnitIdField } from './components/selects/UnitId.Field';
import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['ppi'];
const Versions = VersionsTemplate(types);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

const propTypes = {
  card: PropTypes.object,
  changeFieldValue: PropTypes.func,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  formValues: PropTypes.object,
  mode: PropTypes.object,
  quantityLock: PropTypes.bool,
  required: PropTypes.bool,
};

/**
 * Классовый компонет PpiCard.
 */
class PpiCard extends React.Component {

  /**
   * Компонент жизненого цикла componentDidUpdate.
   *
   * @param {object} prevProps - Предыдущие пропсы.
   * @returns {void} - Nothing.
   * @example --------
   */
  componentDidUpdate(prevProps) {
    const {
      formValues: { unit_id },
      mode,
    } = this.props;
    if (prevProps.formValues.unit_id !== unit_id && !mode.viewMode) {
      this.setDefaultQuantity();
    }
  }

  /**
   * Функция setDefaultQuantity.
   *
   * @returns {void} - Nothing.
   * @example ----
   */
  setDefaultQuantity() {
    const { changeFieldValue, quantityLock } = this.props;
    if (quantityLock) {
      changeFieldValue('quantity', 1);
    } else {
      changeFieldValue('quantity', '');
    }
  }

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const {
      card,
      createMode,
      editMode,
      isEditCurrentObjectIsExternalSystem,
      mode,
      quantityDigits,
      quantityLock,
      required,
    } = this.props;

    return (
      <CardContainer>
        <CardAbstract>
          <div id="editor-card">
            {printOghCardHeader(card, mode)}
            {card && objectTabs(card.type_id)}

            <div id="props">
              <FieldsSplitColumns>
                <ArrangeElementTypeIdField />
                <ParentInfo />
                <NoCalcRF disabled={!editMode} />
                <IsDiffHeightMarkField />
              </FieldsSplitColumns>

              <Tabs tabs={tabs} />

              <div id="characteristics">
                <FieldsSplitColumns>
                  <QuantityField
                    digits={quantityDigits}
                    required={isMandatory(card.type_id, 'quantity', required)}
                    disabled={
                      !(
                        editMode &&
                        !quantityLock &&
                        isEditCurrentObjectIsExternalSystem
                      )
                    }
                  />
                  <TotalAreaGeoRF disabled={true} decimalScale={2} />

                  <UnitIdField />
                  <SelectRF
                    label={'Материал'}
                    options={card.material}
                    required={isMandatory(
                      card.type_id,
                      'material_id',
                      required,
                    )}
                    name={'material_id'}
                    disabled={!editMode}
                  />
                </FieldsSplitColumns>
                <TextField
                  value={card.zone_name || ''}
                  label={'Принадлежность элемента к зоне территории'}
                  id={'zone_name'}
                  name={'zone_name'}
                  disabled={true}
                />
              </div>
              <div hidden={true} id="docs">
                <OtherDocumentsRF />
              </div>
            </div>
            <div hidden={true} id="versions">
              <Versions
                card={card}
                disabled={createMode}
                key={`versions-${card.root_id}`}
              />
            </div>
          </div>
        </CardAbstract>
      </CardContainer>
    );
  }
}

PpiCard.propTypes = propTypes;

const dictFields = ['arrange_element_type_id', 'unit_id', 'material_id'];

const numericFields = [
  {
    name: 'quantity',
    positive: true,
    type: 'decimal',
    zero: false,
  },
];

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 */
const validate = (values) => validateNumeric(values, numericFields);

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
const createAttribute = (formValues) => ({
  ...createDocumentsAttribute(formValues),
});

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void} - Жопа.
 * @example ----
 */
export function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

const improvementElementDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);

  const result = {
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),

    initialValues: {
      arrange_element_type_id: parse(
        'arrange_element_type_id.arrange_element_type',
      ),
      endDate: parseDate(props.card.end_date),
      is_diff_height_mark: parse('is_diff_height_mark'),
      material_id: parse('material_id.material'),
      no_calc: parse('no_calc'),
      parent_name: props.card.parent_info,
      quantity: parse('quantity'),
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
      unit_id: parse('unit_id.unit'),
    },

    onSubmit: onSubmit.bind({ props }),
    quantityDigits: getQuantityDigits(state),
    quantityLock: hasQuantityLock(state),
    required: isRequired(state, props),
    validate,
  };

  return improvementElementDocumentsReducer(result, state, props);
};

/**
 * Функция получения экшенов.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}} - Экшен changeFieldValue.
 * @example ----
 */
const mapDispatchToProps = (dispatch) => ({

  /**
   * Изменение значения.
   *
   * @param {string} field - Имя поля.
   * @param {*} value - Значение.
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

/**
 * Обертка для использования хуков.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - Реакт Компонент.
 * @example -----
 */
const PpiCardContainer = (props) => {
  const { typeId } = useGetParentCard();
  return <PpiCard parentTypeId={typeId} {...props} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(PpiCardContainer));
