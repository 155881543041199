import {
  ABUTMENT,
  BRIDGE,
  ENGINEER_BUILDING,
  ICE_RINK,
  PT,
  RED_BOOK_PLANT,
  TANK,
  VERTICAL_LANDSCAPING,
  WATER_BUILDING,
} from 'app/constants/oghTypes';
import { Abutment } from 'app/pages/cardsOgh/Abutment';
import { Bridge } from 'app/pages/cardsOgh/Bridge/Bridge';
import { EngineerBuilding } from 'app/pages/cardsOgh/EngineerBuilding/EngineerBuilding';
import { PublicToilet } from 'app/pages/cardsOgh/PublicToilet';
import { RedBookPlant } from 'app/pages/cardsOgh/RedBookPlant';
import { Tank } from 'app/pages/cardsOgh/Tank';
import { VerticalLandscaping } from 'app/pages/cardsOgh/VerticalLandscaping';
import { WaterBuilding } from 'app/pages/cardsOgh/WaterBuilding';

import { IceRink } from './IceRink';

export const cards = {
  [ABUTMENT]: {
    component: Abutment,
    map: true,
  },
  [BRIDGE]: {
    component: Bridge,
    map: true,
  },
  [ENGINEER_BUILDING]: {
    component: EngineerBuilding,
    map: true,
  },
  [ICE_RINK]: {
    component: IceRink,
    map: true,
  },
  [PT]: {
    component: PublicToilet,
    map: true,
  },
  [RED_BOOK_PLANT]: {
    component: RedBookPlant,
    map: true,
  },
  [TANK]: {
    component: Tank,
    map: false,
  },
  [VERTICAL_LANDSCAPING]: {
    component: VerticalLandscaping,
    map: true,
  },
  [WATER_BUILDING]: {
    component: WaterBuilding,
    map: true,
  },
};
