import {
  CAR_TYPES_NAME as CAR_TYPES_NAME_D,
  CarTypesFF as CarTypesFFD,
} from '././CarTypes.FF';
import {
  CAR_CONDITIONS_NAME as CAR_CONDITIONS_NAME_D,
  CarConditionsFF as CarConditionsFFD,
} from './CarConditions.FF';
import {
  CAR_FUNC_TYPES_NAME as CAR_FUNC_TYPES_NAME_D,
  CarFuncTypesFF as CarFuncTypesFFD,
} from './CarFuncTypes.FF';
import {
  CAR_SEASONS_NAME as CAR_SEASONS_NAME_D,
  CarSeasonsFF as CarSeasonsFFD,
} from './CarSeasons.FF';
import {
  CHANGE_LOG_TYPE_NAME as CHANGE_LOG_TYPE_NAME_D,
  ChangeLogTypeFF as ChangeLogTypeFFD,
} from './ChangeLogType.FF';
import {
  CHANGE_TYPE_NAME as CHANGE_TYPE_NAME_D,
  ChangeTypeFF as ChangeTypeFFD,
} from './ChangeType.FF';
import {
  SENSOR_TYPE_NAME as SENSOR_TYPE_NAME_D,
  SensorTypeFF as SensorTypeFFD,
} from './SensorType.FF';

export * from './CarConditions.FF';
export * from './GrbsIdMulti.FF';
export * from './ListTypeIdMulti.FF';
export * from './MatchingChangeTypes.FF';
export * from './OghStatusesFF';
export * from './OghTypesFF';

/**
 * @deprecated
 * @see используй из CarTypes.FF
 */
export const CarTypesFF = CarTypesFFD;

/**
 * CAR_TYPES_NAME.
 *
 * @deprecated
 * @see используй из CarTypes.FF
 */
export const CAR_TYPES_NAME = CAR_TYPES_NAME_D;

/**
 * @deprecated
 * @see используй из CarConditions.FF
 */
export const CarConditionsFF = CarConditionsFFD;

/**
 * CAR_CONDITIONS_NAME.
 *
 * @deprecated
 * @see используй из CarConditions.FF
 */
export const CAR_CONDITIONS_NAME = CAR_CONDITIONS_NAME_D;

/**
 * @deprecated
 * @see используй из CarSeasons.FF
 */
export const CarSeasonsFF = CarSeasonsFFD;

/**
 * CAR_SEASONS_NAME.
 *
 * @deprecated
 * @see используй из CarSeasons.FF
 */
export const CAR_SEASONS_NAME = CAR_SEASONS_NAME_D;

/**
 * CarFuncTypesFF.
 *
 * @deprecated
 * @see используй из CarFuncTypes.FF
 */
export const CarFuncTypesFF = CarFuncTypesFFD;

/**
 * CAR_FUNC_TYPES_NAME.
 *
 * @deprecated
 * @see используй из CarFuncTypes.FF
 */
export const CAR_FUNC_TYPES_NAME = CAR_FUNC_TYPES_NAME_D;

/**
 * SensorTypeFF.
 *
 * @deprecated
 * @see используй из SensorType.FF
 */
export const SensorTypeFF = SensorTypeFFD;

/**
 * SENSOR_TYPE_NAME.
 *
 * @deprecated
 * @see используй из SensorType.FF
 */
export const SENSOR_TYPE_NAME = SENSOR_TYPE_NAME_D;

/**
 * ChangeLogTypeFF.
 *
 * @deprecated
 * @see используй из ChangeLogType.FF
 */
export const ChangeLogTypeFF = ChangeLogTypeFFD;

/**
 * CHANGE_LOG_TYPE_NAME.
 *
 * @deprecated
 * @see используй из ChangeLogType.FF
 */
export const CHANGE_LOG_TYPE_NAME = CHANGE_LOG_TYPE_NAME_D;

/**
 * ChangeTypeFF.
 *
 * @deprecated
 * @see используй из ChangeType.FF
 */
export const ChangeTypeFF = ChangeTypeFFD;

/**
 * CHANGE_TYPE_NAME.
 *
 * @deprecated
 * @see используй из ChangeType.FF
 */
export const CHANGE_TYPE_NAME = CHANGE_TYPE_NAME_D;
