import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  ADDRESS_NAME_NAME,
  AddressName,
} from 'core/uiKit/preparedInputs/textField/AddressName/AddressName';
import React from 'react';

/**
 * Текстовое поле withFF __Адрес__.
 *
 * @param {object} props - Пропсы.
 * @returns {React.ReactElement}
 */
export const AddressNameFF = (props) => {
  return (
    <FieldFF {...props} name={ADDRESS_NAME_NAME} component={AddressName} />
  );
};
