import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { OghCardHeaderFF } from 'app/pages/cardsOgh/components/headerFF/OghHeaderFF';
import Versions from 'app/pages/cardsOgh/components/tables/Versions/Versions';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { CheckboxFF, NumberFieldFF } from 'core/form/finalForm/fields';
import { IsDiffHeightMarkFF } from 'core/form/finalForm/fields/prepared/checkboxes/IsDiffHeightMark.FF';
import { TotalAreaGeoFF } from 'core/form/finalForm/fields/prepared/numberField/TotalAreaGeo.FF';
import { WaterTypeIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/WaterTypeId.FF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TabsCardOGH } from 'core/uiKit/components/card.components/TabsCardOGH';
import { TabsCardOGHItem } from 'core/uiKit/components/card.components/TabsCardOGH/TabsCardOGH.Item';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

/**
 * Поля Водных сооружений.
 *
 * @returns {JSX.Element} - Компонент.
 * @example <WaterBuildingLeftPanel />
 */
export const LeftPanelWaterBuilding = () => {
  const { editMode } = useMode();
  const { rootId = 0 } = useGetCurrentCard();
  const isCreatedPassport = Boolean(rootId);
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  return (
    <CardContainer>
      <OghCardHeaderFF />
      <TabsCardOGH>
        <TabsCardOGHItem tabTitle={'Свойства'}>
          <FieldsSplitColumns>
            <WaterTypeIdFF
              withAll={false}
              disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
              required={true}
            />
            <NumberFieldFF
              name={'area'}
              label={'Площадь, кв.м.'}
              maxLength={10}
              required={true}
              disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
              digits={2}
            />
            <TotalAreaGeoFF decimalScale={2} disabled={true} />
            <ParentInfo />
          </FieldsSplitColumns>
          <FieldsSplitColumns>
            <CheckboxFF
              label={'Не учитывать'}
              name={'noCalc'}
              disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
            />
            <IsDiffHeightMarkFF
              disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
            />
          </FieldsSplitColumns>
        </TabsCardOGHItem>
        <TabsCardOGHItem tabTitle={'Версии'} hide={!isCreatedPassport}>
          <Versions />
        </TabsCardOGHItem>
      </TabsCardOGH>
    </CardContainer>
  );
};
