import { Validator } from 'core/form/Validator';
import { OKRUG_NAME, OWNER_ID_NAME } from 'core/uiKit/preparedInputs';
import { CATEGORY_TOILET_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CategoryToiletId';
import { CORRECTION_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CorrectionId';
import { DEPARTMENT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/DepartmentId';
import { DETAILS_MODELS_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/DetailsModelsId';
import { DISTRICT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/DistrictId';
import { FORM_VISIT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/FormVisitId';
import { IS_ADAPTED_PEOPLE_LIMITED_MOBILITY_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/IsAdaptedPeopleLimitedMobility';
import { IS_AVAILABILITY_PAYMENT_TERMINAL_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/IsAvailabilityPaymentTerminal';
import { LOCATION_METHOD_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/LocationMethodId';
import { PUBLIC_TOILET_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/PublicToiletTypeId';
import { ADDRESS_NAME_NAME } from 'core/uiKit/preparedInputs/textField/AddressName/AddressName';
import { CLARIFYING_LOCATION_NAME_NAME } from 'core/uiKit/preparedInputs/textField/ClarifyingLocationName/ClarifyingLocationName';
import { QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/Quantity';

interface IValuesPublicToilet {
  [PUBLIC_TOILET_TYPE_ID_NAME]?: number;
  [QUANTITY_NAME]?: number | string;
  [DISTRICT_ID_NAME]?: number;
  [PUBLIC_TOILET_TYPE_ID_NAME]?: number;
  [OWNER_ID_NAME]?: number;
  [DEPARTMENT_ID_NAME]?: number;
  [OKRUG_NAME]?: number;
  [FORM_VISIT_ID_NAME]?: number;
  [DISTRICT_ID_NAME]?: number;
  [CLARIFYING_LOCATION_NAME_NAME]?: number;
  [ADDRESS_NAME_NAME]?: number;
  [IS_ADAPTED_PEOPLE_LIMITED_MOBILITY_NAME]?: number;
  [IS_AVAILABILITY_PAYMENT_TERMINAL_NAME]?: number;
  [LOCATION_METHOD_ID_NAME]?: number;
  [CATEGORY_TOILET_ID_NAME]?: number;
  [DETAILS_MODELS_ID_NAME]?: number;
  [CORRECTION_ID_NAME]?: number;

  [key: string]: unknown;
}

/**
 * Функция валидации карточки __Общественные туалеты__.
 *
 * @param values - Значения формы.
 * @param values.foo - Поле.
 * @returns
 */
export const validatePublicToilet = (values: IValuesPublicToilet) => {
  const validate = new Validator(values);

  if (values[PUBLIC_TOILET_TYPE_ID_NAME] === 1) {
    validate.setRequired(LOCATION_METHOD_ID_NAME);
    validate.setRequired(QUANTITY_NAME);
    validate.setRequired(CATEGORY_TOILET_ID_NAME);
  }

  if (values[PUBLIC_TOILET_TYPE_ID_NAME] === 2) {
    validate.setRequired(DETAILS_MODELS_ID_NAME);
    validate.setRequired(CORRECTION_ID_NAME);
  }

  validate.setRequired(DISTRICT_ID_NAME);
  validate.setRequired(PUBLIC_TOILET_TYPE_ID_NAME);
  validate.setRequired(OWNER_ID_NAME);
  validate.setRequired(DEPARTMENT_ID_NAME);
  validate.setRequired(OKRUG_NAME);
  validate.setRequired(FORM_VISIT_ID_NAME);
  validate.setRequired(DISTRICT_ID_NAME);
  validate.setRequired(CLARIFYING_LOCATION_NAME_NAME);
  validate.setRequired(ADDRESS_NAME_NAME);
  validate.setRequired(IS_ADAPTED_PEOPLE_LIMITED_MOBILITY_NAME);
  validate.setRequired(IS_AVAILABILITY_PAYMENT_TERMINAL_NAME);

  return validate.getErrors();
};
