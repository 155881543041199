import { FieldRF } from 'core/form/reduxForm/helpers/FieldRF';
import {
  UNIT_ID_NAME,
  UnitId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/UnitId';
import React from 'react';
import { SelectRFProps } from 'types/inputs';

/**
 * Select RF __Единицы измерения__.
 *
 * @param props - Пропсы.
 * @returns Возвращает компонент Единиц измерения.
 */
export const UnitIdRF = (props: SelectRFProps) => {
  return <FieldRF {...props} component={UnitId} name={UNIT_ID_NAME} />;
};
