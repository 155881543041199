import { FormCardFF } from 'app/pages/cardsOgh/components/FormCard.FF';
import React from 'react';

import { LeftPanelPublicToilet } from './LeftPanel.PublicToilet';
import { useSetupPublicToilet } from './useSetup.PublicToilet';
import { validatePublicToilet } from './validate.PublicToilet';

/**
 * Карточка __Публичные туалеты__.
 *
 * @returns {React.ReactElement}
 */
export const PublicToilet = () => {
  const { preparingDataForAttributes, initialValues } = useSetupPublicToilet();

  return (
    <FormCardFF
      validate={validatePublicToilet}
      initialValues={initialValues}
      preparingDataForAttributes={preparingDataForAttributes}
    >
      <LeftPanelPublicToilet />
    </FormCardFF>
  );
};
