import 'assets/styles/dialogs.form.scss';

import { FinalForm } from 'core/form/finalForm/components/FinalForm';
import React, { FC } from 'react';

import {
  DialogContinueFFDialog,
  DialogContinueFFDialogProps,
} from './Dialog.ContinueFF.Dialog';

interface ContinueFFDialogProps<
  FormValues = Record<string, unknown>,
  InitialValues = Partial<FormValues>,
  ValidateValues = Partial<FormValues>,
> extends DialogContinueFFDialogProps {
  onSubmit: (value: FormValues) => unknown;
  initialValues: InitialValues;
  validate: (
    value: ValidateValues,
  ) => null | object | Record<keyof FormValues, string>;
}

/**
 * Диалоговое окно с формой Продолжить.
 *
 * @param {ContinueFFDialogProps} props - Properties.
 * @returns JSX.Element|null.
 */
export const ContinueFFDialog: FC<ContinueFFDialogProps> = ({
  Body,
  closeDialog,
  isLoading,
  isOpen,
  textHeader,
  onSubmit,
  initialValues,
  disabled,
  validate = () => null,
}: ContinueFFDialogProps) =>
  isOpen ? (
    <FinalForm
      onSubmit={(value) => {
        const errors = validate(value);
        if (errors) return errors;
        onSubmit(value);
      }}
      initialValues={initialValues}
    >
      <DialogContinueFFDialog
        {...{
          Body,
          closeDialog,
          disabled,
          isLoading,
          isOpen,
          textHeader,
        }}
      />
    </FinalForm>
  ) : null;
