import { CheckboxFF } from 'core/form/finalForm/fields/default/CheckboxFF';
import { NumberFieldFF } from 'core/form/finalForm/fields/default/NumberField.FF';
import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import { BridgeStateIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/BridgeStateId.FF';
import { ObjectServiceIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/ObjectServiceId.FF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import React from 'react';

import { EgrnGbuDate } from '../../../../fields/EgrnGbuDate';
import { EgrnMoscowDate } from '../../../../fields/EgrnMoscowDate';
import { ExploitationDate } from '../../../../fields/ExploitationDate';
import { showComponent } from '../../../../helpers/showComponent';
import { useSetupInitialDataBridge } from './useSetup.InitialData.Bridge';

/**
 * Таб __Исходные данные__.
 *
 * @returns TabPropertiesBridge.
 */
export const InitialDataBridge = () => {
  const {
    isMapTypeLevel1Bridge,
    isServiceObjectExploitation,
    isServiceObjectMajorRepairsTemporariry,
    isTypeLevel1Bridge,
    isTypeLevel1Tunnel,
  } = useSetupInitialDataBridge();

  return (
    <FieldsSplitColumns>
      <NumberFieldFF
        name={'area'}
        label={'Площадь на плане, кв.м'}
        required={true}
      />
      <NumberFieldFF
        name={'under_area'}
        label={'Площадь на плане подземной части, кв.м'}
      />
      <NumberFieldFF name={'distance'} label={'Длина, пог.м'} required={true} />
      <NumberFieldFF
        name={'quantity'}
        label={'Количество по пространственным координатам'}
      />
      <NumberFieldFF
        name={'unit_id'}
        label={'Единица измерения по пространственным координатам'}
      />
      {showComponent({
        Component: (
          <TextFieldFF
            name={'cad_number'}
            label={'Кадастровый номер'}
            required={true}
          />
        ),
        conditions: isServiceObjectExploitation,
      })}

      {showComponent({
        Component: (
          <TextFieldFF
            name={'get_balance_date'}
            label={'Год принятия на баланс'}
            mask={[/\d/, /\d/, /\d/, /\d/]}
            required={true}
          />
        ),
        conditions: isServiceObjectExploitation,
      })}

      {showComponent({
        Component: (
          <TextFieldFF
            name={'egrn_moscow_num'}
            label={'Запись в ЕГРП/ЕГРН о собственности города Москвы, номер'}
          />
        ),
        conditions: isServiceObjectExploitation,
      })}

      {showComponent({
        Component: (
          <TextFieldFF
            name={'reconstruction_date'}
            label={'Год реконструкции'}
            mask={[/\d/, /\d/, /\d/, /\d/]}
            required={true}
          />
        ),
        conditions: isServiceObjectExploitation,
      })}

      {showComponent({
        Component: <EgrnMoscowDate />,
        conditions: isServiceObjectExploitation,
      })}

      {showComponent({
        Component: <ExploitationDate />,
        conditions: isServiceObjectExploitation,
      })}

      <TextFieldFF
        name={'egrn_gbu_num'}
        label={
          'Запись в ЕГРП/ЕГРН об оперативном управлении ГБУ «Гормост», номер'
        }
      />
      <BridgeStateIdFF required={true} />

      {showComponent({
        Component: <EgrnGbuDate />,
        conditions: isServiceObjectExploitation,
      })}

      <CheckboxFF name={'moved_stuff'} label={'Движимое имущество'} />
      <ObjectServiceIdFF required={true} withAll={false} />

      {showComponent({
        Component: (
          <TextFieldFF
            name={'object_service_organization'}
            label={'Организация, ответственная за содержание'}
          />
        ),
        conditions: isServiceObjectMajorRepairsTemporariry,
      })}

      {showComponent({
        Component: (
          <NumberFieldFF
            name={'height_pilar'}
            label={'Наибольшая высота опоры, м'}
          />
        ),
        conditions: isServiceObjectExploitation && isTypeLevel1Bridge,
      })}

      {showComponent({
        Component: (
          <NumberFieldFF
            name={'lenght_gate_max'}
            label={'Длина наибольшего пролета, м'}
          />
        ),
        conditions: isServiceObjectExploitation && isTypeLevel1Bridge,
      })}

      {showComponent({
        Component: (
          <CheckboxFF
            name={'smis_are_installed'}
            label={'Датчики СМИС'}
            disabled={true}
          />
        ),
        conditions: isServiceObjectExploitation && isTypeLevel1Bridge,
      })}

      {showComponent({
        Component: (
          <NumberFieldFF
            name={'max_car_weight'}
            label={'Максимальная масса транспортного средства, т'}
          />
        ),
        conditions: isServiceObjectExploitation && isMapTypeLevel1Bridge,
      })}

      {showComponent({
        Component: (
          <NumberFieldFF
            name={'max_axle_load'}
            label={'Максимальная нагрузка на ось, т'}
          />
        ),
        conditions: isServiceObjectExploitation && isMapTypeLevel1Bridge,
      })}

      {showComponent({
        Component: (
          <NumberFieldFF
            name={'overpass_height'}
            label={'Высотные габариты подэстакадного пространства, м'}
          />
        ),
        conditions: isServiceObjectExploitation && isMapTypeLevel1Bridge,
      })}

      {showComponent({
        Component: (
          <NumberFieldFF
            name={'tunnel_height'}
            label={'Высотные габариты, м (тоннель)'}
          />
        ),
        conditions: isServiceObjectExploitation && isTypeLevel1Tunnel,
      })}
    </FieldsSplitColumns>
  );
};
