import { print } from 'app/api/requests/print';

/**
 * Метод печати таблицы смежных ОГХ.
 *
 * @param {object} data - Данные запроса.
 * @returns Возвращает данные для печати.
 */
export const printNeighboursTable = (data: Record<string, unknown>) => {
  const preparationData = { ...data, geometry: JSON.stringify(data.geometry) };
  return print('neighbours_list', preparationData);
};
