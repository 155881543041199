import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import { useGetCardCalcAttributeSelector } from 'app/selectors/useSelectors/useGetCardCalcAttribute.Selector';
import getHint from 'app/utils/getHint';

/**
 * Уборочные данные.
 *
 * @returns Данные.
 */
export const useSetupCleaning = () => {
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  const { is_orphan_object } = useEditorCardSelector();
  const calcAttributes = useGetCardCalcAttributeSelector();

  const isOrphanObject = Boolean(is_orphan_object);

  const required = false;

  const shouldDisableAction = !(
    isOrphanObject &&
    editMode &&
    isEditCurrentObjectIsExternalSystem
  );

  /**
   * Fn.
   *
   * @param hint - Хинт.
   * @param hintIsOrphanObject - Ф.
   * @returns - Хинт.
   */
  const hintIfIsNotOrphanObject = (
    hint: string,
    hintIsOrphanObject:
      | string
      | Element
      | undefined = 'Заполняется пользователем вручную',
  ) => (isOrphanObject ? hintIsOrphanObject : getHint(hint));

  return {
    calcAttributes,
    editMode,
    hintIfIsNotOrphanObject,
    isOrphanObject,
    required,
    shouldDisableAction,
  };
};
