import { preparationValues } from 'core/utils/helpers/preparationValues';
import { useQueryParams } from 'core/utils/hooks/useQueryParams';
import { QueryParamConfigMap } from 'use-query-params';

/**
 * Данные QueryParams для фильтра формы FF.
 */
export interface IFilterQueryParams {
  prepareFilters: object; // Отформатированные данные для бэка
  setFilters: (filters: object, action: string) => void; // Функция для переноса состояния формы в урл
  filters: object; // Обычные данные формы
}

/**
 * Функция возвращает тип query-params.
 */
type QueryParamFnType = () => QueryParamConfigMap;

/**
 * Общий Хук QueryParams для фильтра формы FF.
 *
 * @param params - Массив функций.
 * @returns FilterQueryParamsType.
 * @example --------
 * export const useFilterQueryParamsFencing = () => {
 *  return useFilterQueryParams([
 *    customerOznParams,
 *    dateFromParams,
 *    dateToParams,
 *    grbsIdParams,
 *    idOrNameParams,
 *    idOrNameParentParams,
 *    oghStatusesParams,
 *    ownerIdParams,
 *    subTypeIdParams,
 *  ]);
 * };
 */
export const useFilterQueryParams = (
  params: Array<QueryParamFnType>,
): IFilterQueryParams => {
  const [filters, setFilters] = useQueryParams(params);

  return {
    filters,
    prepareFilters: preparationValues(filters),
    setFilters,
  };
};
