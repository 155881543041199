import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect/MultiSelect';

/**
 * Name inputs. Учредитель/ГРБС.
 *
 * @constant
 * @type {string}
 * @default 'grbs_id'
 */
export const GRBS_ID_MULTI_NAME = 'grbs_id';

/**
 * MultiSelect Учредитель/ГРБС использует словарь grbs.
 * `name` - grbs_id.
 *
 * @param {object} props - Properties.
 * @param {string} [props.label] - Лейбл.
 * @param {object} props.otherProps - Properties.
 * @returns {JSX.Element} - JSX.
 */
export const GrbsIdMulti = ({ label = 'ГРБС', ...otherProps }) => {
  const dict = 'grbs';
  const { data = [] } = useGetDictionary(dict);

  return (
    <MultiSelect
      {...otherProps}
      {...{
        label,
        options: data,
      }}
    />
  );
};
