import { LOCATION_METHOD_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/LocationMethodId/LocationMethodId';
import { usePreparation } from 'core/utils/hooks/usePreparation';

/**
 * Returns an object with 'red_book_plant' property if 'value' is truthy.
 *
 * @returns
 */
export const usePreparationLocationMethodId = () =>
  usePreparation(LOCATION_METHOD_ID_NAME, (value) => {
    if (value) {
      return { location_method: value };
    }
  });
