import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';

/**
 * Setup __Тип (наименование)__.
 *
 * @returns {React.ReactElement}
 */
export const useSetupArrangeElementTypeIdField = () => {
  const { editMode } = useMode();

  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  const { data = [] } = useGetDictionary('arrangeElementType');
  const { typeId: parentTypeId } = useGetParentCard();
  const { typeId } = useGetCurrentCard();

  const options = data.map((item) => ({ ...item.attribute, id: item.id }));

  /**
   * Фильтр.
   *
   * @param {Array} data - Данные.
   * @returns {Array} - Возвращает данные фильтрованные.
   */
  const filter = (data) => {
    return data.filter(
      ({ parent_ogh_object_type_list = [], ogh_object_type_id = {} }) => {
        return (
          parent_ogh_object_type_list?.includes(parentTypeId) &&
          String(typeId) === String(ogh_object_type_id?.ogh_object_type)
        );
      },
    );
  };

  const disabled = !(editMode && isEditCurrentObjectIsExternalSystem);

  return { disabled, filter, options };
};
