import { FieldRF } from 'core/form/reduxForm/helpers/FieldRF';
import {
  MAF_TYPE_LEVEL_3_ID_NAME,
  MafTypeLevel3,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/MafTypeLevel3';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * Select with RF MAF_TYPE_LEVEL_3_ID_NAME.
 *
 * @param props - Пропсы.
 * @returns JSX.Element MafTypeLevel2RF.
 */
export const MafTypeLevel3RF = (props: SelectPropsType) => {
  return (
    <FieldRF
      {...props}
      name={MAF_TYPE_LEVEL_3_ID_NAME}
      component={MafTypeLevel3}
    />
  );
};
