import { SelectFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export const IS_ADAPTED_PEOPLE_LIMITED_MOBILITY_NAME =
  'is_adapted_people_limited_mobility';

const options = [
  { id: 1, name: 'Да' },
  { id: -1, name: 'Нет' },
];

/**
 * YesOrNot __Адаптирован для маломобильных групп населения__.
 *
 * @returns {JSX.Element}
 */
export const IsAdaptedPeopleLimitedMobilityFilter = () => {
  return (
    <SelectFF
      className={filterInput}
      options={options}
      label={'Адаптирован для маломобильных групп населения'}
      name={IS_ADAPTED_PEOPLE_LIMITED_MOBILITY_NAME}
    />
  );
};

/**
 * Params __Адаптирован для маломобильных групп населения__.
 *
 * @returns {*}
 */
export const isAdaptedPeopleLimitedMobilityParams = () => {
  addPreparation(IS_ADAPTED_PEOPLE_LIMITED_MOBILITY_NAME, (value) => value > 0);
  return {
    [IS_ADAPTED_PEOPLE_LIMITED_MOBILITY_NAME]: NumberParam,
  };
};
