import { useMemo } from 'react';

import { localStorageDrag } from './localStorageDrag';

/**
 * Хук для сохранения позиции перемещения элемента.
 *
 * @param id - Идентификатор как будет называться в localStorage.
 * @returns Объект с функциями для загрузки и сохранения позиции.
 */
export const useStorageDragPosition = (id: string) => {
  return useMemo(() => localStorageDrag(id), [id]);
};
