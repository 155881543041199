import { CleanCategoryIdsFF } from 'core/form/finalForm/fields/prepared/selects/multiSelect/CleanCategoryIds.FF';
import { CLEAN_CATEGORY_IDS_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/CleanCategoryIds';
import React from 'react';
import { NumericArrayParam } from 'use-query-params';

import { filterInput } from '../../filterInputsClasses';

/**
 * Фильтр __Категория по уборке__.
 *
 * @returns {JSX.Element}
 */
export const CleanCategoryIdsFilter = () => {
  return <CleanCategoryIdsFF className={filterInput} />;
};

export const cleanCategoryIdsParams = {
  [CLEAN_CATEGORY_IDS_NAME]: NumericArrayParam,
};
