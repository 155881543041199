import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { CoatingTypeIdFiled } from 'app/components/card/ogh/components/selects/CoatingTypeId.Field';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { CheckboxRF } from 'core/form/reduxForm/fields/default/CheckboxRF';
import { NumberFieldRF } from 'core/form/reduxForm/fields/default/NumberField.RF';
import { AddressList2RF } from 'core/form/reduxForm/fields/prepared/adressList/AddressList2.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/numberField/TotalAreaGeo.RF';
import { CoatingGroupIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingGroupId.RF';
import { MaterialIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/MaterialId.RF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer/GroupContainer';

/**
 * Таб Исхходные данные Карточки Велопарковки.
 *
 * @returns {JSX.Element} - Реакт компонент.
 */
export const CharacteristicsTabStoragePlace = () => {
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  const { choiceAddressList } = useGetCurrentCard();

  const formattedBtiAddresses = choiceAddressList
    ? choiceAddressList.map(formatAddressOption)
    : [];

  return (
    <div id="characteristics">
      <FieldsSplitColumns>
        <MaterialIdRF label={'Материал'} disabled={!editMode} required={true} />
        <NumberFieldRF
          disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
          id="area"
          label="Площадь, кв.м"
          name="area"
          decimalScale={2}
        />
        <TotalAreaGeoRF disabled={true} decimalScale={2} />
        <CoatingGroupIdRF disabled={!editMode} />
        <CoatingTypeIdFiled />
        <CheckboxRF
          disabled={!editMode}
          id={'no_calc'}
          label={'Не учитывать'}
          name={'no_calc'}
        />
      </FieldsSplitColumns>

      <GroupContainer title={'Адрес БТИ'}>
        <AddressList2RF
          btiAddresses={choiceAddressList}
          onlyOne={true}
          options={formattedBtiAddresses}
          disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
          id={'address_list'}
          label={null}
          name={'address_list'}
        />
      </GroupContainer>
    </div>
  );
};

/**
 * Функция formatAddressOption.
 *
 * @param {object} item - Хопа.
 * @returns {object} - Хопа.
 */
const formatAddressOption = (item) => {
  const name = `${item.okrug_name} / ${item.district_name} / ${item.street_name} / ${item.address_name}`;
  return {
    id: item.address_id,
    name,
  };
};
