import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';

import { useSetupDtsTypeIdRFField } from './useSetup.DtsTypeId.RF.Field';

export const DTS_TYPE_ID_NAME = 'dts_type_id';

/**
 *
 * SelectRF Назначение для Дорожно-тропиночные сети (30 тип).
 *
 * @returns {JSX.Element}
 */
export const DtsTypeIdRFField = () => {
  const { disabled, options, filter } = useSetupDtsTypeIdRFField();

  return (
    <SelectRF
      label={'Назначение'}
      name={DTS_TYPE_ID_NAME}
      options={options}
      filter={filter}
      required={true}
      disabled={disabled}
    />
  );
};
