import { printReport } from 'app/pages/registry/ogh/api/printReport';
import { useFilterQueryParamsPt } from 'app/pages/registry/ogh/PT/Filter.PT';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

interface IuseSetupPrintItemButtonPT {
  template: string;
  publicToiletTypeId: number;
}

/**
 * UseSetup ListItemButton Общественных туалетов.
 *
 * @param props - Пропсы.
 * @param props.template - Темплейт.
 * @param props.publicToiletTypeId - Тип ОТ.
 * @returns
 */
export const useSetupPrintItemButtonPT = ({
  publicToiletTypeId,
  template,
}: IuseSetupPrintItemButtonPT) => {
  const [send, { isLoading }] = useMutationAdaptor(printReport(template));
  const { prepareFilters } = useFilterQueryParamsPt();

  /**
   * Событие onClick.
   *
   */
  const onClickHandler = () => {
    send({ ...prepareFilters, public_toilet_type_id: publicToiletTypeId });
  };

  return {
    isLoading,
    onClickHandler,
  };
};
