import { useMode } from 'app/pages/cardsOgh/hooks/useMode';

export const DESCRIPTION_NAME = 'description';

/**
 * Setup для DescriptionField.
 *
 * @returns Атрибуты.
 */
export const useSetupDescriptionField = () => {
  const { editMode } = useMode();
  return { editMode };
};
