import { SaveFFDialog } from 'core/form/finalForm/components/dialogs/SaveFF.Dialog';
import { Tooltip } from 'core/uiKit/components/Tooltip';
import { Edit, IconButton } from 'core/uiKit/material-ui';
import React from 'react';

import { BodyWorkTimeCell } from './Body.WorkTime.Cell';
import { useSetupWorkTimeCell } from './useSetup.WorkTime.Cell';
import { validateWorkTimeCell } from './validate.WorkTime.Cell';

interface IWorkTimeCell {
  row: {
    original: {
      id: string;
      day: string;
      startTime: string;
      endTime: string;
    };
  };
}

/**
 * Cell Времени в таблице График работы.
 *
 * @param props - Пропсы.
 * @param props.row - Выбранный ряд.
 * @returns
 */
export const WorkTimeCell = ({ row }: IWorkTimeCell) => {
  const { editMode, endTime, isOpen, onSubmit, setIsOpen, startTime } =
    useSetupWorkTimeCell({ row });

  return (
    <>
      <SaveFFDialog
        initialValues={{ endTime, startTime }}
        onSubmit={onSubmit}
        validate={validateWorkTimeCell}
        isOpen={isOpen}
        textHeader={`Редактировать время (${row.original.day})`}
        closeDialog={() => setIsOpen(false)}
        Body={<BodyWorkTimeCell />}
      />
      <span className={'mx-3'}>{`${startTime}-${endTime}`}</span>
      {editMode && (
        <Tooltip title="Редактировать время">
          <IconButton size="small" onClick={() => setIsOpen(true)}>
            <Edit fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};
