import {
  DISTRICT_AL_NAME,
  DistrictAl,
} from 'core/uiKit/preparedInputs/selects/autocompleteAsync/DistrictAl';
import { OKRUG_AL_NAME } from 'core/uiKit/preparedInputs/selects/autocompleteAsync/OkrugAl';
import React, { FC, useCallback } from 'react';

import { AddressFieldProps } from '../../types';

/**
 * Район для renderAddressList2.
 *
 * @param props - Properties.
 * @param props.address - Значение формы.
 * @param props.setAddress - Функция изменения глобальной формы.
 * @param props.errors - Объект ошибок.
 * @returns JSX.
 */
export const DistrictAlRAL2: FC<AddressFieldProps> = ({
  address,
  setAddress,
  errors,
}) => {
  const onChangeDistrictAl = useCallback(
    (value: { id: number; name: string } | undefined) => {
      setAddress({
        [DISTRICT_AL_NAME]: value,
        [OKRUG_AL_NAME]: address[OKRUG_AL_NAME],
      });
    },

    // eslint-disable-next-line
    [address[DISTRICT_AL_NAME], address[OKRUG_AL_NAME]],
  );

  return (
    <DistrictAl
      required={true}
      errorText={errors[DISTRICT_AL_NAME]}
      okrug_id={address[OKRUG_AL_NAME]?.id || 0}
      value={address[DISTRICT_AL_NAME]}
      onChange={onChangeDistrictAl}
      disabled={!address[OKRUG_AL_NAME]}
    />
  );
};
