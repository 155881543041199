import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { parseDate } from 'app/components/card/common/parse';
import { useGetInitialValuesTablesDocuments } from 'app/pages/cardsOgh/components/tables/Documents/hooks/useGetInitialValuesTables';
import { useFilterQueryParamsVersionDefault } from 'app/pages/cardsOgh/components/tables/Versions/Filters/useFilterQueryParamsVersionDefault';
import { getAttributesByMapForForm } from 'app/pages/cardsOgh/helpers/getAttributesByMap.ForForm';
import { START_DATE_NAME } from 'core/form/finalForm/fields/prepared/datePickers/keyboardDatePickerFF/StartDate';

import { mapAttributeBridge } from '../mapAttribute.Bridge';

/**
 * Инициализация формы.
 *
 * @returns
 */
export const useInitialValueBridge = () => {
  const { startDate, endDate, attribute } = useGetCurrentCard();

  const objTables = useGetInitialValuesTablesDocuments();
  const { filters: filtersVersionTable } = useFilterQueryParamsVersionDefault();

  // Используем вспомогательную функцию для получения данных из attribute
  const result = getAttributesByMapForForm(attribute, mapAttributeBridge);

  return {
    ...filtersVersionTable,
    ...result,
    ...objTables,
    [START_DATE_NAME]: parseDate(startDate),
    endDate: parseDate(endDate),
  };
};
