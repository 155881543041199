import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';

/**
 * Setup Кнопки __Утвердить (ЭП)__.
 *
 * @returns
 */
export const useSetupApproveElectronicSignatureButton = () => {
  const { objectId } = useGetObjectId();
  const { data: { approve_eds: approveEds } = { approve_eds: false } } =
    useGetVisibilityButtonsOgh(objectId);

  const { editMode } = useMode();

  const isView = !editMode && approveEds;

  return { isView };
};
