import { Position } from '../../types.DragWrapper';

/**
 * Функция для работы с localStorage, связанная с перетаскиванием элемента.
 *
 * @param {number | string} id - Уникальный идентификатор элемента.
 * @returns Объект с функциями для загрузки и сохранения позиции.
 */
export const localStorageDrag = (id: number | string) => {

  /**
   * Сохраняет позицию элемента в localStorage.
   *
   * @param position - Позиция элемента.
   */
  const savePosition = (position: Position) => {
    localStorage.setItem(`draggable-pos-${id}`, JSON.stringify(position));
  };

  /**
   * Загружает позицию элемента из localStorage.
   *
   * @returns Позиция элемента или null, если данные отсутствуют.
   */
  const loadPosition = (): Position => {
    const data = localStorage.getItem(`draggable-pos-${id}`);
    return data ? JSON.parse(data) : { cX: undefined, cY: undefined };
  };

  const haveStorage = Boolean(localStorage.getItem(`draggable-pos-${id}`));

  return {
    haveStorage,
    loadPosition,
    savePosition,
  };
};
