import { PhoneField } from 'core/uiKit/inputs/PhoneField/PhoneField';
import { Field } from 'redux-form';

/**
 * PhoneFieldWithReduxForm.
 *
 * @param {object} props - Properties.
 * @param {object} props.input - Input.
 * @returns {JSX.Element}
 */
export const PhoneFieldWithReduxForm = ({ input, ...props }) => {
  return <PhoneField {...props} {...input} />;
};

/**
 * Инпут Redux Form для ввода телефона по маске.
 *
 * @param {object} props - Properties.
 * @param {string} [props.name] - Name.
 * @param {string} [props.label] - Label.
 * @param {boolean} [props.disabled] - Disabled.
 * @param {boolean} [props.dark] - Dark.
 * @param {object} [props.otherProps] - Other properties.
 * @returns {JSX.Element}
 */
export const PhoneFieldRF = ({
  name = 'PhoneFieldRF',
  dark = false,
  disabled = false,
  label = 'PhoneFieldRF label',
  ...otherProps
}) => {
  return (
    <Field
      {...{ dark, disabled, label }}
      {...otherProps}
      name={name}
      component={PhoneFieldWithReduxForm}
    />
  );
};
