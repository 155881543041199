import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import isMandatory from 'app/utils/card/isMandatory';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import React from 'react';

export const ENDWISE_NAME = 'endwise';

/**
 * Text Field RF __По оси, м__.
 *
 * @returns
 */
export const EndwiseField = () => {
  const { typeId } = useGetCurrentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  return (
    <TextFieldRF
      name={ENDWISE_NAME}
      label={'По оси, м'}
      required={isMandatory(typeId, ENDWISE_NAME, editMode)}
      disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
    />
  );
};
