import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { SPA } from 'app/constants/oghTypes';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import React from 'react';

/**
 * Протяжённость, км.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.editMode - Режим редактирования.
 * @returns {JSX.Element}
 */
export const SpreadDts = ({ editMode }) => {
  const { typeId } = useGetParentCard();

  return [SPA].includes(typeId) ? (
    <NumberFieldRF
      decimalScale={2}
      label={'Протяжённость, км'}
      name={'spread'}
      disabled={editMode}
    />
  ) : null;
};
