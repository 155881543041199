import './DragWrapper.scss';

import { useDragAction } from 'core/uiKit/components/DragWrapper/hooks/useDrag/useDragAction';
import { useResizeAction } from 'core/uiKit/components/DragWrapper/hooks/useResizePositionEffect/useResizeAction';
import React, { ReactNode } from 'react';

import { useAddDragSelectorPointerDownEffect } from './hooks/useAddDragSelectorPointerDownEffect';
import { useDrag } from './hooks/useDrag';
import { useGrabbingOnDraggingEffect } from './hooks/useGrabbingOnDraggingEffect';
import { useResizePositionEffect } from './hooks/useResizePositionEffect';
import { Position } from './types.DragWrapper';

interface DragSelectorWrapperProps {
  children: ReactNode;
  selector?: string;
  initPosition?: Position;
  isDragTooltip?: boolean;
  onDragStart?: (position: Position) => void;
  onDragEnd?: (position: Position) => void;
  onDragMove?: (position: Position) => void;
  onDragIdle?: (position: Position) => void;
  onResizeStart?: (position: Position) => void;
  onResizeEnd?: (position: Position) => void;
  className?: string;

  [key: string]: unknown;
}

type ChildType = React.ReactElement<{
  style: React.CSSProperties;
  onPointerDown: (ev: PointerEvent) => void;
  ref: React.RefObject<HTMLElement | null>;
}>;

/**
 * Компонент-обертка для Paper, добавляющий функциональность перетаскивания.
 *
 * @param props - Пропсы.
 * @param props.selector - Селектор (за что перетаскиваем, если не передать то событие onPointerDown передается через пропс).
 * @param props.children - Компонент который перемещаем.
 * @param props.onDragStart - Callback, Начало перемещения, принимает позицию.
 * @param props.initPosition - Изначальная позиция.
 * @param props.onDragEnd - Callback, Конец перемещения, принимает позицию.
 * @param props.onDragIdle - Callback, когда драг перешел в ожидание, принимает позицию.
 * @param props.onDragMove - Когда перемещаем.
 * @param props.onResizeStart - Callback начало изменения окна браузера.
 * @param props.onResizeEnd - Callback Конец изменения окна браузера.
 * @returns Обертка для перемещения.
 */
export function DragWrapper({
  selector,
  children,
  onDragStart,
  onDragEnd,
  onDragIdle,
  onDragMove,
  onResizeStart,
  onResizeEnd,
  initPosition = {
    cX: undefined,
    cY: undefined,
  },
}: DragSelectorWrapperProps) {
  const {
    draggableRef,
    handlePointerDown,
    position,
    isDragging,
    dragStatus,
    setPosition,
  } = useDrag({ initPosition });

  const { resizeStatus } = useResizePositionEffect({
    position,
    ref: draggableRef,
    setPosition,
  });
  useGrabbingOnDraggingEffect(isDragging);

  useAddDragSelectorPointerDownEffect({
    draggableRef,
    handlePointerDown,
    selector,
  });
  useResizeAction({ onResizeEnd, onResizeStart, position, resizeStatus });
  useDragAction({
    dragStatus,
    onDragEnd,
    onDragIdle,
    onDragMove,
    onDragStart,
    position,
  });

  const initialStyleDrag: React.CSSProperties = {
    position: 'fixed',
    userSelect: 'none',
  };

  return React.Children.map(children, (child, index) => {
    const el = child as ChildType;
    if (index === 0 && React.isValidElement(el) && selector) {
      return React.cloneElement(el, {
        ...el.props,
        ref: draggableRef,
        style: {
          ...initialStyleDrag,
          ...el.props.style,
          left: position.cX,
          top: position.cY,
        },
      });
    }
    if (index === 0 && React.isValidElement(el) && !selector) {
      return React.cloneElement(el, {
        ...el.props,
        onPointerDown: handlePointerDown,
        ref: draggableRef,
        style: {
          ...initialStyleDrag,
          ...el.props.style,
          left: position.cX,
          top: position.cY,
        },
      });
    }
    return (
      <>
        <h2>В обертке допускается только один дочерний элемент</h2>
        {el}
      </>
    );
  });
}
