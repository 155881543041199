/**
 * Получает марджины элемента.
 *
 * @param element - Элемент, для которого нужно получить марджины.
 * @returns Объект с марджинами элемента.
 */
export const getMargins = (element: HTMLElement) => {
  const computedStyle = getComputedStyle(element);
  return {
    bottom: parseFloat(computedStyle.marginBottom),
    left: parseFloat(computedStyle.marginLeft),
    right: parseFloat(computedStyle.marginRight),
    top: parseFloat(computedStyle.marginTop),
  };
};
