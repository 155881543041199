import { ObjectServiceIdsFF } from 'core/form/finalForm/fields/prepared/selects/multiSelect/ObjectServiceIds.FF';
import { OBJECT_SERVICE_IDS_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/ObjectServiceIds';
import React from 'react';
import { NumericArrayParam } from 'use-query-params';

import { filterInput } from '../../filterInputsClasses';

/**
 * Фильтр __Обслуживание объекта__.
 *
 * @returns {JSX.Element}
 */
export const ObjectServiceIdsFilter = () => {
  return <ObjectServiceIdsFF className={filterInput} />;
};

export const objectServiceIdsParams = {
  [OBJECT_SERVICE_IDS_NAME]: NumericArrayParam,
};
