import { BaseTransform } from './Transform';

/**
 * Функция для получения данных из объекта attribute по ключам из массива mapAttribute.
 *
 * @param attribute - Объект, из которого извлекаются данные.
 * @param mapAttribute - Массив ключей, которые нужно извлечь.
 * @returns Объект с извлеченными данными.
 */
export const getAttributesByMapForForm = <
  Ty extends readonly BaseTransform[], // Берем абстрактный класс
>(
  attribute: Record<string, unknown>,
  mapAttribute: Ty,
) => {
  if (!attribute) return {};
  const result: Record<string, unknown> = {};

  mapAttribute.forEach((transform) => {
    if (transform.key in attribute) {
      const value = attribute[transform.key] as Parameters<
        typeof transform.toForm
      >[0];
      result[transform.key] = transform.toForm(value);
    }
  });

  return result;
};
