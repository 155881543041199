import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  CLEAN_CATEGORY_ID_NAME,
  CleanCategoryId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CleanCategoryId';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * SelectFF __Категория по уборке__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const CleanCategoryIdFF = (props: SelectPropsType) => {
  return (
    <FieldFF
      {...props}
      component={CleanCategoryId}
      name={CLEAN_CATEGORY_ID_NAME}
    />
  );
};
