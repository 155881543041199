import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { Icon, IconButton } from 'core/uiKit/material-ui';
import React from 'react';
import { CellProps } from 'types/table';

import { REPAIRS_INFO_LIST_PLAN_NAME } from './RepairsPlan.Tab';

/**
 * Cell для кнопки удаления __Проекта__.
 *
 * @param {CellProps} props - Пропсы.
 * @returns JSX.Element.
 */
export const DeleteButtonCellRepairsPlan = ({ row }: CellProps) => {
  const { editMode } = useMode();
  const { onChange, value } = useFieldRF<{ id: number }[]>(
    REPAIRS_INFO_LIST_PLAN_NAME,
  );

  return (
    <IconButton
      disabled={!editMode}
      title="Удалить файл"
      size={'small'}
      onClick={() => {
        const clearList = (value || []).filter(
          (__: unknown, idx: number) => +idx !== +row.id,
        );
        onChange(clearList);
      }}
    >
      <Icon>delete</Icon>
    </IconButton>
  );
};
