import { useGetDictionary } from 'app/api/hooks/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const BRIDGE_TYPE_LEVEL_1_ID_NAME = 'bridge_type_level1_id';

/**
 * Select __Тип объекта__.
 *
 * @param {object} props - Пропсы.
 * @returns Select.
 */
export const BridgeTypeLevel1Id = (props: SelectPropsType) => {
  const dict = 'bridge_type_level1';
  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      {...{
        label: 'Тип объекта',
        name: BRIDGE_TYPE_LEVEL_1_ID_NAME,
        options: data,
      }}
    />
  );
};
