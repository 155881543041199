import { NumberField } from 'core/uiKit/inputs/NumberField';
import React from 'react';
import { NumberPropsType } from 'types/inputs';

export const LAYOUT_LENGTH_NAME = 'layout_length';

/**
 * Протяженность объекта по всем осям, п.м. Number.
 *
 * @param props - Props.
 * @returns
 */
export const LayoutLength = (props: NumberPropsType) => {
  return (
    <NumberField
      {...props}
      label={'Протяженность объекта по всем осям, п.м'}
      name={LAYOUT_LENGTH_NAME}
      decimalScale={20}
      id={LAYOUT_LENGTH_NAME}
    />
  );
};
