import { ReactTableBodySkeleton } from 'core/uiKit/components';
import { Checkbox } from 'core/uiKit/inputs';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  ThemeProvider,
} from 'core/uiKit/material-ui';
import isArray from 'lodash/isArray';
import React from 'react';
import { useRowSelect, useSortBy, useTable } from 'react-table';

import { EnhancedTableHead } from './EnhancedTableHead';
import { useCreateMUITableTheme } from './useCreateMUITableTheme';

/**
 * Таблица MUITable.
 *
 * @param {object} props - Пропсы.
 * @param {*} props.columns - Колонки.
 * @param {Array} props.data - Данные.
 * @param {Function} [props.onSelectAllClick] - Функция выбора всех.
 * @param {Function} [props.onSelectRow] - Функция выбора строки.
 * @param {boolean} [props.checkedAll] - Флаг выбора всех.
 * @param {boolean} [props.checkedRows] - Флаг выбора строки.
 * @param {Function} [props.onRowDblClick] - Функция даблклика.
 * @param {Array} [props.sortableColumns] - Сортируемые колонки.
 * @param {string} [props.trClassName] - Класс строки.
 * @param {Function} [props.isCheckedRow] - Функция проверки строки.
 * @param {boolean} [props.isLoading] - Флаг загрузки.
 * @returns {JSX.Element}
 */
export const MUITable = ({
  columns,
  data,
  onSelectAllClick,
  onSelectRow,
  checkedAll,
  checkedRows,
  onRowDblClick,
  sortableColumns = [],
  trClassName,
  isCheckedRow,
  isLoading,
}) => {
  const theme = useCreateMUITableTheme();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },
        manualPagination: true,
      },
      useSortBy,
      useRowSelect,
    );

  /**
   * IsCheckedRowCallback.
   *
   * @param {boolean} originalRow - Оригинальная строка.
   * @returns {boolean}
   */
  const isCheckedRowCallback = (originalRow) => {
    // custom callback
    if (isCheckedRow) {
      return isCheckedRow(originalRow);
    }
    // Array - это массив объектов
    if (isArray(checkedRows)) {
      return checkedRows.some((elem) => {
        return JSON.stringify(originalRow) === JSON.stringify(elem);
      });
    }
    // Set
    return checkedRows.has(originalRow);
  };

  // Render the UI for your table
  return (
    <ThemeProvider theme={theme}>
      <TableContainer>
        <Table
          aria-label="enhanced table"
          aria-labelledby="tableTitle"
          size={'small'}
          {...getTableProps()}
        >
          {/* Рисуем хидер */}
          <EnhancedTableHead
            checkedAll={checkedAll}
            columns={headerGroups}
            rowCount={headerGroups.length}
            rowsCount={rows.length}
            sortableColumns={sortableColumns}
            onSelectAllClick={onSelectAllClick}
            onSelectRow={onSelectRow}
          />
          {isLoading ? (
            <ReactTableBodySkeleton columns={columns} countRows={20} />
          ) : (
            // ... и  тело таблицы
            <TableBody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                const { key: rowKey, ...rowProps } = row.getRowProps();
                return (
                  <TableRow
                    key={rowKey}
                    onDoubleClick={
                      onRowDblClick
                        ? onRowDblClick.bind(this, row.original)
                        : null
                    }
                    {...rowProps}
                    className={`${trClassName[index]} ${row?.original?.trClassName}`}
                  >
                    {checkedRows && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          value={isCheckedRowCallback(row.original)}
                          onChange={onSelectRow.bind(this, row.original)}
                        />
                      </TableCell>
                    )}
                    {row.cells.map((cell) => {
                      const { key: cellKey, ...cellProps } =
                        cell.getCellProps();
                      return (
                        <TableCell key={cellKey} {...cellProps}>
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};
