import {
  TECHNIC_STATUS_NAME,
  TechnicStatusFF,
} from 'core/form/finalForm/fields';
import React from 'react';
import { NumberParam, withDefault } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * Статус.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example ----
 */
export const TechnicStatusFilter = (props) => (
  <TechnicStatusFF {...props} className={filterInput} />
);

export { TECHNIC_STATUS_NAME };

/**
 * @typedef {import('use-query-params').withDefault} withDefault
 */

/**
 * Параметр technicStatusParams.
 *
 * @type {{"[TECHNIC_STATUS_NAME]": withDefault}} - Возвращает значение из url или дефолтное значение (230926).
 */
export const technicStatusParams = {
  [TECHNIC_STATUS_NAME]: withDefault(NumberParam, 230926),
};
