import { BridgeStateIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/BridgeStateId.FF';
import { BRIDGE_STATE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/BridgeStateId';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../../filterInputsClasses';

/**
 * Фильтр __Состояние__.
 *
 * @returns {JSX.Element}
 * @class
 */
export const BridgeStateIdFilter = () => {
  return <BridgeStateIdFF className={filterInput} />;
};

export const bridgeStateParams = {
  [BRIDGE_STATE_ID_NAME]: NumberParam,
};
