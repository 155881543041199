import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  CATEGORY_TOILET_ID_NAME,
  CategoryToiletId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CategoryToiletId';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * Select FF __Категория (Туалета)__.
 *
 * @param props - Пропсы.
 * @returns Select FF.
 */
export const CategoryToiletIdFF = (props: SelectPropsType) => {
  return (
    <FieldFF
      {...props}
      component={CategoryToiletId}
      name={CATEGORY_TOILET_ID_NAME}
    />
  );
};
