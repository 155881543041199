import { egrnGbuDateTransform } from 'app/pages/cardsOgh/Bridge/fields/EgrnGbuDate/egrnGbuDateTransform';
import { egrnMoscowDateTransform } from 'app/pages/cardsOgh/Bridge/fields/EgrnMoscowDate/egrnMoscowDateTransform';
import { EXPLOITATION_DATE_NAME } from 'app/pages/cardsOgh/Bridge/fields/ExploitationDate';
import { CREATE_TYPE_NAME, OWNER_ID_NAME } from 'core/uiKit/preparedInputs';
import { BRIDGE_TYPE_LEVEL_1_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/BridgeTypeLevel1Id';
import { BRIDGE_TYPE_LEVEL_2_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/BridgeTypeLevel2Id';
import { CLEAN_CATEGORY_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleanCategoryId';
import { DISTRICT_BRIDGE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/DistrictBridgeId';
import { GRBS_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/GrbsId';
import { OBJECT_SERVICE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/ObjectServiceId';
import { OKRUG_BRIDGE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/OkrugBridgeId';
import { ADDRESS_NAME } from 'core/uiKit/preparedInputs/textField/Address';
import { CROSSING_NAME } from 'core/uiKit/preparedInputs/textField/Crossing';
import { NAME_NAME } from 'core/uiKit/preparedInputs/textField/Name';
import { PLACEMENT_NAME_NAME } from 'core/uiKit/preparedInputs/textField/PlacementName';
import { SHORT_NAME_NAME } from 'core/uiKit/preparedInputs/textField/ShortName';

import { getTransform } from '../helpers/Transform';

export const mapAttributeBridge = [
  getTransform(CREATE_TYPE_NAME, 'create_type', null),
  getTransform(OWNER_ID_NAME, 'legal_person', null),
  getTransform(GRBS_ID_NAME, 'legal_person', null),
  getTransform(NAME_NAME, '', null),
  getTransform(SHORT_NAME_NAME, '', null),
  getTransform(BRIDGE_TYPE_LEVEL_1_ID_NAME, 'bridge_type_level1', null),
  getTransform(BRIDGE_TYPE_LEVEL_2_ID_NAME, 'bridge_type_level2', null),
  getTransform(CLEAN_CATEGORY_ID_NAME, 'clean_category', null),
  getTransform(ADDRESS_NAME, '', null),
  getTransform(OKRUG_BRIDGE_ID_NAME, 'okrug_bridge', null),
  getTransform(DISTRICT_BRIDGE_ID_NAME, 'okrug_bridge', null),
  getTransform(PLACEMENT_NAME_NAME, '', null),
  getTransform(CROSSING_NAME, '', null),
  getTransform('culture_object', '', null),
  getTransform('area', '', null),
  getTransform('under_area', '', null),
  getTransform('distance', '', null),
  getTransform('quantity', '', null),
  getTransform('unit_id', 'unit', null),
  getTransform('cad_number', '', null),
  getTransform('get_balance_date', '', null),
  getTransform('egrn_moscow_num', '', null),
  getTransform('reconstruction_date', '', null),
  egrnMoscowDateTransform,
  getTransform(EXPLOITATION_DATE_NAME, '', null),
  getTransform('egrn_gbu_num', '', null),
  getTransform('bridge_state_id', 'bridge_state', null),
  egrnGbuDateTransform,
  getTransform('moved_stuff', '', null),
  getTransform(OBJECT_SERVICE_ID_NAME, 'object_service', null),
  getTransform('object_service_organization', '', null),
  getTransform('height_pilar', '', null),
  getTransform('lenght_gate_max', '', null),
  getTransform('smis_are_installed', '', null),
  getTransform('max_car_weight', '', null),
  getTransform('max_axle_load', '', null),
  getTransform('overpass_height', '', null),
  getTransform('tunnel_height', '', null),
  getTransform('total_clean_area', '', null),
  getTransform('manual_clean_area', '', null),
  getTransform('auto_clean_area', '', null),
];
