import { BridgeTypeLevel2IdsFF } from 'core/form/finalForm/fields/prepared/selects/multiSelect/BridgeTypeLevel2Ids.FF';
import { BRIDGE_TYPE_LEVEL_2_IDS_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/BridgeTypeLevel2Ids';
import React from 'react';
import { NumericArrayParam } from 'use-query-params';

import { filterInput } from '../../filterInputsClasses';

/**
 * Фильтр __Назначение__.
 *
 * @returns {JSX.Element}
 */
export const BridgeTypeLevel2IdsFilter = () => {
  return <BridgeTypeLevel2IdsFF className={filterInput} />;
};

export const bridgeTypeLevel2IdsParams = {
  [BRIDGE_TYPE_LEVEL_2_IDS_NAME]: NumericArrayParam,
};
