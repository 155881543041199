import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const CATEGORY_TOILET_ID_NAME = 'category_toilet_id';

/**
 * Select __Категория (Туалета)__.
 *
 * @param props - Пропсы.
 * @returns Select Способ расположения.
 */
export const CategoryToiletId = (props: SelectPropsType) => {
  const dict = 'category_toilet';

  const { data } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={data || []}
      label={props?.label || 'Категория'}
      name={CATEGORY_TOILET_ID_NAME}
    />
  );
};
