import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import { DocumentWithFinalForm } from 'core/form/finalForm/fields/prepared/tables/documentTables/DocumentWithFinalForm';
import React from 'react';
import { Field } from 'react-final-form';

const ID = 25;
const NAME = getNameFile(ID);
const TITLE = 'Правоустанавливающие документы';

/**
 * Таблица документов __Правоустанавливающие документы__.
 *
 * @returns {JSX.Element}
 */
export const TitleDocumentsFF = () => {
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  return (
    <Field
      name={NAME}
      component={DocumentWithFinalForm}
      id={ID}
      title={TITLE}
      disabled={isCurrentObjectIsExternalSystem}
    />
  );
};
