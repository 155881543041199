import { PRIVILEGE_NAME, Privileges } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * ## ----
 *
 * ###### type = `AutocompleteAsync`
 * ###### name = `----`
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли компонент для редактирования.
 * @param {object} [props.otherProps] - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const PrivilegesFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={PRIVILEGE_NAME}>
      {(props) => {
        return (
          <Privileges
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { PRIVILEGE_NAME };
