import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { IBikeParkTypeData } from 'core/uiKit/preparedInputs/selects/simpleSelect/BikeParkTypeId';

/**
 * Setup Field Select RF __Тип велопарковки__.
 *
 * @returns
 */
export const useSetupBikeParkTypeIdField = () => {
  const parent = useGetParentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  /**
   * Функция фильтрации опшинов.
   *
   * @param dict - Опшины.
   * @returns
   */
  const filter = (dict: IBikeParkTypeData[]) => {
    return (dict || []).filter((item) =>
      item.parent_ogh_object_type_list.includes(+parent.typeId),
    );
  };

  const disabled = !(editMode && isEditCurrentObjectIsExternalSystem);

  return { disabled, filter };
};
