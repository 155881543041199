import { INSTALL_DRNO_NAME, InstallDrno } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * ## Не подлежит оснащению ДРНО
 * ### React Final Form Field
 *
 * ###### type = `Select`
 * ###### name = `INSTALL_DRNO_NAME`
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.disabled] - Определяет, будет ли компонент заблокирован.
 * @param {object} [props.otherProps] - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const InstallDrnoFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={INSTALL_DRNO_NAME}>
      {(props) => {
        return (
          <InstallDrno
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { INSTALL_DRNO_NAME };
