import { LIST_TYPE_ID_MULTI_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/ListTypeIdMulti';
import React from 'react';
import { Field } from 'react-final-form';

import { ListTypeIdMultiFFWithFF } from './ListTypeIdMultiFFWithFF';

/**
 * MultiSelect Final Form. Тип списка.
 *
 * @param {object} props - Параметры.
 * @param {boolean} [props.disabled] - Определяет, будет ли поле ввода отключено.
 * @returns {JSX.Element}
 */
export const ListTypeIdMultiFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field
      disabled={disabled}
      name={LIST_TYPE_ID_MULTI_NAME}
      component={ListTypeIdMultiFFWithFF}
      {...otherProps}
    />
  );
};

export { LIST_TYPE_ID_MULTI_NAME };
