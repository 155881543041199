import { GRBS_ID_MULTI_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/GrbsIdMulty/GrbsIdMulty';
import React from 'react';
import { Field } from 'react-final-form';

import { GrbsIdMultiWithFF } from './GrbsIdMultiWithFF';

/**
 * MultiSelect Final Form. Учредитель/ГРБС.
 *
 * @param {object} props - Параметры.
 * @param {boolean} [props.disabled] - Определяет, будет ли поле ввода отключено.
 * @param {object} [props.otherProps] - Пропсы.
 * @returns {JSX.Element} - JSX.
 */
export const GrbsIdMultiFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field
      disabled={disabled}
      name={GRBS_ID_MULTI_NAME}
      component={GrbsIdMultiWithFF}
      {...otherProps}
    />
  );
};

export { GRBS_ID_MULTI_NAME };
