import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  OKRUG_BRIDGE_IDS_NAME,
  OkrugBridgeIds,
} from 'core/uiKit/preparedInputs/selects/multiSelect/OkrugBridgeIds';
import React from 'react';
import { SelectPreparedPropsType } from 'types/inputs';

/**
 * MultiSelect __Округ__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const OkrugBridgeIdsFF = (props: SelectPreparedPropsType) => {
  return (
    <FieldFF
      {...props}
      component={OkrugBridgeIds}
      name={OKRUG_BRIDGE_IDS_NAME}
    />
  );
};
