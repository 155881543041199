import { OghCardHeaderFF } from 'app/pages/cardsOgh/components/headerFF/OghHeaderFF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { TabsCardOGH } from 'core/uiKit/components/card.components/TabsCardOGH';
import { TabsCardOGHItem } from 'core/uiKit/components/card.components/TabsCardOGH/TabsCardOGH.Item';
import React from 'react';

import { PropertiesBridge } from './tabs/Properties.Bridge';

/**
 * Левая панель карточки __Мосты__.
 *
 * @returns React.ReactElement.
 */
export const LeftPanelBridge = () => {
  return (
    <CardContainer>
      <OghCardHeaderFF />
      <TabsCardOGH>
        <TabsCardOGHItem tabTitle={'Свойства'}>
          <PropertiesBridge />
        </TabsCardOGHItem>
      </TabsCardOGH>
    </CardContainer>
  );
};
