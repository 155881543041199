import { Transform } from 'app/pages/cardsOgh/helpers/Transform';
import { parseDateToMoment } from 'app/utils/date/parseDateToMoment';
import { toDate } from 'app/utils/date/toDate';
import { Moment } from 'moment';

import { EGRN_MOSCOW_DATE_NAME } from './EgrnMoscowDate';

export const egrnMoscowDateTransform = new Transform<Moment, string>(
  EGRN_MOSCOW_DATE_NAME,
  (data) => toDate(data),
  (data) => parseDateToMoment(data),
);
