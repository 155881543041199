import React from 'react';

import { FormCardFF } from '../components/FormCard.FF';
import { LeftPanelIceRink } from './LeftPanel.IceRink';
import { useSetupIceRink } from './useSetup.IceRink';
import { validateFormIceRink } from './validateForm.IceRink';

/**
 * Карточка Катки.
 *
 * @returns Карточка Катки.
 */
export const IceRink = () => {
  const { initialValues, preparingDataForAttributes, isLoading } =
    useSetupIceRink();

  return (
    <FormCardFF
      preparingDataForAttributes={preparingDataForAttributes}
      validate={validateFormIceRink}
      initialValues={initialValues}
      loading={isLoading}
    >
      <LeftPanelIceRink />
    </FormCardFF>
  );
};
