import {
  SUB_TYPE_ID_NAME,
  SubTypeIdFF,
} from 'core/form/finalForm/fields/prepared/selects/autocompleteAsync/SubTypeIdFF';
import { ISubTypeIdProps } from 'core/uiKit/preparedInputs/selects/autocompleteAsync/SubTypeId';
import { usePreparation } from 'core/utils/hooks/usePreparation';
import React from 'react';
import { ObjectParam } from 'use-query-params';

import { filterInput } from '../../filterInputsClasses';

/**
 * Filter __Тип__.
 *
 * @param props - Properties.
 * @param props.odsType - Id типа карточки.
 * @param [props.odsParentType] - Id родительского типа.
 * @returns JSX.Element.
 */
export const SubTypeIdFilter = (props: ISubTypeIdProps) => {
  usePreparation(SUB_TYPE_ID_NAME, (value) => value.id);

  return <SubTypeIdFF {...props} className={filterInput} />;
};

export { SUB_TYPE_ID_NAME };

/**
 * Параметры __Тип__.
 *
 * @returns Параметры.
 */
export const subTypeIdParams = () => ({
  [SUB_TYPE_ID_NAME]: ObjectParam,
});
