import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  DETAILS_MODELS_ID_NAME,
  DetailsModelsId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/DetailsModelsId';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * Select FF __Уточнение ОТ__.
 *
 * @param props - Пропсы.
 * @returns Select RF.
 */
export const DetailsModelsIdFF = (props: SelectPropsType) => {
  return (
    <FieldFF
      {...props}
      component={DetailsModelsId}
      name={DETAILS_MODELS_ID_NAME}
    />
  );
};
