export const numericFields = [
  {
    name: 'total_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'manual_clean_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'auto_clean_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'parking_places_quantity',
    positive: true,
    type: 'decimal',
    zero: false,
  },
];
