import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import React from 'react';

import { useSetupBuildingsTypeSpecIdField } from './useSetup.BuildingsTypeSpecId.Field';

/**
 * Уточнение назначения.
 *
 * @returns Реакт компонент.
 */
export const BuildingsTypeSpecIdField = () => {
  const {
    options = [],
    disabled,
    required,
  } = useSetupBuildingsTypeSpecIdField();
  return (
    <SelectRF
      label={'Уточнение назначения'}
      name={'buildings_type_spec_id'}
      required={required}
      options={options}
      disabled={disabled}
    />
  );
};
