import React from 'react';

import { Accordion } from '../Accordion';
import { ElementsTree } from '../Elements.Tree';
import { ISubTypeTree } from '../Tree';
import { useSetupSubTypeItemTree } from './useSetup.SubType.Item.Tree';

/**
 * Один элемент подгруппы дерева элементов.
 *
 * @param {ISubTypeTree} props - Props.
 * @returns {JSX.Element}
 */
export const SubTypeItemTree = (props: ISubTypeTree): React.ReactElement => {
  const { open, setOpen } = useSetupSubTypeItemTree(props);
  const { elements, id, subTypeName } = props;

  return (
    // @ts-ignore
    <Accordion
      accordionOpen={open}
      setAccordionOpen={setOpen}
      key={id}
      HeaderComponent={`${subTypeName} (${elements.length} шт.)`}
      containsChild={!!elements.length}
    >
      <ElementsTree elements={elements} />
    </Accordion>
  );
};
