import { LocationMethodIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/LocationMethodId.FF';
import { LOCATION_METHOD_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/LocationMethodId';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export { LOCATION_METHOD_ID_NAME };

/**
 * Фильтр __Способ расположения__.
 *
 * @returns
 */
export const LocationMethodIdFilter = () => (
  <LocationMethodIdFF className={filterInput} />
);

/**
 * Параметры __Способ расположения__.
 *
 * @returns
 */
export const publicLocationMethodIdParams = () => ({
  [LOCATION_METHOD_ID_NAME]: NumberParam,
});
