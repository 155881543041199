import { parseDateToMoment } from 'app/utils/date/parseDateToMoment';
import { preparationValues } from 'core/utils/helpers/preparationValues';
import { useQueryParams } from 'core/utils/hooks/useQueryParams';

import { dateFromParamsVersionFilter } from './Fields/DateFromVersionFilter';
import { dateToParams } from './Fields/DateToVersionFilter';
import { oghStatusesVersionTableParams } from './Fields/OghStatusesVersionFilter';

/**
 * Хук useFilterQueryParamsVersionDefault.
 *
 * @returns Возвращает фильтры и функцию изменения.
 */
export const useFilterQueryParamsVersionDefault = () => {
  const [filters, setFilters] = useQueryParams([
    dateFromParamsVersionFilter(parseDateToMoment('01.01.1900')),
    dateToParams(parseDateToMoment('01.01.3000')),
    oghStatusesVersionTableParams,
  ]);

  return {
    filters,
    prepareFilters: preparationValues(filters),
    setFilters,
  };
};
