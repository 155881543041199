import {
  ADDRESS_AL_NAME,
  AddressAl,
} from 'core/uiKit/preparedInputs/selects/autocompleteAsync/AddressAl';
import { DISTRICT_AL_NAME } from 'core/uiKit/preparedInputs/selects/autocompleteAsync/DistrictAl';
import { OKRUG_AL_NAME } from 'core/uiKit/preparedInputs/selects/autocompleteAsync/OkrugAl';
import { STREET_AL_NAME } from 'core/uiKit/preparedInputs/selects/autocompleteAsync/StreetAl';
import React, { FC, useCallback, useState } from 'react';

import { AddressFieldProps } from '../../types';

/**
 * Дом, корпус, строение для renderAddressList2.
 *
 * @param props - Properties.
 * @param props.address - Значение формы.
 * @param props.setAddress - Функция изменения глобальной формы.
 * @returns JSX.
 */
export const AddressAlRAL2: FC<AddressFieldProps> = ({
  address,
  setAddress,
}) => {
  //  костыли так как значения для других инпутов приходить в этом options.
  useState(); //?
  const [options, setOptions] = useState<
    | {
        id: number;
        name: string;
        address_name: string;
        is_actual: boolean;
        kl: string;
        unad: string;
        unom: string;
      }[]
    | []
  >([]);

  const onChangeAddressAl = useCallback(
    (value: { id: number; name: string } | undefined) => {
      //  костыли так как значения для других инпутов приходить в этом options.
      const additionalAddress = options?.find((item) => item.id === value?.id);

      setAddress({
        [ADDRESS_AL_NAME]: value,
        [DISTRICT_AL_NAME]: address[DISTRICT_AL_NAME],
        [OKRUG_AL_NAME]: address[OKRUG_AL_NAME],
        [STREET_AL_NAME]: address[STREET_AL_NAME],

        //  костыли так как значения для других инпутов приходить в этом options.
        is_actual: additionalAddress?.is_actual,
        //  костыли так как значения для других инпутов приходить в этом options.
        kl: additionalAddress?.kl,
        //  костыли так как значения для других инпутов приходить в этом options.
        unad: additionalAddress?.unad,
        //  костыли так как значения для других инпутов приходить в этом options.
        unom: additionalAddress?.unom,
      });
    },

    // eslint-disable-next-line
    [
      // eslint-disable-next-line
      address[ADDRESS_AL_NAME],

      // eslint-disable-next-line
      address[DISTRICT_AL_NAME],

      // eslint-disable-next-line
      address[OKRUG_AL_NAME],

      // eslint-disable-next-line
      address[STREET_AL_NAME],
      options,
    ],
  );

  return (
    <AddressAl
      street_id={address[STREET_AL_NAME]?.id}
      value={address[ADDRESS_AL_NAME]}
      onChange={onChangeAddressAl}
      disabled={!address[STREET_AL_NAME]}
      setOptions={setOptions}
    />
  );
};
