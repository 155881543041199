import { Moment } from 'moment/moment';

/**
 * Переводит время из Moment в строку.
 *
 * @param momentTime - Moment время.
 * @example MomentData => '13:00'
 * @returns Время строкой.
 */
export const parseTimeMomentToString = (momentTime: Moment | null) => {
  return momentTime?.format('HH:mm') || '';
};
