/**
 * Пересчитывает позицию элемента при изменении размеров окна.
 *
 * @param prevPosition - Предыдущая позиция элемента.
 * @param prevContainerSize - Предыдущий размер контейнера (ширина или высота окна).
 * @param newContainerSize - Новый размер контейнера.
 * @param margin - Отступ от края (например, margins.left или margins.top).
 * @param elementSize - Размер элемента (ширина или высота элемента).
 * @returns Новая позиция элемента.
 */
export const calculateNewPosition = (
  prevPosition: number,
  prevContainerSize: number,
  newContainerSize: number,
  margin: number,
  elementSize: number,
): number => {
  // Если элемент привязан к левому краю x,y
  if (prevPosition <= margin) {
    return prevPosition;
  }

  // Если элемент привязан к правому краю x,y
  if (prevPosition >= prevContainerSize - elementSize - margin) {
    return newContainerSize - (prevContainerSize - prevPosition);
  }

  // Если элемент не привязан к краям, пересчитываем пропорционально
  return prevPosition * (newContainerSize / prevContainerSize);
};
