import React from 'react';

/**
 * Иконка драг.
 *
 * @returns React компонент.
 */
export const IconDrag = () => {
  return (
    <div className={'icon-drag'}>
      <svg
        width="26"
        height="12"
        viewBox="0 0 26 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.375 7.2C3.68668 7.2 4.75 8.27451 4.75 9.6C4.75 10.9255 3.68668 12 2.375 12C1.06332 12 -4.69687e-08 10.9255 -1.04907e-07 9.6C-1.62846e-07 8.27451 1.06332 7.2 2.375 7.2Z"
          fill="black"
          fillOpacity="0.87"
        />
        <path
          d="M2.375 -1.03815e-07C3.68668 -1.6115e-07 4.75 1.07452 4.75 2.4C4.75 3.72548 3.68668 4.8 2.375 4.8C1.06332 4.8 -3.61691e-07 3.72548 -4.19629e-07 2.4C-4.77568e-07 1.07452 1.06332 -4.64794e-08 2.375 -1.03815e-07Z"
          fill="black"
          fillOpacity="0.87"
        />
        <path
          d="M9.5 7.2C10.8117 7.2 11.875 8.27451 11.875 9.6C11.875 10.9255 10.8117 12 9.5 12C8.18832 12 7.125 10.9255 7.125 9.6C7.125 8.27451 8.18832 7.2 9.5 7.2Z"
          fill="black"
          fillOpacity="0.87"
        />
        <path
          d="M9.5 -4.15258e-07C10.8117 -4.72593e-07 11.875 1.07452 11.875 2.4C11.875 3.72548 10.8117 4.79999 9.5 4.8C8.18832 4.8 7.125 3.72548 7.125 2.4C7.125 1.07452 8.18832 -3.57923e-07 9.5 -4.15258e-07Z"
          fill="black"
          fillOpacity="0.87"
        />
        <path
          d="M16.625 7.19999C17.9367 7.19999 19 8.27451 19 9.6C19 10.9255 17.9367 12 16.625 12C15.3133 12 14.25 10.9255 14.25 9.6C14.25 8.27451 15.3133 7.19999 16.625 7.19999Z"
          fill="black"
          fillOpacity="0.87"
        />
        <path
          d="M16.625 -7.26702e-07C17.9367 -7.84037e-07 19 1.07452 19 2.4C19 3.72548 17.9367 4.79999 16.625 4.79999C15.3133 4.79999 14.25 3.72548 14.25 2.4C14.25 1.07452 15.3133 -6.69367e-07 16.625 -7.26702e-07Z"
          fill="black"
          fillOpacity="0.87"
        />
        <path
          d="M23.625 -1.03268e-06C24.9367 -1.09002e-06 26 1.07451 26 2.4C26 3.72548 24.9367 4.79999 23.625 4.79999C22.3133 4.79999 21.25 3.72548 21.25 2.4C21.25 1.07452 22.3133 -9.75346e-07 23.625 -1.03268e-06Z"
          fill="black"
          fillOpacity="0.87"
        />
        <path
          d="M23.625 7.2C24.9367 7.2 26 8.27452 26 9.6C26 10.9255 24.9367 12 23.625 12C22.3133 12 21.25 10.9255 21.25 9.6C21.25 8.27452 22.3133 7.2 23.625 7.2Z"
          fill="black"
          fillOpacity="0.87"
        />
      </svg>
    </div>
  );
};
