import './TabsCardOGH.scss';

import React, { ReactElement, useEffect, useState } from 'react';

import { getTabsFromChildren } from './getTabsFromChildren';
import { TabsCardOGHPanel, TabsCardOGHPanelPreload } from './TabsCardOGHPanel';
import { TabsOGH } from './TabsOGH';

interface TabChildProps {
  tabTitle: string;
}

interface ITabsCardOGHProps {
  activeTab?: string;
  children: ReactElement<TabChildProps> | ReactElement<TabChildProps>[];
  preloading?: boolean;
}

/**
 * Таб карточки ОГХ.
 *
 * @param props - Пропсы.
 * @param props.activeTab - Значение активного таба.
 * @param props.children - Реакт компонент.
 * @param props.preloading - Загружать данные табов пока они закрыты или нет.
 * @returns
 */
export const TabsCardOGH = ({
  activeTab,
  children,
  preloading = true,
}: ITabsCardOGHProps) => {
  const tabs = getTabsFromChildren(children);
  const [value, setValue] = useState(activeTab ?? tabs[0]);

  useEffect(() => {
    const mapTabValues = tabs.map((value) => value);
    if (!mapTabValues.includes(value)) {
      setValue(tabs[0]);
    }
  }, [setValue, value, tabs]);

  /**
   * Изменение активного таба.
   *
   * @param newValue - Новое значение.
   */
  const handleChange = (newValue: string): void => {
    setValue(newValue);
  };

  return (
    <>
      <div className={'tabs-card-header__container'}>
        <TabsOGH onChange={handleChange} value={value} tabs={tabs} />
      </div>

      <div>
        {Array.isArray(children) ? (
          children.map((child: ReactElement<TabChildProps>) => {
            const { tabTitle } = child.props;
            return preloading ? (
              <TabsCardOGHPanelPreload
                key={`body-${tabTitle}`}
                show={value === tabTitle}
              >
                {child}
              </TabsCardOGHPanelPreload>
            ) : (
              <TabsCardOGHPanel
                key={`body-${tabTitle}`}
                show={value === tabTitle}
              >
                {child}
              </TabsCardOGHPanel>
            );
          })
        ) : (
          <TabsCardOGHPanel show={value === children?.props.tabTitle}>
            {children}
          </TabsCardOGHPanel>
        )}
      </div>
    </>
  );
};
