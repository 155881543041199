import { Cell } from 'types/table';

/**
 * Колонка __Тип ОТ__.
 *
 * @type {{
 * accessor: 'public_toilet_type_name',
 * Header: 'Тип ОТ',
 * }}
 * @augments Cell
 */
export const public_toilet_type_name: Cell = {
  Header: 'Тип ОТ',
  accessor: 'public_toilet_type_name',
  sortable: true,
};
