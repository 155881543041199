import { DatePickerFF } from 'core/form/finalForm/fields/default/datePickers/DatePickerFF';
import React from 'react';

export const EGRN_MOSCOW_DATE_NAME = 'egrn_moscow_date';

/**
 * DatePickerFF __Запись в ЕГРП/ЕГРН о собственности города Москвы, дата__.
 *
 * @returns DatePickerFF.
 */
export const EgrnMoscowDate = () => {
  return (
    <DatePickerFF
      name={EGRN_MOSCOW_DATE_NAME}
      label={'Запись в ЕГРП/ЕГРН о собственности города Москвы, дата'}
    />
  );
};
