import { Entity, ENTITY_NAME } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

export { ENTITY_NAME };

/**
 *
 * Тип согласования.
 *
 * ### React Final Form Field
 * Type = Select
 * name = entity
 * dict = entity.
 *
 * @param {object} props - Props.
 * @param {boolean} [props.dark] - Темная тема.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли компонент для редактирования.
 * @param {object} [props.otherProps] - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const EntityFF = ({ dark = false, disabled = false, ...otherProps }) => {
  return (
    <Field name={ENTITY_NAME}>
      {(props) => {
        return (
          <Entity
            {...{ dark, disabled }}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};
