import React, { useLayoutEffect } from 'react';

interface IuseAddDragSelectorPointerDown {
  draggableRef: React.RefObject<HTMLElement | null>;
  selector?: string;
  handlePointerDown: (ev: PointerEvent) => void;
}

/**
 * Хук добавления обработчик pointerdown к элементу по квери селектору для перетаскивания.
 *
 * @param params - Парметры хука.
 * @param params.draggableRef - Рефка хука useDrag.
 * @param params.selector - Селектор по котору вешаем событие на элемент.
 * @param params.handlePointerDown - Событие начала перетаскивания.
 */
export const useAddDragSelectorPointerDownEffect = ({
  draggableRef,
  selector,
  handlePointerDown,
}: IuseAddDragSelectorPointerDown) => {
  useLayoutEffect(() => {
    if (!selector) {
      return;
    }
    const dragElement = draggableRef.current?.querySelector(
      selector,
    ) as HTMLElement | null;
    if (dragElement) {
      dragElement.addEventListener('pointerdown', handlePointerDown);
    }
    return () => {
      if (dragElement) {
        dragElement.removeEventListener('pointerdown', handlePointerDown);
      }
    };
  }, [draggableRef, handlePointerDown, selector]);
};
