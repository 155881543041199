import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { elementFormGrid } from 'app/components/card/common/grid';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import { useGetCardCalcAttributeSelector } from 'app/selectors/useSelectors/useGetCardCalcAttribute.Selector';
import React from 'react';
import { vi } from 'vitest';

import { useSetupCleaning } from './useSetup.Cleaning';

vi.mock('app/selectors/useSelectors/useEditorCard.Selector');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('app/selectors/useSelectors/useGetCardCalcAttribute.Selector');
vi.mock('app/components/card/common/grid');

vi.mock('core/uiKit/components/TabPanel', () => ({
  /**
   * Mock.
   *
   * @returns {JSX.Element}
   */
  TabPanel: vi.fn(() => <div>TabPanel</div>),
}));

/**
 * Default Mock.
 *
 * @returns {void}
 */
const defaultMock = () => {
  useMode.mockReturnValue({ editMode: true });
  useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
  useEditorCardSelector.mockReturnValue({ is_orphan_object: true });
  useGetCardCalcAttributeSelector.mockReturnValue({});
  elementFormGrid.mockImplementation(() => 'elementFormGrid');
};

describe('🐛 spec useSetupCleaning', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    defaultMock();

    // 🧹 clear mock

    //🔥 Act
    const res = useSetupCleaning();

    //❓ Assert
    expect(res).toStrictEqual({
      calcAttributes: {},
      editMode: true,
      hintIfIsNotOrphanObject: expect.any(Function),
      isOrphanObject: true,
      required: false,
      shouldDisableAction: false,
    });
  });
});
