import { useEffect } from 'react';

import { GeometryUtils } from '..';
import { MapService } from '../MapService';
import { GeometryTypes, Layers, Marker, Polygon, Polyline } from '../types.d';

export const ORDERED_LAYERS: Layers[] = [
  'parent',
  'selected',
  'children',
  'allChildren',
  'intersections',
  'adjacent',
  'reonArea',
  'districts',
  'copyDiff',
  'originalDiff',
];

const REVERSED_ORDERED_LAYERS = [...ORDERED_LAYERS].reverse();

/**
 * Вешает обработчик ховера на карту.
 *
 * @param utilParams - Параметры для хука.
 * @param utilParams.isDisabled - Флаг отключения ховер эффекта.
 * @param utilParams.mapService - Объект управления картой.
 * @param utilParams.geometryUtils - Объект с методами для работы с геометриями.
 */
export const useHandleGeometryHoverEvent = ({
  isDisabled = false,
  mapService,
  geometryUtils,
}: {
  isDisabled: boolean;
  mapService: MapService | null;
  geometryUtils: GeometryUtils | null;
}) => {
  useEffect(() => {
    if (isDisabled || !mapService?.isMapLoaded) return;

    if (mapService && mapService.isMapLoaded) {
      let currentHoveredObjects: (Polygon | Polyline | Marker | null)[];

      /**
       * Обработчик движения мыши по карте. Не срабатывает при наведении на маркеры.
       *
       * @param evt - Объект события.
       * @returns {void} Void.
       */
      const handleMouseMove = (evt: MouseEvent): void => {
        if (!geometryUtils) return;

        let isHovered = false;
        const { offsetX, offsetY } = evt;
        const lngLat = mapService.map.unproject([offsetX, offsetY]);
        const { layers } = mapService;

        currentHoveredObjects = [];
        for (const layer of REVERSED_ORDERED_LAYERS) {
          // Проверяем наведен ли курсор на маркер

          layers[layer][GeometryTypes.Marker].forEach((marker) => {
            if (!marker) return false;

            const hovered = geometryUtils.isPointWithinPolygon(
              lngLat,
              marker.userData.coordinatesToCheckMouseEvent,
            );
            if (hovered) currentHoveredObjects.push(marker);
          });

          // Проверяем наведен ли курсор на линию

          layers[layer][GeometryTypes.Polyline].forEach((line) => {
            if (!line) return false;

            const hovered = geometryUtils.isPointOnLine(
              lngLat,
              line.userData.coordinates,
            );
            if (hovered) currentHoveredObjects.push(line);
          });

          isHovered = !!currentHoveredObjects.length;

          if (isHovered) break;

          // Проверяем наведен ли курсор на полигон

          layers[layer][GeometryTypes.Polygon].forEach((polygon) => {
            if (!polygon) return false;

            const hovered = geometryUtils.isPointWithinPolygon(
              lngLat,
              polygon.userData.coordinates,
            );
            if (hovered) {
              currentHoveredObjects.push(polygon);
            }
          });

          isHovered = !!currentHoveredObjects.length;

          if (isHovered) break;
        }

        const prevHoveredObject = mapService.hoveredObject;

        if (isHovered) {
          if (
            !prevHoveredObject ||
            prevHoveredObject.userData.id !==
              currentHoveredObjects[0]?.userData.id
          ) {
            // @ts-ignore
            mapService.setHoveredObject(currentHoveredObjects[0]);
            mapService.setHoveredGeometries({
              [GeometryTypes.Point]:
                currentHoveredObjects[0]?.userData.type === GeometryTypes.Point
                  ? [currentHoveredObjects[0] as Marker]
                  : [],
              [GeometryTypes.Polyline]:
                currentHoveredObjects[0]?.userData.type ===
                GeometryTypes.Polyline
                  ? [currentHoveredObjects[0] as Polyline]
                  : [],
              [GeometryTypes.Polygon]:
                currentHoveredObjects[0]?.userData.type ===
                GeometryTypes.Polygon
                  ? [currentHoveredObjects[0] as Polygon]
                  : [],
            });

            if (
              !currentHoveredObjects[0] ||
              !currentHoveredObjects[0].userData.layerType
            ) {
              mapService.drawGeometries({ method: 'replaceAll' });
              return;
            }

            mapService.drawHovered(currentHoveredObjects[0], prevHoveredObject);
          }
          return;
        }

        if (prevHoveredObject) {
          mapService.eraseHoveredObject();
        }
        currentHoveredObjects = [];
        return;
      };

      mapService.map.getCanvas().addEventListener('mousemove', handleMouseMove);

      return () => {
        mapService.map
          .getCanvas()
          .removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, [isDisabled, geometryUtils, mapService, mapService?.isMapLoaded]);
};
