import { CheckboxCell } from 'core/uiKit/components/cells/Checkbox.Cell';
import { Cell } from 'types/table';

/**
 * Колонка __Принадлежность к ОГХ__.
 *
 * @type {{
 * accessor: 'is_belonging_ogh',
 * Header: 'Принадлежность к ОГХ',
 * }}
 * @augments Cell
 */
export const is_belonging_ogh: Cell = {
  Cell: CheckboxCell,
  Header: 'Принадлежность к ОГХ',
  accessor: 'is_belonging_ogh',
  sortable: true,
};
