import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import { AreaField } from 'app/components/card/ogh/components/fields/numberFields/Area.Field';
import { BordBeginField } from 'app/components/card/ogh/components/fields/numberFields/BordBegin.Field';
import { BordEndField } from 'app/components/card/ogh/components/fields/numberFields/BordEnd.Field';
import { DistanceField } from 'app/components/card/ogh/components/fields/numberFields/Distance.Field';
import { WidthBeginField } from 'app/components/card/ogh/components/fields/numberFields/WidthBegin.Field';
import { WidthEndField } from 'app/components/card/ogh/components/fields/numberFields/WidthEnd.Field';
import { OdhSideIdField } from 'app/components/card/ogh/components/fields/selectFields/OdhSideId.Field';
import { DescriptionField } from 'app/components/card/ogh/components/fields/textFields/Description.Field';
import { OdhAxisField } from 'app/components/card/ogh/components/fields/textFields/OdhAxis.Field';
import { cleaningTypesWithNoCleaning } from 'app/constants/cleaningTypes';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/numberField/TotalAreaGeo.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { get } from 'lodash';
import React from 'react';

import isMandatory from '../../../../utils/card/isMandatory';
import CardAbstract from '../../common/CardAbstract';
import { objectTabs, Tabs } from '../../common/tabs';
import printOghCardHeader from '../header/oghHeader';
import VersionsTemplate from '../versions/VersionsTemplate';

const tabs = [
  { id: 'characteristics-tab', name: 'Исходные данные' },
  { id: 'docs-tab', name: 'Документы' },
];

const types = ['sidewalks'];
const Versions = VersionsTemplate(types);

/**
 * SidewalksComponent.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example ----
 */
const SidewalksComponent = (props) => {
  const {
    card,
    mode,
    createMode,
    editMode,
    required,
    formValues,
    coatingTypes,
    changeFieldValue,
    isEditCurrentObjectIsExternalSystem,
  } = props;

  return (
    <CardContainer>
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(card.type_id)}

          <div id="props">
            <FieldsSplitColumns>
              <SelectRF
                label={'Тип'}
                name={'flat_element_type_id'}
                options={card.flat_element_type}
                disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                required={isMandatory(
                  card.type_id,
                  'flat_element_type_id',
                  required,
                )}
              />
              <IsDiffHeightMarkField />
              <ParentInfo />
            </FieldsSplitColumns>

            <Tabs tabs={tabs} />

            <div id="characteristics">
              <GroupContainer title={'Местоположение'}>
                <FieldsSplitColumns>
                  <OdhAxisField />
                  <OdhSideIdField />
                  <BordBeginField />
                  <BordEndField />
                </FieldsSplitColumns>
              </GroupContainer>

              <GroupContainer title={'Характеристики'}>
                <FieldsSplitColumns>
                  <SelectRF
                    label={'Вид покрытия'}
                    name={'coating_group_id'}
                    options={card.coating_group}
                    disabled={
                      !(editMode && isEditCurrentObjectIsExternalSystem)
                    }
                    required={isMandatory(
                      card.type_id,
                      'coating_group_id',
                      required,
                    )}
                    withChange={() => {
                      changeFieldValue('coating_type_id', null);
                    }}
                  />
                  <SelectRF
                    label={'Вид покрытия (уточнение)'}
                    name={'coating_type_id'}
                    options={coatingTypes}
                    disabled={
                      !(
                        editMode &&
                        get(formValues, 'coating_group_id') &&
                        isEditCurrentObjectIsExternalSystem
                      )
                    }
                    required={true}
                  />
                  <DistanceField />
                  <AreaField />
                  <TotalAreaGeoRF disabled={true} decimalScale={2} />
                  <WidthBeginField />
                  <WidthEndField />
                </FieldsSplitColumns>
              </GroupContainer>

              <NumberFieldRF
                label={'УТН, кв.м'}
                name={'utn_area'}
                disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                decimalScale={2}
              />

              <GroupContainer title={'Уборочные данные'}>
                <SelectRF
                  label={'Способ уборки'}
                  name={'cleaning_type'}
                  options={cleaningTypesWithNoCleaning}
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                  required={isMandatory(
                    card.type_id,
                    'cleaning_type',
                    required,
                  )}
                />
              </GroupContainer>

              <DescriptionField
                label={
                  'Примечание (с указанием пятиметровых зон без уборки, УТН  и проч.)'
                }
              />
            </div>
            <div hidden={true} id={'docs'}>
              <OtherDocumentsRF />
            </div>
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    </CardContainer>
  );
};

export default SidewalksComponent;
