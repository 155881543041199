import { DragWrapper } from 'core/uiKit/components/DragWrapper';
import { Paper } from 'core/uiKit/material-ui';

/**
 * Компонент PaperComponent.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export function PaperComponent(props) {
  return (
    <DragWrapper selector={'#draggable-dialog-title'}>
      <Paper {...props} />
    </DragWrapper>
  );
}
