import { CATEGORY_TOILET_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CategoryToiletId';
import { CORRECTION_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CorrectionId';
import { DETAILS_MODELS_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/DetailsModelsId';
import { LOCATION_METHOD_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/LocationMethodId';
import { PUBLIC_TOILET_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/PublicToiletTypeId';
import { QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/Quantity';
import { useEffect } from 'react';
import { useField } from 'react-final-form';

/**
 * Setup Список полей отображаемых от типа туалета.
 *
 * @returns {{publicToiletTypeId: number}}
 */
export const useSetupFieldsTypePT = () => {
  const {
    input: { value: publicToiletTypeId },
  } = useField(PUBLIC_TOILET_TYPE_ID_NAME);

  const {
    input: { onChange: onChangeLocationMethodId },
  } = useField(LOCATION_METHOD_ID_NAME);

  const {
    input: { onChange: onChangeCategoryToiletId },
  } = useField(CATEGORY_TOILET_ID_NAME);

  const {
    input: { onChange: onChangeQuantity },
  } = useField(QUANTITY_NAME);

  const {
    input: { onChange: onChangeDetailsModelsId },
  } = useField(DETAILS_MODELS_ID_NAME);

  const {
    input: { onChange: onChangeCorrectionId },
  } = useField(CORRECTION_ID_NAME);

  useEffect(() => {
    return () => {
      onChangeLocationMethodId('');
      onChangeCategoryToiletId('');
      onChangeQuantity('');
      onChangeDetailsModelsId('');
      onChangeCorrectionId('');
    };
    // eslint-disable-next-line
  }, [publicToiletTypeId]);

  return {
    publicToiletTypeId,
  };
};
