import { Cell } from 'types/table';

/**
 * Колонка __Год принятия на баланс__.
 *
 * @type {{
 * accessor: 'get_balance_date',
 * Header: 'Год принятия на баланс',
 * sortable: boolean
 * }}
 * @augments Cell
 */
export const get_balance_date = {
  Header: 'Год принятия на баланс',
  accessor: 'get_balance_date',
  sortable: true,
};
