import {
  CAR_EQUIPMENT_OWNER_NAME as CAR_EQUIPMENT_OWNER_NAME_D,
  CarEquipmentOwnerFF as CarEquipmentOwnerFFD,
} from './CarEquipmentOwner.FF';
import {
  CAR_OWNER_ID_NAME as CAR_OWNER_ID_NAME_D,
  CarOwnerIdFF as CarOwnerIdFFD,
} from './CarOwnerId.FF';
import {
  ORGANIZATION_NAME as ORGANIZATION_NAME_D,
  OrganizationFF as OrganizationFFD,
} from './Organization.FF';
import {
  PRIVILEGE_NAME as PRIVILEGE_NAME_D,
  PrivilegesFF as PrivilegesFFD,
} from './Privileges.FF';
import { ROLE_NAME as ROLE_NAME_D, RoleFF as RoleFFD } from './Role.FF';

export * from './AddressFF';
export * from './CustomerFF';
export * from './CustomerImprovementObjectFF';
export * from './CustomerOdhFF';
export * from './CustomerOznFF';
export * from './CustomerYardFF';
export * from './GroupIdFF';
export * from './OwnerIdFF';
export * from './ProviderIdFF';

/**
 * @deprecated
 * @see используй из CarOwnerId.FF
 */
export const CarOwnerIdFF = CarOwnerIdFFD;

/**
 * CAR_OWNER_ID_NAME.
 *
 * @deprecated
 * @see используй из CarOwnerId.FF
 */
export const CAR_OWNER_ID_NAME = CAR_OWNER_ID_NAME_D;

/**
 * @deprecated
 * @see используй из CarEquipmentOwner.FF
 */
export const CarEquipmentOwnerFF = CarEquipmentOwnerFFD;

/**
 * CAR_EQUIPMENT_OWNER_NAME.
 *
 * @deprecated
 * @see используй из CarEquipmentOwner.FF
 */
export const CAR_EQUIPMENT_OWNER_NAME = CAR_EQUIPMENT_OWNER_NAME_D;

/**
 * @deprecated
 * @see используй из Privileges.FF
 */
export const PrivilegesFF = PrivilegesFFD;

/**
 * PRIVILEGE_NAME.
 *
 * @deprecated
 * @see используй из Privileges.FF
 */
export const PRIVILEGE_NAME = PRIVILEGE_NAME_D;

/**
 * @deprecated
 * @see используй из Organization.FF
 */
export const OrganizationFF = OrganizationFFD;

/**
 * ORGANIZATION_NAME.
 *
 * @deprecated
 * @see используй из Organization.FF
 */
export const ORGANIZATION_NAME = ORGANIZATION_NAME_D;

/**
 * @deprecated
 * @see используй из Role.FF
 */
export const RoleFF = RoleFFD;

/**
 * ROLE_NAME.
 *
 * @deprecated
 * @see используй из Role.FF
 */
export const ROLE_NAME = ROLE_NAME_D;
