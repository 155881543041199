import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  FORM_VISIT_ID_NAME,
  FormVisitId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/FormVisitId';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * Select FF __Форма посещения__.
 *
 * @param props - Пропсы.
 * @returns Select FF.
 */
export const FormVisitIdFF = (props: SelectPropsType) => {
  return (
    <FieldFF {...props} component={FormVisitId} name={FORM_VISIT_ID_NAME} />
  );
};
