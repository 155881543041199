import { MafTypeLevel1IdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/MafTypeLavel1Id.RF';
import React from 'react';
import { SelectRFProps } from 'types/inputs';

import { useSetupMafTypeLevel1IdField } from './useSetup.MafTypeLevel1.Field';

/**
 * Select RF __Вид МАФ__.
 *
 * @param props - Пропсы.
 * @returns Вид МАФ.
 */
export const MafTypeLevel1IdField = (props: SelectRFProps) => {
  const { filter } = useSetupMafTypeLevel1IdField();

  // пока так
  // @ts-ignore
  return <MafTypeLevel1IdRF {...props} filter={filter} />;
};
