import {
  MafTypeLevel2Filter,
  mafTypeLevel2Params,
} from 'core/form/filterForm/components/selects/MafTypeLevel2.Filter';
import React from 'react';

import { useSetupMafTypeLevel2FilterField } from './useSetupMafTypeLevel2FilterField';

export { mafTypeLevel2Params };

/**
 * Фильтр уточнение для реестра МАФ.
 *
 * @returns {JSX.Element}
 */
export const MafTypeLevel2FilterField = () => {
  const { filter, disabled } = useSetupMafTypeLevel2FilterField();

  return <MafTypeLevel2Filter filter={filter} disabled={disabled} />;
};
