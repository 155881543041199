import { DatePickerFF } from 'core/form/finalForm/fields/default/datePickers/DatePickerFF';
import React from 'react';

export const EGRN_GBU_DATE_NAME = 'egrn_gbu_date';

/**
 * DatePickerFF __Запись в ЕГРП/ЕГРН об оперативном управлении ГБУ «Гормост», дата__.
 *
 * @returns DatePickerFF.
 */
export const EgrnGbuDate = () => {
  return (
    <DatePickerFF
      name={EGRN_GBU_DATE_NAME}
      label={'Запись в ЕГРП/ЕГРН об оперативном управлении ГБУ «Гормост», дата'}
    />
  );
};
