import { QuantityFf } from 'core/form/finalForm/fields/prepared/numberField/Quantity.FF';
import { CategoryToiletIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/CategoryToiletId.FF';
import { CorrectionIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/CorrectionId.FF';
import { DetailsModelsIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/DetailsModelsId.FF';
import { LocationMethodIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/LocationMethodId.FF';

import { useSetupFieldsTypePT } from './useSetup.FieldsTypePT';

/**
 * Список полей отображаемых от типа туалета.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.disabled - Флаг редактирования.
 * @returns {React.ReactElement | null}
 */
export const FieldsTypePT = ({ disabled }) => {
  const { publicToiletTypeId } = useSetupFieldsTypePT();

  switch (publicToiletTypeId) {
    case 1: {
      return (
        <>
          <LocationMethodIdFF
            disabled={disabled}
            label={'Способ расположения (СОТ)'}
          />
          <CategoryToiletIdFF disabled={disabled} label={'Категория (СОТ)'} />
          <QuantityFf label={'Количество унитазов (СОТ)'} disabled={disabled} />
        </>
      );
    }
    case 2: {
      return (
        <>
          <DetailsModelsIdFF disabled={disabled} />
          <CorrectionIdFF disabled={disabled} />
        </>
      );
    }
    default: {
      return null;
    }
  }
};
