import {
  CAR_EQUIPMENT_OWNER_ID_NAME,
  CarEquipmentOwnerId,
} from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * ## Владелец НО
 * ### React Final Form Field
 *
 * ###### type = `Select`
 * ###### name = `CAR_EQUIPMENT_OWNER_ID_NAME`
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.disabled] - Определяет, будет ли компонент заблокирован.
 * @param {object} [props.otherProps] - Другие пропсы.
 * @returns {JSX.Element}
 */
export const CarEquipmentOwnerIdFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={CAR_EQUIPMENT_OWNER_ID_NAME}>
      {(props) => {
        return (
          <CarEquipmentOwnerId
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { CAR_EQUIPMENT_OWNER_ID_NAME };
