import { CHANGE_TYPE_NAME, ChangeType } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * ## Тип изменения
 *
 * ### React Final Form Field
 *
 * `type = MultiSelect`
 * `name = CHANGE_TYPE_NAME `
 * `dict = changeType`.
 *
 * @param {object} props - Props.
 * @param {boolean} [props.disabled] - Определяет, будет ли компонент заблокирован.
 * @param {object} [props.otherProps] - Другие пропсы.
 * @returns {JSX.Element}
 */
export const ChangeTypeFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={CHANGE_TYPE_NAME}>
      {(props) => {
        return (
          <ChangeType
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { CHANGE_TYPE_NAME };
