import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect/MultiSelect';
import React from 'react';

export const DISTRICT_LIST_NAME = 'district_list';

/**
 * ## Район.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example
 * <DistrictMulty {...props}/>
 */
export const DistrictList = (props) => {
  const dict = 'districts';

  const { data: options = [] } = useGetDictionary(dict);

  return (
    <MultiSelect
      options={options}
      label={'Район'}
      name={DISTRICT_LIST_NAME}
      {...props}
    />
  );
};
