/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

/**
 * Колонка __Наименование__.
 *
 * @type {{
 * accessor: 'name',
 * Header: 'Наименование',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const name = {
  Header: 'Наименование',
  accessor: 'name',
  sortable: true,
};
