import { getFileList } from '../components/tables/Documents/helpers/getFileList';
import { getAttributesByMapForBackend } from '../helpers/getAttributesByMap.ForBackend';
import { useInitialValueIceRink } from './hooks/useInitialValue.IceRink';
import { mapAttribute } from './mapAttribute';

/**
 * Setup для Катков.
 *
 * @returns Data and fns.
 */
export const useSetupIceRink = () => {
  const initialValues = useInitialValueIceRink();

  /**
   * Функция подготовка данных для Атрибутов.
   *
   * @param values - Значения формы.
   * @returns Атрибуты.
   */
  const preparingDataForAttributes = (values: Record<string, unknown>) => {
    return {
      file_list: getFileList(values),
      ...getAttributesByMapForBackend(values, mapAttribute),
    };
  };

  return { initialValues, isLoading: false, preparingDataForAttributes };
};
