import {
  ISubTypeIdProps,
  SUB_TYPE_ID_NAME,
  SubTypeId,
} from 'core/uiKit/preparedInputs/selects/autocompleteAsync/SubTypeId';
import React from 'react';
import { Field } from 'react-final-form';

export { SUB_TYPE_ID_NAME };

/**
 * ## Тип
 *
 * `type = Autocomplete`
 * `name = SUB_TYPE_ID_NAME`.
 *
 * @param otherProps - Properties.
 * @returns JSX.Element.
 */
export const SubTypeIdFF = (otherProps: ISubTypeIdProps) => {
  return (
    <Field name={SUB_TYPE_ID_NAME}>
      {(props) => {
        return <SubTypeId {...props} {...props.input} {...otherProps} />;
      }}
    </Field>
  );
};
