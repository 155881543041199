import {
  GOV_NUMBER_NAME_NAME,
  GovNumberName,
} from 'core/uiKit/preparedInputs/selects/autocompleteAsync/GovNumberName';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Рег. Номер.
 * ### React Final Form Field
 *
 * ###### type = `AutocompleteAsync`
 * ###### name = `govNumber`
 *
 * @param {object} props - Props.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли компонент для редактирования.
 * @param {object} [props.otherProps] - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const GovNumberNameFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={GOV_NUMBER_NAME_NAME}>
      {(props) => {
        return (
          <GovNumberName
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { GOV_NUMBER_NAME_NAME };
