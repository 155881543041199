import './Table.WorkTime.FF.scss';

import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';
import React from 'react';

import { getColumnsTableWorkTime } from './getColumnsTableWorkTime';
import { IWorkTimeData } from './useGetTableWorkTimeData';

/**
 * Таблица график работы.
 *
 * @param props - Пропсы.
 * @param props.value - Значение таблицы.
 * @returns
 */
export const TableWorkTime = ({ value }: { value: IWorkTimeData }) => {
  return (
    <GroupContainer title={'График работы'}>
      <div className={'table-working-hours__wrapper'}>
        <TableSimple data={value || []} columns={getColumnsTableWorkTime()} />
      </div>
    </GroupContainer>
  );
};
