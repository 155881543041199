import { PUBLIC_TOILET_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/PublicToiletTypeId/PublicToiletTypeId';
import { usePreparation } from 'core/utils/hooks/usePreparation/preparation';

/**
 * Returns an object with 'red_book_plant' property if 'value' is truthy.
 *
 * @returns
 */
export const usePreparationPublicToiletTypeId = () =>
  usePreparation(PUBLIC_TOILET_TYPE_ID_NAME, (value) => {
    if (value) {
      return { public_toilet_type: value };
    }
  });
