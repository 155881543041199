import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { Versions } from 'app/pages/cardsOgh/components/tables/Versions';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { NumberFieldFF } from 'core/form/finalForm/fields/default/NumberField.FF';
import { IsDiffHeightMarkFF } from 'core/form/finalForm/fields/prepared/checkboxes/IsDiffHeightMark.FF';
import { NoCalcFF } from 'core/form/finalForm/fields/prepared/checkboxes/NoCalc.FF';
import { TotalAreaGeoFF } from 'core/form/finalForm/fields/prepared/numberField/TotalAreaGeo.FF';
import { CleanTypeIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/CleanTypeId.FF';
import { CoatingGroupIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/CoatingGroupId.FF';
import { CoatingTypeIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/CoatingTypeId.FF';
import { IceRinkTypeIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/IceRinkTypeId.FF';
import { OtherDocumentsFF } from 'core/form/finalForm/fields/prepared/tables/documentTables/OtherDocuments.FF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TabsCardOGH } from 'core/uiKit/components/card.components/TabsCardOGH';
import { TabsCardOGHItem } from 'core/uiKit/components/card.components/TabsCardOGH/TabsCardOGH.Item';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

import { OghCardHeaderFF } from '../components/headerFF/OghHeaderFF';

/**
 * Форма для карточки Катки .
 *
 * @returns Form.
 */
export const LeftPanelIceRink = () => {
  const { rootId = 0 } = useGetCurrentCard();
  const { editMode } = useMode();

  const isCreatedPassport = Boolean(rootId);

  return (
    <CardContainer>
      <OghCardHeaderFF />
      <TabsCardOGH>
        <TabsCardOGHItem tabTitle={'Свойства'}>
          <FieldsSplitColumns>
            <IceRinkTypeIdFF disabled={!editMode} required={true} />
            <NoCalcFF disabled={!editMode} />
            <ParentInfo />
            <IsDiffHeightMarkFF disabled={!editMode} />
          </FieldsSplitColumns>
          <TabsCardOGH>
            <TabsCardOGHItem tabTitle={'Исходные данные'}>
              <FieldsSplitColumns>
                <CoatingGroupIdFF disabled={true} required={true} />
                <CoatingTypeIdFF disabled={true} required={true} />
                <CleanTypeIdFF disabled={true} required={true} />

                <NumberFieldFF
                  name={'total_area'}
                  label={'Площадь, кв.м.'}
                  maxLength={10}
                  required={true}
                  disabled={!editMode}
                  digits={2}
                />
                <TotalAreaGeoFF digits={2} maxLength={10} disabled={true} />
              </FieldsSplitColumns>
            </TabsCardOGHItem>
            <TabsCardOGHItem tabTitle={'Документы'}>
              <OtherDocumentsFF />
            </TabsCardOGHItem>
          </TabsCardOGH>
        </TabsCardOGHItem>
        <TabsCardOGHItem tabTitle={'Версии'} hide={!isCreatedPassport}>
          <Versions />
        </TabsCardOGHItem>
      </TabsCardOGH>
    </CardContainer>
  );
};
