import { post } from 'app/api/crud/post';
import { useMapContext } from 'app/components/map/useMapContext';
import { FOREGROUND, PARENTS } from 'app/constants/layers';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery/useMutationAdaptor';
import { log } from 'core/utils/log';

/**
 * Запрос на получение геометрии групп.
 *
 * @param {object} params - Параметры запроса.
 * @returns {Promise<*|Response>}
 */
const postGroupGeometry = (params) => post('/ogh/geometry/group', params);

/**
 * Хук использования запроса групп.
 *
 * @returns {{getGroupGeometry: Function, isLoadingGroupGeometry: boolean}}
 */
export const useGroupEgip = () => {
  const { drawParentGeometry, drawCurrentObjectsGeometry, clearLayer } =
    useMapContext();

  const [getGroupGeometry, { isLoading }] = useMutationAdaptor(
    postGroupGeometry,
    {

      /**
       * Ошибочный кейс.
       *
       */
      onError: () => {
        log.info('postGroupGeometry не прошел');
      },

      /**
       * Успешный кейс.
       *
       * @param {Array} data - Ответ.
       * @param {{id: number}} dataRequest - Данные запроса.
       */
      onSuccess: (data, dataRequest) => {
        clearLayer(FOREGROUND);
        clearLayer(PARENTS);

        const dataObjectGeometry = data.map((item) => ({
          id: dataRequest.id,
          ...item,
        }));
        const geo = dataObjectGeometry[0];

        if (geo?.child_object) {
          drawCurrentObjectsGeometry(geo.child_object);
          drawParentGeometry(dataObjectGeometry);
        } else {
          drawCurrentObjectsGeometry(dataObjectGeometry);
        }
      },
    },
  );

  return { getGroupGeometry, isLoadingGroupGeometry: isLoading };
};
