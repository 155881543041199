import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { Icon, IconButton } from 'core/uiKit/material-ui';
import React from 'react';
import { CellProps } from 'types/table';

import { REPAIRS_INFO_LIST_NAME } from './Table.Repairs.Tab';

/**
 * Удаление записи в Ремонты.
 *
 * @param {CellProps} props - Параметры.
 * @returns Кнопка.
 */
export const DeleteButtonCellRepairsTab = ({ row }: CellProps) => {
  const { editMode } = useMode();
  const { onChange, value: repairsInfoList } = useFieldRF<{ id: number }[]>(
    REPAIRS_INFO_LIST_NAME,
  );

  return (
    <IconButton
      disabled={!editMode}
      title="Удалить файл"
      size={'small'}
      onClick={() => {
        const clearList = (repairsInfoList || []).filter(
          (__: unknown, idx: number) => +idx !== +row.id,
        );
        onChange(clearList);
      }}
    >
      <Icon>delete</Icon>
    </IconButton>
  );
};
