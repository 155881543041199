import { getFileList } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getFileList';
import { getAttributeSubmit } from 'app/pages/cardsOgh/helpers/getAttributeSubmit';
import { useInitialValuesPublicToilet } from 'app/pages/cardsOgh/PublicToilet/hooks/useInitialValue.PublicToilet';
import { usePreparationFieldsPublicToilet } from 'app/pages/cardsOgh/PublicToilet/hooks/usePreparationFields.PublicToilet';
import { mapAttribute } from 'app/pages/cardsOgh/PublicToilet/mapAttribute.PublicToilet';

/**
 * Setup карточки __Публичные туалеты__.
 *
 * @returns {object}
 */
export const useSetupPublicToilet = () => {
  usePreparationFieldsPublicToilet();

  /**
   * Функция подготовка данных для Атрибутов.
   *
   * @param {*} values - Значения формы.
   * @returns {*} Атрибуты.
   */
  const preparingDataForAttributes = (values) => {
    return {
      file_list: getFileList(values),
      ...getAttributeSubmit(values, mapAttribute),
    };
  };

  const initialValues = useInitialValuesPublicToilet();

  return { initialValues, preparingDataForAttributes };
};
