import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import React from 'react';

export const MATCHING_CHANGE_TYPES_NAME = 'matchingChangeTypes';

/**
 * ## Операция.
 *
 * @param {*} props - Properties.
 * @param {Function} props.filter - Filter.
 * @param {*} props.otherProps - Properties.
 * @returns {JSX.Element}
 */
export const MatchingChangeTypes = ({ filter = (_) => _, ...props }) => {
  const dict = 'matchingChangeType';
  const { data = [] } = useGetDictionary(dict);

  const options = typeof filter === 'function' ? filter(data) : data;

  return (
    <MultiSelect
      {...props}
      optionKey={'code'}
      {...{ options }}
      label={'Операция'}
      name={MATCHING_CHANGE_TYPES_NAME}
    />
  );
};
