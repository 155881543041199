import { BRIDGE_TYPE_LEVEL_1_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/BridgeTypeLevel1Id';
import { OBJECT_SERVICE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/ObjectServiceId';
import { useField } from 'react-final-form';

/**
 * Setup __Исходные данные__.
 *
 * @returns TabPropertiesBridge.
 */
export const useSetupInitialDataBridge = () => {
  const {
    input: { value: objectServiceId },
  } = useField(OBJECT_SERVICE_ID_NAME);
  const isServiceObjectExploitation = objectServiceId !== 2;
  const isServiceObjectMajorRepairsTemporariry = [4, 5].includes(
    objectServiceId as never,
  );

  const {
    input: { value: bridgeTypeLevel1Id },
  } = useField(BRIDGE_TYPE_LEVEL_1_ID_NAME);
  const isTypeLevel1Bridge = bridgeTypeLevel1Id === 1;
  const isMapTypeLevel1Bridge = [1, 2, 4, 7].includes(
    bridgeTypeLevel1Id as never,
  );
  const isTypeLevel1Tunnel = bridgeTypeLevel1Id === 4;

  return {
    isMapTypeLevel1Bridge,
    isServiceObjectExploitation,
    isServiceObjectMajorRepairsTemporariry,
    isTypeLevel1Bridge,
    isTypeLevel1Tunnel,
  };
};
