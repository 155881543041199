import { post } from 'app/api/crud/post';

type ParamsNoCalcType = {
  parentId?: number;
  childIdsList: number[];
  changeAttribute: {
    noCalc: number;
  };
};

/**
 * Fn.
 *
 * @param params - Parameters.
 * @returns Massive_change_attribute.
 */
export const postSendNoCalc = (params: ParamsNoCalcType) => {
  return post<{ message: string }>('/massive_change_attribute', params);
};
