import { Cell } from 'types/table';

/**
 * Колонка __Тип объекта__.
 *
 * @type {{
 * accessor: 'bridge_type_level1_name',
 * Header: 'Тип объекта',
 * sortable: boolean
 * }}
 * @augments Cell
 */
export const bridge_type_level1_name = {
  Header: 'Тип объекта',
  accessor: 'bridge_type_level1_name',
  sortable: true,
};
