import { SubTypeIdFilter } from 'core/form/filterForm/components/autocompleteAsync/SubTypeId.Filter';
import { IdFilter } from 'core/form/filterForm/components/text/ID.Filter';
import React from 'react';

/**
 * Фильтр Объектов дорожного хозяйства.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Тип ОГХ.
 * @returns {JSX.Element}
 */
export const OdhRegistryFilter = ({ typeId }) => {
  return (
    <>
      <IdFilter />
      <SubTypeIdFilter typeId={typeId} />
    </>
  );
};
