import './Item.FileGrid.KnowledgeBase.scss';

import { useState } from 'react';

interface UseSetupItemFileGridKnowledgeBaseProps {
  file: { file_id: string; file_name: string };

  deleteFile: (fileId: string) => void;
}

/**
 * Setup Плитки файла.
 *
 * @param params - Параметры.
 * @param params.deleteFile - Функция удаления файла.
 * @param params.file - Файл.
 * @returns ItemFileGridKnowledgeBase.
 */
export const useSetupItemFileGridKnowledgeBase = ({
  file,
  deleteFile,
}: UseSetupItemFileGridKnowledgeBaseProps) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const isVideoFile = file?.file_name?.includes('.mp4');

  /**
   * Открытие диалога.
   *
   */
  const openDialog = () => {
    if (isVideoFile) {
      setIsOpenDialog(true);
    }
  };

  /**
   * Закрытие диалога.
   */
  const closeDialog = () => {
    setIsOpenDialog(false);
  };

  /**
   * Функция удаления файла.
   */
  const delFile = () => {
    deleteFile(file?.file_id);
  };

  return {
    closeDialog,
    delFile,
    isOpenDialog,
    isVideoFile,
    openDialog,
  };
};
