import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import { Name, NAME_NAME } from 'core/uiKit/preparedInputs/textField/Name';
import React from 'react';
import { InputPreparedProps } from 'types/inputs';

/**
 * TextField FF __Наименование__.
 *
 * @param props - Пропсы.
 * @returns FieldFF.
 */
export const NameFF = (props: InputPreparedProps) => {
  return <FieldFF {...props} component={Name} name={NAME_NAME} />;
};
