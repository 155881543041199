import { useFetchOghTable } from 'app/pages/registry/ogh/api/useFetchOghTable';
import { useFilterQueryParamsPt } from 'app/pages/registry/ogh/PT/Filter.PT';
import { useParamsTable } from 'core/uiKit/components/tables/Table';

/**
 * Setup Таблицы __Общественных туалетов__.
 *
 * @param props - Пропсы.
 * @param props.typeId - Тип Общественных туалетов.
 * @returns Данные для таблицы.
 */
export const useSetupTablePT = ({ typeId }: { typeId: number }) => {
  const { prepareFilters } = useFilterQueryParamsPt();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TablePublicToilet',
    { ...queryTable, ...prepareFilters, typeId },
  ];

  return useFetchOghTable(queryKey);
};
