import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import React from 'react';

export const EXPLOITATION_DATE_NAME = 'exploitation_date';

const mask = [/\d/, /\d/, /\d/, /\d/];

/**
 * TextFieldFF __Год ввода в эксплуатацию__.
 *
 * @class
 */
export const ExploitationDate = () => {
  return (
    <TextFieldFF
      name={EXPLOITATION_DATE_NAME}
      label={'Год ввода в эксплуатацию'}
      mask={mask}
    />
  );
};
