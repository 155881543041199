import {
  CircularProgress,
  Icon,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from 'core/uiKit/material-ui';
import React from 'react';

import { useSetupPrintItemButtonPT } from './useSetup.PrintItem.Button.PT';

interface IPrintItemButtonPT {
  template: string;
  text: string;
  publicToiletTypeId: number;
}

/**
 * ListItemButton Общественных туалетов.
 *
 * @param props - Пропсы.
 * @param props.template - Темплейт.
 * @param props.text - Текст.
 * @param props.publicToiletTypeId - Тип ОТ.
 * @returns
 */
export const PrintItemButtonPT = ({
  template,
  text,
  publicToiletTypeId,
}: IPrintItemButtonPT) => {
  const { isLoading, onClickHandler } = useSetupPrintItemButtonPT({
    publicToiletTypeId,
    template,
  });

  return (
    <ListItemButton
      data-testid="stationary-item"
      onClick={onClickHandler}
      className={'ml-4'}
    >
      <ListItemIcon>
        {isLoading ? <CircularProgress size={20} /> : <Icon>print</Icon>}
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );
};
