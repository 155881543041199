import { get } from 'app/api/crud/get';

/**
 * Получение Типов Для Автокомплита.
 *
 * @param data - Данные.
 * @param data.typeId - Тип карточки.
 * @param data.maxRows - Максимальное количество записей.
 * @param data.startsWith - Строка для поиска.
 * @param [data.parentTypeId] - Id родительского типа.
 * @returns - Даныые Типов.
 */
export async function fetchOghTypesAutocomplete(data: {
  parentTypeId?: number;
  typeId: number;
  maxRows: number;
  startsWith: string;
}) {
  if (data?.typeId) {
    return await get<{ id: number; name: string }[]>(
      '/autocomplete/ogh_types',
      data,
    );
  }
  return [];
}
