import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import isMandatory from 'app/utils/card/isMandatory';
import { NumberFieldRF } from 'core/form/reduxForm/fields/default/NumberField.RF';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { NoCalcRF } from 'core/form/reduxForm/fields/prepared/checkboxes/NoCalc.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/numberField/TotalAreaGeo.RF';
import { CoatingGroupIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingGroupId.RF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TextField } from 'core/uiKit/inputs/TextField';

import { CoatingFaceTypeIdField } from '../../../components/selects/CoatingFaceTypeId.Field';
import { CoatingTypeIdFiled } from '../../../components/selects/CoatingTypeId.Field';
import { UnitIdField } from '../../../components/selects/UnitId.Field';

/**
 * Таба Исходные данные __Элементы организации рельефа__.
 *
 * @param {*} params - Параметры.
 * @returns {JSX}
 */
export const CharacteristicsTabRelief = (params) => {
  const { editMode, card, required } = params;
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  return (
    <div id="characteristics">
      <FieldsSplitColumns>
        <NumberFieldRF
          required={true}
          label={'Количество'}
          name={'quantity'}
          decimalScale={2}
          disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        />
        <TotalAreaGeoRF disabled={true} decimalScale={2} />
        <UnitIdField />
        <SelectRF
          label={'Материал'}
          options={card.material}
          name={'material_id'}
          required={isMandatory(card.type_id, 'material_id', required)}
          disabled={!editMode}
        />
        <TextField
          value={card.zone_name || ''}
          {...{
            ...{
              disabled: true,
              id: 'zone_name',
              label: 'Принадлежность элемента к зоне территории',
              name: 'zone_name',
            },
          }}
        />
        <CoatingGroupIdRF disabled={!editMode} />
        <CoatingTypeIdFiled />
        <CoatingFaceTypeIdField />
        <NumberFieldRF
          label="Площадь облицовки, кв.м"
          name="face_area"
          decimalScale={2}
          disabled={!editMode}
        />
      </FieldsSplitColumns>
      <FieldsSplitColumns>
        <NoCalcRF disabled={!editMode} />
        <IsDiffHeightMarkField />
      </FieldsSplitColumns>
    </div>
  );
};
