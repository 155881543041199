import { NumberFieldFF } from 'core/form/finalForm/fields/default/NumberField.FF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { OBJECT_SERVICE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/ObjectServiceId';
import React from 'react';
import { useField } from 'react-final-form';

/**
 * Таб __Уборочные площади__.
 *
 * @returns TabPropertiesBridge.
 */
export const CleaningAreasBridge = () => {
  const {
    input: { value: objectServiceId },
  } = useField(OBJECT_SERVICE_ID_NAME);

  return (
    <FieldsSplitColumns>
      <NumberFieldFF
        name={'total_clean_area'}
        label={'Площадь уборки, кв.м'}
        disabled={objectServiceId !== 2}
      />
      <NumberFieldFF
        name={'manual_clean_area'}
        label={'Площадь уборки, кв.м (ручная)'}
        disabled={objectServiceId !== 2}
      />
      <NumberFieldFF
        name={'auto_clean_area'}
        label={'Площадь уборки, кв.м (механизированная)'}
        disabled={objectServiceId !== 2}
      />
    </FieldsSplitColumns>
  );
};
