/**
 * Рекурсивно извлекает тип значения на основе пути.
 * Например, для пути `'a.b.c'` и объекта `{ a: { b: { c: number } } }` вернёт `number`.
 */
type GetValueType<
  ObjectType,
  Path extends string,
> = Path extends `${infer Key}.${infer Rest}`
  ? Key extends keyof ObjectType
    ? GetValueType<ObjectType[Key], Rest>
    : undefined
  : Path extends keyof ObjectType
  ? ObjectType[Path]
  : undefined;

/**
 * Безопасно извлекает значение из объекта по указанному пути.
 * Если путь не существуts: Variable 'deepGet' implicitly has an 'any' type. [7005]ет, возвращает значение по умолчанию.
 *
 * @template ObjectType - Тип объекта, из которого извлекается значение.
 * @template Path - Путь до значения в виде строки (например, 'a.b.c').
 * @param obj - Объект, из которого извлекается значение.
 * @param path - Путь до значения в виде строки.
 * @param defaultValue - Значение по умолчанию, если путь не существует.
 * @returns Значение по указанному пути или значение по умолчанию.
 *
 * @example
 * const obj = { a: { b: { c: 42 } } };
 * const value = deepGet(obj, 'a.b.c', 0); // 42
 */
export const deepGet = <ObjectType, Path extends string>(
  obj: ObjectType,
  path: Path | null,
  defaultValue: GetValueType<ObjectType, Path>,
): GetValueType<ObjectType, Path> => {
  if (!path) return (obj as GetValueType<ObjectType, Path>) || defaultValue; // Если путь пустой, возвращаем сам объект или дефолтное заначение.

  const keys = path.split('.') as (keyof ObjectType)[]; // Разбиваем путь на ключи
  let result: unknown = obj;

  for (const key of keys) {
    if (result && typeof result === 'object' && key in (result as object)) {
      result = (result as Record<string, unknown>)[key as string];
    } else {
      return defaultValue; // Если ключ не найден, возвращаем значение по умолчанию
    }
  }

  return (result !== undefined ? result : defaultValue) as GetValueType<
    ObjectType,
    Path
  >;
};
