import { filterInput } from 'core/form/filterForm/components/filterInputsClasses';
import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import React from 'react';
import { StringParam } from 'use-query-params';

const ADDRESS_NAME = 'address';

/**
 * Фильтр Адрес.
 *
 * @returns
 */
export const AddressFilter = () => {
  return (
    <TextFieldFF label={'Адрес'} name={ADDRESS_NAME} className={filterInput} />
  );
};

/**
 * Параметры для фильтрации.
 *
 * @returns
 */
export const addressParams = () => ({
  [ADDRESS_NAME]: StringParam,
});
