import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import { NumberField } from 'core/uiKit/inputs';
import React from 'react';
import { NumberPropsType } from 'types/inputs';

/**
 * Компонент WaterTypeIdFF.
 *
 * @param props - Свойства компонента.
 * @param [props.name] - Имя поля.
 * @param [props.otherProps] - Дополнительные  пропсы, которые будут переданы компоненту `CoatingGroupId`.
 *
 * @returns JSX.Element.
 */
export const NumberFieldFF = ({ name, ...otherProps }: NumberPropsType) => {
  return <FieldFF name={name} {...otherProps} component={NumberField} />;
};
