import { Document } from 'app/pages/cardsOgh/components/tables/Documents/Document';
import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import React from 'react';
import { Field } from 'react-final-form';

const ID = 14;
const NAME = getNameFile(14);
const TITLE = 'Иные документы';

/**
 * Иные документы.
 *
 * @deprecated
 * @see
 * @returns {React.ReactElement}
 */
export const OtherDocumentsFF = () => {
  return (
    <Field name={NAME}>
      {({ input }) => {
        const { value, onChange } = input;
        return (
          <Document
            titleDialog={`Добавление файла (${TITLE})`}
            title={TITLE}
            dataTable={value || []}
            typeId={ID}
            onChange={onChange}
          />
        );
      }}
    </Field>
  );
};
