import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { useEffect, useState } from 'react';

import { useGetNeighboursMuttation } from './useGetNeighbours.Muttation';

/**
 * Setup Кнопка показать смежные ОГХ.
 *
 * @returns Данные для отрисовки компонента.
 */
export const useSetupNeighboursButtonDTW = () => {
  const { getGeometryToJson, mapService } = useMapContext();
  const { root_id, start_date, end_date, record_id } = useGetCurrentCard();

  const [hasNeighbours, setHasNeighbours] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { drawNeighboursGeometryWithFormat } = useMapContext();

  const { count, getNeighbours, isLoading, neighbors, radius } =
    useGetNeighboursMuttation({
      //

      /**
       * Обработчик успешного завершения запроса.
       *
       * @param data - Данные с бэка.
       */
      onSuccess: (data) => {
        drawNeighboursGeometryWithFormat(data?.data);

        if (Number(data?.count) > 0) {
          setIsOpen(true);
        }
      },
    });

  /**
   * Функция получения показа таблицы смежных ОГХ и отрисовки геометрии.
   *
   *
   */
  const handleClickOpen = () => {
    if (hasNeighbours) {
      setHasNeighbours(false);
      getNeighbours(undefined);
    } else {
      const data = {
        end_date,
        geometry: getGeometryToJson(record_id),
        record_id,
        root_id,
        start_date,
      };

      getNeighbours(data);
      setHasNeighbours(true);
    }
  };

  /**
   * Функция закрыть диалоговое окно.
   *
   */
  const closeDialog = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    closeDialog();
  }, [record_id]);

  return {
    closeDialog,
    count,
    handleClickOpen,
    hasNeighbours,
    isLoading,
    isMapLoaded: mapService?.isMapLoaded,
    isOpen: isOpen && !isLoading,
    neighbors,
    params: {
      end_date,
      geometry: getGeometryToJson(record_id),
      record_id,
      root_id,
      start_date,
    },
    radius,
  };
};
