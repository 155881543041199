import { useGetDictionary } from 'app/api/hooks/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const BRIDGE_TYPE_LEVEL_2_ID_NAME = 'bridge_type_level2_id';

/**
 * Select __Назначение__.
 *
 * @param {object} props - Пропсы.
 * @returns Select.
 */
export const BridgeTypeLevel2Id = (props: SelectPropsType) => {
  const dict = 'bridge_type_level2';
  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      {...{
        label: 'Назначение',
        name: BRIDGE_TYPE_LEVEL_2_ID_NAME,
        options: data,
      }}
    />
  );
};
