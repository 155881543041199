import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { vi } from 'vitest';

import { useSetupMafTypeLevel1IdField } from './useSetup.MafTypeLevel1.Field';

vi.mock('app/api/hooks/useGetParentCard');

describe('🐛 UseSetupMafTypeLevel1Field', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    //☣️ Arrange (всякие моки)
    useGetParentCard.mockReturnValue({ typeId: RANDOM_NUMBER });

    //🔥 Act
    const res = useSetupMafTypeLevel1IdField();

    //❓ Assert
    expect(res).toStrictEqual({
      filter: expect.any(Function),
      typeId: RANDOM_NUMBER,
    });
  });

  it('🧪 filter', () => {
    expect.hasAssertions();

    //☣️ Arrange (всякие моки)
    useGetParentCard.mockReturnValue({ typeId: RANDOM_NUMBER });

    //🔥 Act
    const { filter } = useSetupMafTypeLevel1IdField();
    const res = filter([
      {
        id: 1,
        name: 'name1',
        parent_ogh_object_type_list: [RANDOM_NUMBER, 1, 2],
      },
      {
        id: 1,
        name: 'name1',
        parent_ogh_object_type_list: [1, 2],
      },
    ]);

    //❓ Assert
    expect(res).toStrictEqual([
      {
        id: 1,
        name: 'name1',
        parent_ogh_object_type_list: [RANDOM_NUMBER, 1, 2],
      },
    ]);
  });
});
