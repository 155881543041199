import { usePreparation } from 'core/utils/hooks/usePreparation';

import { DETAILS_MODELS_ID_NAME } from './DetailsModelsId';

/**
 * Returns an object with 'red_book_plant' property if 'value' is truthy.
 *
 * @returns
 */
export const usePreparationDetailsModelsId = () =>
  usePreparation(DETAILS_MODELS_ID_NAME, (value) => {
    if (value) {
      return { details_models: value };
    }
  });
