import { parseTimeStringToMoment } from 'app/utils/time/parseTimeStringToMoment';
import React from 'react';

interface IWorkingHoursDurationCell {
  value: string;
  row: {
    original: {
      startTime: string;
      endTime: string;
    };
  };
}

/**
 * Cell __Продолжительность работы, час__.
 *
 * @param props - Пропсы.
 * @param props.row - Данные ряда.
 * @returns
 */
export const WorkingHoursDurationCell = ({
  row,
}: IWorkingHoursDurationCell) => {
  const {
    original: { startTime, endTime },
  } = row;

  const startTimeMoment = parseTimeStringToMoment(startTime);
  const endTimeMoment = parseTimeStringToMoment(endTime);

  const diffMinutes = endTimeMoment?.diff(startTimeMoment, 'minutes') || 0;

  const hours = String(Math.floor(diffMinutes / 60)).padStart(2, '0');
  const minutes = String(diffMinutes % 60).padStart(2, '0');
  const text = `${hours}ч. ${minutes}м.`;

  return <span>{text}</span>;
};
