import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import React from 'react';

export const LIST_TYPE_ID_MULTI_NAME = 'list_type_id';

/**
 * Тип списка.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const ListTypeIdMulti = (props) => {
  const tlTypes = 'tl_types';

  const { data = [] } = useGetDictionary(tlTypes);

  const options = data.sort((a_, b_) => a_.id - b_.id);
  return (
    <MultiSelect
      label={'Тип списка'}
      name={LIST_TYPE_ID_MULTI_NAME}
      options={options}
      {...props}
    />
  );
};
