import { usePreparationCategoryToiletId } from 'core/uiKit/preparedInputs/selects/simpleSelect/CategoryToiletId/usePreparation.CategoryToiletId';
import { usePreparationCorrectionId } from 'core/uiKit/preparedInputs/selects/simpleSelect/CorrectionId/usePreparation.CorrectionId';
import { usePreparationDepartmentId } from 'core/uiKit/preparedInputs/selects/simpleSelect/DepartmentId/usePreparation.DepartmentId';
import { usePreparationDetailsModelsId } from 'core/uiKit/preparedInputs/selects/simpleSelect/DetailsModelsId/usePreparation.DetailsModelsId';
import { usePreparationFormVisitId } from 'core/uiKit/preparedInputs/selects/simpleSelect/FormVisitId/usePreparation.FormVisitId';
import { usePreparationIsAdaptedPeopleLimitedMobility } from 'core/uiKit/preparedInputs/selects/simpleSelect/IsAdaptedPeopleLimitedMobility/usePreparation.IsAdaptedPeopleLimitedMobility';
import { usePreparationIsAvailabilityPaymentTerminal } from 'core/uiKit/preparedInputs/selects/simpleSelect/IsAvailabilityPaymentTerminal/usePreparation.IsAvailabilityPaymentTerminal';
import { usePreparationLocationMethodId } from 'core/uiKit/preparedInputs/selects/simpleSelect/LocationMethodId/usePreparation.LocationMethodId';
import { usePreparationPublicToiletTypeId } from 'core/uiKit/preparedInputs/selects/simpleSelect/PublicToiletTypeId/usePreparation.PublicToiletTypeId';

import { usePreparationDistrictList } from './preparations/usePreparation.DistrictList';
import { usePreparationOkrugList } from './preparations/usePreparation.OkrugList';
import { usePreparationOwnerId } from './preparations/usePreparation.OwnerId';
import { usePreparationWorkTime } from './preparations/usePreparationWorkTime';

/**
 * Хук преобразования полей для карточки __Общественные туалеты__.
 *
 */
export const usePreparationFieldsPublicToilet = () => {
  usePreparationIsAdaptedPeopleLimitedMobility();
  usePreparationIsAvailabilityPaymentTerminal();
  usePreparationPublicToiletTypeId();
  usePreparationDepartmentId();
  usePreparationFormVisitId();
  usePreparationLocationMethodId();
  usePreparationCategoryToiletId();
  usePreparationDetailsModelsId();
  usePreparationCorrectionId();

  usePreparationOwnerId();
  usePreparationOkrugList();
  usePreparationDistrictList();
  usePreparationWorkTime();
};
