import {
  OGH_TITLE_REASON_NAME,
  OghTitleReason,
} from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

export { OGH_TITLE_REASON_NAME };

/**
 * ## Основание.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.dark] - Флаг темы.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли компонент для редактирования.
 * @param {object} [props.otherProps] - Другие пропсы.
 * @returns {JSX.Element}
 */
export const OghTitleReasonFF = ({
  dark = false,
  disabled = false,
  ...otherProps
}) => {
  return (
    <Field name={OGH_TITLE_REASON_NAME}>
      {(props) => {
        return (
          <OghTitleReason
            {...{ dark, disabled }}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};
