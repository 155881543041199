import { SelectFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export const IS_BELONGING_OGH_NAME = 'is_belonging_ogh';

const options = [
  { id: 1, name: 'Да' },
  { id: -1, name: 'Нет' },
];

/**
 * YesOrNot __Принадлежность к ОГХ__.
 *
 * @returns {JSX.Element}
 */
export const IsBelongingOghFilter = () => {
  return (
    <SelectFF
      className={filterInput}
      options={options}
      label={'Принадлежность к ОГХ'}
      name={IS_BELONGING_OGH_NAME}
    />
  );
};

/**
 * Params __Принадлежность к ОГХ__.
 *
 * @returns {*}
 */
export const isBelongingOghParams = () => {
  addPreparation(IS_BELONGING_OGH_NAME, (value) => value > 0);
  return {
    [IS_BELONGING_OGH_NAME]: NumberParam,
  };
};
