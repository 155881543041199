import { log } from 'core/utils/log';

import { DEFAULT_LAYER_INSTANCES, DEFAULT_MAPPED_GEOMETRIES } from './utils';
import { MapglContextValue } from './utils/types.d';

// @ts-ignore
export const initialMapglContextValue: MapglContextValue = {
  $mapElement: { current: null },

  /**
   * Draw geometries.
   *
   * @returns Object.
   */
  drawGeometries: () => {
    log.warn('drawGeometries is not implemented');
    return DEFAULT_LAYER_INSTANCES;
  },

  /**
   * Draw intersections.
   *
   * @returns Object.
   */
  drawIntersections: () => {
    log.warn('drawIntersections is not implemented');
    return DEFAULT_LAYER_INSTANCES;
  },

  /**
   * Draw layers.
   *
   * @returns Object.
   */
  drawLayers: () => {
    log.warn('drawLayers is not implemented');
    return DEFAULT_LAYER_INSTANCES;
  },
  geometryUtils: null,

  /**
   * Get geometries.
   *
   * @returns Object.
   */
  getGeometries: () => {
    log.warn('getGeometries is not implemented');
    return {};
  },
  id: '',
  instances: null,
  loadedGeometries: null,
  mapService: null,
  mapgl: null,
  onClickHintElementRef: {
    current: null,
  },
  onHoverHintElementRef: {
    current: null,
  },

  /**
   * Set mapgl context.
   *
   */
  setMapglContext: () => {
    log.warn('setMapglContext is not implemented');
  },

  /**
   * Set on click hint element.
   */
  setOnClickHintElement: () => {
    log.warn('setOnClickHintElement is not implemented');
  },

  /**
   * Set on hover hint element.
   */
  setOnHoverHintElement: () => {
    log.warn('setOnHoverHintElement is not implemented');
  },

  /**
   * Update loaded geometries.
   *
   * @returns Object.
   */
  updateLoadedGeometries: () => {
    log.warn('updateLoadedGeometries is not implemented');
    return DEFAULT_MAPPED_GEOMETRIES;
  },

  /**
   * Zoom to geometries.
   */
  zoomToGeometries: () => {
    log.warn('zoomToGeometries is not implemented');
  },
};
