import { BarnewJerseyHints } from 'app/components/card/ogh/Odh/components/hints/BarnewJersey.Hints/BarnewJersey.Hints';
import { createNumberedList } from 'app/utils/createNumberedList';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TabPanel } from 'core/uiKit/components/TabPanel';
import { NumberField } from 'core/uiKit/inputs/NumberField';
import React from 'react';

import { useSetupCleaning } from './useSetup.Cleaning';

/**
 * Уборочные данные.
 *
 * @param {object} params - Parameters.
 * @param {string} params.propertyTab - Активная вкладка.
 * @returns
 */
export const Cleaning = ({ propertyTab }: { propertyTab: string }) => {
  const {
    calcAttributes,
    editMode,
    hintIfIsNotOrphanObject,
    isOrphanObject,
    required,
    shouldDisableAction,
  } = useSetupCleaning();

  return (
    <TabPanel show={propertyTab === 'cleaning'}>
      <FieldsSplitColumns>
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.cleaning_area,
            digits: 20,
            disabled: true,
            helpTitle: hintIfIsNotOrphanObject(
              'cleaning_area',
              // @ts-ignore
              createNumberedList({
                header: 'Сумма значений полей',
                list: [
                  'Площадь ручной уборки проезжей части (без парковочного пространства), кв.м',
                  'Площадь механизированной уборки проезжей части (без парковочного пространства), кв.м',
                  'Площадь ручной уборки парковочного пространства, кв.м',
                  'Площадь мехнизированной уборки парковочного пространства, кв.м',
                  'Площадь уборки обочин, мех., кв.м',
                  'Площадь уборки обочин, руч., кв.м',
                  'Площадь уборки тротуаров мех., кв.м',
                  'Площадь уборки тротуаров ручн., кв.м',
                  'Площадь убираемых остановок, кв.м',
                ],
              }),
            ),
            id: 'cleaning_area',
            label: 'Площадь уборки, кв.м',
            name: 'cleaning_area',
            required: isOrphanObject,
            zero: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            digits: 20,
            editable: editMode,
            helpTitle: hintIfIsNotOrphanObject('snow_area'),
            id: 'snow_area',
            label: 'Площадь вывоза снега, кв.м',
            name: 'snow_area',
            required,
            zero: isOrphanObject,
          }}
        />

        {isOrphanObject ? (
          <NumberField
            {...{
              digits: 20,
              disabled: !editMode,
              helpTitle: hintIfIsNotOrphanObject('manual_edit'),
              id: 'reagent_area',
              label: 'Площадь обработки реагентами, кв.м',
              name: 'reagent_area',
              required,
              zero: true,
            }}
          />
        ) : null}
        <NumberFieldRF
          {...{
            digits: 20,
            disabled: !editMode,
            helpTitle: hintIfIsNotOrphanObject('rotor_area'),
            id: 'rotor_area',
            label: 'Площадь роторной перекидки, кв.м',
            name: 'rotor_area',
            required,
            zero: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject(
                'roadway_noprkg_manual_clean_area',
              ),
              required: isOrphanObject,
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.roadway_noprkg_manual_clean_area,
            disabled: shouldDisableAction,
            id: 'roadway_noprkg_manual_clean_area',
            label:
              'Площадь ручной уборки проезжей части (без парковочного пространства), кв.м',
            name: 'roadway_noprkg_manual_clean_area',
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.roadway_noprkg_auto_clean_area,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject(
              'roadway_noprkg_auto_clean_area',
            ),
            id: 'roadway_noprkg_auto_clean_area',
            label:
              'Площадь механизированной уборки проезжей части (без парковочного пространства), кв.м',
            name: 'roadway_noprkg_auto_clean_area',
            required: isOrphanObject,
            zero: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.roadway_prkg_manual_clean_area,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject(
              'roadway_prkg_manual_clean_area',
            ),
            id: 'roadway_prkg_manual_clean_area',
            label: 'Площадь ручной уборки парковочного пространства, кв.м',
            name: 'roadway_prkg_manual_clean_area',
            required: isOrphanObject,
            zero: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.roadway_prkg_auto_clean_area,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject('roadway_prkg_auto_clean_area'),
            id: 'roadway_prkg_auto_clean_area',
            label:
              'П�ощадь механизированной уборки парковочного пространства, кв.м',
            name: 'roadway_prkg_auto_clean_area',
            required: isOrphanObject,
            zero: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.auto_footway_area,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject('auto_footway_area'),
            id: 'auto_footway_area',
            label: 'Площадь уборки тротуаров мех., кв.м',
            name: 'auto_footway_area',
            required: isOrphanObject,
            zero: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.manual_footway_area,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject('manual_footway_area'),
            id: 'manual_footway_area',
            label: 'Площадь уборки тротуаров ручн., кв.м',
            name: 'manual_footway_area',
            required: isOrphanObject,
            zero: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.station_number,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject('station_number'),
            id: 'station_number',
            label: 'Количество убираемых остановок, ед.',
            name: 'station_number',
            required: isOrphanObject,
            zero: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.station_clean_area,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject('station_clean_area'),
            id: 'station_clean_area',
            label: 'Площадь убираемых остановок, кв.м',
            name: 'station_clean_area',
            required: isOrphanObject,
            zero: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.margin_auto_clean_area,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject('margin_auto_clean_area'),
            id: 'margin_auto_clean_area',
            label: 'Площадь уборки обочин, мех., кв.м',
            name: 'margin_auto_clean_area',
            positive: true,
            required: isOrphanObject,
            type: 'decimal',
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.margin_manual_clean_area,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject('margin_manual_clean_area'),
            id: 'margin_manual_clean_area',
            label: 'Площадь уборки обочин, руч., кв.м',
            name: 'margin_manual_clean_area',
            positive: true,
            required: isOrphanObject,
            type: 'decimal',
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.bar_new_jersey,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: <BarnewJerseyHints />,
            id: 'bar_new_jersey',
            label: 'Стенка «Нью-Джерси», п.м',
            name: 'bar_new_jersey',
            required: isOrphanObject,
            zero: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.bar_wave,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject('bar_wave'),
            id: 'bar_wave',
            label: 'Металлические барьерные ограждения Волна, п.м',
            name: 'bar_wave',
            required: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.bar_trans,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject('bar_trans'),
            id: 'bar_trans',
            label: 'Металлические барьерные ограждения Трансбарьер, п.м',
            name: 'bar_trans',
            required: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.bar_pipe,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject('bar_pipe'),
            id: 'bar_pipe',
            label: 'Металлические барьерные ограждения Труба, п.м',
            name: 'bar_pipe',
            required: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.bar_foot,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject('bar_foot'),
            id: 'bar_foot',
            label: 'Пешеходные ограждения, п.м',
            name: 'bar_foot',
            required: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.footway_pole,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject('footway_pole'),
            id: 'footway_pole',
            label: 'Тротуарные столбики, п.м',
            name: 'footway_pole',
            required: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.bar_antinoise,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject('bar_antinoise'),
            id: 'bar_antinoise',
            label: 'Шумозащитный экран (стенка), п.м',
            name: 'bar_antinoise',
            required: isOrphanObject,
            zero: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.asperity,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject('asperity'),
            id: 'asperity',
            label: 'ИДН, ед.',
            name: 'asperity',
            required: isOrphanObject,
            zero: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.bar_fracasso,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject('bar_fracasso'),
            id: 'bar_fracasso',
            label: 'Металлическое барьерное ограждение Фракассо, п.м',
            name: 'bar_fracasso',
            required: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.bar_concrete,
            digits: 20,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject('bar_concrete'),
            id: 'bar_concrete',
            label: 'Парапет, п.м',
            name: 'bar_concrete',
            required: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.buffer,
            disabled: shouldDisableAction,
            helpTitle: hintIfIsNotOrphanObject('buffer'),
            id: 'buffer',
            label: 'Буфер безопасности, ед.',
            name: 'buffer',
            required: isOrphanObject,
            zero: isOrphanObject,
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.anti_ram_barrier_num,
            disabled: !editMode,
            formValue: true,
            helpTitle: hintIfIsNotOrphanObject('anti_ram_barrier_num'),
            id: 'anti_ram_barrier_num',
            label: 'Ограждающая (противотаранная) конструкция, ед.',
            name: 'anti_ram_barrier_num',
          }}
        />
        <NumberFieldRF
          {...{
            defaultValue: calcAttributes.bollard_num,
            disabled: !editMode,
            formValue: true,
            helpTitle: hintIfIsNotOrphanObject('bollard_num'),
            id: 'bollard_num',
            label: 'Дорожный блокиратор (боллард), ед.',
            name: 'bollard_num',
          }}
        />
      </FieldsSplitColumns>
    </TabPanel>
  );
};
