import { cellsMainMap } from 'core/uiKit/components/cells/cellsMainMap';

/**
 *  Колонки для таблицы реестра __Общественных туалетов__.
 *
 * @returns Возвращает колонки.
 */
export const getColumnsPT = () => [
  cellsMainMap.short_root_id, // ID
  cellsMainMap.okrug_name, // Округ
  cellsMainMap.district_name, // Район
  cellsMainMap.object_name, // Наименование
  cellsMainMap.department_name, // Отраслевой ОИВ
  cellsMainMap.owner_name, // Балансодержатель
  cellsMainMap.address_name, // Адрес ОТ
  cellsMainMap.category_toilet_name, // Категория
  cellsMainMap.start_date, // Дата начала
  cellsMainMap.end_date, // Дата конца
  cellsMainMap.object_status_name, // Статус
  cellsMainMap.public_toilet_type_name, // Тип ОТ
  cellsMainMap.location_method_name, // Способ расположения
  cellsMainMap.form_visit_name, // Форма посещения
  cellsMainMap.is_availability_payment_terminal, // Наличие терминала оплаты
  cellsMainMap.is_adapted_people_limited_mobility, // Адаптирован для маломобильных групп населения
  cellsMainMap.is_belonging_ogh, // Принадлежность к ОГХ
];
