import { Checkbox } from 'core/uiKit/inputs';
import React from 'react';
import { Field } from 'redux-form';

/**
 * CheckboxWithReduxForm.
 *
 * @param {object} props - Пропсы компонента.
 * @param {object} props.input - Инпут.
 * @returns {JSX.Element}
 */
const CheckboxWithReduxForm = ({ input, ...props }) => {
  return <Checkbox {...props} {...input} />;
};

/**
 * Компонент Checkbox.
 *
 * @param {object} params - Параметры.
 * @param {object} [params.dark] - Пропсы, передаваемые от Redux Form (например, value, onChange).
 * @param {boolean} [params.disabled] - Флаг для отключения компонента.
 * @param {string} params.label - Лейбл для компонента.
 * @param {string} params.name - Имя поля для Redux Form.
 * @param {object} [params.otherProps] - Дополнительные пропсы для компонента `Checkbox`.
 * @returns {JSX.Element}
 */
export const CheckboxRF = ({
  name = 'CheckboxRF',
  dark = false,
  disabled = false,
  label = 'CheckboxRF label',
  ...otherProps
}) => {
  return (
    <Field
      type={'checkbox'}
      name={name}
      component={CheckboxWithReduxForm}
      {...{ dark, disabled, label }}
      {...otherProps}
    />
  );
};
