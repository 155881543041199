import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  CLEAN_CATEGORY_IDS_NAME,
  CleanCategoryIds,
} from 'core/uiKit/preparedInputs/selects/multiSelect/CleanCategoryIds';
import React from 'react';
import { SelectPreparedPropsType } from 'types/inputs';

/**
 * MultiSelect __Категория по уборке__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const CleanCategoryIdsFF = (props: SelectPreparedPropsType) => {
  return (
    <FieldFF
      {...props}
      component={CleanCategoryIds}
      name={CLEAN_CATEGORY_IDS_NAME}
    />
  );
};
