export interface IMafType {
  parent_ogh_object_type_list: number[];
  maf_type_level1_id: { maf_type_level1: number };
}

/**
 * Функция подготовки значений mafTypeList для дальнейшей фильтрации.
 *
 * @param mafTypeList - Список полей.
 * @returns Подготовленный mafTypeList.
 */
export const preparationMafTypeListLevel2 = (mafTypeList: IMafType[]) => {
  return (mafTypeList || []).map((item) => {
    return {
      mafTypeLevel1Id: item?.maf_type_level1_id?.maf_type_level1,
      parentTypeIdList: item?.parent_ogh_object_type_list || [],
    };
  });
};
