import React, { createContext, ReactNode } from 'react';

import { initialMapglContextValue } from './initialMapglContextValue';
import { MapglEditorContextProvider } from './MapglEditorContextProvider';
import { useSetupMapglContextProvider } from './useSetup.MapglContextProvider';
import { Layers, MapglContextValue } from './utils/types.d';

export const ORDERED_LAYERS: Layers[] = [
  'parent',
  'selected',
  'children',
  'allChildren',
  'intersections',
  'adjacent',
  'reonArea',
  'districts',
  'copyDiff',
  'originalDiff',
];

export const MapglContext = createContext<MapglContextValue>(
  initialMapglContextValue,
);

/**
 * Контекст провайдер с апи карты.
 *
 * @param props - Пропсы.
 * @param props.children - Реакт нода.
 * @returns Реакт компонент.
 */
export const MapglContextProvider = ({ children }: { children: ReactNode }) => {
  const {
    drawGeometries,
    geometryUtils,
    isDrawing,
    isEditing,
    loadedGeometries,
    mapService,
    recordId,
    setIsDrawing,
    setIsEditing,
    value,
  } = useSetupMapglContextProvider();
  return (
    // @ts-ignore
    <MapglContext.Provider value={value}>
      <MapglEditorContextProvider
        {...{
          drawGeometries,
          geometryUtils,
          isDrawing,
          isEditing,
          loadedGeometries,
          mapService,
          recordId,
          setIsDrawing,
          setIsEditing,
        }}
      >
        {children}
      </MapglEditorContextProvider>
    </MapglContext.Provider>
  );
};

export default MapglContextProvider;
