import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  IS_ADAPTED_PEOPLE_LIMITED_MOBILITY_NAME,
  IsAdaptedPeopleLimitedMobility,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/IsAdaptedPeopleLimitedMobility';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * Select FF __Адаптирован для маломобильных групп населения__.
 *
 * @param props - Пропсы.
 * @returns Select Адаптирован для маломобильных групп населения.
 */
export const IsAdaptedPeopleLimitedMobilityFF = (props: SelectPropsType) => {
  return (
    <FieldFF
      {...props}
      component={IsAdaptedPeopleLimitedMobility}
      name={IS_ADAPTED_PEOPLE_LIMITED_MOBILITY_NAME}
    />
  );
};
