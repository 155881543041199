import { Map, MapEventTable, MapPointerEvent } from '@2gis/mapgl/types';
import { getMarkerIcons } from 'app/services/mapService/pointMarkers';
import { Polyline } from 'core/uiKit/components/DTW/contexts/utils/Polyline';
import { log } from 'core/utils/log';
import { v4 } from 'uuid';

import { GeometryUtils, sizeInPixelsToCoords } from '..';
import GeometryFactory from '../GeometryFactory';
import { MapService } from '../MapService';
import { layerStyles } from '../MapService/utils';
import {
  GenericCoordinates,
  GenericGeometryOptions,
  GeometryToDraw,
  GeometryTypes,
  Marker,
  MarkerOptions,
  Polygon,
  PolygonOptions,
  RawGeometry,
  UserData,
} from '../types.d';
import {
  isGeometryPoint,
  isGeometryPolygon,
  isGeometryPolyline,
} from './../ShapeEditor';
import Vertex from './utils/Vertex';

export type DrawEventHandler = (geometry: RawGeometry | null) => void;

/**
 * Сервис для рисования геометрии.
 */
export default class DrawService {
  public geometryUtils: GeometryUtils;

  public currentGeometry: RawGeometry | null = null;

  public map: Map | null = null;

  public vertex: Vertex | null = null;

  public startVertex: Vertex | null = null;

  public isStartVertexHovered: boolean = false;

  public drawingType: GeometryTypes | null = null;

  public holeOptions: {
    index: number;
    mainGeometry: Polygon;
  } | null = null;

  private finishDrawingHandler: VoidFunction | null = null;

  private firstClickHandler: (({ lngLat }: MapPointerEvent) => void) | null =
    null;

  private startVertexHoverHandler:
    | (({ lngLat }: MapPointerEvent) => void)
    | null = null;

  private startVertexMouseOutHandler:
    | (({ lngLat }: MapPointerEvent) => void)
    | null = null;

  private currentGeometryOptions: GenericGeometryOptions<GeometryTypes> | null =
    null;

  private tempCoordinates: GenericCoordinates<GeometryTypes> | null = null;

  private mapService: MapService;

  private eventHandlers: {
    type: keyof MapEventTable;
    handler: (event: MapPointerEvent) => void;
  }[] = [];

  /**
   * Конструктор сервиса для рисования геометрии.
   *
   * @param mapService - Сервис карты.
   * @param geometryUtils - Утилиты для геометрий.
   */
  constructor(mapService: MapService, geometryUtils: GeometryUtils) {
    this.mapService = mapService;
    this.map = mapService.map;
    this.geometryUtils = geometryUtils;
  }

  /**
   * Начало рисовании геометрии. Должна вызываться
   * при первом добавлении точки в геометрию. Координаты
   * должы состоять из минимум 2х точек для полигона или
   * линии.
   *
   * @param geometryType - Тип геометрии.
   * @param options - Опции геометрии.
   * @param holeOptions - Опции для добавления дыры.
   * @returns {this}
   */
  public startDrawing<Type extends GeometryTypes>(
    geometryType: Type,
    options: GenericGeometryOptions<Type> & {
      onChange?: DrawEventHandler;
      onPointAdded?: DrawEventHandler;
      onDrawFinish?: DrawEventHandler;
    },
    holeOptions: this['holeOptions'] = null,
  ) {
    const { onDrawFinish } = options;
    options.userData.isEditing = true;
    options.userData.isChanging = true;

    this.holeOptions = holeOptions;
    this.currentGeometryOptions = {
      ...GeometryFactory.changingOptions[
        geometryType === GeometryTypes.Polygon
          ? 'Polygon'
          : geometryType === GeometryTypes.Polyline
          ? 'Polyline'
          : 'Marker'
      ],
      ...options,
    };
    this.drawingType = geometryType;

    if (
      geometryType === GeometryTypes.Point &&
      this.mapService.selectedLayerType
    ) {
      this.currentGeometry =
        this.mapService.layers[this.mapService.selectedLayerType][
          GeometryTypes.Point
        ][0];
      if (!this.currentGeometry) {
        this.currentGeometry = this.mapService.createGeometry(geometryType, {
          ...(this.currentGeometryOptions! as MarkerOptions),
          icon: getMarkerIcons(this.currentGeometryOptions!.userData.type_id)
            .hoverIcon,
        });

        (this.currentGeometry as Marker).setIcon({
          ...GeometryFactory.hoveredOptions.Marker,
          icon: getMarkerIcons(this.currentGeometryOptions!.userData.type_id)
            .hoverIcon,
        });
      }
    }

    this.vertex = new Vertex(
      this.currentGeometry,
      [],
      [0, 0],
      this.mapService.map,
      0,
      0,
      this.mapService,
      this.geometryUtils,
    );

    /**
     * Обработчик для события mousehover на начальной вершине.
     *
     * @param event - Событие mousemove.
     * @param event.lngLat - Координаты точки.
     * @returns {void}
     */
    const handleStartVertexHover = ({ lngLat }: MapPointerEvent) => {
      if (
        this.startVertex &&
        GeometryUtils.isPointWithinPolygon(
          lngLat,
          this.startVertex.shape.coordinatesToCheckMouseEvent,
        )
      ) {
        this.isStartVertexHovered = true;

        this.draw(this.startVertex.coordinates);

        if (this.vertex && this.vertex.isVisible) {
          this.vertex?.setIsVisible(false);
        }

        if (this.startVertex && !this.startVertex.isVisible) {
          this.startVertex?.setIsVisible(true);
        }
      }
    };

    this.startVertexHoverHandler = handleStartVertexHover;

    /**
     * Обработчик для события mouseoute на начальной вершине.
     *
     * @param event - Событие mousemove.
     * @param event.lngLat - Координаты точки.
     * @returns {void}
     */
    const handleStartVertexMouseOut = ({ lngLat }: MapPointerEvent) => {
      if (
        this.startVertex &&
        !GeometryUtils.isPointWithinPolygon(
          lngLat,
          this.startVertex.shape.coordinatesToCheckMouseEvent,
        )
      ) {
        this.isStartVertexHovered = false;

        this.draw(lngLat);

        if (this.vertex && !this.vertex.isVisible) {
          this.vertex?.setIsVisible(true);
        }

        if (this.startVertex && this.startVertex.isVisible) {
          this.startVertex?.setIsVisible(false);
        }
      }
    };

    this.startVertexMouseOutHandler = handleStartVertexMouseOut;

    /**
     * Обработчик для события mousemove на вершине.
     *
     * @param event - Событие mousemove.
     * @param event.lngLat - Координаты точки.
     */
    const handleVertexMove = ({ lngLat }: MapPointerEvent) => {
      this.vertex?.onChange({ coordinates: lngLat });
    };

    /**
     * Обработчик для события mousemove на карте.
     *
     * @param event - Событие mousemove.
     * @param event.lngLat - Координаты точки.
     */
    const handleMouseMove = ({ lngLat }: MapPointerEvent) => {
      if (this.drawingType !== GeometryTypes.Marker) {
        this.draw(lngLat);
      } else {
        this.addPoint(lngLat);
      }
    };

    /**
     * Обработчик для события click на карте.
     *
     * @param event - Событие click.
     * @param event.lngLat - Координаты точки.
     */
    const handleClick = ({ lngLat }: MapPointerEvent) => {
      if (geometryType !== GeometryTypes.Point && this.isStartVertexHovered) {
        finishDrawing();
      } else {
        this.draw(lngLat, true);
      }
    };

    /**
     * Обработчик для первого клика по карте.
     *
     * @param event - Событие click.
     * @param event.lngLat - Координаты точки.
     */
    const handleFirstClick = ({ lngLat }: MapPointerEvent) => {
      if (
        geometryType === GeometryTypes.Polygon ||
        geometryType === GeometryTypes.Polyline
      ) {
        this.tempCoordinates = [lngLat, lngLat];

        this.startVertex = new Vertex(
          this.currentGeometry,
          [],
          lngLat,
          this.mapService.map,
          0,
          0,
          this.mapService,
          this.geometryUtils,
          { isAppended: true, isVisible: false },
        );

        this.map?.on('mousemove', handleStartVertexHover);
        this.map?.on('mousemove', handleStartVertexMouseOut);

        this.eventHandlers.push(
          { handler: handleStartVertexHover, type: 'mousemove' },
          { handler: handleStartVertexMouseOut, type: 'mouseout' },
        );

        this.currentGeometryOptions = {
          ...this.currentGeometryOptions,
          ...options,
          coordinates: [...this.tempCoordinates],
          // @ts-ignore
          userData: {
            // @ts-ignore
            ...options.userData,
            // @ts-ignore
            coordinates: [...this.tempCoordinates],
          },
          zIndex: 10000,
        };

        this.tempCoordinates = [lngLat];
      }

      this.currentGeometry = this.mapService.createGeometry(
        // @ts-ignore
        geometryType as GeometryTypes.Polygon | GeometryTypes.Point,
        this.currentGeometryOptions! as PolygonOptions,
      );

      this.eventHandlers = this.eventHandlers.filter(
        ({ handler }) => handler !== handleFirstClick,
      );
      this.map?.off('click', handleFirstClick);

      this.eventHandlers.push({
        handler: handleClick,
        type: 'click',
      });
      this.map?.on('click', handleClick);
    };

    this.firstClickHandler = handleFirstClick;

    this.eventHandlers.push(
      {
        handler: handleMouseMove,
        type: 'mousemove',
      },
      {
        handler: handleVertexMove,
        type: 'mousemove',
      },
    );

    this.map?.on('mousemove', handleVertexMove);
    this.map?.on('mousemove', handleMouseMove);

    if (geometryType === GeometryTypes.Point) {
      this.eventHandlers.push({
        handler: handleClick,
        type: 'click',
      });
      this.map?.on('click', handleClick);
    } else {
      this.eventHandlers.push({
        handler: handleFirstClick,
        type: 'click',
      });
      this.map?.on('click', handleFirstClick);
    }

    const mapContainer = this.map?.getContainer();

    /**
     * Обработчик для события двойного клика по карте.
     * Завершает рисование геометрии.
     *
     * @returns {void}
     */
    const finishDrawing = () => {
      const currentZoom = this.map?.getZoom();

      const { drawingType } = this;

      if (drawingType === GeometryTypes.Polygon) {
        if (!this.tempCoordinates || this.tempCoordinates.length < 2) return;

        if (
          this.tempCoordinates[0] !==
          this.tempCoordinates[this.tempCoordinates.length - 1]
        ) {
          const currentCoordinates = this.currentGeometry?.userData
            .coordinates[0] as number[][];
          this.addPoint(currentCoordinates[currentCoordinates.length - 1]);
          this.draw(this.tempCoordinates[0] as number[], true);
        }
      }

      this.vertex?.destroy();
      this.vertex = null;

      this.map?.setMaxZoom(currentZoom!);

      this.eventHandlers = this.eventHandlers.filter(
        ({ handler }) =>
          handler !== handleVertexMove &&
          handler !== handleMouseMove &&
          handler !== handleClick &&
          handler !== handleStartVertexHover &&
          handler !== handleStartVertexMouseOut,
      );
      this.map?.off('mousemove', handleVertexMove);
      this.map?.off('mousemove', handleMouseMove);

      this.map?.off('click', handleClick);

      this.map?.off('mousemove', handleStartVertexHover);
      this.map?.off('mousemove', handleStartVertexMouseOut);
      mapContainer?.removeEventListener('dblclick', finishDrawing);

      this.map?.setMaxZoom(30);

      const currentGeometry = this.finishDrawing();

      if (!currentGeometry) return;

      const newGeometryType = (currentGeometry as RawGeometry).userData.type;
      const newGeometryLayerType = (currentGeometry as RawGeometry).userData
        .layerType;

      if (drawingType !== GeometryTypes.Point) {
        const geometryToAdd: GeometryToDraw<GeometryTypes.Polygon> = {
          options: {
            coordinates: currentGeometry.userData.coordinates as number[][][],
            mapService: this.mapService,
            userData: {
              ...currentGeometry.userData,
              coordinatesToCheckMouseEvent:
                currentGeometry.userData.coordinates,
              id: v4(),
              isChanging: false,
              isClicked: false,
              isHovered: false,
              isSelected: true,
              oghObjectId: this.mapService.selectedId,
            } as UserData<GeometryTypes.Polygon>,
          },
          type: newGeometryType as GeometryTypes.Polygon,
        };

        this.mapService.updateGeometriesData({
          [newGeometryLayerType]: [
            ...(this.holeOptions
              ? // @ts-ignore
                this.mapService.geometriesData[newGeometryLayerType][
                  newGeometryType
                  // @ts-ignore
                ].map((geometry) => {
                  return this.mapService.mainGeometryForHole?.userData.id ===
                    geometry.options.userData.id
                    ? geometryToAdd
                    : geometry;
                })
              : // @ts-ignore
                this.mapService.geometriesData[newGeometryLayerType][
                  newGeometryType
                ].concat(geometryToAdd)),
            ...(newGeometryType === GeometryTypes.Polyline
              ? // @ts-ignore
                this.mapService.geometriesData[newGeometryLayerType][
                  GeometryTypes.Polygon
                ]
              : // @ts-ignore
                this.mapService.geometriesData[newGeometryLayerType][
                  GeometryTypes.Polyline
                ]),
            // @ts-ignore
            ...this.mapService.geometriesData[newGeometryLayerType][
              GeometryTypes.Point
            ],
          ],
        });

        this.mapService.drawGeometries({ method: 'replaceAll' });
        currentGeometry.destroy();
      } else {
        const geometryToAdd: GeometryToDraw<GeometryTypes.Point> = {
          options: {
            coordinates: currentGeometry.userData.coordinates as number[],
            mapService: this.mapService,
            userData: {
              ...currentGeometry.userData,
              coordinatesToCheckMouseEvent:
                currentGeometry.userData.coordinates,
              id: v4(),
              isChanging: false,
              isClicked: false,
              isHovered: false,
              isSelected: true,
              oghObjectId: this.mapService.selectedId,
            } as UserData<GeometryTypes.Point>,
          },
          type: newGeometryType,
        };

        this.mapService.updateGeometriesData({
          [newGeometryLayerType]: [
            // @ts-ignore
            ...this.mapService.geometriesData[newGeometryLayerType][
              GeometryTypes.Polygon
            ],
            // @ts-ignore
            ...this.mapService.geometriesData[newGeometryLayerType][
              GeometryTypes.Polyline
            ],
            geometryToAdd,
          ],
        });

        this.mapService.drawGeometries({ method: 'replaceAll' });
        currentGeometry.destroy();
      }

      this.holeOptions = null;

      onDrawFinish?.(currentGeometry);
    };

    this.finishDrawingHandler = finishDrawing;

    mapContainer?.addEventListener('dblclick', finishDrawing);

    return this;
  }

  /**
   * Завершает рисование геометрии.
   *
   * @returns {RawGeometry} - Геометрия.
   */
  public finishDrawing() {
    if (this.currentGeometry) {
      const factoryType =
        this.drawingType === GeometryTypes.Polygon
          ? 'Polygon'
          : this.drawingType === GeometryTypes.Polyline
          ? 'Polyline'
          : 'Marker';

      let newOptions = {
        ...this.currentGeometryOptions,
        ...GeometryFactory.selectedOptions[factoryType],
        coordinates: this.currentGeometry!.userData.coordinates,
        userData: {
          ...this.currentGeometryOptions?.userData,
          coordinates: this.currentGeometry!.userData.coordinates,
          id:
            this.drawingType === GeometryTypes.Polyline
              ? layerStyles.selected.id
              : this.currentGeometry!.userData.id,
          isChanging: false,
          isEditing: false,
          isSelected: true,
        },
      };

      if (this.holeOptions && this.drawingType === GeometryTypes.Polygon) {
        const { mainGeometry, index } = this.holeOptions;
        mainGeometry.destroy();

        const newCoordinates = [...mainGeometry.userData.coordinates];

        if (this.currentGeometry?.userData.coordinates?.[0]) {
          newCoordinates[index] = this.currentGeometry?.userData
            .coordinates[0] as number[][];
        }

        newOptions = {
          ...newOptions,
          coordinates: newCoordinates,
          userData: {
            ...newOptions?.userData,
            coordinates: newCoordinates,
          },
        };
      }

      this.currentGeometry?.destroy();

      this.currentGeometry = this.mapService.createGeometry(
        // @ts-ignore
        this.drawingType!,
        newOptions,
      );

      const { currentGeometry } = this;

      this.currentGeometry = null;
      this.currentGeometryOptions = null;
      this.tempCoordinates = null;
      this.drawingType = null;

      this.vertex?.destroy();
      this.vertex = null;

      this.startVertex?.destroy();
      this.startVertex = null;

      return currentGeometry;
    }

    return null;
  }

  /**
   * Уничтожает все элементы редактора на карте.
   *
   * @param onDestroy - Функция вызываемая при уничножении ShapeEditor'a.
   */
  public destroy(onDestroy?: VoidFunction) {
    this.cancelDrawing();
    onDestroy?.();
  }

  /**
   * Отменяет рисование геометрии.
   *
   * @returns This.
   */
  public cancelDrawing() {
    this.currentGeometry?.destroy();
    if (this.drawingType !== GeometryTypes.Point) {
      // this.currentGeometry?.destroy();
    } else {
      (this.currentGeometry as Marker)?.setIcon({
        ...(this.mapService.selectedLayerType === 'parent'
          ? GeometryFactory.parentOptions.Marker
          : GeometryFactory.childOptions.Marker),
        icon: getMarkerIcons(this.mapService.selectedLayerType).icon,
      });
    }

    this.vertex?.destroy();
    this.currentGeometry = null;
    this.tempCoordinates = null;
    this.drawingType = null;

    if (this.finishDrawingHandler) {
      this.map
        ?.getContainer()
        ?.removeEventListener('dblclick', this.finishDrawingHandler);
    }

    if (this.firstClickHandler)
      this.map?.off('mousemove', this.firstClickHandler);
    if (this.startVertexHoverHandler)
      this.map?.off('mousemove', this.startVertexHoverHandler);
    if (this.startVertexMouseOutHandler)
      this.map?.off('mousemove', this.startVertexMouseOutHandler);

    this.eventHandlers.forEach(({ type, handler }) => {
      // @ts-expect-error
      this.map?.off(type, handler);
    });

    return this;
  }

  /**
   * Рисует геометрию.
   *
   * @param lngLat - Координаты точки.
   * @param shouldAppendPoint - Нужно ли добавить точку в координаты.
   * @returns This.
   */
  public draw(
    lngLat: MapPointerEvent['lngLat'],
    shouldAppendPoint: boolean = false,
  ) {
    if (
      this.currentGeometryOptions?.userData.type !== GeometryTypes.Marker &&
      (!this.currentGeometry || !this.tempCoordinates)
    ) {
      return this;
    }

    if (shouldAppendPoint) this.addPoint(lngLat);

    if (this.currentGeometryOptions?.userData.type === GeometryTypes.Polyline) {
      this.updateLine(shouldAppendPoint ? undefined : lngLat);
      return this;
    }

    if (this.currentGeometryOptions?.userData.type === GeometryTypes.Polygon) {
      this.updatePolygon(shouldAppendPoint ? undefined : lngLat);
      return this;
    }

    if (this.currentGeometryOptions?.userData.type === GeometryTypes.Marker) {
      this.updateMarker(lngLat);
      return this;
    }

    return this;
  }

  /**
   * Обновляет линию.
   *
   * @param additionalPoint - Дополнительная точка для добавления в координаты.
   * @returns This.
   */
  public updateLine(additionalPoint?: GenericCoordinates<GeometryTypes.Point>) {
    if (!this.tempCoordinates) return this;

    const newCoordinates = (
      additionalPoint
        ? [...this.tempCoordinates, additionalPoint]
        : this.tempCoordinates
    ) as GenericCoordinates<GeometryTypes.Polyline>;
    const currentGeometryOptions = this
      .currentGeometryOptions as GenericGeometryOptions<GeometryTypes.Polyline>;

    this.currentGeometry?.destroy();
    this.currentGeometry = this.mapService.createGeometry<Polyline>(
      GeometryTypes.Polyline,
      {
        ...(this
          .currentGeometryOptions as GenericGeometryOptions<GeometryTypes.Polyline>),
        coordinates: newCoordinates,
        userData: {
          ...currentGeometryOptions?.userData,
          coordinates: newCoordinates,
        },
      },
    );

    return this;
  }

  /**
   * Обновляет полигон.
   *
   * @param additionalPoint - Дополнительная точка для добавления в координаты.
   * @returns {this}
   */
  public updatePolygon(
    additionalPoint?: GenericCoordinates<GeometryTypes.Point>,
  ) {
    if (!this.tempCoordinates) return this;

    const newCoordinates = (
      additionalPoint
        ? [[...this.tempCoordinates, additionalPoint]]
        : [this.tempCoordinates]
    ) as GenericCoordinates<GeometryTypes.Polygon>;
    const currentGeometryOptions = this
      .currentGeometryOptions as GenericGeometryOptions<GeometryTypes.Polygon>;

    this.currentGeometry?.destroy();
    this.currentGeometry = this.mapService.createGeometry(
      // @ts-ignore
      GeometryTypes.Polygon,
      {
        ...currentGeometryOptions,
        coordinates: newCoordinates,
        userData: {
          ...currentGeometryOptions?.userData,
          coordinates: newCoordinates,
        },
      },
    );

    return this;
  }

  /**
   * Обновляет маркер.
   *
   * @param newCoordinates - Дополнительная точка для добавления в координаты.
   * @returns {this}
   */
  public updateMarker(
    newCoordinates?: GenericCoordinates<GeometryTypes.Point>,
  ) {
    if (!this.currentGeometry) return this;

    if (isGeometryPoint(this.currentGeometry)) {
      this.currentGeometry.setCoordinates(
        newCoordinates ??
          (this.tempCoordinates as GenericCoordinates<GeometryTypes.Point>),
      );
      if (newCoordinates) {
        this.currentGeometry.userData.coordinates = newCoordinates;
        this.currentGeometry.userData.recalcHoverArea?.();
      }

      const markerLayerType = this.currentGeometry.userData.layerType;
      const { currentGeometry } = this;

      const geometryToAdd: GeometryToDraw<GeometryTypes.Point> = {
        options: {
          coordinates: currentGeometry.userData.coordinates,
          mapService: this.mapService,
          userData: {
            ...currentGeometry.userData,
            coordinatesToCheckMouseEvent: sizeInPixelsToCoords(
              this.map!,
              currentGeometry.userData.coordinates,
            ),
            id: v4(),
            isChanging: false,
            isClicked: false,
            isHovered: false,
            isSelected: true,
            oghObjectId: this.mapService.selectedId,
          },
        },
        type: GeometryTypes.Point,
      };

      this.mapService.updateGeometriesData({
        [markerLayerType]: [
          ...this.mapService.geometriesData[markerLayerType][
            GeometryTypes.Polygon
          ],
          ...this.mapService.geometriesData[markerLayerType][
            GeometryTypes.Polyline
          ],
          geometryToAdd,
        ],
      });
    } else {
      log.warn(
        `Current geometry is not a Marker, but a ${this.currentGeometry.userData.type}`,
      );
    }

    return this;
  }

  /**
   * Добавляет точку в координаты.
   *
   * @param point - Точка.
   * @returns {this}
   */
  private addPoint(point: number[]) {
    const isMarker = this.drawingType === GeometryTypes.Marker;
    if (!isMarker && (!this.currentGeometry || !this.tempCoordinates)) {
      return this;
    }

    if (isMarker) {
      this.tempCoordinates = point;
      return this;
    }

    if (
      this.currentGeometry &&
      (isGeometryPolygon(this.currentGeometry) ||
        isGeometryPolyline(this.currentGeometry))
    ) {
      this.addPointToPolylineCoordinates(point);
      return this;
    }

    return this;
  }

  /**
   * Добавляет точку в координаты линии.
   *
   * @param point - Точка.
   * @returns {this}
   */
  private addPointToPolylineCoordinates(point: number[]) {
    if (!this.tempCoordinates) return this;

    (this.tempCoordinates as GenericCoordinates<GeometryTypes.Polyline>)?.push(
      point,
    );

    return this;
  }
}
