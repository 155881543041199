import { OkrugBridgeIdsFF } from 'core/form/finalForm/fields/prepared/selects/multiSelect/OkrugBridgeIds.FF';
import { OKRUG_BRIDGE_IDS_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/OkrugBridgeIds';
import React from 'react';
import { NumericArrayParam } from 'use-query-params';

import { filterInput } from '../../filterInputsClasses';

/**
 * Фильтр __Округ_.
 *
 * @returns {JSX.Element}
 */
export const OkrugBridgeIdsFilter = () => {
  return <OkrugBridgeIdsFF className={filterInput} />;
};

export const okrugBridgeIdsParams = {
  [OKRUG_BRIDGE_IDS_NAME]: NumericArrayParam,
};
