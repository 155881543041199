import { toast } from 'core/uiKit/components/Toast';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

import { postSendNoCalc } from '../api/postSendNoCalc';

/**
 * Получение метода sendNoCalc.
 *
 * @returns Данные и функция.
 */
export const useSendNoCalc = () => {
  const [sendNoCalc, params] = useMutationAdaptor(postSendNoCalc, {

    /**
     * Fn.
     *
     * @param res - Response.
     * @param res.message - Message.
     */
    onSuccess(res: { message: string }) {
      toast.successDying(res?.message);
    },
  });

  return { isLoadingSend: params.isLoading, sendNoCalc, ...params };
};
