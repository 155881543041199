import './ShowAlert.scss';

import { Button } from 'core/uiKit/components/buttons/Button';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import React from 'react';

/**
 * ShowAlert.
 *
 * @param root0 - Properties.
 * @param root0.alert - Предупреждение.
 * @param root0.setAlert - Функция закрытия диалога.
 * @returns JSX.Element.
 */
export const ShowAlert = ({
  alert,
  setAlert,
}: {
  alert: string | React.ReactElement;
  setAlert: (alert: string | React.ReactElement) => void;
}) => {

  /**
   * Close the dialog.
   *
   */
  const closeDialog = () => {
    setAlert('');
  };

  return (
    <Dialog
      isOpen={Boolean(alert)}
      maxWidth={'sm'}
      Body={
        <div className={'alert-crete-update-card'}>
          <pre>{alert}</pre>
          <div className={'alert-crete-update-card__buttons__wrapper'}>
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={closeDialog}
            >
              Закрыть
            </Button>
          </div>
        </div>
      }
    />
  );
};
