import { GrbsIdMulti } from 'core/uiKit/preparedInputs/selects/multiSelect/GrbsIdMulty/GrbsIdMulty';
import React from 'react';

 
/**
 * Компонент обертка для Final Form.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Параметры инпута.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element} - JSX.
 * @example ----
 */
export const GrbsIdMultiWithFF = ({
  input,
  ...otherProps
}) => {
  return <GrbsIdMulti {...otherProps} {...input} />;
};
