import {
  OZN_TYPE_ID_NAME,
  OznTypeID,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/OznTypeID';
import React from 'react';
import { Field } from 'redux-form';

/**
 *  Компонент ReasonDeleteRFWithReduxForm.
 *
 * @param {object} props - Properties.
 * @param {object} props.input - Input.
 * @param {object} props.props - Other properties.
 * @returns {JSX.Element}
 */
export const ReasonDeleteRFWithReduxForm = ({ input, ...props }) => {
  return <OznTypeID {...input} {...props} />;
};

/**
 * OznTypeIDRF.
 *
 * @param {object} props - Properties.
 * @param {boolean} [props.disabled] - Disabled.
 * @param {object} props.otherProps - Other properties.
 * @returns {JSX.Element}
 */
export const OznTypeIDRF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field
      disabled={disabled}
      {...otherProps}
      name={OZN_TYPE_ID_NAME}
      component={ReasonDeleteRFWithReduxForm}
    />
  );
};

export { OZN_TYPE_ID_NAME };
