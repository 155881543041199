import { useEffect } from 'react';

/**
 * Хук эффект, Добавляем класс 'grabbing' к body при перетаскивании.
 *
 * @param isDragging - Флаг указывающий на начало и конец перетаскивания перемещения.
 */
export const useGrabbingOnDraggingEffect = (isDragging: boolean) => {
  useEffect(() => {
    if (isDragging) {
      document.body.classList.add('grabbing');
    }
    return () => {
      document.body.classList.remove('grabbing');
    };
  }, [isDragging]);
};
