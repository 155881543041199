import PaperMUI from '@mui/material/Paper';
import { ThemeProvider } from 'core/uiKit/material-ui';
import React, { FC } from 'react';

import { useCreatePaperTheme } from './useCreatePaperTheme';

interface PaperProps {

  /**
   * Class css.
   */
  className?: string;

  /**
   * Флаг отвечающий за темную тему.
   */
  dark?: boolean;

  /**
   * Закругленные края.
   */
  rounded?: boolean;

  /**
   * Поднятие.
   */
  elevation?:
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24;
  children: React.ReactNode;
}

/**
 * Paper.
 *
 * @param {PaperProps} props - Properties.
 * @param {string} props.className - Класс стилей.
 * @param {boolean} props.dark - Флаг отвечающий за темную тему.
 * @param {boolean} props.rounded - Закругленные края.
 * @param {{}} props.otherProps - Other properties.
 * @returns {JSX.Element}
 */
const Paper: FC<PaperProps> = ({ dark, className, rounded, ...otherProps }) => {
  const themePaper = useCreatePaperTheme({ dark });

  return (
    <ThemeProvider theme={themePaper}>
      <PaperMUI
        {...otherProps}
        square={!rounded}
        className={className + (dark ? ' dark' : '')}
      />
    </ThemeProvider>
  );
};

export { Paper };
