import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useDataTableDelete } from 'app/pages/cardsOgh/components/tables/TableDelete/useDataTableDelete';
import { Button } from 'core/uiKit/components';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import React, { useState } from 'react';

import { BodyDialogEditButton } from './Body.DialogEditButton';
import { changeIds } from './helpers/changeIds';
import { getIds } from './helpers/getIds';
import { useSaveDialogEditButton } from './hooks/useSave.DialogEditButton';
import { useSendNoCalc } from './hooks/useSendNoCalc';
import { ListDataType } from './listDataType';

const message = {
  remove:
    'Выбранные элементы будут исключены из текущего ОГХ. Подтвердите операцию.',
  sendNoCalc:
    'Подтвердите, пожалуйста, внесение изменений в выбранные элементы.',
};

interface DialogEditButtonType {
  isOpen: boolean;
  listData: ListDataType;
  closeDialog: () => void;
  openContinueDialog?: (message: string, send: () => void) => void;
}

/**
 * Компонент  диалогового окна кнопки изменить.
 *
 * @param {object} props - Пропсы.
 * @param {Function} [props.openContinueDialog] - Функция вызова диалогового окна.
 * @param {boolean} props.isOpen - Флаг isOpen.
 * @param {ListDataType} props.listData - Данные списка.
 * @param {Function} props.closeDialog - Функция закрытия окна.
 * @returns {JSX.Element} - Возвращает компонент Диалогового окна.
 */
export const DialogEditButton = ({
  closeDialog,
  isOpen,
  listData,
  openContinueDialog,
}: DialogEditButtonType) => {
  const [ids, setIds] = useState(getIds(listData));
  const [selectRowsTableDelete, setSelectRowsTableDelete] = useState<number[]>(
    [],
  );
  const [valuesFilter, setValuesFilter] = useState({});

  const { sendNoCalc, isLoadingSend } = useSendNoCalc();
  const parent = useGetParentCard();
  const { isLoading, table, count } = useDataTableDelete({
    ids,
    listData,
    valuesFilter,
  });

  const save = useSaveDialogEditButton({
    closeDialog,
    ids,
    listData,
  });

  /**
   * Save the.
   *
   * @param formValues - Form values .
   * @returns {void}
   */
  const handleRegistryFormSubmit = (formValues: object) => {
    setValuesFilter(formValues);
  };

  /**
   * Fn.
   *
   * @returns {void}
   */
  const account = () => {

    /**
     * Fn.
     *
     * @returns {void}
     */
    const send = () => {
      sendNoCalc({
        changeAttribute: {
          noCalc: 0,
        },
        childIdsList: selectRowsTableDelete,
        parentId: parent.recordId,
      });
    };

    openContinueDialog?.(message.sendNoCalc, send);
  };

  /**
   * Fn.
   *
   * @returns {void}
   */
  const notAccount = () => {

    /**
     * Fn.
     *
     * @returns {void}
     */
    const send = () => {
      sendNoCalc({
        changeAttribute: {
          noCalc: 1,
        },
        childIdsList: selectRowsTableDelete,
        parentId: parent.recordId,
      });
    };

    openContinueDialog?.(message.sendNoCalc, send);
  };

  /**
   * Fn.
   *
   * @returns {void}
   */
  const removeRow = () => {
    openContinueDialog?.(
      message.remove,
      changeIds({ ids, selectRowsTableDelete, setIds }),
    );
  };

  return (
    <Dialog
      isOpen={isOpen}
      maxWidth={'lg'}
      textHeader={'Групповое изменение элементов паспорта ОГХ'}
      Body={
        <BodyDialogEditButton
          {...{
            account,
            count,
            handleRegistryFormSubmit,
            isLoading,
            isLoadingSend,
            listData,
            notAccount,
            removeRow,
            selectRowsTableDelete,
            setSelectRowsTableDelete,
            table,
          }}
        />
      }
      ButtonsActions={
        <>
          <Button
            className={'mx-1'}
            color={'primary'}
            onClick={closeDialog}
            variant={'outlined'}
          >
            Закрыть
          </Button>
          <Button color={'primary'} onClick={save} variant={'contained'}>
            Готово
          </Button>
        </>
      }
    />
  );
};
