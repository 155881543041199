import { Skeleton } from 'core/uiKit/components/skeletons/Skeleton';
import {
  LinearProgress,
  TableBody,
  TableCell,
  TableRow,
} from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';

/**
 * Скелетон для тела таблицы ReactTable.
 *
 * @param {*} props - Пропсы.
 * @param {*} props.countRows - Число рядов.
 * @returns {JSX.Element}
 */
export const ReactTableBodySkeleton = ({ countRows = 2 }) => {
  const rows = Array(countRows).fill(1);
  return (
    <TableBody>
      <TableRow key={`TableRow`}>
        <td key={`TableCell`} colSpan={50}>
          <LinearProgress />
        </td>
      </TableRow>
      {rows.map((_, idx) => (
        <TableRow key={`TableRow-${idx}`}>
          <TableCell colSpan={50}>
            <Skeleton key={+new Date()} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

ReactTableBodySkeleton.propTypes = {

  /**
   * Данные для формирования нужного количества ячеек.
   */
  columns: PropTypes.array,

  /**
   * Нужное количество строчек.
   */
  countRows: PropTypes.number,
};
