import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as TimePickerMUI } from '@mui/x-date-pickers/TimePicker';
import { parseTimeMomentToString } from 'app/utils/time/parseTimeMomentToString';
import { parseTimeStringToMoment } from 'app/utils/time/parseTimeStringToMoment';
import { TextField } from 'core/uiKit/inputs/TextField';
import { Moment } from 'moment';
import React, { useState } from 'react';

/**
 * Интерфейс для пропсов компонента TimePicker.
 */
interface ITimePicker {
  label?: string; // Опциональный заголовок поля
  helperText?: string; // Текст помощник
  maxTime?: string; // Максимально возможно время
  minTime?: string; // Минимально возможное время
  required?: boolean; // Флаг обязательности поля
  value: string; // Значение времени в виде строки
  onChange: (value: string) => void; // Обработчик изменения значения
  meta: {
    submitError?: string; // Ошибки формы при сабмите
    error?: boolean;
    submitFailed: boolean;
  };
}

/**
 * Поле выбора времени.
 *
 * @param props - Пропсы компонента.
 * @param props.required - Флаг обязательности.
 * @param props.label - Заголовок поля.
 * @param props.value - Время строковый формат.
 * @param props.onChange - Функция изменения времени.
 * @param props.helperText - Текст помощник.
 * @param props.maxTime - Максимально возможное время.
 * @param props.minTime - Минимально возможное время.
 * @param props.meta - Мета данные формы.
 * @returns JSX элемент.
 */
export const TimePicker: React.FC<ITimePicker> = ({
  required,
  label,
  value,
  onChange,
  helperText,
  maxTime,
  minTime,
  meta,
  ...otherProps
}) => {
  const [error, setError] = useState('');

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'ru'}>
      <TimePickerMUI
        label={label}
        value={parseTimeStringToMoment(value)}
        onChange={(newTime: Moment | null) => {
          onChange(parseTimeMomentToString(newTime)); // Преобразуем обратно в строку
        }}
        renderInput={(params) => {
          return (
            // @ts-ignore
            <TextField
              {...params}
              required={required}
              helperText={meta?.submitError || error || helperText}
              error={params.error || meta.submitFailed}
            />
          );
        }}
        maxTime={parseTimeStringToMoment(maxTime)}
        minTime={parseTimeStringToMoment(minTime)}
        onError={(reason) => {
          switch (reason) {
            case 'maxTime': {
              setError(`не может быть больше ${maxTime}`);
              return;
            }
            case 'minTime': {
              setError(`не может быть меньше ${minTime}`);
              return;
            }
            case 'invalidDate': {
              setError('не валидное время');
              return;
            }
            default: {
              setError('');
              return;
            }
          }
        }}
        {...otherProps}
      />
    </LocalizationProvider>
  );
};
