import destroyMiddleware from 'app/middlewares/destroyMiddleware';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';

import * as reducers from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * Configures the redux store.
 *
 * @param {object} initialState - Initial state.
 * @returns {object} Store.
 */
export default function configureStore(initialState) {
  const enhancer = composeEnhancers(
    applyMiddleware(thunkMiddleware, promiseMiddleware, destroyMiddleware),
  );

  const store = createStore(
    combineReducers({
      ...reducers,
    }),
    initialState,
    enhancer,
  );

  // if (module.hot) {
  //   // Enable Webpack hot module replacement for reducers
  //   module.hot.accept('./reducers', () => {
  //     const nextRootReducer = require('./reducers/index');
  //     store.replaceReducer(nextRootReducer);
  //   });
  // }

  return store;
}
