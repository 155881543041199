import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const ICE_RINK_TYPE_ID_NAME = 'ice_rink_type_id';

/**
 * Select Тип катка.
 *
 * @param props - Properties.
 * @returns JSX.Element.
 */
export const IceRinkTypeId = (props: SelectPropsType) => {
  const dict = 'ice_rink_type';
  const { data = [] } = useGetDictionary<
    {
      id: number;
      name: string;
    }[]
  >(dict);

  return (
    <Select
      {...{
        ...props,
        label: 'Тип катка',
        options: data,
      }}
      name={ICE_RINK_TYPE_ID_NAME}
    />
  );
};
