import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  COATING_TYPE_ID_NAME,
  CoatingTypeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingTypeId';
import React from 'react';
import { SelectFFPreparedPropsType } from 'types/inputs';

import { usePreparationCoatingTypeIdFF } from './usePreparation.CoatingTypeId.FF';

/**
 * Select FF __Вид покрытия (уточнение)__.
 *
 * @param props - Пропсы.
 * @returns JSX.Element.
 */
export const CoatingTypeIdFF = (props: SelectFFPreparedPropsType) => {
  usePreparationCoatingTypeIdFF();
  return (
    <FieldFF {...props} name={COATING_TYPE_ID_NAME} component={CoatingTypeId} />
  );
};
