import { TextField } from 'core/uiKit/inputs/TextField';
import PropTypes from 'prop-types';
import React from 'react';

export const PASSWORD_NAME = 'password';

/**
 * Пароль.
 *
 * `type = password
 * name = password`.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.label - Лейбел.
 * @returns {JSX}
 */
export const Password = ({ label = 'Пароль', ...otherProps }) => {
  return (
    <TextField
      {...otherProps}
      type={'password'}
      label={label}
      inputProps={{ autoComplete: 'off', 'data-testid': 'password' }}
      name={PASSWORD_NAME}
      dark={false}
    />
  );
};

Password.propTypes = {

  /**
   * Выбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение.
   */
  value: PropTypes.any,
};
