import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'core/uiKit/components/buttons/Button';
import React from 'react';

import { useSetupApproveElectronicSignatureButton } from './useSetup.ApproveElectronicSignature.Button';

interface ApproveElectronicSignatureButton {
  onClick: (event: React.MouseEvent) => void;
}

/**
 * Кнопка __Утвердить (ЭП)__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const ApproveElectronicSignatureButton = (
  props: ApproveElectronicSignatureButton,
) => {
  const { isView } = useSetupApproveElectronicSignatureButton();

  return isView ? (
    <Button
      {...props}
      color={'success'}
      variant={'contained'}
      startIcon={<FontAwesomeIcon icon={faFileSignature} />}
    >
      Утвердить (ЭП)
    </Button>
  ) : null;
};
