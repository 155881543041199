import { Cell } from 'types/table';

/**
 * Колонка __Район__.
 *
 * @type {{
 * accessor: 'district_bridge_name',
 * Header: 'Район',
 * sortable: boolean
 * }}
 * @augments Cell
 */
export const district_bridge_name = {
  Header: 'Район',
  accessor: 'district_bridge_name',
  sortable: true,
};
