import { Cell } from 'types/table';

/**
 * Колонка __Площадь уборки, кв.м (ручная)__.
 *
 * @type {{
 * accessor: 'manual_clean_area',
 * Header: 'Площадь уборки, кв.м (ручная)',
 * sortable: boolean
 * }}
 * @augments Cell
 */
export const manual_clean_area = {
  Header: 'Площадь уборки, кв.м (ручная)',
  accessor: 'manual_clean_area',
  sortable: true,
};
