import React from 'react';

import { ItemFileGridKnowledgeBase } from '../Item.FileGrid.KnowledgeBase';

interface File {
  file_id: string;
  file_name: string;
}

interface FileGridInterface {
  value: { file_id: string; file_name: string }[];

  /**
   * Флаг редактирования.
   */
  disabled?: boolean;
  onChange: (files: File[]) => void;
}

/**
 * Список файлов с удалением.
 *
 * @param {FileGridInterface} props - Пропсы.
 * @param props.files - Файлы.
 * @class
 */
export const FileGridKnowledgeBase = ({
  value: files,
  disabled,
  onChange,
}: FileGridInterface) => {

  /**
   * Функция удаления файла по id.
   *
   * @param fileId - Id файла.
   */
  const deleteFile = (fileId: string) => {
    const fileList = files.filter(({ file_id }) => file_id !== fileId);
    onChange(fileList);
  };

  return (
    <div className="display-flex flex-wrap gap-4 mt-4">
      {(files || []).map((item) => (
        <ItemFileGridKnowledgeBase
          key={item.file_id}
          file={item}
          disabled={disabled}
          deleteFile={deleteFile}
        />
      ))}
    </div>
  );
};
