import { CUSTOMER_NAME, CustomerFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { ObjectParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * Заказчик  customer.
 *
 * @param {object} props - Параметры компонента.
 * @returns {JSX.Element}
 */
export const CustomerFilter = (props) => {
  return <CustomerFF {...props} className={filterInput} />;
};

export { CUSTOMER_NAME };

/**
 * CustomerParams..
 *
 * @returns {{CUSTOMER_NAME: ObjectParam}}
 */
export const customerParams = () => {
  addPreparation(CUSTOMER_NAME, (value) => value.id);
  return {
    [CUSTOMER_NAME]: ObjectParam,
  };
};
