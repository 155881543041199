import { Coordinate, ILine, IPoint, IPolygon } from 'types/api';
type OptionalCrsLine = Omit<ILine, 'crs'> & { crs?: ILine['crs'] };
type OptionalCrsPoint = Omit<IPoint, 'crs'> & { crs?: IPoint['crs'] };
type OptionalCrsPolygon = Omit<IPolygon, 'crs'> & { crs?: IPolygon['crs'] };

/**
 * Оставляет только первые два значения в массиве координат, если массив содержит только числа.
 *
 * @param geometry - Объект геометрии (ILine, IPoint, IPolygon).
 * @returns Объект геометрии с обновленными координатами.
 */
export const trimCoordinates = <
  Type extends OptionalCrsLine | OptionalCrsPoint | OptionalCrsPolygon,
>(
  geometry: Type,
): Type => {
  //
  if (!geometry) return geometry;

  /**
   * Функция для проверки, содержит ли массив только числа.
   *
   * @param arr - Массив для проверки.
   * @returns True, если массив содержит только числа, false в противном случае.
   */
  const isNumberArray = (arr: unknown[]): boolean => {
    return arr.every((item) => typeof item === 'number');
  };

  /**
   * Функция для обрезки массива до двух элементов.
   *
   * @param arr - Массив для обрезки.
   * @returns Обрезанный массив.
   */
  const trimArray = (arr: number[]): Coordinate => {
    // return arr.slice(0, 2);
    return [arr[0], arr[1]]; // Гарантируем, что возвращается ровно два числа
  };

  // Обработка для ILine
  if (geometry.type === 'LineString') {
    const line = geometry as ILine;
    line.coordinates = line.coordinates.map((coord) => {
      if (isNumberArray(coord)) {
        return trimArray(coord);
      }
      return coord;
    });
  }

  // Обработка для IPoint
  if (geometry.type === 'Point') {
    const point = geometry as IPoint;
    if (isNumberArray(point.coordinates)) {
      point.coordinates = trimArray(point.coordinates);
    }
  }

  // Обработка для IPolygon
  if (geometry.type === 'Polygon') {
    const polygon = geometry as IPolygon;
    polygon.coordinates = polygon.coordinates.map((ring) => {
      return ring.map((coord) => {
        if (isNumberArray(coord)) {
          return trimArray(coord);
        }
        return coord;
      });
    });
  }

  return geometry;
};
