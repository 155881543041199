import { AbutmentTypeNameFF } from 'core/form/finalForm/fields/prepared/selects/simple/AbutmentTypeName.FF';
import React from 'react';

import { useSetupAbutmentTypeNameFFField } from './useSetup.AbutmentTypeName.FF.Field';

/**
 * Field __Тип элемента сопряжения__  "Элементы сопряжения поверхностей".
 *
 * @returns JSX.Element.
 */
export const AbutmentTypeNameFFField = () => {
  const { editMode, filter } = useSetupAbutmentTypeNameFFField();

  return (
    <AbutmentTypeNameFF disabled={!editMode} filter={filter} withAll={false} />
  );
};
