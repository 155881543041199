import { useCheckingItIsChildOfImprovementObject } from 'app/pages/registry/ogh/hooks/useCheckingItIsChildOfImprovementObject';
import {
  AddressFilter,
  addressParams,
  BuildingTypeFilter,
  buildingTypeParams,
  CustomerFilter,
  customerParams,
  FilterFF,
  OkrugFilter,
  okrugParams,
  OwnerIdFilter,
  ownerIdParams,
} from 'core/form/filterForm';
import {
  SubTypeIdFilter,
  subTypeIdParams,
} from 'core/form/filterForm/components/autocompleteAsync/SubTypeId.Filter';
import {
  DateFromFilter,
  dateFromParams,
} from 'core/form/filterForm/components/dateFilters/DateFromFilter';
import {
  DateToFilter,
  dateToParams,
} from 'core/form/filterForm/components/dateFilters/DateToFilter';
import {
  OghStatusesFilter,
  oghStatusesParams,
} from 'core/form/filterForm/components/miltiSelect/OghStatusesFilter';
import {
  GrbsIdFilter,
  grbsIdParams,
} from 'core/form/filterForm/components/selects/GrbsId.Filter';
import {
  IdOrNameFilter,
  idOrNameParams,
} from 'core/form/filterForm/components/text/IdOrName.Filter';
import {
  IdOrNameParentFilter,
  idOrNameParentParams,
} from 'core/form/filterForm/components/text/IdOrNameParent.Filter';
import { useFilterQueryParams } from 'core/form/filterForm/hooks/useFilterQueryParams';
import React from 'react';

/**
 * @typedef {object} FilterQueryParamsType - Данные QueryParams для фильтра формы FF.
 * @property {object} prepareFilters - Отформатированые данные для бэка.
 * @property {Function} setFilters - Функция для переноса состояния формы в урл.
 * @property {object} filters - Обычные данные формы.
 */

/**
 * Хук QueryParams для фильтра Объекты капитального строительства.
 *
 * @returns {FilterQueryParamsType} Фильтры.
 * @example --------
 * const { setFilters, filters, prepareFilters } = useFilterQueryParamsBoardStone()
 */
export const useFilterQueryParamsOtherOks = () => {
  return useFilterQueryParams([
    addressParams,
    subTypeIdParams,
    buildingTypeParams,
    customerParams,
    dateFromParams,
    dateToParams,
    grbsIdParams,
    idOrNameParams,
    idOrNameParentParams,
    oghStatusesParams,
    okrugParams,
    ownerIdParams,
  ]);
};

/**
 * Filter FF реестров __Объекты капитального строительства__.
 *
 * @param {object} params - Properties.
 * @param {number} params.parentTypeId - Id типа родителя реестра.
 * @param {number} params.typeId - Id типа огх.
 * @returns {JSX.Element} - JSX.
 * @example ---
 */
export const FilterOtherOks = ({ typeId, parentTypeId }) => {
  const { setFilters, filters } = useFilterQueryParamsOtherOks();
  const { isChildOfImprovementObject } =
    useCheckingItIsChildOfImprovementObject(parentTypeId);

  /**
   * Фукция submit фильтра формы FF.
   *
   * @param {object} values - Values.
   * @returns {void} - Nothing.
   * @example ---
   */
  const onSubmit = (values) => {
    setFilters(values, 'push');
  };

  /**
   * Функия сброса формы FF.
   *
   * @param {object} form - Параметры формы.
   * @param {Function} form.reset - Функция сброса формы FF.
   * @returns {void} - Nothing.
   * @example ---------
   */
  const reset = ({ reset }) => {
    reset();
    setFilters({}, 'replace');
  };

  return (
    <FilterFF {...{ initialValues: filters, onSubmit, reset }}>
      <IdOrNameFilter label={'ID'} />
      <IdOrNameParentFilter />
      <BuildingTypeFilter />
      <OghStatusesFilter />
      <DateFromFilter />
      <DateToFilter />
      <AddressFilter />
      <SubTypeIdFilter
        odsType={typeId}
        odsParentType={parentTypeId}
        label={'Уточнение типа'}
      />
      <GrbsIdFilter hidden={isChildOfImprovementObject} />
      <OkrugFilter />
      <CustomerFilter />
      <OwnerIdFilter />
    </FilterFF>
  );
};
