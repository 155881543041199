import {
  STAGE_RESOLUTION_NAME,
  StageResolution,
} from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

export { STAGE_RESOLUTION_NAME };

/**
 * ## Границы.
 *
 * `type = Select`
 * `name = STAGE_RESOLUTION_NAME`.
 *
 * @param {object} props - Props.
 * @param {boolean} [props.dark] - Темная тема.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли компонент для редактирования.
 * @param {object} [props.otherProps] - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const StageResolutionFF = ({
  dark = false,
  disabled = false,
  ...otherProps
}) => {
  return (
    <Field name={STAGE_RESOLUTION_NAME}>
      {(props) => {
        return (
          <StageResolution
            {...{ dark, disabled }}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};
