import { MafTypeLevel3RF } from 'core/form/reduxForm/fields/prepared/selects/simple/MafTypeLevel3.RF/MafTypeLevel3.RF';
import React from 'react';

import { useSetupMafTypeLevel3Field } from './useSetup.MafTypeLevel3.Field';

/**
 * Поле для МАФ __Детализация__.
 *
 * @returns {React.ReactElement}
 */
export const MafTypeLevel3Field = () => {
  const { disabled, filter } = useSetupMafTypeLevel3Field();

  return (
    <MafTypeLevel3RF filter={filter} disabled={disabled} withAll={false} />
  );
};
