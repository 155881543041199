import React from 'react';

interface IshowComponent {
  conditions: boolean;
  Component: React.ReactNode;
}

/**
 * Помощник отображения по условию.
 *
 * @param params - Параметры.
 * @param params.conditions - Условия.
 * @param params.Component - Компонент.
 * @returns React.ReactElement | null.
 */
export const showComponent = ({ conditions, Component }: IshowComponent) => {
  if (conditions) {
    return Component;
  }
  return null;
};
