import { Cell } from 'types/table';

/**
 * Колонка __Длина, пог.м__.
 *
 * @type {{
 * accessor: 'length',
 * Header: 'Длина, пог.м',
 * sortable: boolean
 * }}
 * @augments Cell
 */
export const length_pgm = {
  Header: 'Длина, пог.м',
  accessor: 'length',
  sortable: true,
};
