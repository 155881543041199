import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  BRIDGE_TYPE_LEVEL_2_ID_NAME,
  BridgeTypeLevel2Id,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/BridgeTypeLevel2Id';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * SelectFF __Назначение__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const BridgeTypeLevel2IdFF = (props: SelectPropsType) => {
  return (
    <FieldFF
      {...props}
      component={BridgeTypeLevel2Id}
      name={BRIDGE_TYPE_LEVEL_2_ID_NAME}
    />
  );
};
