/**
 * Дает текущее значение позиции элемента не выходя за минимальные максимальные рамки.
 *
 * @param current - Текущее положение.
 * @param min - Минимально возможно положение.
 * @param max - Максимально возможное положение.
 * @returns Возвращает либо текущую позицию, либо мин макс.
 */
export const getScopedPosition = (current = 0, min = 0, max = 0) => {
  return Math.min(Math.max(current, min), max);
};
