import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPreparedPropsType } from 'types/inputs';

export const IMPROVEMENT_OBJECT_CATEGORY_ID = 'improvement_object_category_id';

export interface IImprovementObjectCategoryData {
  code?: string;
  id?: number;
  name?: string;
  parent_ogh_object_type_list?: number[];
}
export interface IImprovementObjectCategoryIdProps
  extends SelectPreparedPropsType {
  filter?: (
    data: IImprovementObjectCategoryData[],
  ) => IImprovementObjectCategoryData[];
}

/**
 * Категория озеленения.
 *
 * @param props - The props.
 * @returns JSX.Element.
 */
export const ImprovementObjectCategoryId = (
  props: IImprovementObjectCategoryIdProps,
) => {
  const { data = [] } = useGetDictionary<IImprovementObjectCategoryData[]>(
    'improvement_object_category',
  );

  return (
    <Select
      {...props}
      name={'improvement_object_category_id'}
      label={'Категория озеленения'}
      options={data}
    />
  );
};
