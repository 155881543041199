import { useGetDictionary } from 'app/api/hooks/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const OBJECT_SERVICE_IDS_NAME = 'object_service_ids';

/**
 * MultiSelect __Обслуживание объекта__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const ObjectServiceIds = (props: SelectPropsType) => {
  const dict = 'object_service';
  const { data } = useGetDictionary(dict);

  return (
    // @ts-ignore
    <MultiSelect
      {...props}
      options={data || []}
      label={'Обслуживание объекта'}
      name={OBJECT_SERVICE_IDS_NAME}
    />
  );
};
