
import { cards } from 'app/pages/cardsOgh/cards';



/**
 * Проверка на новый паспорт.
 *
 * @param {number} typeId - Тип ОГХ.
 * @returns {boolean}
 */
export const checkIsNewCard = (typeId) => Boolean(cards[+typeId]);
