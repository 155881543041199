import { turnIntoCamelCase } from 'app/utils/turnIntoCamelCase';

import { useGetCardSelector } from './useGetCard.Selector';

interface CalcAttribute {
  asperity: number;
  auto_footway_area: number;
  autoFootwayArea: number;

  /**
   * Протяженность осевой разделительной линии, п.м.
   */
  axis_length: number;

  /**
   * Протяженность осевой разделительной линии, п.м.
   */
  axisLength: number;

  /**
   * Ограждающая (противотаранная) конструкция, ед.
   */
  anti_ram_barrier_num: number;
  barAntinoise: number;
  barConcrete: number;
  barFoot: number;
  barFracasso: number;
  barNewJersey: number;
  barNewJerseyX2: number;
  barPipe: number;
  barTrans: number;
  bar_antinoise: number;
  bar_concrete: number;
  bar_foot: number;
  bar_fracasso: number;
  bar_new_jersey: number;
  bar_new_jersey_x2: number;
  bar_pipe: number;
  bar_trans: number;
  bar_wave: number;
  barrier: number;

  /**
   * Дорожный блокиратор (боллард), ед.
   */
  bollard_num: number;
  barWave: number;
  bicycle_area: number;
  bicycle_length: number;
  bicycleArea: number;
  bicycleLength: number;
  bound_stone_length: number;
  boundStoneLength: number;
  buffer: number;
  cleaning_area: number;
  cleaning_guiding_length: number;
  cleaning_guiding_qty: number;
  cleaningArea: number;
  cleaningGuidingLength: number;
  cleaningGuidingQty: number;
  emergency_parking_area: number;
  emergencyParkingArea: number;
  engin_construct_area: number;
  engin_own_territory_area: number;
  engin_qty: number;
  enginConstructArea: number;
  enginOwnTerritoryArea: number;
  enginQty: number;
  fencing_area: number;
  fencing_own_territory_area: number;
  fencingArea: number;
  fencingOwnTerritoryArea: number;
  footway_area: number;
  footway_pole: number;
  footwayArea: number;
  footwayPole: number;
  guiding_area: number;
  guiding_length: number;
  guiding_qty: number;
  guidingArea: number;
  guidingLength: number;
  guidingQty: number;
  gutters_length: number;
  guttersLength: number;

  /**
   * Общая площадь в границах ОДХ, кв.м.
   */
  inbound_area: number;

  /**
   * Общая площадь в границах ОДХ, кв.м.
   */
  inboundArea: number;
  info: number;
  maf_area: number;
  maf_own_territory_area: number;
  mafArea: number;
  mafOwnTerritoryArea: number;
  manual_footway_area: number;
  manualFootwayArea: number;
  margin_area: number;
  margin_auto_clean_area: number;
  margin_clean_area: number;
  margin_manual_clean_area: number;
  marginArea: number;
  marginAutoCleanArea: number;
  marginCleanArea: number;
  marginManualCleanArea: number;
  navigation_pointer: number;
  navigationPointer: number;
  other_area: number;
  otherArea: number;
  pointer: number;
  road_mark_distance: number;
  roadMarkDistance: number;
  roadway_area: number;
  roadway_noprkg_auto_clean_area: number;
  roadway_noprkg_clean_area: number;
  roadway_noprkg_manual_clean_area: number;
  roadway_prkg_auto_clean_area: number;
  roadway_prkg_clean_area: number;
  roadway_prkg_manual_clean_area: number;
  roadwayArea: number;
  roadwayNoprkgAutoCleanArea: number;
  roadwayNoprkgCleanArea: number;
  roadwayNoprkgManualCleanArea: number;
  roadwayPrkgAutoCleanArea: number;
  roadwayPrkgCleanArea: number;
  roadwayPrkgManualCleanArea: number;
  sign: number;
  station_area: number;
  station_clean_area: number;
  station_number: number;
  station_qty: number;
  stationArea: number;
  stationCleanArea: number;
  stationNumber: number;
  stationQty: number;

  /**
   * Общая площадь в ТС, кв.м.
   */
  total_area: number;

  /**
   * Общая площадь в ТС, кв.м.
   */
  totalArea: number;
  tpu_area: number;
  tpuArea: number;
  traff_light_qty: number;
  traffic_signs_qty: number;
  trafficSignsQty: number;
  traffLightQty: number;
  tram_rails_detached_area: number;
  tram_rails_length: number;
  tramRailsDetachedArea: number;
  tramRailsLength: number;
}

/**
 * Selector получения карточки калькуляционных данных из state.
 *
 * @function
 * @category useSelector
 * @returns {CalcAttribute}
 */
export const useGetCardCalcAttributeSelector = (): CalcAttribute => {
  const { calcAttribute } = useGetCardSelector();
  const calcAttributeCamel = turnIntoCamelCase(calcAttribute);
  return {
    ...calcAttribute,
    ...calcAttributeCamel,
  };
};
