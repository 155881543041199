import { TimePickerFF } from 'core/form/finalForm/fields/default/timePickers/TimePicker.FF/TimePicker.FF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import React from 'react';
import { useField } from 'react-final-form';

/**
 * Тело формы редактирования времени.
 *
 * @class
 */
export const BodyWorkTimeCell = () => {
  const {
    input: { value: valueStartTime },
  } = useField('startTime');

  return (
    <FieldsSplitColumns>
      <TimePickerFF label={'Время с'} name={'startTime'} />
      <TimePickerFF
        label={'Время по'}
        name={'endTime'}
        minTime={valueStartTime}
      />
    </FieldsSplitColumns>
  );
};
