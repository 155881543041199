import { OWNER_ID_NAME } from 'core/uiKit/preparedInputs';
import { usePreparation } from 'core/utils/hooks/usePreparation';

/**
 * Хук перевода поля OWNER_ID_NAME.
 *
 */
export const usePreparationOwnerId = () => {
  usePreparation(OWNER_ID_NAME, (value) => {
    if (value) {
      return { legal_person: value.id };
    }
  });
};
