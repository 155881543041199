import { ODH, OZN, YARD } from 'app/constants/oghTypes';
import { PARENT_TYPE_ID_NAME } from 'app/pages/registry/ogh/MafRfid/filters/TypeIdOgh.Filter';
import { SUB_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/autocompleteAsync/SubTypeId';
import { useSkipFirstRender } from 'core/utils/hooks/useSkipFirstRender';
import { useField } from 'react-final-form';

const map = {
  [ODH]: 7,
  [OZN]: 37,
  [YARD]: 37,
};

/**
 * Setup Тип.
 *
 * @returns {object}
 */
export const useSetupSubTypeIdFilterField = () => {
  const {
    input: { value: parentTypeIdValue },
  } = useField(PARENT_TYPE_ID_NAME);

  const {
    input: { onChange },
  } = useField(SUB_TYPE_ID_NAME);

  useSkipFirstRender(() => {
    onChange('');
  }, [parentTypeIdValue]);

  return {
    disabled: !parentTypeIdValue,
    odsParentType: parentTypeIdValue,
    odsType: map[parentTypeIdValue],
  };
};
