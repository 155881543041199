import { CATEGORY_TOILET_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CategoryToiletId/CategoryToiletId';
import { usePreparation } from 'core/utils/hooks/usePreparation';

/**
 * Returns an object with 'category_toilet' property if 'value' is truthy.
 *
 * @returns
 */
export const usePreparationCategoryToiletId = () =>
  usePreparation(CATEGORY_TOILET_ID_NAME, (value) => {
    if (value) {
      return { category_toilet: value };
    }
  });
