import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  BRIDGE_STATE_ID_NAME,
  BridgeStateId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/BridgeStateId';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

/**
 * SelectFF __Состояние__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const BridgeStateIdFF = (props: SelectPropsType) => {
  return (
    <FieldFF {...props} component={BridgeStateId} name={BRIDGE_STATE_ID_NAME} />
  );
};
