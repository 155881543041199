import { FilterFF } from 'core/form/filterForm/FilterFF';
import React from 'react';

import { IFilterQueryParams } from '../hooks/useFilterQueryParams';

interface IRegistryFilterFormFF {
  children: React.ReactNode;

  /**
   * Хук QueryParams для фильтра.
   */
  useFilterQueryParams: () => IFilterQueryParams;
}

/**
 * Обертка для фильтра реестра.
 *
 * @param props - Пропсы.
 * @param props.children - Поля фильтрации реестра.
 * @param props.useFilterQueryParams - Хук получения фильтров, и функции изменения фильтра.
 * @returns Фильтра.
 */
export const RegistryFilterFormFF = ({
  children,
  useFilterQueryParams,
}: IRegistryFilterFormFF) => {
  const { setFilters, filters } = useFilterQueryParams();

  /**
   * Функция submit фильтра формы FF.
   *
   * @param values - Значения формы.
   */
  const onSubmit = (values: object) => {
    setFilters(values, 'push');
  };

  /**
   * Функция сброса формы FF.
   *
   * @param {object} form - Параметры формы.
   * @param {Function} form.reset - Функция сброса формы FF.
   */
  const reset = ({ reset }: { reset: () => void }) => {
    reset();
    setFilters({}, 'replace');
  };

  return (
    <FilterFF initialValues={filters} reset={reset} onSubmit={onSubmit}>
      {children}
    </FilterFF>
  );
};
