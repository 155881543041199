import { useGetDictionary } from 'app/api/hooks/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const PUBLIC_TOILET_TYPE_ID_NAME = 'public_toilet_type_id';

interface IPublicToiletTypeData {
  id: number;
  name: string;
}

/**
 * Select __Тип ОТ__.
 *
 * @param props - Пропсы.
 * @returns Select Тип ОТ.
 */
export const PublicToiletTypeId = (props: SelectPropsType) => {
  const dict = 'public_toilet_type';

  const { data } = useGetDictionary<IPublicToiletTypeData[]>(dict);

  return (
    <Select
      {...props}
      options={data || []}
      label={'Тип ОТ'}
      name={PUBLIC_TOILET_TYPE_ID_NAME}
    />
  );
};
