import './DragWrapperTooltip.scss';

import React from 'react';

import { IconDrag } from './IconDrag';

interface DragWrapperTooltipProps {
  children: React.ReactNode;
  id: string;
}

/**
 * Обертка за что перетаскивать.
 *
 * @param props - Пропсы.
 * @param props.children - Элемент который будем перетаскивать.
 * @param props.id - Идентификатор для связи с селектором.
 * @returns
 */
export const DragWrapperTooltip = ({
  children,
  id,
  ...props
}: DragWrapperTooltipProps) => {
  return (
    <div {...props} className={'drag-wrapper'}>
      <div id={id}>
        <div className={'drag-wrapper__tooltip'}>
          <div className={'icon-drag__wrapper'} title={'Перетащите элемент'}>
            <IconDrag />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};
