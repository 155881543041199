import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import React from 'react';

import { MafQuantityCharacteristicsField } from './fields/MafQuantityCharacteristics.Field';
import { MafTypeLevel1IdField } from './fields/MafTypeLevel1Id.Field';
import { MafTypeLevel2IdField } from './fields/MafTypeLevel2Id.Field';
import { MafTypeLevel3Field } from './fields/MafTypeLevel3.Field';

/**
 * MafType.
 *
 * @param {object} props - Property.
 * @returns {JSX}
 */
const MafType = (props) => {
  const { editable, isEditCurrentObjectIsExternalSystem, required } = props;

  return (
    <FieldsSplitColumns>
      <MafTypeLevel1IdField
        disabled={!(editable && isEditCurrentObjectIsExternalSystem)}
        required={required}
      />
      <MafTypeLevel2IdField />
      <MafTypeLevel3Field />
      <MafQuantityCharacteristicsField />
    </FieldsSplitColumns>
  );
};

export default MafType;
