import { PlanarStructureTypeRefIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/PlanarStructureTypeRefId.FF';
import { PLANAR_STRUCTURE_TYPE_REF_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/PlanarStructureTypeRefId';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * @typedef {import('use-query-params').NumberParam} NumberParam
 */

/**
 * Уточнение.
 *
 * @returns {JSX.Element} - JSX.
 * @example --------
 */
export const PlanarStructureTypeRefIdFilter = () => (
  <PlanarStructureTypeRefIdFF className={filterInput} withAll={false} />
);

/**
 * QueryParam Уточнение.
 *
 * @returns {{PLANAR_STRUCTURE_TYPE_REF_NAME: NumberParam}}
 */
export const planarStructureTypeRefIdParams = () => ({
  [PLANAR_STRUCTURE_TYPE_REF_ID_NAME]: NumberParam,
});
