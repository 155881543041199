import { GetBalanceDateFF } from 'core/form/finalForm/fields/prepared/selects/simple/GetBalanceDate.FF';
import { GET_BALANCE_DATE_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/GetBalanceDate';
import React from 'react';
import { StringParam } from 'use-query-params';

import { filterInput } from '../../filterInputsClasses';

/**
 * Фильтр __Состояние__.
 *
 * @returns {JSX.Element}
 * @class
 */
export const GetBalanceDateFilter = () => {
  return <GetBalanceDateFF className={filterInput} />;
};

export const getBalanceDateParams = {
  [GET_BALANCE_DATE_NAME]: StringParam,
};
