import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import { TimePicker } from 'core/uiKit/inputs/timePickers/TimePicker';
import React from 'react';

interface ITimePickerFF {
  name: string;
  label?: string;
  minTime?: string;
  maxTime?: string;
}

/**
 * Default FF TimePicker.
 *
 * @param props - Пропсы.
 * @param props.name - Имя поля для FF формы.
 * @returns
 */
export const TimePickerFF = ({ name, ...otherProps }: ITimePickerFF) => {
  return <FieldFF {...otherProps} component={TimePicker} name={name} />;
};
