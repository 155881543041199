import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  PLACEMENT_NAME_NAME,
  PlacementName,
} from 'core/uiKit/preparedInputs/textField/PlacementName';
import React from 'react';
import { InputPreparedProps } from 'types/inputs';

/**
 * TextField FF __Местоположение__.
 *
 * @param props - Пропсы.
 * @returns FieldFF.
 */
export const PlacementNameFF = (props: InputPreparedProps) => {
  return (
    <FieldFF {...props} component={PlacementName} name={PLACEMENT_NAME_NAME} />
  );
};
