import {
  MATCHING_CHANGE_TYPES_NAME,
  MatchingChangeTypes,
} from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * ## Операция.
 *
 * ### React Final Form Field
 *
 * `type = MultiSelect`
 * `name = matchingChangeTypes`
 * `dict = matchingChangeType`.
 *
 * @param {object} props - Props.
 * @param {boolean} [props.dark] - Темная тема.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли компонент для редактирования.
 * @param {string} [props.errorText] - Текст ошибки.
 * @param {Function} [props.filter] - Фильтр.
 * @param {object} [props.otherProps] - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const MatchingChangeTypesFF = ({
  dark = false,
  disabled = false,
  errorText = '',
  filter = (_) => _,
  ...otherProps
}) => {
  return (
    <Field name={MATCHING_CHANGE_TYPES_NAME}>
      {(props) => {
        return (
          <MatchingChangeTypes
            {...{ dark, disabled, errorText, filter }}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { MATCHING_CHANGE_TYPES_NAME };
