import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select/Select';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

export const PLACEMENT_ID_NAME = 'placement_id';

/**
 * Место размещения (наименование плоскостного элемента).
 *
 *
 * `type = Select`.
 *
 *
 * `name = PLACEMENT_ID_NAME`
 * `name = placement_id`.
 * ```js
 * import { PlacementId, PLACEMENT_ID_NAME } from 'core/uiKit/preparedInputs';
 * ```
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const PlacementId = (props) => {
  const dict = 'placement';

  const { data = [] } = useGetDictionary(dict);
  const preparedData = useMemo(() => {
    return data.map(({ id, attribute }) => ({
      id,
      ...attribute,
    }));
  }, [data]);

  return (
    <Select
      {...props}
      options={preparedData}
      label={
        props.label ?? 'Место размещения (наименование плоскостного элемента)'
      }
      name={PLACEMENT_ID_NAME}
    />
  );
};

PlacementId.propTypes = {

  /**
   * Очищаем инпут с сохранением значения.
   */
  clear: PropTypes.bool,

  /**
   * Выбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Вывод вспомогательного текста.
   */
  helperText: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение.
   */
  value: PropTypes.any,
};
