import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  MAF_TYPE_LEVEL_2_ID_NAME,
  MafTypeLevel2,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/MafTypeLevel2';

/**
 * Select Final Form. Уточнение.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 */
export const MafTypeLevel2FF = (props) => {
  return (
    <FieldFF
      {...props}
      name={MAF_TYPE_LEVEL_2_ID_NAME}
      component={MafTypeLevel2}
    />
  );
};
