import { Table } from 'core/uiKit/components/tables/Table';
import React from 'react';

import { getColumnsPT } from './columns.PT';
import { useSetupTablePT } from './useSetup.Table.PT';

/**
 * Таблица __Общественных туалетов__.
 *
 * @param props - Пропсы.
 * @param props.typeId - Тип Общественных туалетов.
 * @returns Таблицу общественных туалетов.
 */
export const TablePT = ({ typeId }: { typeId: number }) => {
  const { count, isLoading, table } = useSetupTablePT({ typeId });

  return (
    <Table
      columns={getColumnsPT()}
      data={table}
      total={count}
      isLoading={isLoading}
    />
  );
};
