import { useGetDictionary } from 'app/api/hooks/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const OBJECT_SERVICE_ID_NAME = 'object_service_id';

/**
 * Select __Обслуживание объекта__.
 *
 * @param {object} props - Пропсы.
 * @returns Select.
 */
export const ObjectServiceId = (props: SelectPropsType) => {
  const dict = 'object_service';
  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      {...{
        label: 'Обслуживание объекта',
        name: OBJECT_SERVICE_ID_NAME,
        options: data,
      }}
    />
  );
};
