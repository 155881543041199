import { FORM_VISIT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/FormVisitId/FormVisitId';
import { usePreparation } from 'core/utils/hooks/usePreparation/preparation';

/**
 * Returns an object with 'form_visit' property if 'value' is truthy.
 *
 * @returns
 */
export const usePreparationFormVisitId = () =>
  usePreparation(FORM_VISIT_ID_NAME, (value) => {
    if (value) {
      return { form_visit: value };
    }
  });
