import './Item.FileGrid.KnowledgeBase.scss';

import cn from 'classnames';
import { VIDEO_API_URL } from 'config/env';
import { Button } from 'core/uiKit/components/buttons';
import { Icon, IconButton } from 'core/uiKit/material-ui';
import React from 'react';

import { useSetupItemFileGridKnowledgeBase } from './useSetup.Item.FileGrid.KnowledgeBase';

interface ItemFileGridKnowledgeBaseProps {
  file: { file_id: string; file_name: string };

  /**
   * Флаг редактирования.
   */
  disabled?: boolean;
  deleteFile: (fileId: string) => void;
  key: string;
}

/**
 * Плитка файла.
 *
 * @param props - Пропсы.
 * @param props.deleteFile - Функция удаления файла.
 * @param props.file - Файл.
 * @param props.key - Ключ.
 * @param props.disabled - Флаг редактирования.
 * @returns ItemFileGridKnowledgeBase.
 */
export const ItemFileGridKnowledgeBase = ({
  file,
  deleteFile,
  disabled,
  key,
}: ItemFileGridKnowledgeBaseProps) => {
  const { isOpenDialog, openDialog, isVideoFile, closeDialog, delFile } =
    useSetupItemFileGridKnowledgeBase({ deleteFile, file });

  return (
    <>
      {isOpenDialog ? (
        <dialog open={true} className="item_file_grid_knowledge_base__dialog">
          <div className="d-flex justify-content-end">
            <Button onClick={closeDialog}>Закрыть</Button>
          </div>
          <video controls className="item_file_grid_knowledge_base__video">
            <source
              src={`${VIDEO_API_URL}/${file?.file_id}`}
              type="video/mp4"
            />
          </video>
        </dialog>
      ) : null}
      <div key={key} className="item_file_grid_knowledge_base__container">
        <div
          onClick={openDialog}
          className={cn(' item_file_grid_knowledge_base__name', {
            'item_file_grid_knowledge_base__name--video': isVideoFile,
          })}
        >
          {file?.file_name}
        </div>
        {disabled ? null : (
          <IconButton
            data-testId={`deleteIconList-${file?.file_id}`}
            className={'item_file_grid_knowledge_base__delete-file'}
            onClick={delFile}
            title="Удалить файл"
            size={'small'}
          >
            <Icon color={'action'}>close</Icon>
          </IconButton>
        )}
      </div>
    </>
  );
};
