import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  COATING_GROUP_ID_NAME,
  CoatingGroupId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';
import React from 'react';
import { SelectFFPreparedPropsType } from 'types/inputs';

import { usePreparationCoatingGroupIdFF } from './usePreparation.CoatingGroupId.FF';

/**
 * Select FF __Вид покрытия__.
 *
 * @param props - Пропсы.
 * @returns JSX.Element.
 */
export const CoatingGroupIdFF = (props: SelectFFPreparedPropsType) => {
  usePreparationCoatingGroupIdFF();
  return (
    <FieldFF
      {...props}
      name={COATING_GROUP_ID_NAME}
      component={CoatingGroupId}
    />
  );
};
