import { useGetDictionary } from 'app/api/hooks/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPropsType } from 'types/inputs';

export const BRIDGE_STATE_ID_NAME = 'bridge_state_id';

/**
 * Select __Состояние__.
 *
 * @param {*} props - Properties.
 * @returns Select.
 */
export const BridgeStateId = (props: SelectPropsType) => {
  const dict = 'bridge_state';
  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      name={BRIDGE_STATE_ID_NAME}
      {...{
        ...props,
        label: props?.label ?? 'Состояние',
        options: data,
      }}
    />
  );
};
